import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../core/services/notification.service';
import {WebSocketEventInterface} from '../core/models/web-socket-event.interface';
import {User} from '../core/models/user.interface';
import {AuthService} from '../core/services/auth.service';
import {NotifyUsers} from '../core/models/notify-users.interface';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import {delay, Notification, retryWhen, take} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../core/enums/snackbar-action.enum';
import {format} from 'date-fns';
@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
	userNotifications: NotifyUsers[] | null;
	user: User | null;

	constructor(
		private notificationService: NotificationService,
		private authService: AuthService,
		private router: Router,
		private dialog: MatDialog,
		private snackbar: MatSnackBar
	) {}

	ngOnInit() {
		this.authService.getCurrentUser().subscribe((response) => {
			this.user = response.user;
			this.userNotifications = response.notifications;
			if (response.notifications.some((notification) => notification.active)) this.showNotificationModal();

			this.notificationService.sub.pipe(retryWhen((errors) => errors.pipe(delay(2000)))).subscribe((response: WebSocketEventInterface) => {
				if (Array.isArray(response.data) && response.event === 'get-user-notifications') {
					response.data.forEach((notification: NotifyUsers): void => {
						this.userNotifications?.push(notification);
					});
				}
			});
		});
	}

	deleteNotification(notification: NotifyUsers, event: any) {
		event.stopPropagation();
		const found = this.userNotifications?.find((notif) => notif.id === notification.id);
		if (found) {
			this.notificationService
				.deleteNotification(found.id)
				.pipe(take(1))
				.subscribe(
					(res) => {
						this.snackbar.open('Notification Deleted!', SnackbarActionEnum.SUCCESS);
						const index = this.userNotifications?.indexOf(found);
						this.userNotifications?.splice(index!, 1);
					},
					(err) => {
						this.snackbar.open('Error Deleting Notification!', SnackbarActionEnum.ERROR);
					}
				);
		}
	}

	notifBadge(): number | undefined {
		return this.userNotifications?.filter((each: NotifyUsers) => each.active).length;
	}

	async setNotificationsAsRead(): Promise<void> {
		//find all active notifications
		const activeNotifications: NotifyUsers[] | undefined = this.userNotifications?.filter((each: NotifyUsers) => each.active);
		if (activeNotifications?.length) {
			this.notificationService.markNotificationsAsRead(activeNotifications).subscribe((response: NotifyUsers[]): void => {
				this.userNotifications = response;
			});
		}
	}

	routeToRelatedPage(projectId: number): void {
		this.router.navigate([`/project/${projectId}/overview`]);
	}

	showNotificationModal() {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			disableClose: true,
			data: {
				title: 'You have new notifications!',
				message: 'To view your notifications, click the bell icon in the top right of your screen.'
			}
		});
		dialogRef.componentInstance.notificationMode = true;
	}

	clearAllNotifications() {
		if (this.userNotifications?.length) {
			this.notificationService.deleteAllNotifications().subscribe(
				(res) => {
					this.userNotifications = [];
					this.snackbar.open('Notifications Deleted!', SnackbarActionEnum.SUCCESS);
				},
				(err) => this.snackbar.open('Error deleting notifications!', SnackbarActionEnum.ERROR)
			);
		}
	}
}
