import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShipmentComponent} from './projects/project-freight/shipment/shipment.component';
import {LoginComponent} from './core/components/login/login.component';
import {ProjectComponent} from './project/project.component';
import {ProjectsComponent} from './projects/projects.component';
import {ProjectRackSolidComponent} from './projects/project-rack-solid/project-rack-solid.component';
import {ProjectQuoteComponent} from './projects/project-quote/project-quote.component';
import {ProjectCreateComponent} from './project-create/project-create.component';
import {ProjectFreightComponent} from './projects/project-freight/project-freight.component';
import {ProjectDraftingComponent} from './project/project-drafting/project-drafting.component';
import {ProjectOverviewComponent} from './project/project-overview/project-overview.component';
import {ProjectActivityComponent} from './project/project-activity/project-activity.component';
import {ProjectSchedulingComponent} from './projects/project-scheduling/project-scheduling.component';
import {ProjectEstimateComponent} from './projects/project-estimate/project-estimate.component';
import {CalendarComponent} from './calendar/calendar.component';
import {UsersListComponent} from './maintenance/users/users-list/users-list.component';
import {QuoteHeaderListComponent} from './quote/quote-header-list/quote-header-list.component';
import {QuoteComponent} from './quote/quote.component';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';
import {PriceLevelListComponent} from './maintenance/price-level/price-level-list/price-level-list.component';
import {ContactsListComponent} from './maintenance/contacts/contacts-list/contacts-list.component';
import {BrokersListComponent} from './maintenance/brokers/brokers-list/brokers-list.component';
import {TransitTimeListComponent} from './maintenance/transit-time/transit-time-list/transit-time-list.component';
import {RolesListComponent} from './maintenance/roles/roles-list/roles-list.component';
import {PriceConfigurationListComponent} from './maintenance/price-configuration/price-configuration-list/price-configuration-list.component';
import {StatusListComponent} from './maintenance/statuses/status-list/status-list.component';
import {CodesListComponent} from './maintenance/codes/codes-list/codes-list.component';
import {ShippingLogComponent} from './shipping-log/shipping-log.component';
import {ConfirmNavigateAwayGuard} from './shared/guards/confirm-navigate-away.guard';
import {GlCodesListComponent} from './maintenance/gl-codes/gl-codes-list/gl-codes-list.component';
import {TaskTypeListComponent} from './maintenance/task-type/task-type-list/task-type-list.component';
import {AdminGuard} from './core/guards/admin.guard';
import {InstallCalendarComponent} from './install-calendar/install-calendar.component';
import {ProjectPicklistComponent} from './projects/project-picklist/project-picklist.component';
import {ProjectActiveGuard} from './core/guards/project-active.guard';
import {QuoteEmailListComponent} from './quote/quote-email-list/quote-email-list.component';
import {AuditLogComponent} from './core/components/audit-log/audit-log.component';
import {QuickQuoteComponent} from './quick-quote/quick-quote.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'projects',
		pathMatch: 'full'
	},
	{path: 'login', component: LoginComponent},
	{path: 'forgot-password', component: ChangePasswordRequestComponent},
	{path: 'new-login', component: FirstLoginResetPasswordComponent},
	{path: 'audit-log', component: AuditLogComponent},
	{
		path: 'project/:projectId',
		component: ProjectComponent,
		canActivate: [ProjectActiveGuard],
		children: [
			{
				path: '',
				redirectTo: 'overview',
				pathMatch: 'full'
			},
			{
				path: 'overview',
				component: ProjectOverviewComponent
			},
			{
				path: 'activity',
				component: ProjectActivityComponent
			},
			{
				path: 'drafting',
				component: ProjectDraftingComponent,
				canDeactivate: [ConfirmNavigateAwayGuard]
			},
			{
				path: 'quote',
				children: [
					{
						path: '',
						component: QuoteHeaderListComponent
					},
					{
						path: ':quoteId/email',
						component: QuoteEmailListComponent
					},
					{
						path: 'new',
						component: ProjectQuoteComponent,
						canDeactivate: [ConfirmNavigateAwayGuard]
					},
					{
						path: ':quoteId',
						component: ProjectQuoteComponent,
						canDeactivate: [ConfirmNavigateAwayGuard]
					},
					{
						path: ':quoteId/copy',
						component: ProjectQuoteComponent,
						canDeactivate: [ConfirmNavigateAwayGuard]
					},
					{
						path: ':quoteId/requote',
						component: ProjectQuoteComponent,
						canDeactivate: [ConfirmNavigateAwayGuard]
					},
					{
						path: ':quoteId/create-order',
						component: ProjectQuoteComponent,
						canDeactivate: [ConfirmNavigateAwayGuard]
					},
					{
						path: ':quoteId/revision',
						component: ProjectQuoteComponent,
						canDeactivate: [ConfirmNavigateAwayGuard]
					}
				]
			},
			{
				path: 'freight',
				children: [
					{path: '', component: ProjectFreightComponent},
					{path: 'shipment', component: ShipmentComponent, children: [{path: ':shipmentId', component: ShipmentComponent}]}
				]
			},
			{
				path: 'create-order',
				component: ProjectQuoteComponent
			},
			{
				path: 'estimate',
				component: ProjectEstimateComponent
			},
			{
				path: 'scheduling',
				component: ProjectSchedulingComponent
			},
			{
				path: 'picklist',
				component: ProjectPicklistComponent
			}
		]
	},
	{path: 'projects', component: ProjectsComponent},
	{path: 'create-project', component: ProjectCreateComponent},
	{path: 'quick-quote', component: QuickQuoteComponent},
	{
		path: 'quote',
		component: QuoteComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				component: QuoteHeaderListComponent
			},
			{
				path: 'new',
				component: ProjectQuoteComponent
			}
		]
	},
	{path: 'rack-solid', component: ProjectRackSolidComponent},
	{path: 'calendar', component: CalendarComponent},
	{path: 'users', component: UsersListComponent, canActivate: [AdminGuard]},
	{path: 'price-level', component: PriceLevelListComponent, canActivate: [AdminGuard]},
	{path: 'price-configuration', component: PriceConfigurationListComponent, canActivate: [AdminGuard]},
	{path: 'codes', component: CodesListComponent, canActivate: [AdminGuard]},
	{path: 'brokers', component: BrokersListComponent},
	{path: 'statuses', component: StatusListComponent, canActivate: [AdminGuard]},
	//	{path: 'transit-times', component: TransitTimeListComponent, canActivate: [AdminGuard]},
	{path: 'roles', component: RolesListComponent, canActivate: [AdminGuard]},
	{path: 'contacts', component: ContactsListComponent, canActivate: [AdminGuard]},
	{path: 'gl-codes', component: GlCodesListComponent, canActivate: [AdminGuard]},
	{path: 'task-types', component: TaskTypeListComponent, canActivate: [AdminGuard]},
	{
		path: 'freight',
		children: [
			{path: '', component: ProjectFreightComponent},
			{path: 'shipment', component: ShipmentComponent, children: [{path: ':shipmentId', component: ShipmentComponent}]}
		]
	},
	{path: 'shipping-log', component: ShippingLogComponent},
	{path: 'install-calendar', component: InstallCalendarComponent},
	{path: '**', redirectTo: '/projects'}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
