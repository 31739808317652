import {NavigationExtras} from '@angular/router';

export interface navItem {
	routerLink: string;
	icon: string;
	text: string;
	extras?: NavigationExtras;
}
export const navData: navItem[] = [
	{
		routerLink: '/projects',
		icon: 'format_list_bulleted',
		text: 'Projects'
	},
	{
		routerLink: '/quote',
		icon: 'request_quote',
		text: 'Quotes',
		extras: {queryParams: {projectId: null}}
	},
	{
		routerLink: '/calendar',
		icon: 'format_list_bulleted',
		text: 'Calendar'
	},
	{
		routerLink: '/freight',
		icon: 'format_list_bulleted',
		text: 'Freight'
	},
	{
		routerLink: '/install-calendar',
		icon: 'format_list_bulleted',
		text: 'Installs'
	}
];
