<mat-card [formGroup]="contactForm" class="flex flex-col p-4 space-y-2">
	<mat-card-title> Send Approval Request </mat-card-title>

	<mat-label> {{ clientName }} </mat-label>

	<app-contacts
		(createContactEvent)="hideMessageInput = $event"
		[clientId]="erpClientId"
		[selectedContacts]="contacts"
    [resend]="resend"
    [quoteId]="quoteId"
		(selectedContactsChanged)="selectionChanged($event)"
	>
	</app-contacts>

	<mat-form-field appearance="outline" floatLabel="always">
		<mat-label> Subject </mat-label>
		<input formControlName="subject" matInput />
	</mat-form-field>

	<mat-form-field appearance="outline" floatLabel="always" *ngIf="!hideMessageInput">
		<mat-label> Email Message </mat-label>
		<textarea [placeholder]="DEFAULT_MESSAGE" formControlName="message" matInput></textarea>
	</mat-form-field>

	<div class="flex flex-row space-x-2 justify-end" *ngIf="!hideMessageInput">
		<button mat-flat-button (click)="close()">CANCEL</button>
		<button mat-flat-button color="primary" (click)="onSubmit()">{{resend ? 'RESEND' : 'REQUEST APPROVAL'}}</button>
	</div>
</mat-card>
