import {Component, OnDestroy, OnInit, ViewChild, Output, EventEmitter, Optional} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {Client} from '../../core/models/client.interface';
import {ClientService} from '../../core/services/client.service';
import {ClientPreview} from '../../core/models/client-preview.interface';
import {MatDialogRef} from '@angular/material/dialog';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatInput} from '@angular/material/input';
import {MatFormField} from '@angular/material/form-field';

@Component({
	selector: 'app-client-search',
	templateUrl: './client-search.component.html',
	styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: false}) sort: MatSort;
	@ViewChild('prospectCheckbox') prospectCheckbox: MatCheckbox;
	@ViewChild(MatInput, {static: false}) input: MatInput;
	@Output('prospectSelected') prospectSelected: EventEmitter<boolean> = new EventEmitter();
	@Output() selectedClientEmitter: EventEmitter<Client> = new EventEmitter<Client>();
	@Output() modalClientEmitter: EventEmitter<Client> = new EventEmitter<Client>();
	selectedClient: Client | undefined;
	clientList: ClientPreview[];
	clientsSubscription: Subscription;
	isLoadingClients: boolean = true;
	displayedColumns: string[] = ['select', 'id', 'contactName', 'location'];
	dataSource: MatTableDataSource<any>;
	modalMode: boolean = false;

	constructor(private clientService: ClientService, @Optional() public dialogRef: MatDialogRef<ClientSearchComponent>) {}

	ngOnInit() {
		this.clientsSubscription = this.clientService.findAllFromErp().subscribe((response) => {
			this.clientList = response;
			this.dataSource = new MatTableDataSource(response);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
			this.isLoadingClients = false;
		});
	}

	setSelectedClient(client: Client) {
		if (this.selectedClient && this.selectedClient.id === client.id) {
			this.selectedClient = undefined;
		} else {
			this.selectedClient = client;
		}
		this.selectedClientEmitter.emit(this.selectedClient);
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	selectModalClient() {
		if (this.selectedClient) {
			this.modalClientEmitter.emit(this.selectedClient);
			this.dialogRef.close();
		}
	}

	prospectSelect() {
		if (this.prospectCheckbox.checked) {
			this.input.value = 'Prospect';
			this.input.readonly = true;
		} else {
			this.input.value = '';
			this.input.readonly = false;
		}
		this.prospectSelected.emit(this.prospectCheckbox.checked);
	}

	stopPropagation(event: Event) {
		event.stopPropagation();
	}

	ngOnDestroy(): void {
		this.clientsSubscription.unsubscribe();
	}
}
