<div class="p-2 pb-0">
	<h2>Create Install Location</h2>
	<button mat-dialog-close="true" class="absolute top-2 right-2">
		<mat-icon aria-label="Close dialog">close</mat-icon>
	</button>

	<mat-dialog-content class="m-2" [formGroup]="form">
		<div class="flex gap-4">
			<mat-form-field class="pt-2">
				<mat-label>Name</mat-label>
				<input matInput formControlName="name" />
			</mat-form-field>
		</div>
	</mat-dialog-content>
	<div class="flex justify-end p-4">
		<button mat-button mat-dialog-close="true" class="mr-2">Cancel</button>
		<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createLocation()">Create</button>
	</div>
</div>
