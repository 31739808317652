import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Calendar} from '../models/calendar.interface';

@Injectable({
	providedIn: 'root'
})
export class CalendarService {
	baseUrl: string = `${environment.url}/calendar`;
	constructor(private http: HttpClient) {}

	getCalendar(selectedDate: Date | null = null): Observable<Calendar> {
		let params = new HttpParams();
		if (selectedDate) {
			params = params.append('selectedDate', selectedDate.toISOString().substring(0, 10));
		}
		return this.http.get<Calendar>(`${this.baseUrl}`, {params: params});
	}
}
