import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-change-request-info',
  templateUrl: './change-request-info.component.html',
  styleUrls: ['./change-request-info.component.scss']
})
export class ChangeRequestInfoComponent {
    changeRequestEmail: string = '';
    changeRequestReason: string = '';
    changeRequestDescription: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) data: ChangeRequestInfoComponent,
                private dialog: MatDialogRef<ChangeRequestInfoComponent>) {
      this.changeRequestEmail = data.changeRequestEmail;
      this.changeRequestReason = data.changeRequestReason;
      this.changeRequestDescription = data.changeRequestDescription;
    }

    closeModal(): void {
      this.dialog.close();
    }
}
