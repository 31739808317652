<h1 mat-dialog-title>Halt Project</h1>
<div mat-dialog-content class="min-h-[250px]">
	<mat-form-field appearance="outline" class="pt-2 w-full">
		<mat-label>Reason</mat-label>
		<textarea cdkTextareaAutosize cdkAutosizeMinRows="8" [(ngModel)]="haltReason" matInput type="text" class="w-full"></textarea>
	</mat-form-field>
</div>
<div class="flex justify-end mt-4">
	<button (click)="closeModal()" class="mr-2" mat-button color="primary">Cancel</button>
	<button (click)="haltProject()" mat-flat-button color="warn" class="mr-4 mb-4">Halt</button>
</div>
