import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {OrderHeader} from '../models/order-header.model';
import {SalesOrderResponse} from '../models/sales-order-response.model';

@Injectable({
	providedIn: 'root'
})
export class SalesOrderService {
	baseUrl: string = `${environment.url}/order`;
	constructor(private http: HttpClient) {}

	create(order: OrderHeader): Observable<OrderHeader> {
		return this.http.post<OrderHeader>(this.baseUrl, order);
	}

	findAll(): Observable<OrderHeader[]> {
		return this.http.get<OrderHeader[]>(this.baseUrl);
	}

	findOne(id: number): Observable<OrderHeader> {
		return this.http.get<OrderHeader>(`${this.baseUrl}/${id}`);
	}

	update(orderNumber: string, order: SalesOrderResponse): Observable<SalesOrderResponse> {
		return this.http.put<SalesOrderResponse>(`${this.baseUrl}/${orderNumber}`, order);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}
}
