<div *ngIf="isLoading | async" class="w-full h-full flex justify-center m-2">
	<mat-spinner></mat-spinner>
</div>
<mat-dialog-content>
	<div [ngClass]="{hidden: (isLoading | async)}">
		<div class="flex flex-row mx-4 m-4 w-full justify-between">
			<mat-form-field appearance="outline" class="w-1/4">
				<mat-label>Load Rack Solid Template</mat-label>
				<mat-select [(ngModel)]="selectedTemplate" (selectionChange)="templateSelection($event.value)">
					<mat-option *ngFor="let template of templateOptions" [value]="template">{{ template.description }}</mat-option>
					<mat-option *ngIf="templateOptions.length === 0" disabled>No Templates for Client</mat-option>
				</mat-select>
			</mat-form-field>
			<div class="flex">
				<div matTooltip="Print not supported on Firefox" [matTooltipDisabled]="!isFirefox" matTooltipPosition="above">
					<button
						class="mr-4"
						mat-raised-button
						color="primary"
						ngxPrint
						[printTitle]="printTitle()"
						printSectionId="quoteForm"
						[useExistingCss]="true"
						[disabled]="isFirefox"
					>
						<mat-icon>print</mat-icon>
						Print
					</button>
				</div>
				<div class="mt-2 mr-8 scale-150">
					<button (click)="dialogRef.close()" matTooltip="Discard changes and close"><mat-icon>close</mat-icon></button>
				</div>
			</div>
		</div>

		<div id="quoteForm" [formGroup]="quoteForm" class="m-4">
			<div class="hidden print:flex xl:flex-row flex-col w-full xl:space-x-4 sticky">
				<div class="flex flex-col w-full xl:w-1/2">
					<span class="text-xl m-4">{{ printTitle() }}</span>
				</div>
			</div>
			<div class="flex xl:flex-row flex-col w-full xl:space-x-4">
				<div class="flex flex-col w-full xl:w-1/2">
					<span class="text-xl ml-4 mb-2">Configurator</span>
					<mat-accordion>
						<mat-expansion-panel expanded="true" class="print:mat-expanded">
							<mat-expansion-panel-header>
								<mat-panel-title class="font-bold"> Options </mat-panel-title>
							</mat-expansion-panel-header>

							<div class="flex flex-row flex-wrap space-x-2">
								<div class="flex flex-row space-x-2 w-full ml-4">
									<span class="font-bold mt-3">Price Level:</span>
									<span class="mt-3">{{ this.priceLevel }}</span>
									<mat-checkbox formControlName="isShelvingOnly"> Shelving Only </mat-checkbox>
									<mat-checkbox formControlName="needsExtraParts"> Extra Parts </mat-checkbox>
								</div>

								<div class="flex flex-col">
									<div class="flex flex-row mb-2">
										<mat-label class="ml-4"># of Static Units</mat-label>
									</div>

									<div class="flex flex-row flex-wrap space-x-2">
										<mat-form-field appearance="outline" class="ml-2">
											<mat-label>Starters</mat-label>
											<input matInput type="number" min="0" formControlName="staticStarters" />
										</mat-form-field>

										<mat-form-field appearance="outline">
											<mat-label>Adders</mat-label>
											<input matInput type="number" min="0" formControlName="staticAdders" />
										</mat-form-field>

										<mat-form-field appearance="outline">
											<mat-label>B2B Kits</mat-label>
											<input min="0" matInput formControlName="b2bKits" />
										</mat-form-field>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>

					<app-quote-config
						*ngFor="let configForm of configs.controls; let i = index"
						formArrayName="configs"
						[index]="i"
						[typeOptions]="typeOptions"
						[partArray]="shelves"
						(copyConfigEvent)="copyConfig($event)"
						(deleteConfigEvent)="deleteConfig($event)"
					></app-quote-config>

					<div class="flex flex-row justify-end mt-2 print:hidden">
						<button mat-raised-button color="primary" (click)="addConfig()">ADD CONFIG</button>
					</div>
				</div>

				<div class="flex flex-col w-full xl:w-1/2 page-break">
					<span class="text-xl ml-4 mb-2">Part List</span>
					<app-part-list
						class="mat-elevation-z2 py-4"
						*ngIf="partCatalog | async as parts"
						[quote]="quoteForm.valueChanges | async"
						[partCatalog]="parts"
						[isSeismic]="project?.seismic ?? false"
						(updateQuoteParts)="updateQuoteParts($event)"
						[manualPartForm]="manualPartForm"
					></app-part-list>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>

<div class="mb-4 w-[96%] mx-auto">
	<mat-divider></mat-divider>
</div>

<mat-dialog-actions *ngIf="!quote.salesOrder">
	<div class="flex flex-row justify-end space-x-2 w-full mb-4">
		<button mat-button (click)="dialogRef.close()">CANCEL</button>
		<button mat-raised-button color="primary" (click)="saveTemplate()">SAVE AS TEMPLATE</button>
		<button mat-raised-button color="primary" (click)="addItemsToQuote()">ADD TO QUOTE</button>
	</div>
</mat-dialog-actions>
