import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {Accessorial} from '../models/accessorial.interface';

@Injectable({
	providedIn: 'root'
})
export class AccessorialService {
	private accessorialDataSource = new BehaviorSubject<Accessorial[]>([]);
	readonly accessorials = this.accessorialDataSource.asObservable();

	constructor(private http: HttpClient) {
		this.findAll();
	}

	findAll() {
		this.http.get<Accessorial[]>(`${environment.url}/core/accessorial`).subscribe((accessorials) => {
			this.accessorialDataSource.next(accessorials);
		});
	}
}
