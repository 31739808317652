<div class="flex flex-row items-center mb-4">
	<mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
		<input
			matInput
			type="text"
			[placeholder]="partsLoading ? 'Fetching parts...' : 'Type to filter parts...'"
			[matAutocomplete]="auto"
			[formControl]="selectControl"
			(click)="onClicked()"
		/>
	</mat-form-field>

	<mat-autocomplete #auto [displayWith]="displayFn">
		<mat-option *ngIf="partsLoading"><mat-spinner class="scale-50"></mat-spinner></mat-option>
		<mat-option *ngIf="!partsLoading && !(filteredData | async)?.length">No parts match this search</mat-option>
		<mat-option *ngFor="let data of filteredData | async | slice : 0 : 50" (click)="optionClicked($event, data)">
			<div class="inline-flex flex-row min-w-full my-2 text-black">
				<mat-checkbox
					[checked]="data.selected"
					(change)="toggleSelection(data)"
					(click)="$event.stopPropagation()"
					class="w-12"
				></mat-checkbox>
				<div class="flex flex-col">
					<div class="flex flex-row space-x-2">
						<span class="text-sm font-bold">Item #:</span>
						<span>{{ data.part.item }}</span>
					</div>
					<div class="flex flex-row space-x-2">
						<span class="text-sm font-bold">Description:</span>
						<span>{{ data.part.description }}</span>
					</div>
				</div>
			</div>
		</mat-option>
	</mat-autocomplete>
</div>
