import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ClientSite} from '../models/client-site.model';

@Injectable({
	providedIn: 'root'
})
export class ClientSiteService {
	baseUrl = `${environment.url}/client-sites`;

	constructor(private http: HttpClient) {}

	create(clientSite: ClientSite): Observable<ClientSite> {
		return this.http.post<ClientSite>(this.baseUrl, clientSite);
	}

	findAll(): Observable<ClientSite[]> {
		return this.http.get<ClientSite[]>(this.baseUrl);
	}

	findOne(id: number): Observable<ClientSite> {
		return this.http.get<ClientSite>(`${this.baseUrl}/${id}`);
	}

	update(clientSiteWithId: ClientSite): Observable<ClientSite> {
		return this.http.put<ClientSite>(`${this.baseUrl}/${clientSiteWithId.id}`, clientSiteWithId);
	}

	patch(clientSiteWithId: ClientSite): Observable<ClientSite> {
		return this.http.patch<ClientSite>(`${this.baseUrl}/${clientSiteWithId.id}`, clientSiteWithId);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}

	findByClient(id: string): Observable<ClientSite[]> {
		return this.http.get<any>(`${this.baseUrl}/client/${id}`);
	}

	getInternal(): Observable<ClientSite[]> {
		return this.http.get<any>(`${this.baseUrl}/internal`);
	}
}
