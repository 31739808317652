<mat-card class="flex flex-col p-4" [formGroup]="form">
	<mat-card-title>{{editMode ? 'Update Price Configuration' : 'Create Price Configuration'}}</mat-card-title>
	<mat-form-field class="mt-2" *ngIf="!editMode">
		<mat-label>Type</mat-label>
		<mat-select formControlName="type">
			<mat-option *ngFor="let code of codeOptions" [value]="code.code"> {{ code.code }}</mat-option>
		</mat-select>
		<mat-error *ngIf="form.get('type')?.touched && form.get('type')?.invalid"> This is a required field </mat-error>
	</mat-form-field>
	<div class="flex space-x-2">
		<mat-form-field class="w-1/2 {{editMode ? 'mt-2' : ''}}">
			<mat-label>Charge</mat-label>
			<span matPrefix class="ml-3 mr-2 text-gray-500" *ngIf="!form.get('isPercent')?.value">$</span>
			<input type="number" formControlName="charge" matInput />
			<span matSuffix class="mr-3 text-gray-500" *ngIf="form.get('isPercent')?.value">%</span>
			<mat-error *ngIf="form.get('charge')?.touched && form.get('charge')?.invalid"> This is a required field</mat-error>
		</mat-form-field>
		<mat-checkbox class="w-1/2 mt-2" formControlName="isPercent" color="primary"> Percentage</mat-checkbox>
	</div>
	<mat-form-field>
		<mat-label>Effective Date</mat-label>
		<input matInput [matDatepicker]="picker" formControlName="effectiveDate" />
		<mat-error *ngIf="form.get('effectiveDate')?.hasError('required')"> Effective Date is <strong>required</strong> </mat-error>
		<mat-error *ngIf="form.get('effectiveDate')?.hasError('invalidDate')"> Date must not be before today </mat-error>
		<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>
	<div class="flex justify-end space-x-2">
		<button mat-flat-button (click)="close()">CANCEL</button>
		<button mat-flat-button color="primary" *ngIf=!editMode (click)="createPriceConfig()">CREATE PRICE CONFIG</button>
		<button mat-flat-button color="primary" *ngIf="editMode" (click)="editPriceConfig()">UPDATE PRICE CONFIG</button>
	</div>
</mat-card>
