import {Component} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {User} from '../../models/user.interface';
import {navData, navItem} from './nav-options';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../services/user.service';
import {UserTypeEnum} from "../../enums/user-type.enum";

@Component({
	selector: 'app-main-toolbar',
	templateUrl: './main-toolbar.component.html',
	styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent {
	user: User | null;
	navOptions: navItem[];
	constructor(private authService: AuthService, private router: Router, private dialog: MatDialog, private userService: UserService) {}

	ngOnInit(): void {
		this.authService.user.subscribe((user: User | null): void => {
			this.user = user;
		});
		this.navOptions = navData;
	}

  isAdmin(): boolean {
    return !!(this.user?.type && this.user.type === UserTypeEnum.ADMIN);
  }

	getInitials(): string {
		const name: string | undefined = this.user?.firstName?.concat(' ', this.user?.lastName);
		if (!name) {
			return '';
		}
		return name
			.split(' ')
			.map((n: string) => n[0])
			.join('');
	}

	getColor(): string {
		const name: string | undefined = this.user?.firstName?.concat(' ', this.user?.lastName);
		return this.userService.getUserColor(name);
	}

	navigate(item: navItem): void {
		this.router.navigate([item.routerLink], item.extras);
	}

	logout() {
		this.authService.logout();
	}
}
