import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShippingLog} from '../../core/models/shipping-log.model';
import {Code as ShippingCode} from '../../core/models/shipping-code.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ShippingLogService} from '../../core/services/shipping-log.service';
import {ShippingCodesService} from '../../core/services/shipping-codes.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Code} from '../../core/models/code.interface';

@Component({
	selector: 'app-shipping-log-list-detail',
	templateUrl: './shipping-log-list-detail.component.html',
	styleUrls: ['./shipping-log-list-detail.component.scss']
})
export class ShippingLogListDetailComponent implements OnInit {
	@Input() log: ShippingLog;
	@Input() codes: Code[] = [];
	@Output() updateLog: EventEmitter<ShippingLog> = new EventEmitter<ShippingLog>();
	timeList: ShippingCode[] = [];
	timeZoneList: ShippingCode[] = [];
	defaultTime: string | undefined = '';
	defaultTimeZone: string | undefined = '';

	busy = false;

	shippingLogForm = new FormGroup({
		id: new FormControl(0),
		accountManager: new FormControl('', [Validators.required]),
		orderNo: new FormControl(''),
		jobName: new FormControl(''),
		city: new FormControl(''),
		state: new FormControl(''),
		postal: new FormControl(''),
		budget: new FormControl(0, [Validators.min(1)]),
		cost: new FormControl(0, [Validators.min(1)]),
		shipDate: new FormControl(new Date()),
		originalDeliveryDate: new FormControl(new Date()),
		originalDeliveryTime: new FormControl(''),
		deliveryDate: new FormControl(new Date(), [Validators.required]),
		deliveryTime: new FormControl('', [Validators.required]),
		bookingId: new FormControl(0),
		brokerId: new FormControl(0),
		brokerName: new FormControl(''),
		carrierName: new FormControl(''),
		proNo: new FormControl(''),
		invoiceDate: new FormControl(new Date()),
		additionalCost: new FormControl(0),
		additionalCostReason: new FormControl('')
	});

	constructor(
		private shippingLogService: ShippingLogService,
		private shippingCodeService: ShippingCodesService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.timeList = this.shippingCodeService.getCodes('TIME');
		this.timeZoneList = this.shippingCodeService.getCodes('TIMEZONE');
		this.defaultTime = this.shippingCodeService.getDefault('TIME');
		this.defaultTimeZone = this.shippingCodeService.getDefault('TIMEZONE');

		this.shippingLogForm.patchValue(this.log);
		//this.shippingLogForm.disable();
	}

	onSubmit() {
		const updatedLog = this.shippingLogForm.getRawValue() as ShippingLog;
		this.busy = true;
		this.shippingLogService.update(updatedLog).subscribe({
			next: (shippingLog) => {
				this._snackBar.open('Shipping Log updated.');
				this.busy = false;
				this.updateLog.emit(shippingLog);
			},
			error: (err) => {
				this._snackBar.open('Save Shipping Log failed: ' + err.error.message);
				this.busy = false;
			}
		});
	}
}
