<mat-card class="flex flex-col p-8 space-y-4">
	<mat-card-title>New Quote</mat-card-title>
	<mat-form-field appearance="outline" subscriptSizing="dynamic">
		<mat-label>Select Project</mat-label>
		<input matInput type="text" [matAutocomplete]="auto" [formControl]="projectControl" placeholder="Search Projects" />
		<mat-autocomplete #auto="matAutocomplete" [displayWith]="projectControlDisplay" (optionSelected)="onProjectSelection()">
			<mat-option class="border-b border-gray-400 w-full" *ngFor="let project of filteredProjects | async" [value]="project">
				<div class="flex flex-row w-full">
					<div class="flex flex-col">
						<div class="flex flex-row">
							<div class="flex flex-col font-medium">{{ project.name }}</div>
						</div>
						<div class="flex flex-row">
							<div class="flex flex-col">{{ project.clientId }}</div>
						</div>
						<div class="flex flex-row">{{ project.city }} {{ project.state }} {{ project.postal }}</div>
					</div>
				</div>
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
	<div class="flex flex-row place-content-center">
		<label>-- OR --</label>
	</div>
	<button mat-raised-button color="primary" (click)="newProject()">CREATE NEW PROJECT</button>
</mat-card>
