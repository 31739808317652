import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup, FormGroupDirective} from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {QuoteLine} from '../../../core/models/quote-line.interface';
import {PriceConfigurationService} from '../../../core/services/price-configuration.service';
import {PriceConfigType} from '../../../core/models/price-config-types.interface';

@Component({
	selector: 'app-associated-costs',
	templateUrl: './associated-costs.component.html',
	styleUrls: ['./associated-costs.component.scss']
})
export class AssociatedCostsComponent implements OnInit {
	@Output() totalCostChanged = new EventEmitter<number>();
	@Input() quoteForm: FormGroup;
	@Input() ngForm: FormGroupDirective;

	priceConfigs: PriceConfigType[];
	associatedCostsTotal: number;

	constructor(private decimalPipe: DecimalPipe, private priceConfigService: PriceConfigurationService) {}

	ngOnInit() {
		this.calculateTotal();
		this.quoteForm.get('associatedCosts')?.valueChanges.subscribe(() => {
			this.calculateTotal();
		});
		this.priceConfigService.findAllPriceConfigTypes().subscribe((response) => {
			this.priceConfigs = response;
			this.assignPrices();
		});
	}

	assignPrices() {
		const associatedCosts = this.quoteForm.controls['associatedCosts'].value;
		if (associatedCosts) {
			//cost parameter is really of type QuoteLine but the correlated input uses cost.price as type string whereas
			//it is of type number in its' model. String/number decimal stuff is wonky.
			associatedCosts.forEach((cost: any) => {
				const match = this.priceConfigs.find((configType: PriceConfigType) => configType.name === cost?.type?.toUpperCase());
				if (match) {
					cost.price = cost.price ? cost.price : this.decimalPipe.transform(match.currentCharge, '.2');
				}
			});
		}
		this.quoteForm.controls['associatedCosts'].patchValue(associatedCosts);
	}

	get associatedCostFormArray(): FormArray {
		return this.quoteForm.controls['associatedCosts'] as FormArray;
	}

	calculateTotal(): void {
		const values: QuoteLine[] = this.quoteForm.get('associatedCosts')?.value;
		let total: number = 0;
		values.forEach((value: QuoteLine): void => {
			if (value.price && value.type !== 'stockPermitReq') {
				const valueWithCommaRemoved: string = this.format(value.price.toString());
				total += parseFloat(valueWithCommaRemoved);
			}
		});
		this.associatedCostsTotal = total;
		this.totalCostChanged.emit(total);
	}

	format(input: string): string {
		return input !== null && input.includes(',') ? input.replaceAll(',', '') : input;
	}

	checkForTrailingZero(index: number): void {
		const control = this.associatedCostFormArray.at(index).get('price');
		const costValue: number = parseFloat(this.format(control?.value.toString()));
		control?.setValue(this.decimalPipe.transform(costValue, '.2'));
	}
}
