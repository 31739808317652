<div class="p-2 flex justify-center">
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="user-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, email or phone number."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openPriceConfigDialog(false)">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingPriceConfigs">
		<!--Name Column-->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Price Configuration Name</th>
			<td mat-cell *matCellDef="let priceConfigType">
				{{ priceConfigType.name ? (priceConfigType.name | titlecase) : 'N/A' }}
			</td>
		</ng-container>

		<!--Name Column-->
		<ng-container matColumnDef="currentCharge">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Current Charge</th>
			<td mat-cell *matCellDef="let priceConfigType">
				{{ priceConfigType.isPercent ? priceConfigType.currentCharge! * 100 + '%' : (priceConfigType.currentCharge | currency) }}
			</td>
		</ng-container>

		<!--Name Column-->
		<ng-container matColumnDef="lastEffectiveDate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Last Effective Date</th>
			<td mat-cell *matCellDef="let priceConfigType">
				{{ priceConfigType.lastEffectiveDate ? (priceConfigType.lastEffectiveDate | date) : 'N/A' }}
			</td>
		</ng-container>

		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let priceConfigType" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="priceConfigType.expanded ? 'expanded' : 'collapsed'">
					<app-price-configuration-detail
						class="w-full"
						[expanded]="priceConfigType.expanded"
						[priceConfigType]="priceConfigType"
            [priceConfigTypes]="priceConfigTypes"
            (refreshPartsEvent)="this.refreshPriceConfigs()"
					></app-price-configuration-detail>
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let broker; let i = dataIndex; columns: columnsToDisplay"
			class="broker-row"
			(click)="broker.expanded = !broker.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="1" *ngIf="!isLoadingPriceConfigs">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingPriceConfigs">
		<mat-spinner></mat-spinner>
	</div>
</div>
