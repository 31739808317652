import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectComponent} from './project.component';
import {ProjectCreateModule} from '../project-create/project-create.module';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {MatTabsModule} from '@angular/material/tabs';
import {ProjectDraftingComponent} from './project-drafting/project-drafting.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ProjectDraftingDeleteComponent} from './project-drafting/project-drafting-delete/project-drafting-delete.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProjectStopModalComponent} from './project-stop-modal/project-stop-modal.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {ProjectsModule} from '../projects/projects.module';
import {MatInputModule} from '@angular/material/input';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ProjectOverviewComponent} from './project-overview/project-overview.component';
import {ProjectActivityComponent} from './project-activity/project-activity.component';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
@NgModule({
	declarations: [
		ProjectComponent,
		ProjectDraftingComponent,
		ProjectDraftingDeleteComponent,
		ProjectOverviewComponent,
		ProjectActivityComponent,
		ProjectStopModalComponent
	],
	exports: [ProjectComponent],
	imports: [
		CommonModule,
		ProjectCreateModule,
		MatButtonModule,
		MatExpansionModule,
		SharedModule,
		MatTabsModule,
		MatDialogModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,
		RouterModule,
		ProjectsModule,
		FormsModule,
		MatInputModule,
		MatButtonToggleModule,
		MatIconModule,
		MatStepperModule,
		MatCardModule,
		MatDividerModule
	]
})
export class ProjectModule {}
