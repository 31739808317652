import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PartCategoryEnum} from '../../../core/enums/part-category.enum';
import {QuotePart} from '../../../core/models/quote-part.interface';
import {Quote} from '../../../core/models/quote.interface';
import {Part} from '../../../core/models/part.interface';
import {QuoteConfig} from '../../../core/models/quote-config.interface';
import {QuoteConfigShelf} from '../../../core/models/quote-config-shelf.interface';
import {ConfigTypeEnum} from '../../../core/enums/config-type.enum';
import {TitleCasePipe} from '@angular/common';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PriceConfig} from '../../../core/models/price-config.interface';
import {PriceConfigurationService} from '../../../core/services/price-configuration.service';

@Component({
	selector: 'app-part-list',
	templateUrl: './part-list.component.html',
	styleUrls: ['./part-list.component.scss']
})
export class PartListComponent implements OnInit, OnChanges {
	@Input() quote: Quote = {};
	@Input() isSeismic: boolean = false;
	@Input() partCatalog: Part[] = [];
	@Input() useReadOnlyView: boolean = false;
	@Input() partsListUpdatedSubject: Subject<void>;
	@Input() manualPartForm: FormGroup;
	@Output() updateQuoteParts: EventEmitter<QuotePart[]> = new EventEmitter<QuotePart[]>();

	quoteInitialized: boolean = false;
	manualPartsInitialized: boolean = false;

	categories: PartCategoryEnum[] = [];
	generatedParts: QuotePart[] = [];
	manuallyAddedParts: QuotePart[] = [];

	priceFees: PriceConfig[] = [];

	// Arrays for calculations
	validWidthsForHangRods: number[] = [24, 30, 36, 42, 48, 60];
	validDepthsForSplitting: number[] = [24, 30, 36];
	invalidHeightsForPosts: number[] = [13, 14];

	// We want to separate these from the final Rack Solid Shelving Frames GL
	excludedFromFrames: string[] = Object.values(ConfigTypeEnum);

	MIN_POST_HEIGHT: number = 6;
	MAX_POST_HEIGHT: number = 15;

	constructor(
		private titleCasePipe: TitleCasePipe,
		private fb: FormBuilder,
		private snackbar: MatSnackBar,
		private priceConfigService: PriceConfigurationService
	) {}

	ngOnInit() {
		this.categories = Object.values(PartCategoryEnum);
		this.categories.forEach((category: PartCategoryEnum) => this.manualPartForm.addControl(category, this.fb.array([])));
		this.priceConfigService.findPriceConfigsByType('FEE').subscribe((priceConfigs: PriceConfig[]) => {
			this.priceFees = priceConfigs;
		});
		this.manualPartForm.valueChanges.subscribe(() => {
			this.updatePartsOnQuote();
		});

		this.partsListUpdatedSubject?.subscribe({
			next: () => {
				this.separateQuoteParts();
				this.createManualPartForms();
			},
			error: (err) => {
				console.error(err);
				this.snackbar.open('Error updating parts list', SnackbarActionEnum.ERROR);
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['quote'] && this.quote) {
			this.separateQuoteParts();
			if (!this.useReadOnlyView) {
				if (!this.quoteInitialized) {
					// If this is the initial load, then we don't want to recalculate parts
					this.createManualPartForms();
					this.quoteInitialized = true;
				} else {
					this.generateParts();
				}
			}
		}
	}

	get totalUnits(): number {
		let totalStarters: number = 0;
		let totalAdders: number = 0;
		this.quote?.configs?.forEach((quoteConfig: QuoteConfig) => {
			quoteConfig.quoteConfigShelves?.forEach((shelf: QuoteConfigShelf) => {
				totalStarters += shelf.starters ?? 0;
				totalAdders += shelf.adders ?? 0;
			});
		});

		return totalStarters + totalAdders;
	}

	get generatedFrameParts(): QuotePart[] {
		return this.generatedParts.filter((part: QuotePart) => !this.excludedFromFrames.includes(part.type ?? ''));
	}

	get manualFrameParts(): QuotePart[] {
		return this.manuallyAddedParts.filter((part: QuotePart) => !this.excludedFromFrames.includes(part.type ?? ''));
	}

	getCatalogPartsByCategory(category: PartCategoryEnum): Part[] {
		const filteredParts: Part[] = this.partCatalog.filter((part: Part) => part.category === category);
		switch (category) {
			case PartCategoryEnum.SHELVES:
				return filteredParts.sort((a, b) => (a.depth! > b.depth! ? 1 : -1)).sort((a, b) => (a.width! > b.width! ? 1 : -1));
			case PartCategoryEnum.HANG_RODS:
			case PartCategoryEnum.POSTS:
				return filteredParts.sort((a, b) => (a.width! > b.width! ? 1 : -1));
			case PartCategoryEnum.REBAR:
				return filteredParts.sort((a, b) => (a.depth! > b.depth! ? 1 : -1));
			case PartCategoryEnum.CAPS:
			case PartCategoryEnum.PLATES:
				return filteredParts.sort((a, b) => (a.partNumber! > b.partNumber! ? 1 : -1));
			case PartCategoryEnum.BEAMS:
				return filteredParts.sort((a, b) => (a.partNumber! > b.partNumber! ? 1 : -1)).sort((a, b) => (a.width! > b.width! ? 1 : -1));
			default:
				return filteredParts;
		}
	}

	getManualPartsFormByCategory(category: PartCategoryEnum) {
		return this.manualPartForm.controls[category] as FormArray;
	}

	createManualPartForms(): void {
		this.manualPartsInitialized = !!this.manuallyAddedParts.length;
		this.categories.forEach((category: PartCategoryEnum) => {
			const partsOfCategory: QuotePart[] = this.manuallyAddedParts.filter((part: QuotePart) => part.category === category);
			partsOfCategory.forEach((part: QuotePart) => {
				const control = this.fb.group({
					id: [part.id],
					partId: [null],
					quoteId: [part.quoteId],
					partName: [part.partName],
					modelNumber: [part.modelNumber],
					qty: [part.qty, (control: AbstractControl) => this.validateManualPart(control)],
					price: [part.price, Validators.min(0)],
					weight: [part.weight, Validators.min(0)],
					type: [part.type],
					category: [part.category, Validators.required],
					comment: [part.comment, Validators.required],
					manuallyAdded: [part.manuallyAdded]
				});
				(this.manualPartForm.controls[category] as FormArray).push(control);
			});
		});
		this.validateManualParts();
	}

	separateQuoteParts(): void {
		this.manuallyAddedParts = this.quote.parts?.filter((part: QuotePart) => part.manuallyAdded) ?? [];
		this.generatedParts = this.quote.parts?.filter((part: QuotePart) => !part.manuallyAdded) ?? [];
	}

	updatePartsOnQuote(): void {
		const manualPartMap: Map<string, QuotePart[]> = new Map<string, QuotePart[]>(Object.entries(this.manualPartForm.value));
		let manualParts: QuotePart[] = [];
		manualPartMap.forEach((value) => {
			const partsToAdd: QuotePart[] = value.filter((part) => part.qty);
			manualParts = manualParts.concat(partsToAdd);
		});
		this.updateQuoteParts.emit(this.generatedParts.concat(manualParts));
	}

	addManualPart(category: PartCategoryEnum): void {
		const manualPartForm = this.fb.group({
			partId: [null],
			partName: [null],
			modelNumber: [null],
			qty: [null, (control: AbstractControl) => this.validateManualPart(control)],
			price: [null, Validators.min(0)],
			weight: [null, Validators.min(0)],
			type: [null],
			category: [category],
			comment: [null, Validators.required],
			manuallyAdded: [true]
		});
		this.getManualPartsFormByCategory(category).push(manualPartForm);
		this.validateManualParts();
	}

	deleteManualPart(category: PartCategoryEnum, partIndex: number) {
		this.manualPartForm.markAsDirty();
		this.getManualPartsFormByCategory(category).removeAt(partIndex);
		this.validateManualParts();
	}

	validateManualPart(qty: AbstractControl) {
		let invalid = true;
		if (qty.value && qty.value !== 0) {
			if (qty.value > 0) {
				invalid = false;
			} else {
				const partForm = qty.parent as FormGroup;
				if (partForm) {
					let partQty = 0;
					const category = partForm.controls['category'].value;
					const modelNumber = partForm.controls['modelNumber'].value;
					const genPart = this.getGeneratedPartsByCategory(category).filter((part) => part.modelNumber === modelNumber);
					if (genPart.length) {
						genPart.forEach((part) => {
							if (part.qty) {
								partQty += part.qty;
							}
						});
					}
					this.getManualPartsFormByCategory(category)
						.controls.filter((part) => (part as FormGroup).controls['modelNumber'].value === modelNumber)
						.forEach((part) => {
							const pForm = part as FormGroup;
							if (pForm.controls['qty'].value) {
								partQty += pForm.controls['qty'].value;
							}
						});
					if (partQty >= 0) {
						invalid = false;
					}
				}
			}
		}
		return invalid ? {qty} : null;
	}

	onPartSelect(partId: string, partControl: AbstractControl) {
		const catalogPart: Part | undefined = this.partLookUp(partId);
		if (catalogPart) {
			partControl.get('price')?.setValue(catalogPart.price);
			partControl.get('weight')?.setValue(catalogPart.weight);
			partControl.get('partName')?.setValue(catalogPart.description);
			partControl.get('category')?.setValue(catalogPart.category);
			partControl.get('type')?.setValue(catalogPart.type);
			partControl.get('qty')?.setValue(1);
		}
	}

	getConfigTypeName(value: ConfigTypeEnum): string {
		const key: string = Object.keys(ConfigTypeEnum)[Object.values(ConfigTypeEnum).indexOf(value)];
		if (!key) {
			return '';
		}

		return this.titleCasePipe.transform(key.replace('_', ' '));
	}

	get cuttingFee(): number {
		const postCutConfig: PriceConfig | undefined = this.priceFees.find((priceConfig: PriceConfig) => priceConfig.type === 'POSTCUT');
		return postCutConfig?.charge ?? 0;
	}

	/**
	 * Creates a generated part to add to the parts array of the Quote
	 * @param partNumber - The partNumber of the part, used for looking up the part in the catalog.
	 * @param category - The type of part we are adding, e.g. Rebar, Hang Rods, etc...
	 * @param qty - The quantity of parts to appear on the line item of the quote.
	 * @param comment - Optional - Special instructions or information.
	 * @param additionalFee - Optional - Add fee to price
	 */
	addGeneratedPart(partNumber: string, category: PartCategoryEnum, qty: number, comment?: string, additionalFee?: number): void {
		if (qty < 1) {
			return;
		}

		const part: Part | undefined = this.partLookUp(partNumber);
		if (part) {
			part.price && part.price > 0 ? (part.price = parseFloat(part.price.toFixed(4))) : (part.price = 0);

			const generatedPart: QuotePart = {
				partName: part.description,
				modelNumber: part.partNumber,
				weight: part.weight,
				price: part.price + (additionalFee ?? 0),
				category,
				qty
			};
			// I wanted to do this in one step, but the unit tests expect comment to be undefined if comment is always added
			if (comment) {
				generatedPart.comment = comment;
			}
			if (additionalFee) {
				generatedPart.additionalFee = additionalFee;
			}
			this.generatedParts.push(generatedPart);
			return;
		}
		console.error(`Failed to add generated part, ${partNumber} not found.`);
	}

	/**
	 * Creates a generated shelf part to add to the parts array of the Quote.
	 * This is only for parts of the Shelf Category.
	 * @param shelfCount - The ShelfCount object containing the Part and Quantity values.
	 */
	addGeneratedShelf(shelfCount: ShelfCount): void {
		const part: Part | undefined = this.getShelfPart(shelfCount.part.width, shelfCount.part.depth, shelfCount.part.type);
		if (part) {
			part.price && part.price > 0 ? (part.price = parseFloat(part.price.toFixed(4))) : (part.price = 0);

			this.generatedParts.push({
				partName: `${part.description} ${part.type ? '(' + this.getConfigTypeName(part.type) + ')' : ''}`,
				modelNumber: part.partNumber,
				weight: part.weight,
				price: part.price,
				type: shelfCount.part.type,
				category: PartCategoryEnum.SHELVES,
				qty: this.quote.needsExtraParts && shelfCount.qty > 99 ? shelfCount.qty + 2 : shelfCount.qty
			});
			return;
		}
		console.error(`Failed to add generated shelf, ${shelfCount.part.width}x${shelfCount.part.depth} ${shelfCount.part.type} not found.`);
	}

	/**
	 * Look up parts of the Shelf category in the Part Catalog by providing dimensions and type.
	 * @param width - The width of the shelf to look up.
	 * @param depth - The depth of the shelf to look up.
	 * @param type - The type of the shelf to look up, e.g. wg for Wire Grid.
	 */
	getShelfPart(width?: number, depth?: number, type?: ConfigTypeEnum): Part | undefined {
		return this.partCatalog.find((part: Part) => part.width === width && part.depth === depth && part.type === type);
	}

	/**
	 * Creates a unique string key for a particular shelf part.
	 * @param shelfPart - The part data of the shelf.
	 */
	getShelfPartKey(shelfPart: Part): string {
		return `${shelfPart.width}x${shelfPart.depth} ${shelfPart.type}`;
	}

	getPartsByCategory(category: PartCategoryEnum): QuotePart[] {
		let parts: QuotePart[] = this.getGeneratedPartsByCategory(category);
		if (this.useReadOnlyView) {
			parts = parts.concat(this.getManualPartsByCategory(category));
		}

		return this.sortParts(parts);
	}

	/**
	 * Returns selected parts of a provided category.
	 * @param category - The category of parts to fetch.
	 */
	getGeneratedPartsByCategory(category: PartCategoryEnum): QuotePart[] {
		return this.generatedParts
			.filter((part: QuotePart) => part.category === category)
			.sort((a, b) => {
				if (a.partName && b.partName) {
					if (a.partName > b.partName) {
						return 1;
					} else if (a.partName < b.partName) {
						return -1;
					}
				}
				return 0;
			});
	}

	/**
	 * Returns selected parts of a provided category.
	 * @param category - The category of parts to fetch.
	 */
	getManualPartsByCategory(category: PartCategoryEnum): QuotePart[] {
		return this.manuallyAddedParts.filter((part: QuotePart) => part.category === category);
	}

	/**
	 * Calculates the total price of the selected parts.
	 */
	get totalPrice(): number {
		return this.generatedPrice + this.manualPrice;
	}

	get generatedPrice(): number {
		return this.generatedFrameParts.reduce((accumulator: number, {price, qty}) => {
			return accumulator + (price ?? 0) * (qty ?? 0);
		}, 0);
	}

	get manualPrice(): number {
		return this.manualFrameParts.reduce((accumulator: number, {price, qty}) => {
			return accumulator + (price ?? 0) * (qty ?? 0);
		}, 0);
	}

	/**
	 * Look up parts in the part catalog by their part number.
	 * @param partNumber - The part number of the part to find.
	 */
	partLookUp(partNumber: string): Part | undefined {
		return this.partCatalog.find((part: Part) => part.partNumber === partNumber);
	}

	/**
	 * Build the generated parts list from the User Inputs and Selected Shelves
	 */
	generateParts(): void {
		// Reset the generated parts list
		this.generatedParts = [];

		// Part Counts
		const postCounts: Map<string, PostCount> = new Map<string, PostCount>();
		const hangRodCounts: Map<number, number> = new Map<number, number>();
		const rebarCounts: Map<number, number> = new Map<number, number>();
		const shelfCounts: Map<string, ShelfCount> = new Map<string, ShelfCount>();
		const beamCounts: Map<string, number> = new Map<string, number>();
		const capCounts: Map<string, number> = new Map<string, number>();
		let fplrCount: number = 0;
		let fpdCount: number = 0;

		this.quote.configs?.forEach((config: QuoteConfig) => {
			const hangRodMultiplier: number = (config.hangShelf ?? 0) + (config.hangFront ?? 0);

			// Handle each shelf config
			config.quoteConfigShelves?.forEach((shelf: QuoteConfigShelf) => {
				const configShelfPart: Part | undefined = this.partCatalog.find((part: Part) => part.id === shelf.partId);

				if (config.height && config.height > 0) {
					let postSize: number = config.height;
					let shouldCut: boolean = false;

					// Check to see if Posts need to be cut, happens when shelf is too short or height is not an integer
					if (postSize < this.MIN_POST_HEIGHT) {
						// We select the minimum post height, and cut it into a smaller one
						postSize = this.MIN_POST_HEIGHT;
						shouldCut = true;
					} else if (config.height % 1 !== 0) {
						// We select the next post size up
						postSize = Math.ceil(config.height);
						shouldCut = true;
					}

					// A post's height cannot be among the invalid heights
					// If it is ever an invalid height, bump it up to a valid height, then cut it
					while (this.invalidHeightsForPosts.includes(postSize) && postSize < this.MAX_POST_HEIGHT) {
						postSize++;
						shouldCut = true;
					}

					// Accumulate L Posts
					if (shelf.starters) {
						this.accumulatePosts(postCounts, shelf.starters, config.height, 'L', postSize, shouldCut);
					}

					// Accumulate T Posts
					if (shelf.adders) {
						this.accumulatePosts(postCounts, shelf.adders, config.height, 'T', postSize, shouldCut);
					}
				}

				// Accumulate Hang Rods
				if (
					(hangRodMultiplier || config.hangNoFront) &&
					configShelfPart &&
					configShelfPart.width &&
					this.validWidthsForHangRods.includes(configShelfPart.width)
				) {
					const depth: number = configShelfPart.depth ?? 0;
					const totalShelves: number = (shelf.starters ?? 0) + (shelf.adders ?? 0);
					let noFrontsToAdd: number;
					if (depth <= 24) {
						noFrontsToAdd = (config.hangNoFront ?? 0) * totalShelves;
					} else {
						noFrontsToAdd = (config.hangNoFront ?? 0) * totalShelves * 2;
					}
					const newHangRodsToAdd: number =
						totalShelves * // Total shelves of these dimensions
							hangRodMultiplier * // Specified hang rod values added together
							((configShelfPart.depth ?? 0) >= 24 ? 2 : 1) +
						noFrontsToAdd; // If shelf is deep, then we want to double the number
					const value: number = (hangRodCounts.get(configShelfPart.width) ?? 0) + newHangRodsToAdd;
					hangRodCounts.set(configShelfPart.width, value);
				}

				// Accumulate Rebar
				if (
					config.levelsToSplit &&
					configShelfPart &&
					configShelfPart.depth &&
					this.validDepthsForSplitting.includes(configShelfPart.depth)
				) {
					const rebarMultiplier: number = (configShelfPart.width ?? 0) >= 60 ? 2 : 1;
					const rebarToAdd: number =
						((shelf.starters ?? 0) + (shelf.adders ?? 0)) * // Total shelves of these dimensions
						rebarMultiplier * // Double Rebar if width is 60 or higher
						config.levelsToSplit; // How many of the shelf levels should be split
					const value: number = (rebarCounts.get(configShelfPart.depth) ?? 0) + rebarToAdd;
					rebarCounts.set(configShelfPart?.depth, value);
				}

				if (config.shelves) {
					// Accumulate Shelves
					const shelfPart: Part | undefined = this.partCatalog.find((part: Part) => part.id === shelf.partId);

					if (shelfPart && shelfPart.depth && shelfPart.type) {
						// Get the key to be used in the shelfCounts map
						const shelfPartKey: string = this.getShelfPartKey(shelfPart);
						// See if we have an existing ShelfCount, if not then initialize one
						const shelfCount: ShelfCount = this.getShelfCountFromShelfCounts(shelfCounts, shelfPartKey, shelfPart);

						const baseTotal: number = (shelf.starters ?? 0) + (shelf.adders ?? 0);
						const unitTotal: number = baseTotal * config.shelves;
						const hangRows: number = baseTotal * (config.hangShelf ?? 0);
						let removeShelvesForSplitLevels: number = 0;

						// Do we need to split any shelves depth-wise?
						if (config.levelsToSplit && this.validDepthsForSplitting.includes(shelfPart.depth)) {
							removeShelvesForSplitLevels = baseTotal * config.levelsToSplit;
							const halfShelvesToAdd: number = removeShelvesForSplitLevels * 2;
							// Create or Update the half shelves that we need
							this.splitShelves(shelfCounts, shelfPart, shelfPart.type, halfShelvesToAdd);
						}

						// Update shelf counts
						shelfCount.qty += unitTotal + hangRows - removeShelvesForSplitLevels;
						shelfCounts.set(shelfPartKey, shelfCount);
					}

					if (shelfPart) {
						this.accumulateDrlBeams(beamCounts, config, shelf, shelfPart);
						this.accumulateDrhBeams(beamCounts, config, shelf, shelfPart);
						this.accumulateDrcBeams(beamCounts, config, shelf, shelfPart);
					}
				}
			});
		});

		postCounts.forEach((value: PostCount, key: string) => {
			if (value) {
				if (value.qtyToCut) {
					const comment: string = `Cut ${key}s for ${value.postSizes.sort((a, b) => a - b).join(`', `)}' uprights.${
						this.cuttingFee ? '' : ' No Cutting fee applied.'
					}`;
					// Add post with cutting fee
					this.addGeneratedPart(`${key}`, PartCategoryEnum.POSTS, value.qtyToCut, comment, this.cuttingFee);
					// Add a cap for every cut post
					const caps: number = (capCounts.get(value.type) ?? 0) + value.qtyToCut;
					capCounts.set(value.type, caps);
				}
				this.addGeneratedPart(`${key}`, PartCategoryEnum.POSTS, value.qty - value.qtyToCut);
			}
		});

		capCounts.forEach((value: number, key: string) => this.addGeneratedPart(`UC${key}RUB`, PartCategoryEnum.CAPS, value));

		// Static Starters mean we need Left and Right Foot Plates
		const seismicSuffix: string = this.isSeismic ? '-S' : '-NS';
		if (this.quote.staticStarters) {
			fplrCount = this.quote.staticStarters * 2;
			this.addGeneratedPart(`FPL${seismicSuffix}`, PartCategoryEnum.PLATES, fplrCount);
			this.addGeneratedPart(`FPR${seismicSuffix}`, PartCategoryEnum.PLATES, fplrCount);
		}

		// Static Adders mean we need Double Foot Plates
		if (this.quote.staticAdders) {
			fpdCount = this.quote.staticAdders * 2;
			this.addGeneratedPart(`FPD${seismicSuffix}`, PartCategoryEnum.PLATES, fpdCount);
		}

		hangRodCounts.forEach((value: number, key: number) => {
			if (value) {
				if (this.quote.needsExtraParts) {
					if (value > 500) {
						value += 4;
					} else if (value > 100) {
						value += 2;
					}
				}
				this.addGeneratedPart(`HR${key}`, PartCategoryEnum.HANG_RODS, value);
			}
		});

		rebarCounts.forEach((value: number, key: number) => {
			if (value) {
				if (this.quote.needsExtraParts) {
					if (value > 500) {
						value += 4;
					} else if (value > 100) {
						value += 2;
					}
				}
				this.addGeneratedPart(`RB${key}`, PartCategoryEnum.REBAR, value);
			}
		});

		beamCounts.forEach((value: number, key: string) => {
			if (value) {
				if (this.quote.needsExtraParts) {
					if (value > 500) {
						value += 4;
					} else if (value > 100) {
						value += 2;
					}
				}
				this.addGeneratedPart(key, PartCategoryEnum.BEAMS, value);
			}
		});

		shelfCounts.forEach((value: ShelfCount) => {
			if (value.qty) {
				this.addGeneratedShelf(value);
			}
		});

		// We only generate hardware if config is Shelving Only
		if (this.quote.isShelvingOnly) {
			this.generateHardware(hangRodCounts, fplrCount, fpdCount);
		}

		this.validateManualParts();

		this.updatePartsOnQuote();
	}

	/**
	 * Retrieve ShelfCount from the given ShelfCount map. If not found, creates a new ShelfCount object.
	 * @param shelfCounts - The ShelfCount map to look through.
	 * @param shelfPartKey - The key of the ShelfCount to look up.
	 * @param shelfPart - The shelf part to add to the new ShelfCount if created.
	 */
	getShelfCountFromShelfCounts(shelfCounts: Map<string, ShelfCount>, shelfPartKey: string, shelfPart: Part): ShelfCount {
		return shelfCounts.get(shelfPartKey) ?? {part: shelfPart, qty: 0};
	}

	/**
	 * Splits a shelf depth-wise and adds it to the ShelfCount map.
	 * @param shelfCounts - The ShelfCount map to add to.
	 * @param shelfPart - The Shelf Part to be split.
	 * @param type - The type of the shelf part to be split.
	 * @param qty - The quantity value to assign to the newly split shelf.
	 */
	splitShelves(shelfCounts: Map<string, ShelfCount>, shelfPart: Part, type: ConfigTypeEnum, qty: number): void {
		// Get the half shelf part
		const halfDepth: number = (shelfPart.depth ?? 0) / 2;
		const halfShelfPart: Part | undefined = this.getShelfPart(shelfPart.width ?? 0, halfDepth, type);
		if (!halfShelfPart) {
			console.error(`Could not find half shelf part: ${shelfPart.width}x${halfDepth} ${shelfPart.type}`);
			return;
		}

		// See if the half shelf exists, if not create it
		const halfShelfKey: string = this.getShelfPartKey(halfShelfPart);
		const halfShelfCount: ShelfCount = this.getShelfCountFromShelfCounts(shelfCounts, halfShelfKey, halfShelfPart);
		halfShelfCount.qty += qty;

		shelfCounts.set(halfShelfKey, halfShelfCount);
	}

	/**
	 * Calculates key and adds value to the BeamCounts Map
	 * @param beamCounts - The BeamCounts Map
	 * @param type - The type of beam to add
	 * @param dimensionValue - The width or depth of the shelf
	 * @param count - The number of beams to add
	 */
	addBeamsToCounts(beamCounts: Map<string, number>, type: string, dimensionValue: number, count: number): void {
		const key: string = `${type}${dimensionValue}`;
		const value: number = (beamCounts.get(key) ?? 0) + count;
		beamCounts.set(key, value);
	}

	/**
	 * Calculates DRL Beams based on configs
	 * @param beamCounts - The BeamCounts Map
	 * @param config - The Quote Config object
	 * @param shelf - The Shelf Config object
	 * @param shelfPart - The shelf part object
	 */
	accumulateDrlBeams(beamCounts: Map<string, number>, config: QuoteConfig, shelf: QuoteConfigShelf, shelfPart: Part): void {
		const drlCount: number = ((config.shelves ?? 0) - (config.thickLevels ?? 0)) * ((shelf.starters ?? 0) + (shelf.adders ?? 0)) * 2;
		if (drlCount > 0) {
			// Add DRLs for width
			if (shelfPart.width) {
				this.addBeamsToCounts(beamCounts, 'DRL', shelfPart.width, drlCount);
			}

			if (shelfPart.depth) {
				// Add DRLs for depth
				this.addBeamsToCounts(beamCounts, 'DRL', shelfPart.depth, drlCount);
			}
		}
	}

	/**
	 * Calculates DRH Beams based on configs
	 * @param beamCounts - The BeamCounts Map
	 * @param config - The Quote Config object
	 * @param shelf - The Shelf Config object
	 * @param shelfPart - The shelf part object
	 */
	accumulateDrhBeams(beamCounts: Map<string, number>, config: QuoteConfig, shelf: QuoteConfigShelf, shelfPart: Part): void {
		const drhCount: number = (config.thickLevels ?? 0) * ((shelf.starters ?? 0) + (shelf.adders ?? 0)) * 2;
		// Add DRHs for width
		if (shelfPart.width) {
			this.addBeamsToCounts(beamCounts, 'DRH', shelfPart.width, drhCount);
		}

		if (shelfPart.depth) {
			const forFtb: number = ((shelf.starters ?? 0) + (shelf.adders ?? 0)) * (config.addFtbDrh ?? 0) * 2;
			// Add DRHs for depth
			this.addBeamsToCounts(beamCounts, 'DRH', shelfPart.depth, drhCount + forFtb);
		}
	}

	/**
	 * Calculates DRC Beams based on configs
	 * @param beamCounts - The BeamCounts Map
	 * @param config - The Quote Config object
	 * @param shelf - The Shelf Config object
	 * @param shelfPart - The shelf part object
	 */
	accumulateDrcBeams(beamCounts: Map<string, number>, config: QuoteConfig, shelf: QuoteConfigShelf, shelfPart: Part): void {
		const shelfTotal: number = (shelf.starters ?? 0) + (shelf.adders ?? 0);
		const hangWithFrontCount: number = shelfTotal * (config.hangFront ?? 0) * 2;
		const hangWithShelf: number = shelfTotal * (config.hangShelf ?? 0) * 2;
		const hangRodNoFrontCount: number = shelfTotal * (config.hangNoFront ?? 0) * 2;
		// Add DRCs for width
		if (shelfPart.width) {
			this.addBeamsToCounts(
				beamCounts,
				'DRC',
				shelfPart.width,
				hangWithFrontCount + hangWithShelf + (shelfPart.width === 60 ? hangRodNoFrontCount : 0)
			);
		}

		if (shelfPart.depth) {
			// Add DRCs for depth
			this.addBeamsToCounts(beamCounts, 'DRC', shelfPart.depth, hangRodNoFrontCount + hangWithFrontCount + hangWithShelf);
		}
	}

	/**
	 * Calculates posts based on configs
	 * @param postCounts The PostCounts Map
	 * @param shelfQty The number of shelf starters for L posts OR shelf adders for T posts
	 * @param shelfHeight The height of the shelf
	 * @param type The type of post, 'L' or 'T'
	 * @param postSize How big the post should be
	 * @param shouldCut Whether or not the posts should be cut
	 */
	accumulatePosts(
		postCounts: Map<string, PostCount>,
		shelfQty: number,
		shelfHeight: number,
		type: string,
		postSize: number,
		shouldCut: boolean
	): void {
		const multiplier = type === 'L' ? 4 : 2;
		const key: string = `U${type}${postSize}`;
		const qty: number = shelfQty * multiplier;
		const value: PostCount = postCounts.get(key) ?? {type, postSizes: [], qty: 0, qtyToCut: 0};
		if (!value.postSizes.includes(shelfHeight) && !this.heightIsValid(shelfHeight)) {
			value.postSizes.push(shelfHeight);
		}
		value.qty += qty;
		value.qtyToCut += shouldCut ? qty : 0;
		postCounts.set(key, value);
	}

	/**
	 * Checks if a shelf height is valid for posts
	 * @param shelfHeight The height of the shelf
	 */
	heightIsValid(shelfHeight: number): boolean {
		// The height is invalid if it is under minimum height, is within the invalid height list, or is a fractional value
		return !(shelfHeight < this.MIN_POST_HEIGHT || this.invalidHeightsForPosts.includes(shelfHeight) || shelfHeight % 1 !== 0);
	}

	/**
	 * Calculates hardware based on configs
	 * @param hangRodCounts - The HangRodCounts Map
	 * @param leftRightFootPlates - The number used to set the quantities of FPL and FPR
	 * @param doubleFootPlates - The number user to set the quantity of FPD
	 */
	generateHardware(hangRodCounts: Map<number, number>, leftRightFootPlates: number, doubleFootPlates: number): void {
		let totalHangRods: number = 0;
		hangRodCounts.forEach((value) => (totalHangRods += value));

		if (this.quote.needsExtraParts) {
			if (totalHangRods > 500) {
				totalHangRods += 4;
			} else if (totalHangRods > 100) {
				totalHangRods += 2;
			}
		}

		// Generate 3/8 x 3 Screw Anchors
		this.addGeneratedPart('HAANCHOR.3753SEISMIC', PartCategoryEnum.HARDWARE, 2 * (leftRightFootPlates + doubleFootPlates));

		// Generate Self Drilling Screws
		this.addGeneratedPart('HASELFDRILL#141Z', PartCategoryEnum.HARDWARE, (leftRightFootPlates * 2 + doubleFootPlates) * 2);

		// Generate Hex Bolts
		this.addGeneratedPart('HAHBLT.25201SS', PartCategoryEnum.HARDWARE, (this.quote.b2bKits ?? 0) * 3);

		// Generate 1/4 - 20 Lock Nuts
		this.addGeneratedPart('HALKNT.2520', PartCategoryEnum.HARDWARE, (this.quote.b2bKits ?? 0) * 3);

		// Generate 1/4 Washers
		this.addGeneratedPart('HAWSHRSTEEL1/4', PartCategoryEnum.HARDWARE, (this.quote.b2bKits ?? 0) * 6);
	}

	validateManualParts() {
		this.updateFormValidity(this.manualPartForm);
	}

	updateFormValidity(form: FormGroup | FormArray): void {
		Object.keys(form.controls).forEach((key) => {
			const control = form.get(key);

			if (control instanceof FormGroup || control instanceof FormArray) {
				this.updateFormValidity(control);
			} else {
				if (control) {
					control.updateValueAndValidity();
				}
			}
		});
	}

	sortParts(parts: QuotePart[]): QuotePart[] {
		return parts
			.sort((a, b) => {
				const descA: string = a.partName?.toLowerCase() ?? '';
				const descB: string = b.partName?.toLowerCase() ?? '';

				if (descA < descB) {
					return -1;
				}
				if (descA > descB) {
					return 1;
				}
				return 0;
			})
			.sort((a, b) => {
				const regex: RegExp = /\d+(?=')/g;

				const descA: string = a.partName?.match(regex)?.pop() ?? '';
				const descB: string = b.partName?.match(regex)?.pop() ?? '';

				if (descA && descB) {
					const numA: number = parseInt(descA);
					const numB: number = parseInt(descB);

					if (numA < numB) {
						return -1;
					}
					if (numA > numB) {
						return 1;
					}
				}

				return 0;
			});
	}
}

interface ShelfCount {
	part: Part;
	qty: number;
}

interface PostCount {
	type: string;
	postSizes: number[];
	qty: number;
	qtyToCut: number;
}
