import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {TitleCasePipe} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BrokersService} from '../../../core/services/brokers.service';
import {Broker} from '../../../core/models/broker.interface';
import {ContactInfoComponent} from '../../../shared/components/contacts/contact-info/contact-info.component';
import {ContactService} from '../../../core/services/contact.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ContactTypeEnum} from '../../../core/enums/contact-type.enum';
import {Contact} from '../../../core/models/contact.interface';

@Component({
	selector: 'app-brokers-list-detail',
	templateUrl: './brokers-list-detail.component.html',
	styleUrls: ['./brokers-list-detail.component.scss']
})
export class BrokersListDetailComponent implements OnInit, OnChanges {
	@Input() broker: Broker;
	@Input() expanded: boolean = false;

	form: FormGroup;
	relatedContacts: Contact[] = [];
	contactSubscription: any;
	loading: boolean = true;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private brokersService: BrokersService,
		private titlecasePipe: TitleCasePipe,
		private snackbar: MatSnackBar,
		private contactService: ContactService
	) {
		this.form = this.formBuilder.group({
			id: [''],
			email: [''],
			name: ['', Validators.required],
			phone: [''],
			phoneExt: [''],
			active: ['']
		});
	}

	ngOnInit(): void {
		this.form.patchValue(this.broker);
		this.form.disable();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.expanded && !this.contactSubscription) {
			this.contactSubscription = this.contactService.findContactsByType(this.broker.id!.toString(), 'BROKER').subscribe({
				next: (contacts: Contact[]) => {
					this.relatedContacts = contacts;
					this.loading = false;
				},
				error: (error) => console.error(error)
			});
		}
	}

	onSubmit(): void {
		const formValue = this.form.getRawValue();
		this.brokersService.update(this.broker.id!, formValue).subscribe({
			next: () => {
				this.snackbar.open('Broker info updated', SnackbarActionEnum.SUCCESS);
				this.brokersService.findAll();
				this.form.disable();
			},
			error: (error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		});
	}

	showToggleInactiveModal(activeColor: string): void {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				message:
					activeColor == 'green'
						? 'Are you sure you want to deactivate ' + this.titlecasePipe.transform(this.broker.name) + '?'
						: 'Are you sure you want to activate ' + this.titlecasePipe.transform(this.broker.name) + '?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				const active = this.form.get('active');
				if (active?.value) {
					this.form.get('active')?.setValue(0);
					this.onSubmit();
				} else {
					this.form.get('active')?.setValue(1);
					this.onSubmit();
				}
			} else {
				return;
			}
		});
	}

	removeContactAssociation(contact: Contact, index: number): void {
		this.contactService.deleteContactAssociation(contact.id!, this.broker.id!.toString(), ContactTypeEnum.BROKER).subscribe({
			next: () => {
				this.relatedContacts.splice(index, 1);
			},
			error: (err: any) => {
				this.snackbar.open('Failed to delete contact', SnackbarActionEnum.ERROR);
				console.error(err);
			}
		});
	}

	openContactModal(editMode: boolean, contact?: Contact): void {
		const contactInfoDialog = this.dialog.open(ContactInfoComponent, {
			data: {
				contact: contact,
				editMode: editMode,
				refId: this.broker.id,
				refType: ContactTypeEnum.BROKER
			}
		});

		contactInfoDialog.afterClosed().subscribe((result: Contact) => {
			if (!result) {
				return;
			}

			const contactIndex = this.relatedContacts.findIndex((contact: Contact) => contact.id === result.id);

			if (contactIndex !== -1) {
				this.relatedContacts[contactIndex] = result;
			} else {
				this.relatedContacts.push(result);
			}
		});
	}
}
