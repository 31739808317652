<h2 mat-dialog-title>Update Install</h2>
<mat-dialog-content class="mat-typography">
	<div class="flex flex-col" [formGroup]="projectInstallForm" *ngIf="!loading">
		<div class="flex">
			<div class="flex flex-col font-medium mr-8">
				<div class="m-2">{{ this.projectInstallForm.controls.orderNo.value }} - {{ this.projectInstallForm.controls.name.value }}</div>
				<div class="m-2">
					{{ this.projectInstallForm.controls.city.value }}, {{ this.projectInstallForm.controls.state.value }}
					{{ this.projectInstallForm.controls.postal.value }}
				</div>
				<div class="m-2">
					<span class="font-normal mr-2">Shift: </span>
					<span *ngIf="projectInstallForm.controls.nightWork.value">Night <mat-icon>dark_mode</mat-icon></span>
					<span *ngIf="!projectInstallForm.controls.nightWork.value">Day <mat-icon>light_mode</mat-icon></span>
				</div>
				<div class="m-2">
					<span class="font-normal mr-2">Union:</span>
					{{ this.projectInstallForm.controls.union.value ? 'Yes' : 'No' }}
					<mat-icon *ngIf="projectInstallForm.controls.union.value">handshake</mat-icon>
				</div>
				<div class="m-2" *ngIf="projectInstallForm.controls.stockPermitReq.value">
					<mat-checkbox formControlName="installPermitPickup">Permit Pickup</mat-checkbox><mat-icon>fact_check</mat-icon>
				</div>
				<div class="m-2" *ngIf="projectInstallForm.controls.specialInspection.value">
					<mat-checkbox formControlName="installInspection">Inspection</mat-checkbox><mat-icon>verified</mat-icon>
				</div>
				<div class="m-2">
					<app-contact #contact contactId="installContactId" [context]="ContactComponentContext.INSTALL"></app-contact>
				</div>
			</div>
			<div class="flex flex-col">
				<div class="m-2" *ngIf="data.project.locationName">
					<span class="font-normal mr-2">Shipping From:</span> {{ data.project.locationName }}
				</div>
				<div class="m-2">
					<span class="font-normal mr-2">Install Date:</span> {{ this.projectInstallForm.controls.installDate.value | date : 'fullDate' }}
				</div>
				<div class="m-2">
					<span class="font-normal mr-2">Duration:</span>
					<mat-form-field appearance="outline" class="w-36">
						<mat-label>Duration</mat-label>
						<input matInput (change)="calcEndDate()" formControlName="installDuration" />
						<mat-error>Duration <strong>required</strong>.</mat-error>
					</mat-form-field>
				</div>
				<div class="m-2">
					<mat-checkbox formControlName="installWeekend" (change)="calcEndDate()">Weekend Work?</mat-checkbox>
					<mat-icon class="ml-2" *ngIf="projectInstallForm.controls.installWeekend.value">weekend</mat-icon>
				</div>
				<div class="m-2">
					<span class="font-normal mr-2">End Date:</span> {{ this.projectInstallForm.controls.installEndDate.value | date : 'fullDate' }}
				</div>
				<div class="m-2">
					<span class="font-normal mr-2">Color:</span>
					<mat-form-field appearance="outline">
						<mat-label>Color</mat-label>
						<mat-select formControlName="installColor" [style.background]="projectInstallForm.controls.installColor.value">
							<mat-option value=""></mat-option>
							<mat-option [style.background]="color" [value]="color" *ngFor="let color of colors"></mat-option>
						</mat-select>
						<mat-error>Color <strong>required</strong>.</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div class="m-8" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!loading">
	<button mat-flat-button mat-dialog-close [routerLink]="['/', 'project', data.project.id]">View Project</button>
	<button mat-raised-button mat-dialog-close *ngIf="!busy">Cancel</button>
	<button mat-raised-button color="primary" (click)="saveProjectInstall()" *ngIf="!busy" [disabled]="projectInstallForm.invalid">
		Save
	</button>
	<div class="m-8" *ngIf="busy">
		<mat-spinner diameter="20"></mat-spinner>
	</div>
</mat-dialog-actions>
