import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ProjectDraftingComponent} from '../../project/project-drafting/project-drafting.component';
import {ProjectQuoteComponent} from '../../projects/project-quote/project-quote.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmNavigateAwayGuard implements CanDeactivate<ProjectDraftingComponent | ProjectQuoteComponent> {
	async canDeactivate(
		component: ProjectDraftingComponent | ProjectQuoteComponent,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Promise<boolean | UrlTree> {
		if (component instanceof ProjectDraftingComponent) {
			if (component.hasUnsavedFiles()) {
				await component.confirmNavigationAway(nextState);
				return false;
			} else {
				return true;
			}
		} else {
			if (component.cantNavigateAway()) {
				await component.confirmNavigationAway(nextState);
				return false;
			} else {
				return true;
			}
		}
	}
}
