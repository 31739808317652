<div class="p-2 flex justify-center">
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="user-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, email or phone number."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openCreateContactModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 h-[80vh] border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingContacts">
		<!--Name Column-->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
			<td mat-cell *matCellDef="let contact">
				{{ contact.name ? (contact.name | titlecase) : 'N/A' }}
			</td>
		</ng-container>
		<!--Email Column-->
		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email">Email</th>
			<td mat-cell *matCellDef="let contact">
				{{ contact.email ? contact.email : 'N/A' }}
			</td>
		</ng-container>
		<!--Phone Column-->
		<ng-container matColumnDef="phone">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by phone">Phone</th>
			<td mat-cell *matCellDef="let contact">
				{{ contact.phone ? (contact.phone | phone) : 'N/A' }}
			</td>
		</ng-container>
		<!--Phone Ext Column-->
		<ng-container matColumnDef="phoneExt">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by phone ext">Phone Ext</th>
			<td mat-cell *matCellDef="let contact">
				{{ contact.phoneExt ? contact.phoneExt : 'N/A' }}
			</td>
		</ng-container>
		<!--Active Column-->
		<ng-container matColumnDef="active">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let contact">
				<ng-container *ngIf="!contact.active">
					<div
						[ngStyle]="{backgroundColor: contact.active === 1 ? 'green' : 'red'}"
						class="py-1 px-2 ml-2 rounded-full text-white w-20 h-8 flex justify-center items-center"
						#pill
					>
						<span class="">{{ contact.active === 1 ? 'Active' : 'Inactive' }}</span>
					</div>
				</ng-container>
			</td>
		</ng-container>
		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let contact" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="contact.expanded ? 'expanded' : 'collapsed'">
					<app-contacts-list-detail [contact]="contact" class="w-full"></app-contacts-list-detail>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let contact; let i = dataIndex; columns: columnsToDisplay"
			class="contact-row"
			(click)="contact.expanded = !contact.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingContacts">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingContacts">
		<mat-spinner></mat-spinner>
	</div>
</div>
