import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {GlCode} from '../models/gl-code.interface';

@Injectable({
	providedIn: 'root'
})
export class GlCodeService {
	baseUrl: string = `${environment.url}/core/gl-codes`;
	glCodeSubject: Subject<GlCode[]> = new Subject<GlCode[]>();
	constructor(private http: HttpClient) {}

	findAll(): Observable<GlCode[]> {
		return this.http.get<GlCode[]>(this.baseUrl);
	}

	subjectFindAll(): void {
		this.http.get<GlCode[]>(this.baseUrl).subscribe((response) => {
			this.glCodeSubject.next(response);
		});
	}

	findOne(id: number): Observable<GlCode> {
		return this.http.get<GlCode>(`${this.baseUrl}/${id}`);
	}

	create(glCode: GlCode): Observable<GlCode> {
		return this.http.post<GlCode>(this.baseUrl, glCode);
	}

	update(id: number | undefined, glCode: GlCode): Observable<GlCode> {
		return this.http.put<GlCode>(`${this.baseUrl}/${id}`, glCode);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}
}
