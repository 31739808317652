import {Component, Input, OnInit} from '@angular/core';
import {Project, ProjectUpdate} from '../../core/models/project.interface';
import {ProjectsService} from '../../core/services/projects.service';
import {ProjectActivity} from '../../core/models/project-activity.interface';
import {formatDistanceToNowStrict} from 'date-fns';
import {ActivityTypeEnum} from '../../core/enums/activity-type.enum';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {CodeTypeEnum} from '../../core/enums/code-type.enum';
import {CodeConfirmationDialogComponent} from '../../shared/components/code-confirmation-dialog/code-confirmation-dialog.component';
import {Code} from '../../core/models/code.interface';
import {LcdChangeModalComponent} from './lcd-change-modal/lcd-change-modal.component';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
	selector: 'app-project-detail',
	templateUrl: './project-detail.component.html',
	styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
	@Input() projectId: number;
	@Input() codes: Code[];
	project: Project;
	activitySorted: ProjectActivity[];
	lastActivityDate: Date | undefined;
	isLoading: boolean = true;
	buttonDisabled: boolean = false;

	constructor(private projectsService: ProjectsService, private dialog: MatDialog, public snackbar: MatSnackBar, private router: Router) {}

	ngOnInit() {
		this.projectsService.findOne(this.projectId).subscribe((response) => {
			this.project = response;
			this.activitySorted = this.project.activity.sort((a: ProjectActivity, b: ProjectActivity) => {
				return new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime();
			});
			const lastDate: Date | undefined = this.activitySorted.length ? new Date(this.activitySorted[0].dateTime) : undefined;
			if (lastDate) {
				this.lastActivityDate = new Date(
					Date.UTC(
						lastDate.getFullYear(),
						lastDate.getMonth(),
						lastDate.getDate(),
						lastDate.getHours(),
						lastDate.getMinutes(),
						lastDate.getSeconds()
					)
				);
			}
			this.isLoading = false;
		});
	}

	openLcdModal() {
		const dialogRef = this.dialog.open(LcdChangeModalComponent, {
			disableClose: true
		});
		dialogRef.componentInstance.lastDraftingContactDate = this.project.lastDraftingContactDate;
		dialogRef.componentInstance.projectId = this.project.id;

		dialogRef.afterClosed().subscribe((response) => {
			if (response) this.updateLcdDate(response);
		});
	}

	updateLcdDate(updatedProject: ProjectUpdate) {
		this.projectsService.update(updatedProject, false, false, 'Drafting Last Contact Updated!');
	}

	getDaysAtStatus(createdTs: Date): number {
		if (!this.activitySorted.length) {
			const created = new Date(createdTs);
			return +formatDistanceToNowStrict(created, {unit: 'day'}).split(' ')[0];
		} else {
			const lastStatusChange = this.activitySorted.find((activity) => activity.type === ActivityTypeEnum.STATUS_CHANGE);
			const lastChangedDate = new Date(lastStatusChange!.dateTime);
			return +formatDistanceToNowStrict(lastChangedDate, {unit: 'day'}).split(' ')[0];
		}
	}

	getPillBackgroundColor(createdTs: Date): string {
		const difference = this.getDaysAtStatus(createdTs);
		switch (true) {
			case difference <= 3:
				return 'bg-lightGreen';
			case difference > 3 && difference <= 7:
				return 'bg-yellow';
			case difference > 7:
				return 'bg-red';
			default:
				return 'bg-gray';
		}
	}

	navigateToProject() {
		this.buttonDisabled = true;
		this.router.navigate(['/project/' + this.project.id.toString()]).then((response: boolean) => {
			this.buttonDisabled = response;
		});
	}

	showToggleInactiveModal(): void {
		let dialogRef;
		if (this.project.active) {
			dialogRef = this.dialog.open(CodeConfirmationDialogComponent, {
				width: '300px',
				disableClose: true,
				data: {
					message: 'Are you sure you want to deactivate ' + this.project.name + '?',
					code: CodeTypeEnum.PROJECT_INACTIVE_REASON,
					codeLabel: 'Inactive Reason'
				}
			});
		} else {
			dialogRef = this.dialog.open(ConfirmationDialogComponent, {
				width: '300px',
				disableClose: true,
				data: {
					message: this.project.active
						? 'Are you sure you want to deactivate ' + this.project.name + '?'
						: 'Are you sure you want to activate ' + this.project.name + '?'
				}
			});
		}
		dialogRef.afterClosed().subscribe((result) => {
			if (result || result.success) {
				if (this.project.active == 1) {
					// deactivate
					this.projectsService.update(
						{
							id: this.project.id,
							inactiveReasonCode: result.code,
							active: 0
						},
						false,
						false
					);
					this.projectsService.findAllActive();
				} else {
					// activate
					this.projectsService.update(
						{
							id: this.project.id,
							inactiveReasonCode: '',
							active: 1
						},
						false,
						false
					);
					this.projectsService.findAllInactive();
				}
			} else {
				return;
			}
		});
	}

	codeDescription(code: string | undefined): string {
		let codeDescription = 'N/A';
		if (code) {
			const selectedCode = this.codes.find((c) => c.code === code);
			if (selectedCode && selectedCode.codeDescription) {
				codeDescription = selectedCode.codeDescription;
			}
		}
		return codeDescription;
	}
}
