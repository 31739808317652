import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../core/models/client.interface';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../core/models/user.interface';
import {ProjectsService} from '../core/services/projects.service';
import {Project} from '../core/models/project.interface';
import {SnackbarActionEnum} from '../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ProjectDocument} from '../core/models/project-document.interface';
import {TemplatesService} from '../core/services/templates.service';
import {ClientService} from '../core/services/client.service';
import {Template, TemplateUpdate} from '../core/models/template.interface';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TemplateCreateDialogComponent} from '../shared/components/template-create-dialog/template-create-dialog.component';
import {ProjectTypeEnum} from '../core/enums/project-type.enum';
import {TemplateTypeEnum} from '../core/enums/template-type.enum';
import {Subscription} from 'rxjs';
import {ProjectDocumentService} from '../core/services/project-document.service';
import {RfqConfirmDialogComponent} from '../shared/components/rfq-confirm-dialog/rfq-confirm-dialog.component';
@Component({
	selector: 'app-project-create',
	templateUrl: './project-create.component.html',
	styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit, OnDestroy {
	isLoading: boolean = false;
	form: FormGroup;
	selectedClient: Partial<Client> | undefined;
	prospectClient: boolean = false;
	teamMembers: User[] = [];
	project: Project;
	files: File[] = [];
	projectDocumentDtos: ProjectDocument[] = [];
	surveyFileSubscription: Subscription;
	surveyDtoSubscription: Subscription;
	surveyFileRemoveSubscription: Subscription;
	surveyDtoRemoveSubscription: Subscription;
	//locations is a FormArray and/or AbstractControl, but angular doesn't like either of those types so leave it as any type.
	locations: any;
	templateOptions: Template[] = [];
	selectedTemplate: Template;

	constructor(
		private templatesService: TemplatesService,
		private fb: FormBuilder,
		private projectsService: ProjectsService,
		private snackbar: MatSnackBar,
		private router: Router,
		private clientService: ClientService,
		private dialog: MatDialog,
		private projectDocumentService: ProjectDocumentService
	) {}

	ngOnInit() {
		this.form = this.fb.group(
			{
				name: ['', [Validators.required, Validators.maxLength(100)]],
				installDate: [''],
				estimateDate: [''],
				lastRfqDate: [''],
				lastDraftingContactDate: [''],
				address1: ['', Validators.maxLength(50)],
				address2: ['', Validators.maxLength(50)],
				city: ['', [Validators.required, Validators.maxLength(100)]],
				state: ['', [Validators.required, Validators.maxLength(10)]],
				postal: [''],
				country: ['', Validators.maxLength(10)],
				union: [false],
				nonUnion: [false],
				stockPermitReq: [false],
				prevailingWage: [false],
				seismic: [false],
				includeInstall: [false],
				structuralCalcs: [false],
				seismicCalcs: [false],
				survey: [false],
				skidding: [false],
				thirdPartyFreight: [false],
				specialInspection: [false],
				nightWork: [false],
				demo: [false],
				demoRemoval: [false],
				locations: this.fb.array([this.fb.control(null)]),
				locationId: [1, [Validators.required]],
				note: ['', Validators.maxLength(500)],
				transfer: [false]
			},
			{validator: this.installOrEstDateReq}
		);

		//clear the project in service, in case its previously set from another page.
		this.projectsService.clearProject();

		this.locations = this.form.get('locations')!;
		this.surveyFileSubscription = this.projectDocumentService.surveyFileSubject.subscribe((file) => {
			this.files.push(file);
		});
		this.surveyDtoSubscription = this.projectDocumentService.surveyDtoSubject.subscribe((dto) => {
			this.projectDocumentDtos.push(dto);
		});
		this.surveyFileRemoveSubscription = this.projectDocumentService.surveyFileRemoveSubject.subscribe((file) => {
			this.handleFileRemoveEmit(file);
		});
		this.surveyDtoRemoveSubscription = this.projectDocumentService.surveyDtoRemoveSubject.subscribe((dto) => {
			this.handleDocumentDtoRemoveEmit(dto);
		});
	}
	confirmRfqStatus(returnToProjects: boolean) {
		const dialogRef: MatDialogRef<RfqConfirmDialogComponent> = this.dialog.open(RfqConfirmDialogComponent, {disableClose: true});
		dialogRef.afterClosed().subscribe((response) => {
			if (response) {
				this.createProject(true, returnToProjects);
			} else {
				this.createProject(false, returnToProjects);
			}
		});
	}

	handleFileAddEmit(files: File[]) {
		files.forEach((file: File) => {
			this.files.push(file);
		});
	}

	handleFileRemoveEmit(file: File) {
		this.files.splice(this.files.indexOf(file), 1);
		let documentDto = this.projectDocumentDtos.find((dto: ProjectDocument) => dto.documentName === file.name);
		this.projectDocumentDtos.splice(this.projectDocumentDtos.indexOf(documentDto!), 1);
	}

	handleDocumentDtoEmit(documents: ProjectDocument[]) {
		documents.forEach((document: ProjectDocument) => {
			if (
				!this.projectDocumentDtos.find(
					(dto: ProjectDocument) => dto.documentName === document.documentName && dto.installLocationId === document.installLocationId
				)
			) {
				this.projectDocumentDtos.push(document);
			}
		});
	}

	handleDocumentDtoRemoveEmit(document: ProjectDocument) {
		this.projectDocumentDtos = this.projectDocumentDtos.filter((doc) => document.documentName !== doc.documentName);
	}

	installOrEstDateReq(form: FormGroup) {
		if (form.value.installDate === '' && form.value.estimateDate === '') {
			return {installEstimateDateReq: true};
		}
		return null;
	}

	selectClient(selectedClient: Partial<Client>) {
		this.selectedClient = selectedClient;

		if (selectedClient) {
			this.clientService.findAllTemplatesForClient(selectedClient.id!, 'project').subscribe(
				(response) => {
					this.templateOptions = response;
				},
				(error) => {
					this.snackbar.open('Failed to Load Project Templates', SnackbarActionEnum.ERROR);
				}
			);
		}
		//reset form and default 'name' to client name
		this.form.reset();
		this.form.get('name')?.setValue(selectedClient.name);
	}

	addUser(user: User) {
		this.teamMembers.push(user);
	}

	removeUser(id: number) {
		const index = this.teamMembers.findIndex((user: User) => user.id === id);
		this.teamMembers.splice(index, 1);
	}

	submitDisabled() {
		const locations = this.form.get('locations') as FormArray;
		const invalidLocationSetup: boolean = !locations.controls[0].value && locations.controls[1]?.value;
		if (invalidLocationSetup) {
			locations.controls[0].setErrors({required: true});
			locations.controls[0].markAsTouched();
		} else if (locations.controls.length === 1) {
			locations.controls[0].setErrors(null);
		}
		//check for a client selection
		//check if project details form valid
		return this.form.invalid || !this.selectedClient || invalidLocationSetup;
	}

	createProject(rfqStatus: boolean, returnToProjects: boolean) {
		this.isLoading = true;
		//combine clientId, members, details, files, before submitting
		let project: Project = this.form.getRawValue();

		project.clientId = this.selectedClient?.id!;

		if (project.installDate) {
			// @ts-ignore
			project.installDate = new Date(project.installDate).toISOString().substring(0, 10);
		}
		if (project.estimateDate) {
			// @ts-ignore
			project.estimateDate = new Date(project.estimateDate).toISOString().substring(0, 10);
		}

		project.members = this.teamMembers.map((user: User) => {
			return {userInfo: user};
		});

		project.documents = this.projectDocumentDtos;

		const wholeProject = new FormData();
		for (let i = 0; i < this.files.length; i++) {
			wholeProject.append('files', this.files[i], this.files[i].name);
		}
		wholeProject.append('body', JSON.stringify(project));

		this.projectsService.create(wholeProject, rfqStatus).subscribe(
			(response: Project) => {
				this.isLoading = false;
				this.snackbar.open('Project Created', SnackbarActionEnum.SUCCESS);
				if (returnToProjects) {
					this.router.navigate(['/projects']);
				} else {
					this.router.navigate([`/project/${response.id}`]);
				}
			},
			(error) => {
				this.isLoading = false;
				this.snackbar.open('Failed to Create Project', SnackbarActionEnum.ERROR);
			}
		);
	}

	prospectSelected(prospectChecked: boolean) {
		this.prospectClient = prospectChecked;
		if (prospectChecked) {
			this.selectedClient = {
				id: 'PROSPECT'
			};
			this.selectClient(this.selectedClient);
		} else {
			this.selectedClient = undefined;
		}
	}

	ngOnDestroy() {
		this.surveyFileSubscription.unsubscribe();
		this.surveyDtoSubscription.unsubscribe();
		this.surveyFileRemoveSubscription.unsubscribe();
		this.surveyDtoRemoveSubscription.unsubscribe();
	}

	saveTemplate() {
		const dialog = this.dialog.open(TemplateCreateDialogComponent);
		dialog.componentInstance.templateOptions = this.templateOptions;

		dialog.afterClosed().subscribe((result) => {
			if (result?.templateName) {
				//We're creating a new template and project
				let project: Project = this.form.getRawValue();
				project.clientId = <string>this.selectedClient?.id;
				project.type = ProjectTypeEnum['TEMPLATE'];

				if (project.installDate) {
					// @ts-ignore
					project.installDate = new Date(project.installDate).toISOString().substring(0, 10);
				}
				if (project.estimateDate) {
					// @ts-ignore
					project.estimateDate = new Date(project.estimateDate).toISOString().substring(0, 10);
				}
				const projectForm = new FormData();
				projectForm.append('body', JSON.stringify(project));

				this.projectsService.create(projectForm, false).subscribe(
					(response: Project) => {
						// Create template record to save
						const template: TemplateUpdate = {
							description: result?.templateName,
							projectId: response.id,
							clientId: this.selectedClient?.id,
							type: TemplateTypeEnum['PROJECT']
						};

						this.templatesService.create(template).subscribe(
							(newTemplate: Template) => {
								this.snackbar.open('Template Saved', SnackbarActionEnum.SUCCESS);
								this.templateOptions.push(newTemplate);
								this.selectedTemplate = newTemplate;
							},
							() => {
								this.snackbar.open('Failed to Save Template', SnackbarActionEnum.ERROR);
							}
						);
					},
					(error) => {
						this.snackbar.open('Failed to Save Project for Template', SnackbarActionEnum.ERROR);
					}
				);
				return;
			}
			if (result?.selectedTemplate) {
				// Updating an existing project template
				const project = this.form.getRawValue();
				project.id = result.selectedTemplate.projectId;
				project.type = ProjectTypeEnum['TEMPLATE'];
				this.projectsService.update(project, false, false, 'Project Template Updated');
				this.selectedTemplate = result.selectedTemplate;
			}
		});
	}
}
