import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormControl, AbstractControl, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {FreightBroker} from '../../../core/models/freight-broker.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FreightBrokerStatusEnum} from '../../../core/enums/freight-broker-status.enum';
import {ShipmentService} from '../../../core/services/shipment.service';
import {FreightService} from '../../../core/services/freight.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BrokerInfoComponent} from '../../../maintenance/brokers/broker-info/broker-info.component';
import {MatDialog} from '@angular/material/dialog';
import {ValidatorRegexEnum} from '../../../core/enums/validator-regex.enum';

export interface BrokerForm {
	id: number;
	brokerId: number;
	name: {editable: boolean; value: string};
	email: {editable: boolean; value: string};
	brokerStatus?: FreightBrokerStatusEnum;
	shipmentIds?: number;
	projectId?: number | undefined;

	freightId?: number;
	selected?: boolean;
}

@Component({
	selector: 'app-broker-carrier',
	templateUrl: './broker.component.html',
	styleUrls: ['./broker.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class BrokerComponent implements OnInit {
	@Input() shipmentIds: number[];
	@Input() projectId: number | undefined;
	@Input() shippingDetailsInvalid = false;

	displayedColumns: string[] = ['select', 'name', 'info', 'status'];
	dataSource: MatTableDataSource<AbstractControl> = new MatTableDataSource<AbstractControl>();
	filterValue: string = '';
	isLoading: boolean = true;
	expandedElement: BrokerForm | null;
	brokerForm: FormGroup = new FormGroup({});
	brokers: FreightBroker[] = [];

	constructor(
		private fb: FormBuilder,
		private _formBuilder: FormBuilder,
		private shipmentService: ShipmentService,
		private freightService: FreightService,
		private _snackBar: MatSnackBar,
		private changeDetectorRefs: ChangeDetectorRef,
		private dialog: MatDialog
	) {}

	async ngOnInit(): Promise<void> {
		this.brokerForm = this._formBuilder.group({
			formRows: this._formBuilder.array([])
		});

		await this.getBrokers();
	}

	async getBrokers(): Promise<void> {
		await this.freightService.findAllBrokersByShipmentIds(this.shipmentIds).subscribe((brokerCarriers) => {
			this.buildFormGroup(brokerCarriers);
		});
	}

	buildFormGroup(brokers: FreightBroker[]): void {
		this.brokerForm = this.fb.group({
			formRows: this.fb.array(
				brokers.map((val) =>
					this.fb.group({
						id: new FormControl(val.id),
						brokerId: new FormControl(val.brokerId),
						name: this.fb.group({editable: false, value: new FormControl(val.name, Validators.required)}),
						email: this.fb.group({
							editable: false,
							value: new FormControl(val.email, [Validators.pattern(ValidatorRegexEnum.EMAIL)])
						}),
						phone: new FormControl(val.phone),
						phoneExt: new FormControl(val.phoneExt),
						brokerStatus: new FormControl(val.brokerStatus),
						freightId: new FormControl(val.freightId),
						selected: new FormControl(!!val.freightId)
					})
				)
			)
		});
		this.isLoading = false;
		this.dataSource = new MatTableDataSource((this.brokerForm.get('formRows') as FormArray).controls);
		this.applyFilterPredicate();
		this.dataSource.filter = this.filterValue;
	}

	toggleAllRows(): void {
		const control = this.brokerForm.get('formRows') as FormArray;
		if (this.isAllSelected()) {
			control.controls.forEach((row) => (row.value.selected = false));
			return;
		}
		control.controls.forEach((row) => (row.value.selected = true));
	}

	isNoneSelected(): boolean {
		const control = this.brokerForm.get('formRows') as FormArray;
		return control.controls.every((row) => !row.value.selected);
	}

	isAllSelected(): boolean {
		const control = this.brokerForm.get('formRows') as FormArray;
		return control.controls.every((row) => row.value.selected);
	}

	applyFilter() {
		this.dataSource.filter = this.filterValue.trim().toLowerCase();
	}

	async openBrokerDialog(editMode: boolean, broker?: FreightBroker): Promise<void> {
		const brokerDialog = this.dialog.open(BrokerInfoComponent, {
			data: {
				editMode: editMode,
				broker: broker
			}
		});

		brokerDialog.afterClosed().subscribe(() => this.getBrokers());
	}

	requestRates(): void {
		// Valid Form
		if (!this.brokerForm.valid) {
			this._snackBar.open('Empty Broker/Carrier or Invalid Email provided.', '', {
				duration: 2000
			});
			return;
		}

		this.isLoading = true;
		this.brokers = this.selectedBrokers;
		const requestPlural = `Request${this.brokers.length > 1 ? 's' : ''}`;
		this.freightService.requestRates(this.shipmentIds, this.brokers, this.projectId).subscribe({
			next: (shipmentBrokerResponse) => {
				this.buildFormGroup(shipmentBrokerResponse);
				this.dataSource.filter = this.filterValue;
				this.changeDetectorRefs.detectChanges();
				this.isLoading = false;

				this._snackBar.open(`Rate ${requestPlural} Sent`, SnackbarActionEnum.SUCCESS, {
					duration: 2000
				});
			},
			error: () => {
				this.isLoading = false;
				this._snackBar.open(`Error Sending Rate ${requestPlural}`, SnackbarActionEnum.ERROR, {
					duration: 2000
				});
			}
		});
	}

	get selectedBrokers() {
		return this.brokerForm.value.formRows
			.filter((row: BrokerForm) => {
				return row.selected;
			})
			.map((row: BrokerForm) => {
				return {
					id: row.id,
					brokerId: row.brokerId,
					freightId: row.freightId,
					name: row.name.value,
					email: row.email.value,
					brokerStatus: row.brokerStatus,
					shipmentIds: row.shipmentIds
				};
			});
	}

	applyFilterPredicate(): void {
		const filterPredicate = this.dataSource.filterPredicate;
		this.dataSource.filterPredicate = (data: AbstractControl, filter) => {
			const filterValue = {
				...data.value,
				name: data.value.name.value,
				email: data.value.email.value
			};
			delete filterValue.id;
			delete filterValue.selected;
			delete filterValue.brokerId;
			delete filterValue.selected;
			delete filterValue.freightId;
			return filterPredicate.call(this.dataSource, filterValue, filter);
		};
	}

	getStatusClass(element: any): string | null {
		switch (element.controls.brokerStatus.value) {
			case FreightBrokerStatusEnum.SENT:
				return 'status-sent';
			case FreightBrokerStatusEnum.ERROR:
				return 'status-error';
			case FreightBrokerStatusEnum.BID:
				return 'status-bid';
			default:
				return null;
		}
	}
}
