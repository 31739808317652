import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {QuickQuote} from '../../core/models/quick-quote.interface';
import {QuickQuoteConfig} from '../../core/models/quick-quote-config.interface';
import {QuickQuoteService} from '../../core/services/quick-quote.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DefaultHandlingUnit} from '../../shared/components/handling-unit/handling-unit.component';

@Component({
	selector: 'app-quick-quote-config',
	templateUrl: './quick-quote-config.component.html',
	styleUrls: ['./quick-quote-config.component.scss']
})
export class QuickQuoteConfigComponent implements OnInit {
	@ViewChild('room', {static: false}) set content(content: ElementRef) {
		if (content) {
			this.roomDiv = content.nativeElement;
		}
	}
	@Input() index: number = 0;
	@Input() componentFormArrayName: string = '';
	@Output() updateEstimate = new EventEmitter<void>();
	quickQuoteForm: FormGroup;

	roomDiv: HTMLElement;
	heightClass = '';
	shelfWidthClass = '';
	shelfHeightClass = '';
	aisleClass = '';
	backAisleClass = '';
	mainAisleClass = '';
	shelfSpanClass = '';
	extraShelfClass = '';

	pixelsPerIn = 0;

	zoom = '40%';

	roomArea = 0;
	displayWidth = 0;
	displayLength = 0;

	widthSplit1 = 0;
	widthSplit2 = 0;
	lengthSplit1 = 0;
	lengthSplit2 = 0;

	quickQuote: QuickQuote;
	quickQuoteConfig: QuickQuoteConfig | {} = {};

	loaded = false;
	busy = false;

	constructor(
		private rootFormGroup: FormGroupDirective,
		private quickQuoteService: QuickQuoteService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.quickQuoteForm = this.rootFormGroup.form as FormGroup;
		if (this.componentFormArrayName > '') {
			this.quickQuoteForm = (this.quickQuoteForm.controls[this.componentFormArrayName] as FormArray).controls[this.index] as FormGroup;
		}

		//this.buildQuickQuoteForm();
		if (this.quickQuoteForm.controls['count']) {
			this.quickQuoteForm.controls['count'].valueChanges.subscribe((val) => {
				this.updateEstimates();
			});
		}
		this.toggleMezzanine();
		this.generateEstimate();
	}

	generateEstimate() {
		this.loaded = false;
		this.quickQuoteForm.controls['estimate'].setValue(null);
		if (this.quickQuoteForm.valid) {
			this.busy = true;
			Object.assign(this.quickQuoteConfig, this.quickQuoteForm.value);
			this.quickQuoteService.getQuickQuote(this.quickQuoteConfig).subscribe({
				next: (quickQuote) => {
					this.quickQuote = quickQuote;
					this.quickQuoteForm.controls['estimate'].setValue(quickQuote);

					this.busy = false;
					this.loaded = true;
					this.generateRoom();
					this.updateEstimates();
				},
				error: (err) => {
					this.updateEstimates();
					this._snackBar.open('Get Quick Quote Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	generateRoom() {
		setTimeout(() => {
			if (this.roomDiv) {
				this.widthSplit1 = Math.floor(this.quickQuote.widthDisplayCount / 2);
				this.widthSplit2 = Math.ceil(this.quickQuote.widthDisplayCount / 2);
				this.lengthSplit1 = Math.floor(this.quickQuote.lengthDisplayCount / 2);
				this.lengthSplit2 = Math.ceil(this.quickQuote.lengthDisplayCount / 2);
				let shelfSpan = 0;
				let aisleSpan = 0;
				let roomPx = this.roomDiv.clientWidth;
				let roomW = this.quickQuote.roomWidth;
				let roomL = this.quickQuote.roomLength;
				this.roomArea = roomW * roomL;
				this.pixelsPerIn = roomPx / roomW;
				this.heightClass = Math.floor(roomL * this.pixelsPerIn) + 'px';
				this.shelfWidthClass = Math.floor(this.quickQuote.shelfDisplayWidth * this.pixelsPerIn) - 2 + 'px';
				this.shelfHeightClass = Math.floor(this.quickQuote.shelfDisplayLength * this.pixelsPerIn) - 2 + 'px';
				aisleSpan = Math.floor(this.quickQuote.aisle * this.pixelsPerIn) - 2;
				this.aisleClass = aisleSpan + 'px';
				this.backAisleClass = Math.floor(this.quickQuote.backAisle * this.pixelsPerIn) + 'px';
				this.mainAisleClass = Math.floor(this.quickQuote.mainAisle * this.pixelsPerIn) + 'px';
				shelfSpan = (Math.floor(this.quickQuote.shelfDisplayWidth * this.pixelsPerIn) - 2) * this.quickQuote.widthDisplayCount;
				if (this.quickQuote.orientation === 'LENGTH') {
					shelfSpan += Math.floor(this.quickQuote.extraShelfSize * this.pixelsPerIn);
					this.displayWidth = this.quickQuote.shelfDisplayLength;
					this.displayLength = this.quickQuote.shelfDisplayWidth;
				} else {
					shelfSpan += aisleSpan;
					this.displayWidth = this.quickQuote.shelfDisplayWidth;
					this.displayLength = this.quickQuote.shelfDisplayLength;
				}
				this.shelfSpanClass = shelfSpan + 'px';
				this.extraShelfClass = Math.floor(this.quickQuote.extraShelfSize * this.pixelsPerIn) + 'px';
			}
		}, 0);
	}

	setZoom() {
		if (this.quickQuoteForm.controls['zoom'].value) {
			this.zoom = this.quickQuoteForm.controls['zoom'].value * 20 + '%';
			this.generateRoom();
		}
	}

	zoomIn() {
		if (this.quickQuoteForm.controls['zoom'].value && this.quickQuoteForm.controls['zoom'].value < 5) {
			this.quickQuoteForm.controls['zoom'].setValue(this.quickQuoteForm.controls['zoom'].value + 1);
		}
		this.setZoom();
	}

	zoomOut() {
		if (this.quickQuoteForm.controls['zoom'].value && this.quickQuoteForm.controls['zoom'].value > 1) {
			this.quickQuoteForm.controls['zoom'].setValue(this.quickQuoteForm.controls['zoom'].value - 1);
		}
		this.setZoom();
	}

	inputFocused(e: Event) {
		const input = e.target as HTMLInputElement;
		input.select();
	}

	get shelvingTiers(): number {
		let tiers = 0;
		if (this.quickQuoteForm.controls['height'].value) {
			tiers = Math.floor(this.quickQuoteForm.controls['height'].value / 2);
		}
		return tiers;
	}

	get totalCost(): number {
		let total = 0;
		if (this.quickQuote.shelfCost) {
			total = this.quickQuote.shelfCost + this.quickQuote.carriageCost;
			if (this.quickQuoteForm.controls['airFlow'].value) {
				total += (this.quickQuote.shelfCost + this.quickQuote.carriageCost) * this.quickQuote.airFlowPct;
			}
			if (this.quickQuoteForm.controls['tray'].value && this.quickQuoteForm.controls['tray'].enabled) {
				total += (this.quickQuote.shelfCost + this.quickQuote.carriageCost) * this.quickQuote.trayPct;
			}
			if (this.quickQuoteForm.controls['lighting'].value) {
				total += (this.quickQuote.shelfCost + this.quickQuote.carriageCost) * this.quickQuote.lightingPct;
			}
			if (this.quickQuoteForm.controls['mezzanine'].value && this.quickQuoteForm.controls['mezzanine'].enabled) {
				total += (this.quickQuote.shelfCost + this.quickQuote.carriageCost) * this.quickQuote.mezzaninePct;
			}
			if (this.quickQuoteForm.controls['install'].value) {
				total += (this.quickQuote.shelfCost + this.quickQuote.carriageCost) * this.quickQuote.installPct;
			}
		}
		return total;
	}

	heightChange() {
		if (
			this.quickQuoteForm.controls['tiers'].value &&
			this.quickQuoteForm.controls['height'].value &&
			this.quickQuoteForm.controls['tiers'].value > this.quickQuoteForm.controls['height'].value / 2
		) {
			this.quickQuoteForm.controls['tiers'].setValue(Math.floor(this.quickQuoteForm.controls['height'].value / 2));
		}
		this.toggleMezzanine();
		this.generateEstimate();
	}

	toggleMezzanine() {
		if (this.quickQuoteForm.controls['height'].value && this.quickQuoteForm.controls['height'].value >= 14) {
			this.quickQuoteForm.controls['mezzanine'].enable();
		} else {
			this.quickQuoteForm.controls['mezzanine'].setValue(false);
			this.quickQuoteForm.controls['mezzanine'].disable();
		}
	}

	toggleAirFlow() {
		if (this.quickQuoteForm.controls['airFlow'].value) {
			this.quickQuoteForm.controls['tray'].setValue(false);
			this.quickQuoteForm.controls['tray'].disable();
		} else {
			this.quickQuoteForm.controls['tray'].enable();
		}
		this.updateEstimates();
	}

	get hasErrors(): boolean {
		return this.carriageErrors || this.shelfErrors;
	}

	get carriageErrors(): boolean {
		let errors = false;
		if (this.quickQuote) {
			errors = this.quickQuote.carriageError || this.quickQuote.trackError || this.quickQuote.trackHardwareError;
		}
		return errors;
	}

	get shelfErrors(): boolean {
		let errors = false;
		if (this.quickQuote) {
			errors =
				this.quickQuote.shelfError ||
				this.quickQuote.extraShelfError ||
				this.quickQuote.tierComponentError ||
				this.quickQuote.extraTierComponentError;
		}
		return errors;
	}

	updateEstimates() {
		this.updateEstimate.emit();
	}
}
