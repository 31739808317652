import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TitleCasePipe} from '@angular/common';
import {TaskTypeService} from '../../../core/services/task-type.service';
import {TaskType} from '../../../core/models/task-type.model';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
	selector: 'app-task-type-list-detail',
	templateUrl: './task-type-list-detail.component.html',
	styleUrls: ['./task-type-list-detail.component.scss']
})
export class TaskTypeListDetailComponent implements OnInit {
	@Input() task: TaskType;
	form: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private taskTypeService: TaskTypeService,
		private snackbar: MatSnackBar,
		private titlecasePipe: TitleCasePipe
	) {
		this.form = this.formBuilder.group({
			id: [''],
			name: ['', Validators.required],
			glList: [''],
			catList: [''],
			multiplier: ['', Validators.required],
			listType: ['']
		});
	}

	ngOnInit() {
		this.form.patchValue(this.task);
		this.form.disable();
	}

	onSubmit() {
		const formValue = this.form.getRawValue();
		const id = this.form.get('id')?.value;
		this.taskTypeService.update(id, formValue).subscribe(
			(response) => {
				this.snackbar.open('Task Type Updated!', SnackbarActionEnum.SUCCESS);
				this.taskTypeService.findAll();
				this.form.disable();
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}

	openConfirmDeleteModal(task: TaskType) {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				title: 'Confirm Delete',
				message: 'Are you sure you want to delete the ' + this.titlecasePipe.transform(task.name) + ' Task-Type?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.deleteCode(task.id!);
			}
		});
	}

	deleteCode(id: number) {
		this.taskTypeService.remove(id).subscribe(
			(response) => {
				this.taskTypeService.findAll();
				this.snackbar.open('Task Type Deleted!', SnackbarActionEnum.SUCCESS);
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}
}
