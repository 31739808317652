<mat-expansion-panel [formGroup]="parentForm">
	<mat-expansion-panel-header>
		<mat-panel-title
			*ngIf="
				!ngForm.submitted ||
				(parentForm.get('totalSalesTax')?.valid &&
					parentForm.get('amountExemptFromTax')?.valid &&
					parentForm.get('amountSubjectToTax')?.valid)
			"
		>
			Additional Info
		</mat-panel-title>
		<mat-panel-title
			*ngIf="
				ngForm.submitted &&
				(parentForm.get('totalSalesTax')?.invalid ||
					parentForm.get('amountExemptFromTax')?.invalid ||
					parentForm.get('amountSubjectToTax')?.invalid)
			"
			class="text-red"
		>
			Additional Info*
		</mat-panel-title>
	</mat-expansion-panel-header>

	<div class="ml-2 mb-4">Total Cost of Material: {{ totalCostOfMaterial | currency }}</div>

	<span class="ml-2 mb-2"> Tax Info </span>

	<div class="flex flex-row space-x-2 mt-2">
		<mat-form-field class="w-1/2">
			<span matPrefix class="ml-3 text-gray-500">$</span>
			<mat-label> Tax Amount </mat-label>
			<input matInput IsDecimal formControlName="totalSalesTax" #taxes (blur)="checkForTrailingZero(taxes)" />
			<mat-error *ngIf="parentForm.get('totalSalesTax')?.touched && parentForm.get('totalSalesTax')?.invalid">
				Value is required
			</mat-error>
		</mat-form-field>

		<mat-form-field class="w-1/2">
			<span matPrefix class="ml-3 text-gray-500">$</span>
			<mat-label> Amount Exempt from Taxes </mat-label>
			<input matInput IsDecimal formControlName="amountExemptFromTax" #taxExempt (blur)="checkForTrailingZero(taxExempt)" />
			<mat-error *ngIf="parentForm.get('amountExemptFromTax')?.touched && parentForm.get('amountExemptFromTax')?.invalid">
				Value is required
			</mat-error>
		</mat-form-field>
	</div>

	<div class="flex flex-row space-x-2">
		<mat-form-field class="w-1/2">
			<span matPrefix class="ml-3 text-gray-500">$</span>
			<mat-label> Amount Subject to Sales Tax </mat-label>
			<input matInput IsDecimal formControlName="amountSubjectToTax" #taxSubject (blur)="checkForTrailingZero(taxSubject)" />
			<mat-error *ngIf="parentForm.get('amountSubjectToTax')?.touched && parentForm.get('amountSubjectToTax')?.invalid">
				Value is required
			</mat-error>
		</mat-form-field>
		<div class="w-1/2"></div>
	</div>

	<span class="ml-2 mb-2"> Team Members </span>

	<div class="flex flex-row space-x-2 w-full mt-2">
		<mat-form-field class="w-1/2">
			<mat-label> Sales Person</mat-label>
			<input matInput formControlName="salesPerson" />
		</mat-form-field>

		<mat-form-field class="w-1/2">
			<mat-label> Quoter</mat-label>
			<input matInput formControlName="quoter" />
		</mat-form-field>
	</div>

	<div class="flex flex-row space-x-2 w-full">
		<mat-form-field class="w-1/2">
			<mat-label> Account Manager </mat-label>
			<input matInput formControlName="accountManager" />
		</mat-form-field>
		<div class="w-1/2"></div>
	</div>

	<span class="ml-2 mb-2">Internal Notes</span>

	<mat-form-field class="w-full mt-2">
		<mat-label> Description </mat-label>
		<textarea rows="3" formControlName="note" matInput></textarea>
	</mat-form-field>

	<span class="ml-2 mb-2">Client Visible Notes</span>

	<mat-form-field class="w-full mt-2">
		<mat-label> Description </mat-label>
		<textarea rows="3" formControlName="clientComment" matInput></textarea>
		<mat-error *ngIf="parentForm.get('clientComment')?.hasError">Max length is 250 characters</mat-error>
	</mat-form-field>
</mat-expansion-panel>
