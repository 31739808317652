import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {TransitTimeService} from '../../../core/services/transit-time.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-create-transit-time',
	templateUrl: './create-transit-time.component.html',
	styleUrls: ['./create-transit-time.component.scss']
})
export class CreateTransitTimeComponent {
	form: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialogRef: MatDialogRef<CreateTransitTimeComponent>,
		private transitTimeService: TransitTimeService
	) {
		this.form = this.formBuilder.group({
			state: ['', [Validators.required, Validators.maxLength(10)]],
			days: ['', [Validators.required, Validators.pattern(/^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)]]
		});
	}
	createTransitTime() {
		this.transitTimeService.create(this.form.getRawValue()).subscribe(
			(response) => {
				this.dialogRef.close();
				this.snackbar.open('Transit Time Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.transitTimeService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
}
