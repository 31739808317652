import {APP_INITIALIZER, Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable()
export class AppInitService {
	constructor(private authService: AuthService, private location: Location, private router: Router) {}

	async initialLoad(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.authService.getCurrentUser().subscribe({
				next: (response) => {
					this.authService.userLoggedIn = true;
					this.authService.userDataSource.next(response.user);
					resolve();
				},
				error: (err) => {
					this.authService.redirectUrl = this.location.path();
					this.router.navigate(['/login']);
					resolve();
				}
			});
		});
	}
}

export function app_init(appInitService: AppInitService): Function {
	return () => appInitService.initialLoad();
}

export const AppInitProvider = {provide: APP_INITIALIZER, useFactory: app_init, deps: [AppInitService], multi: true};
