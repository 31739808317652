import {Component, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Template} from '../../../core/models/template.interface';

@Component({
	selector: 'app-template-create-dialog',
	templateUrl: './template-create-dialog.component.html',
	styleUrls: ['./template-create-dialog.component.scss']
})
export class TemplateCreateDialogComponent {
	templateName: string = '';
	templateOptions: Template[] = [];
	selectedTemplate: Template | null;
	constructor(public dialogRef: MatDialogRef<TemplateCreateDialogComponent>) {}

	saveTemplate() {
		this.dialogRef.close({templateName: this.templateName, selectedTemplate: this.selectedTemplate});
	}

	templateSelected(template: Template) {
		//Since a template was selected, clear out the 'new template' name input to avoid confusion
		if (template) {
			this.templateName = '';
		}
	}

	templateNameChange() {
		this.selectedTemplate = null;
	}
}
