import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Quote} from '../../core/models/quote.interface';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {QuoteService} from '../../core/services/quote.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RequestApprovalDialogComponent} from './dialogs/request-approval-dialog/request-approval-dialog.component';
import {DecimalPipe, Location, TitleCasePipe} from '@angular/common';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import JSPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {QuoteApprovalRequest} from '../../core/models/quote-approval-request.interface';
import {ProjectsService} from '../../core/services/projects.service';
import {combineLatestWith, Observable, Subject, Subscription} from 'rxjs';
import {Project} from '../../core/models/project.interface';
import {ActivatedRoute, Params, Router, RouterStateSnapshot} from '@angular/router';
import {CommaRemovalPipe} from '../../shared/pipes/comma-removal.pipe';
import {AssociatedCostEnum} from '../../core/enums/associated-cost.enum';
import {QuoteLineCategoryEnum} from '../../core/enums/quote-line-category.enum';
import {QuoteLine} from '../../core/models/quote-line.interface';
import {Contact} from '../../core/models/contact.model';
import {QuoteAccessorial} from '../../core/models/quote-accessorial.interface';
import {ClientPreview} from '../../core/models/client-preview.interface';
import {ClientService} from '../../core/services/client.service';
import {PartService} from '../../core/services/part.service';
import {CreateOrderResponseComponent} from './dialogs/create-order-response/create-order-response.component';
import {SalesOrderResponse} from '../../core/models/sales-order-response.model';
import {ProjectRackSolidComponent} from '../project-rack-solid/project-rack-solid.component';
import {GlCodeTypeEnum} from '../../core/enums/gl-code-type.enum';
import {ConfigTypeEnum} from '../../core/enums/config-type.enum';
import {QuotePart} from '../../core/models/quote-part.interface';
import {PartData} from '../../core/models/part-data.interface';
import {Part} from '../../core/models/part.interface';
import {QuoteStatusEnum} from '../../core/enums/quote-status.enum';
import {ChangeRequestInfoComponent} from './change-request-info/change-request-info.component';
import {QuoteLineTypeEnum} from '../../core/enums/quote-line-type.enum';
import {UrlService} from '../../core/services/url.service';
import {QuoteConfig} from '../../core/models/quote-config.interface';
import {QuoteConfigShelf} from '../../core/models/quote-config-shelf.interface';
import {SaveQuoteChangesDialogComponent} from './dialogs/save-quote-changes-dialog/save-quote-changes-dialog.component';
import {SalesOrderCreate} from '../../core/models/sales-order-create.interface';
import {CodeService} from '../../core/services/code.service';
import {ProjectInstallShip} from '../../core/models/project-install-ship.interface';
import {ErrorDialogComponent} from './dialogs/error-dialog/error-dialog.component';
import {UnderscoreRemove} from '../../shared/pipes/underscore-remove.pipe';
import {QuotePdf, QuotePdfPage} from '../../core/models/quote-pdf.interface';
import {ProjectMember} from '../../core/models/project-member.interface';
import {PartCategoryEnum} from '../../core/enums/part-category.enum';
@Component({
	selector: 'app-project-quote',
	templateUrl: './project-quote.component.html',
	styleUrls: ['./project-quote.component.scss']
})
export class ProjectQuoteComponent implements OnInit, OnDestroy {
	@ViewChild('quotePreview', {static: false}) quotePreview: ElementRef;
	partsListChangedSubject: Subject<void> = new Subject<void>();

	quote: Quote;
	quoteForm: FormGroup = new FormGroup({
		clientName: new FormControl(''),
		note: new FormControl(''),
		accessorials: this.fb.array([]),
		totalSalesTax: new FormControl('0.00', Validators.required),
		amountExemptFromTax: new FormControl('0.00', Validators.required),
		amountSubjectToTax: new FormControl('0.00', Validators.required),
		manuallyAddedPartTotal: new FormControl('0.00'),
		grandTotal: new FormControl('0.00'),
		salesPerson: new FormControl(''),
		quoter: new FormControl(''),
		accountManager: new FormControl(''),
		lineItemParts: new FormArray([]),
		associatedCosts: new FormArray([]),
		miscMaterials: new FormArray([]),
		miscMaterialTotal: new FormControl(0),
		miscMaterialWeight: new FormControl(0),
		clientComment: new FormControl('', Validators.maxLength(250)),
		shipDate: new FormControl(null),
		installDays: new FormControl(0),
		installComment: new FormControl(null),
		installers: new FormControl(0),
		salesOrderNo: new FormControl(''),
		paymentTerms: new FormControl(''),
		getBacks: new FormArray([]),
		discount: new FormGroup({
			type: new FormControl(GlCodeTypeEnum.DISCOUNT),
			glNumber: new FormControl(46100),
			description: new FormControl('Discounts and Allowances'),
			qty: new FormControl(1),
			comment: new FormControl(''),
			category: new FormControl(QuoteLineCategoryEnum.GENERAL_LEDGER),
			price: new FormControl(0, Validators.max(0))
		}),
		materialCost: new FormGroup({
			price: new FormControl(0),
			glNumber: new FormControl(46200),
			description: new FormControl('Material Cost Surcharge'),
			type: new FormControl(GlCodeTypeEnum.MATERIAL_COST_SURCHARGE),
			qty: new FormControl(1),
			comment: new FormControl('')
		}),
		freightOption: new FormGroup({
			type: new FormControl(GlCodeTypeEnum.FREIGHT),
			glNumber: new FormControl(48000),
			description: new FormControl('Freight'),
			category: new FormControl(QuoteLineCategoryEnum.GENERAL_LEDGER),
			qty: new FormControl(1),
			price: new FormControl(0),
			weight: new FormControl(0, [Validators.required])
		}),
		rackSolidLineItem: new FormGroup({
			glNumber: new FormControl(43000),
			description: new FormControl('Rack Solid Shelving Frames'),
			category: new FormControl(QuoteLineCategoryEnum.GENERAL_LEDGER),
			qty: new FormControl(1),
			price: new FormControl(0),
			weight: new FormControl(0),
			type: new FormControl(GlCodeTypeEnum.RACK_SOLID)
		}),
		particleBoardLineItem: new FormGroup({
			glNumber: new FormControl(42000),
			description: new FormControl('Particle Board Shelves'),
			qty: new FormControl(1),
			category: new FormControl(QuoteLineCategoryEnum.GENERAL_LEDGER),
			price: new FormControl(0),
			type: new FormControl(GlCodeTypeEnum.PARTICLE_BOARD)
		}),
		wireGridLineItem: new FormGroup({
			glNumber: new FormControl(43010),
			description: new FormControl('Wire Grid Shelves for Frames'),
			category: new FormControl(QuoteLineCategoryEnum.GENERAL_LEDGER),
			qty: new FormControl(1),
			price: new FormControl(0),
			type: new FormControl(GlCodeTypeEnum.WIRE_GRID)
		}),
		steelPerfLineItem: new FormGroup({
			glNumber: new FormControl(43025),
			type: new FormControl(GlCodeTypeEnum.STEEL_PERF),
			description: new FormControl('Perforated Steel Shelves for Frames'),
			category: new FormControl(QuoteLineCategoryEnum.GENERAL_LEDGER),
			qty: new FormControl(1),
			price: new FormControl(0)
		}),
		installLineItem: new FormGroup({
			description: new FormControl('Install for System & Shelving'),
			price: new FormControl(0, Validators.required),
			glNumber: new FormControl(44000),
			category: new FormControl(QuoteLineCategoryEnum.GENERAL_LEDGER),
			qty: new FormControl(1),
			type: new FormControl(GlCodeTypeEnum.INSTALL),
			comment: new FormControl('')
		})
	});
	salesOrderForm: FormGroup = new FormGroup({
		po: new FormControl('', [Validators.required, Validators.maxLength(35)]),
		installDate: new FormControl(null, Validators.required),
		shipDate: new FormControl(null, Validators.required),
		transitDays: new FormControl(0, Validators.required),
		location: new FormControl(''),
		estimatedTransit: new FormControl(0),
		salesOrderNo: new FormControl('')
	});
	manualPartForm: FormGroup = new FormGroup({});
	contactForm: FormGroup;

	itemTotalSubject: Subject<number> = new Subject<number>();
	manuallyAddedPartTotal: number = 0;
	materialCostTotal: number = 0;
	rackSolidItemTotal: number = 0;

	calculated: boolean = false;
	showFullPartList: boolean = false;
	editMode: boolean = true;
	loading: boolean;
	clientLoading: boolean = true;
	isRefreshingCatalogPrices: boolean = false;
	isRefreshingPartPrices: boolean = false;
	partsLoading: Observable<boolean> = this.partService.isLoading;
	createOrderMode: boolean = false;
	created: boolean = false;
	submitted: boolean = false;
	creatingSalesOrder: boolean = false;
	requestingApproval: boolean = false;
	saving: boolean = false;
	categories: PartCategoryEnum[] = Object.values(PartCategoryEnum);

	quoteDefaults = {
		quoteTerms: 30,
		quoteText:
			'***QUOTE IS ONLY VALID FOR 15 DAYS*** LEAD TIMES ON ALL ORDERS SHIPPING 5-6 WEEKS FROM DATE OF PO. ORDER MUST SHIP WITHIN 6 ' +
			'WEEKS OF PO PLACEMENT. MMI RESERVES THE RIGHT TO RE-QUOTE ANY PROJECT PAST THE 6 WEEK WINDOW.',
		quoteDate: new Date()
	};

	// We want to separate these from the final Rack Solid Shelving Frames GL
	excludedFromFrames: string[] = Object.values(ConfigTypeEnum);

	mobileItemsGrossWeight: number = 0;
	rackSolidGrossWeight: number = 0;
	quoteApprovalRequest: QuoteApprovalRequest = {};
	associatedCostTypes: string[] = Object.values(AssociatedCostEnum);
	associatedCostLines: QuoteLine[] | undefined;
	associatedCostTotal: number = 0;
	costAdjustmentTotal: number = 0;

	costAdjustments: QuoteLine[] = [];
	params: Subscription;
	combinedSubscription: Subscription;
	clientSubscription: Subscription;
	partCatalogSubscription: Subscription;
	partSubscription: Subscription;
	previousUrlSubscription: Subscription;
	reQuoting: boolean;

	project: Project;
	client: ClientPreview;
	projectId: number;
	quoteId: number;
	navigateAwayConfirmed: boolean;
	buildingPDF: boolean;

	quotePdf: QuotePdf;
	showPdf: boolean = false;
	MAX_LINES_PDF_PAGE: 42 = 42;
	MAX_PDF_LINE_LENGTH: 42 = 42;

	constructor(
		private fb: FormBuilder,
		private quoteService: QuoteService,
		private projectsService: ProjectsService,
		private snackbar: MatSnackBar,
		private dialog: MatDialog,
		private decimalPipe: DecimalPipe,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private format: CommaRemovalPipe,
		private titleCasePipe: TitleCasePipe,
		private underscoreRemove: UnderscoreRemove,
		private clientService: ClientService,
		private partService: PartService,
		private projectService: ProjectsService,
		private urlService: UrlService,
		private codeService: CodeService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.editMode = true;
		this.params = this.route.params.subscribe((params: Params): void => {
			this.quoteId = params['quoteId'];
			this.projectId = params['projectId'];
		});

		if (this.quoteId) {
			this.combinedSubscription = this.projectService.project
				.pipe(combineLatestWith(this.quoteService.findOne(this.quoteId)))
				.subscribe(([project, quote]) => {
					if (project) {
						this.project = project;
						if (this.quoteId) {
							this.quote = quote;
							const path: string = this.route.snapshot.url.pop()?.path ?? '';
							if (path === 'copy') {
								delete this.quote.quoteNumber;
								this.sanitizeCopiedQuote();
								this.deleteAssociations();
								this.location.go(`project/${this.project.id}/quote/new`);
							} else if (path === 'revision') {
								this.sanitizeCopiedQuote();
								this.deleteAssociations();
								this.quote.versionSeq!++;
								this.quote.version = `Revision ${this.quote.versionSeq}`;
								this.location.go(`project/${this.project.id}/quote/new`);
							} else if (path === 'requote') {
								this.deleteAssociations();
								this.reQuoting = true;
								this.quote.versionSeq!++;
								this.quote.version = `Revision ${this.quote.versionSeq}`;
								this.location.go(`project/${this.project.id}/quote/requote`);
								this.openChangeRequestDialog();
							}
							this.partService.findAll(this.project.clientId, this.quote.date);
							this.partService.findAllFromErp(this.project.clientId, this.quote.date);
							this.initializeQuote(quote);
							if (!quote.date) {
								this.refreshPrices(this.quote);
							}
						}
					}
				});
		} else {
			this.combinedSubscription = this.projectService.project.subscribe({
				next: (project: Project | null): void => {
					if (project) {
						this.project = project;
						this.quote = {
							status: QuoteStatusEnum.IN_PROGRESS
						};
						this.partService.findAll(this.project.clientId);
						this.partService.findAllFromErp(this.project.clientId);
						this.initializeQuote({});
					}
				},
				error: (err: any): void => {
					console.error(err);
					this.snackbar.open('Error loading project', SnackbarActionEnum.ERROR);
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.params?.unsubscribe();
		this.combinedSubscription?.unsubscribe();
		this.clientSubscription?.unsubscribe();
		this.partCatalogSubscription?.unsubscribe();
		this.partSubscription?.unsubscribe();
		this.previousUrlSubscription?.unsubscribe();
	}

	confirmNavigationAway(nextState: RouterStateSnapshot | undefined): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const dialogRef: MatDialogRef<SaveQuoteChangesDialogComponent> = this.dialog.open(SaveQuoteChangesDialogComponent);
			dialogRef.afterClosed().subscribe((response): void => {
				if (response === 'NoSave') {
					this.navigateAwayConfirmed = true;
					this.router.navigate([nextState?.url]);
					resolve();
				} else if (response === 'Save') {
					this.navigateAwayConfirmed = true;
					this.saveQuote();
					this.router.navigate([nextState?.url]);
					resolve();
				} else {
					reject();
				}
			});
		});
	}

	cantNavigateAway(): boolean {
		return (
			(this.quoteForm.dirty || this.isGetBackFormArrayDirty()) &&
			this.quote.status === QuoteStatusEnum.IN_PROGRESS &&
			!this.navigateAwayConfirmed
		);
	}

	async printQuotePdf() {
		this.buildingPDF = true;
		this.buildQuotePdfData();
		this.showPdf = true;
		this.cdr.detectChanges();
		const blob = await this.buildPdf();
		const blobURL = URL.createObjectURL(blob);
		const iframe = document.createElement('iframe');
		document.body.appendChild(iframe);
		iframe.style.display = 'none';
		iframe.src = blobURL;
		iframe.onload = function () {
			setTimeout(function () {
				iframe.focus();
				if (iframe.contentWindow) iframe.contentWindow.print();
			}, 1);
		};
		this.showPdf = false;
		this.buildingPDF = false;
	}

	isGetBackFormArrayDirty(): boolean {
		//Reactive forms don't automatically track if FormArray's are dirty.
		const formArray = this.quoteForm.get('getBacks') as FormArray;
		return formArray.controls.some((control) => control.dirty);
	}

	watchForManuallyAddedPartChanges(): void {
		this.quoteForm.get('lineItemParts')?.valueChanges.subscribe({
			next: (): void => {
				this.calculateManuallyAddedPartWeightAndCost();
			},
			error: (error): void => {
				console.error(error);
			}
		});
	}

	resetForm(): void {
		this.quoteForm.get('freightWeight')?.reset();
		this.quoteForm.get('freightCost')?.reset();
		this.quoteForm.get('note')?.reset();
		this.quoteForm.get('accessorials')?.reset();
		this.quoteForm.get('installWeight')?.reset();
	}

	goToRackSolid(): void {
		const dialog = this.dialog.open(ProjectRackSolidComponent, {
			data: {
				quote: this.quote,
				project: this.project,
				priceLevel: this.client.priceLevel
			},
			disableClose: true,
			height: '96vh',
			width: '96vw',
			maxHeight: '96vh',
			maxWidth: '96vw'
		});

		dialog.afterClosed().subscribe({
			next: (result: Quote | null): void => {
				if (result) {
					this.quote = result;
					this.calculateRackSolidWeightAndCost();
					this.calculateManuallyAddedPartWeightAndCost();
					this.setConfiguratorValues(true);
					this.itemTotalSubject.next(this.rackSolidItemTotal + this.manuallyAddedPartTotal + this.shelvesTotal);
					this.partsListChangedSubject.next();
				}
			},
			error: (err: any): void => {
				console.error(err);
				this.snackbar.open('Error adding parts to quote', SnackbarActionEnum.ERROR);
			}
		});
	}

	setConfiguratorValues(afterRackSolidClosed: boolean): void {
		this.setShelvingLineValue(ConfigTypeEnum.PARTICLE_BOARD, 'particleBoardLineItem');
		this.setShelvingLineValue(ConfigTypeEnum.STEEL_PERF, 'steelPerfLineItem');
		this.setShelvingLineValue(ConfigTypeEnum.WIRE_GRID, 'wireGridLineItem');

		if (afterRackSolidClosed) {
			this.quoteForm.markAsDirty();
		}
	}

	setShelvingLineValue(configType: ConfigTypeEnum, formControlName: string): void {
		const parts: QuotePart[] | undefined = this.quote.parts?.filter((part: QuotePart): boolean => part.type === configType);
		if (parts) {
			const price: number =
				parts?.reduce((accumulator: number, {price, qty}) => {
					return accumulator + (price ?? 0) * (qty ?? 0);
				}, 0) ?? 0;
			this.quoteForm.get(formControlName)?.get('price')?.setValue(price.toFixed(4));
		}
	}

	backToQuoteList(): void {
		this.previousUrlSubscription = this.urlService.previousUrl$.subscribe((previousUrl: string): void => {
			if (previousUrl === '/quote') {
				this.router.navigate(['/quote']).then();
			} else {
				this.router.navigate([`/project/${this.projectId}/quote`]).then();
			}
		});
	}

	initializeQuote(quote: Quote): void {
		if (quote) {
			this.loading = true;
			this.resetForm();

			this.quoteForm.patchValue(quote);

			if (!this.client || this.client?.name?.toLowerCase() !== this.project.clientId.toLowerCase()) {
				this.getClientInfo();
			}

			this.setCurrencyValues(quote);

			this.setTeamMembers();

			if (this.route.snapshot.routeConfig?.path === ':quoteId/create-order') {
				this.createOrderMode = true;
				this.editMode = false;

				if (this.quote.approvals?.length) {
					this.salesOrderForm.get('po')?.setValue(this.quote.approvals[0].po ?? '');
				}

				this.codeService.incrementCodeValue({codeType: 'nextSalesOrderNo', incrementAmount: 10}).subscribe({
					next: (value: number) => this.salesOrderForm.get('salesOrderNo')?.setValue(value),
					error: (error: Error): void => {
						console.error(error);
						this.snackbar.open('Failed to retrieve next sales order number.', SnackbarActionEnum.ERROR);
					}
				});
			}

			this.projectService.getProjectInstallShip(this.project.id).subscribe({
				next: (projectInstallShip: ProjectInstallShip) => {
					this.salesOrderForm.patchValue(projectInstallShip);
				},
				error: (error: Error): void => {
					console.error(error);
					this.snackbar.open('Failed to retrieve project install info.', SnackbarActionEnum.ERROR);
				}
			});

			let poNumber: string | undefined;
			if (quote.poNumber) {
				poNumber = quote.poNumber;
			} else if (quote.approvals?.length) {
				poNumber = quote.approvals[0].po;
			}

			if (poNumber) {
				this.quoteForm.get('po')?.setValue(poNumber);
			}

			if (this.createOrderMode) {
				this.quoteForm.get('po')?.setValidators(Validators.required);
			}

			this.setQuoteSiteAddress();

			const accessorials: FormArray = this.quoteForm.get('accessorials') as FormArray;
			accessorials.clear();
			quote.accessorials?.forEach((accessorial: QuoteAccessorial): void => {
				accessorials.push(new FormControl(accessorial));
			});

			this.calculateRackSolidWeightAndCost();
			this.separateQuoteLines();

			if (this.quote?.expandedPartList) {
				this.showFullPartList = true;
			}

			this.calculated = true;
			this.loading = false;
		}
	}

	setTeamMembers(): void {
		if (this.project.members.length) {
			if (!this.quote.salesPerson) {
				const found: ProjectMember | undefined = this.project.members.find(
					(member: ProjectMember): boolean => member.userInfo.role?.name === 'Sales Person'
				);
				if (found) {
					this.quoteForm.get('salesPerson')?.setValue(found.userInfo.firstName + ' ' + found.userInfo.lastName);
				}
			}

			if (!this.quote.quoter) {
				const found: ProjectMember | undefined = this.project.members.find(
					(member: ProjectMember): boolean => member.userInfo.role?.name === 'Project Estimator'
				);
				if (found) {
					this.quoteForm.get('quoter')?.setValue(found.userInfo.firstName + ' ' + found.userInfo.lastName);
				}
			}

			if (!this.quote.accountManager) {
				const found: ProjectMember | undefined = this.project.members.find(
					(member: ProjectMember): boolean => member.userInfo.role?.name === 'Account Manager'
				);
				if (found) {
					this.quoteForm.get('accountManager')?.setValue(found.userInfo.firstName + ' ' + found.userInfo.lastName);
				}
			}
		}
	}

	separateQuoteLines(): void {
		if (this.project) {
			this.buildAssociatedCostsForm(this.project);
		}

		this.separateMiscMaterials();
		this.separatePartLineItems();
		this.watchForManuallyAddedPartChanges();

		this.separateCostAdjustments();
		this.separateLineItem(GlCodeTypeEnum.FREIGHT, 'freightOption');
		this.separateLineItem(GlCodeTypeEnum.MATERIAL_COST_SURCHARGE, 'materialCost');
		this.separateLineItem(GlCodeTypeEnum.RACK_SOLID, 'rackSolidLineItem');
		this.separateLineItem(GlCodeTypeEnum.WIRE_GRID, 'wireGridLineItem');
		this.separateLineItem(GlCodeTypeEnum.STEEL_PERF, 'steelPerfLineItem');
		this.separateLineItem(GlCodeTypeEnum.PARTICLE_BOARD, 'particleBoardLineItem');
		this.separateLineItem(GlCodeTypeEnum.INSTALL, 'installLineItem');
	}

	separateMiscMaterials(): void {
		(this.quoteForm.get('miscMaterials') as FormArray).clear();
		this.quote.lines?.forEach((line: QuoteLine): void => {
			if (line.type === GlCodeTypeEnum.MISC_MAT) {
				let group: FormGroup = new FormGroup({
					price: new FormControl(line.price, Validators.required),
					description: new FormControl(line.description, Validators.required),
					glNumber: new FormControl(43025),
					qty: new FormControl(1),
					type: new FormControl(GlCodeTypeEnum.MISC_MAT),
					weight: new FormControl(line.weight)
				});
				(this.quoteForm.get('miscMaterials') as FormArray).push(group);
			}
		});
	}

	separateLineItem(glType: GlCodeTypeEnum, formGroupAccessor: string): void {
		const option: QuoteLine | undefined = this.quote.lines?.find(
			(line: QuoteLine): boolean => line.type === glType && line.category !== QuoteLineCategoryEnum.GET_BACK
		);
		if (option) {
			this.quoteForm.get(formGroupAccessor)?.patchValue(option);
		}
	}

	refreshPrices(quote: Quote): void {
		this.partCatalogSubscription = this.partService.partCatalog.subscribe((catalogParts: Part[]) => {
			this.isRefreshingCatalogPrices = true;
			quote.parts?.forEach((part: QuotePart): void => {
				const catalogPart: Part | undefined = catalogParts.find((catalogPart: Part) => catalogPart.partNumber === part.modelNumber);
				if (catalogPart?.price) {
					part.price = parseFloat(catalogPart.price.toFixed(4)) + (part?.additionalFee ?? 0);
				}
			});
			this.calculateRackSolidWeightAndCost();
			this.setConfiguratorValues(false);
			this.setQuoteSiteAddress();
			this.setTeamMembers();
			this.isRefreshingCatalogPrices = false;
		});

		this.partSubscription = this.partService.parts.subscribe((parts: PartData[]) => {
			this.isRefreshingPartPrices = true;
			quote.lines?.forEach((line: QuoteLine) => {
				const partData: PartData | undefined = parts.find((partData: PartData) => partData.part.item === line.item);
				if (partData?.part?.price) {
					line.price = partData.part.price;
				}
			});
			this.separatePartLineItems();
			this.isRefreshingPartPrices = false;
		});
	}

	get isRefreshingPrices(): boolean {
		return this.isRefreshingCatalogPrices || this.isRefreshingPartPrices;
	}

	separateCostAdjustments(): void {
		const adjustments: QuoteLine[] | undefined = this.quote.lines?.filter(
			(line: QuoteLine): boolean => line.category === QuoteLineCategoryEnum.GET_BACK
		);
		this.costAdjustmentTotal = 0;
		if (adjustments) {
			this.costAdjustments = adjustments;
			this.costAdjustments.forEach((cost: QuoteLine) => (this.costAdjustmentTotal += cost.price!));
		}

		this.separateLineItem(GlCodeTypeEnum.DISCOUNT, 'discount');
	}

	calculateManuallyAddedPartWeightAndCost(): void {
		this.manuallyAddedPartTotal =
			this.lineItemParts?.reduce((accumulator: number, {price, qty}) => {
				return accumulator + (price ?? 0) * (qty ?? 0);
			}, 0) ?? 0;
		this.itemTotalSubject.next(this.rackSolidItemTotal + this.manuallyAddedPartTotal + this.shelvesTotal);

		this.mobileItemsGrossWeight =
			this.lineItemParts?.reduce((sum: number, part: QuoteLine) => sum + (part.weight ?? 0) * (part.qty ?? 0), 0) +
			this.rackSolidGrossWeight +
			(this.quoteForm.get('miscMaterialWeight')?.value ?? 0);

		this.materialCostTotal =
			this.getManualParts(true)?.reduce((accumulator: number, {price, qty}) => {
				return accumulator + (price ?? 0) * (qty ?? 0);
			}, 0) ?? 0;
	}

	calculateRackSolidWeightAndCost(): void {
		const rackSolidFrameParts: QuotePart[] | undefined = this.quote.parts?.filter(
			(part: QuotePart) => !this.excludedFromFrames.includes(part.type ?? '')
		);
		this.rackSolidItemTotal =
			rackSolidFrameParts?.reduce((accumulator: number, {price, qty}) => {
				return accumulator + (price ?? 0) * (qty ?? 0);
			}, 0) ?? 0;
		this.rackSolidItemTotal = parseFloat(this.rackSolidItemTotal.toFixed(4));
		this.quoteForm.get('rackSolidLineItem')?.get('price')?.setValue(this.rackSolidItemTotal);

		this.rackSolidGrossWeight =
			this.quote.parts?.reduce((accumulator: number, {weight, qty}) => {
				return accumulator + (weight ?? 0) * (qty ?? 0);
			}, 0) ?? 0;
	}

	get totalUnits(): number {
		let totalStarters: number = 0;
		let totalAdders: number = 0;
		this.quote?.configs?.forEach((quoteConfig: QuoteConfig) => {
			quoteConfig.quoteConfigShelves?.forEach((shelf: QuoteConfigShelf) => {
				totalStarters += shelf.starters ?? 0;
				totalAdders += shelf.adders ?? 0;
			});
		});

		return totalStarters + totalAdders;
	}

	get totalCostOfMaterial(): number {
		return (
			this.rackSolidItemTotal +
			this.manuallyAddedPartTotal +
			this.quoteForm.get('miscMaterialTotal')?.value +
			this.shelvesTotal +
			this.quoteForm.get('materialCost')?.value['price']
		);
	}

	getClientInfo(): void {
		if (this.project.clientId) {
			this.clientSubscription = this.clientService.findOneFromErp(this.project.clientId).subscribe({
				next: (client: ClientPreview): void => {
					if (client) {
						this.client = client;
						if (!this.quote.date) {
							if (this.quoteForm.controls['paymentTerms'].value !== client.paymentTerms) {
								this.quoteForm.markAsDirty();
							}
							this.quoteForm.controls['paymentTerms'].setValue(client.paymentTerms);
						}
					}
				},
				error: (err): void => {
					console.error(err);
					const dialogRef: MatDialogRef<ErrorDialogComponent> = this.dialog.open(ErrorDialogComponent, {
						data: {
							message:
								'We are temporarily unable to load client info from navision. ' +
								'If the problem persists please contact your network administrator.'
						},
						width: '28%'
					});

					dialogRef.afterClosed().subscribe((): void => {
						this.backToQuoteList();
					});
				},
				complete: (): void => {
					this.clientLoading = false;
				}
			});
		} else {
			this.clientLoading = false;
		}
	}

	setQuoteSiteAddress(): void {
		this.quote.siteAddress1 = this.project.address1 ?? null;
		this.quote.siteAddress2 = this.project.address2 ?? null;
		this.quote.siteCity = this.project.city ?? null;
		this.quote.siteState = this.project.state ?? null;
		this.quote.siteCountry = this.project.country ?? null;
		this.quote.siteZip = this.project.postal ?? null;
	}

	separatePartLineItems(): void {
		(this.quoteForm.get('lineItemParts') as FormArray).clear();
		this.quote.lines?.filter((line: QuoteLine) => {
			if (line.erpItemRef) {
				let group: FormGroup = new FormGroup({
					item: new FormControl(line.item),
					erpItemRef: new FormControl(line.erpItemRef),
					description: new FormControl(line.description),
					category: new FormControl(line.category),
					qty: new FormControl(line.qty, [Validators.required, Validators.min(1)]),
					price: new FormControl(line.price?.toFixed(4)),
					unitOfMeasure: new FormControl(line.unitOfMeasure),
					type: new FormControl(line.type),
					weight: new FormControl(line.weight)
				});

				(this.quoteForm.get('lineItemParts') as FormArray).push(group);
			}
			return line;
		});
		this.calculateManuallyAddedPartWeightAndCost();
	}

	setCurrencyValues(quote: Quote): void {
		this.setCurrencyValue('freightCost', quote.freightCost);
		this.setCurrencyValue('grandTotal', quote.grandTotal);
		this.setCurrencyValue('totalSalesTax', quote.totalSalesTax);
		this.setCurrencyValue('amountExemptFromTax', quote.amountExemptFromTax);
		this.setCurrencyValue('amountSubjectToTax', quote.amountSubjectToTax);
	}

	setCurrencyValue(accessor: string, value: number | undefined): void {
		this.quoteForm.get(accessor)?.setValue(this.decimalPipe.transform(value ?? '0.00', '.2'));
	}

	toggleEdit(): void {
		if (this.editMode) {
			this.saveQuote();
			return;
		}
		this.editMode = true;
	}

	cancelEdit(): void {
		this.editMode = false;
		this.initializeQuote(this.quote);
	}

	get hasThirdPartyFreightCost(): boolean {
		return !!this.associatedCostLines?.some((line: QuoteLine) => line.type === AssociatedCostEnum.THIRD_PARTY_FREIGHT);
	}

	buildAssociatedCostsForm(project: Project): void {
		const projectMap: Map<string, any> = new Map(Object.entries(project));
		const projectKeys: string[] = Object.keys(project);
		this.associatedCostFormArray.clear();
		this.associatedCostLines = this.quote.lines?.filter((line: QuoteLine) => line.category === QuoteLineCategoryEnum.ASSOCIATED_COST);
		this.associatedCostTypes.forEach((type: string): void => {
			if (
				(projectKeys.includes(type) && !!projectMap.get(type)) ||
				(type === AssociatedCostEnum.THIRD_PARTY_FREIGHT && this.hasThirdPartyFreightCost)
			) {
				const line: QuoteLine | undefined = this.associatedCostLines?.find((line) => line.type === type);
				this.associatedCostFormArray.push(
					new FormGroup({
						description: new FormControl(this.getAssociatedCostDescriptionByType(type as AssociatedCostEnum)),
						type: new FormControl(type),
						category: new FormControl(QuoteLineCategoryEnum.ASSOCIATED_COST),
						price: new FormControl(this.decimalPipe.transform(line?.price, '.2') ?? 0, [Validators.required]),
						qty: new FormControl(1),
						glNumber: new FormControl(line?.glNumber ?? 45100)
					})
				);
			}
		});
		if (this.submitted) {
			this.quoteForm.disable();
		}
	}

	getAssociatedCostDescriptionByType(type: AssociatedCostEnum) {
		const key: string = Object.keys(AssociatedCostEnum)[Object.values(AssociatedCostEnum).indexOf(type)];
		if (!key) {
			return '';
		}

		return this.titleCasePipe.transform(key.replace('_', ' '));
	}

	get associatedCostFormArray() {
		return this.quoteForm.controls['associatedCosts'] as FormArray;
	}

	processLineItems() {
		const formValue = this.quoteForm.value;
		formValue.lines = [];

		if (formValue?.associatedCosts?.length) {
			formValue.lines = formValue.lines.concat(formValue.associatedCosts);
			delete formValue.associatedCosts;
		}

		return formValue;
	}

	formatQuoteBeforeSaving(): void {
		Object.assign(this.quote, this.processLineItems());

		this.quote.totalSalesTax = this.moneyStringToNumber('totalSalesTax');
		this.quote.amountSubjectToTax = this.moneyStringToNumber('amountSubjectToTax');
		this.quote.amountExemptFromTax = this.moneyStringToNumber('amountExemptFromTax');

		this.quote.subTotal = this.subTotal;
		this.quote.grandTotal = this.grandTotal;

		// Add line item parts back to quote lines
		let array: QuoteLine[] = this.quoteForm.get('lineItemParts')?.value;
		array.forEach((part: QuoteLine) => this.quote.lines?.push(part));

		array = this.quoteForm.get('miscMaterials')?.value;
		array.forEach((material: QuoteLine) => this.quote.lines?.push(material));

		array = this.quoteForm.get('getBacks')?.value;
		array.forEach((getBack: QuoteLine) => this.quote.lines?.push(getBack));

		this.quote.lines?.push(this.quoteForm.get('freightOption')?.getRawValue());
		this.quote.lines?.push(this.quoteForm.get('discount')?.value);
		this.quote.lines?.push(this.quoteForm.get('materialCost')?.value);
		this.quote.lines?.push(this.quoteForm.get('installLineItem')?.value);
		this.quote.lines?.push(this.quoteForm.get('rackSolidLineItem')?.value);
		this.quote.lines?.push(this.quoteForm.get('steelPerfLineItem')?.value);
		this.quote.lines?.push(this.quoteForm.get('wireGridLineItem')?.value);
		this.quote.lines?.push(this.quoteForm.get('particleBoardLineItem')?.value);

		// Last check to verify all prices are numbers
		this.quote.lines?.forEach((line: QuoteLine): void => {
			if (line.price) {
				line.price = parseFloat(this.format.transform(line.price.toString(), true));
			}
		});

		this.quote.parts = this.sortedRackSolidParts;
	}

	getQuoteLineWithGetBacks(formKey: string): QuoteLine | undefined {
		if (this.quoteForm.get(formKey)?.value['price'] && this.quoteForm.get(formKey)?.value['price'] !== '0.00') {
			const line: QuoteLine = this.quoteForm.get(formKey)?.getRawValue();
			line.price = this.checkForGetBacks(line);
			return line;
		}
		return;
	}

	checkForGetBacks(line: QuoteLine): number {
		let getBackAmount: number = 0;
		const getBacks: QuoteLine[] = this.quoteForm.get('getBacks')?.value;
		if (getBacks) {
			const found: QuoteLine | undefined = getBacks.find(
				(getBack: QuoteLine): boolean => getBack.glNumber == line.glNumber && getBack.description === line.description
			);
			if (found) {
				getBackAmount = found.price!;
			}
		}
		return parseFloat(getBackAmount.toString()) + parseFloat(this.format.transform(line.price!.toString(), true));
	}

	moneyStringToNumber(accessor: string): number {
		return parseFloat(this.format.transform(this.quoteForm.controls[accessor].value, true));
	}

	get grandTotal(): number {
		return this.subTotal + parseFloat(this.format.transform(this.quoteForm.get('totalSalesTax')?.value, true));
	}

	get maxGrandTotal(): number {
		return this.grandTotal + this.getPriceOfControl('stockPermitReq');
	}

	get quoteNumber(): string {
		if (!this.quote) {
			return '';
		}
		return `${this.quote.quoteNumber}${(this.quote.versionSeq ?? 0) > 0 ? '-' + this.quote.versionSeq : ''}`;
	}

	addThirdPartyFreightAssociatedCost(value: boolean): void {
		if (value) {
			(this.quoteForm.controls['associatedCosts'] as FormArray).push(
				this.fb.group({
					description: new FormControl('Third Party Freight'),
					type: new FormControl(AssociatedCostEnum.THIRD_PARTY_FREIGHT),
					category: new FormControl(QuoteLineCategoryEnum.ASSOCIATED_COST),
					qty: new FormControl(1),
					glNumber: new FormControl(48000),
					unitOfMeasure: new FormControl('EACH'),
					price: new FormControl('45.00')
				})
			);
		} else {
			const associateCostArray: QuoteLine[] = this.associatedCostFormArray?.value;
			const index: number = associateCostArray.findIndex(
				(line: QuoteLine): boolean => line.type === AssociatedCostEnum.THIRD_PARTY_FREIGHT
			);
			if (index > -1) {
				(this.quoteForm.controls['associatedCosts'] as FormArray).removeAt(index);
			}
		}
	}

	get shelvesTotal(): number {
		return (
			parseFloat(this.format.transform(this.quoteForm.get('particleBoardLineItem')?.get('price')?.value.toString(), true) ?? 0) +
			parseFloat(this.format.transform(this.quoteForm.get('wireGridLineItem')?.get('price')?.value.toString(), true) ?? 0) +
			parseFloat(this.format.transform(this.quoteForm.get('steelPerfLineItem')?.get('price')?.value.toString(), true) ?? 0)
		);
	}

	get subTotal(): number {
		const quoteLinesArray = [
			parseFloat(this.format.transform(this.quoteForm.get('freightOption')?.get('price')?.value.toString(), true) ?? 0),
			parseFloat(this.format.transform(this.quoteForm.get('installLineItem')?.get('price')?.value.toString(), true) ?? 0),
			this.quoteForm.get('materialCost')?.get('price')?.value ?? 0,
			this.costAdjustmentTotal,
			this.manuallyAddedPartTotal,
			this.associatedCostTotal,
			this.rackSolidItemTotal,
			this.shelvesTotal,
			this.quoteForm.get('miscMaterialTotal')?.value,
			this.quoteForm.get('discount')?.value['price'] ?? 0
		];

		const roundedLines = quoteLinesArray.map((each) => {
			if (+each) {
				return +each.toFixed(2);
			} else {
				return 0;
			}
		});

		return roundedLines.reduce(function (a, b) {
			return a + b;
		});
	}

	getManualParts(isMobile: boolean): QuoteLine[] {
		return this.lineItemParts.filter((line: QuoteLine): boolean => (isMobile ? this.isMobilePart(line) : !this.isMobilePart(line)));
	}

	get lineItemParts(): QuoteLine[] {
		return this.quoteForm.get('lineItemParts')?.value;
	}

	get sortedRackSolidParts(): QuotePart[] {
		if (!this.quote?.parts?.length) {
			return [];
		}
		let sortedParts: QuotePart[] = [];
		this.categories.forEach(
			(category: PartCategoryEnum) =>
				(sortedParts = sortedParts.concat(
					(this.quote?.parts ?? [])
						.filter((part: QuotePart) => part.category === category)
						.sort((a, b) => {
							const descA: string = a.partName?.toLowerCase() ?? '';
							const descB: string = b.partName?.toLowerCase() ?? '';

							if (descA < descB) {
								return -1;
							}
							if (descA > descB) {
								return 1;
							}
							return 0;
						})
						.sort((a, b) => {
							const regex: RegExp = /\d+(?=')/g;

							const descA: string = a.partName?.match(regex)?.pop() ?? '';
							const descB: string = b.partName?.match(regex)?.pop() ?? '';

							if (descA && descB) {
								const numA: number = parseInt(descA);
								const numB: number = parseInt(descB);

								if (numA < numB) {
									return -1;
								}
								if (numA > numB) {
									return 1;
								}
							}

							return 0;
						})
				))
		);
		return sortedParts;
	}

	toggleShowPartsList(): void {
		this.showFullPartList = !this.showFullPartList;
		this.quote.expandedPartList = this.showFullPartList;
		this.quoteForm.markAsDirty();
	}

	isMobilePart(line: QuoteLine): boolean {
		return (
			line.category?.toUpperCase() === QuoteLineCategoryEnum.MOBILE_PART ||
			(line.category?.toUpperCase() === QuoteLineCategoryEnum.AG &&
				line.type?.toUpperCase() === QuoteLineTypeEnum.MATERIAL_SURCHARGE_COST_AG)
		);
	}

	saveQuote(): void {
		this.saving = true;

		if (!this.quote.projectId) {
			this.quote.projectId = this.project.id;
		}

		this.formatQuoteBeforeSaving();
		if (this.reQuoting) {
			this.deleteAssociations();
		}

		this.quote.id ? this.updateQuote() : this.createQuote();
	}

	sanitizeCopiedQuote(): void {
		delete this.quote.approvals;
		delete this.quote.documents;
		delete this.quote.poNumber;
		delete this.quote.salesOrder;
		delete this.quote.changeReqDescription;
		delete this.quote.changeReqDate;
		delete this.quote.changeReqEmail;
		delete this.quote.changeReqReason;
	}

	deleteAssociations(): void {
		this.quote.status = QuoteStatusEnum.IN_PROGRESS;

		delete this.quote.id;
		delete this.quote.date;

		this.quote.parts?.forEach((part: QuotePart): void => {
			delete part.id;
			delete part.quoteId;
		});
		this.quote.accessorials?.forEach((accessorial: QuoteAccessorial): void => {
			delete accessorial.accessorialId;
			delete accessorial.quoteId;
		});
		this.quote.configs?.forEach((config: QuoteConfig): void => {
			delete config.id;
			delete config.quoteId;
			config.quoteConfigShelves?.forEach((shelf: QuoteConfigShelf): void => {
				delete shelf.id;
				delete shelf.quoteConfigId;
			});
		});
	}

	createQuote(): void {
		this.quote.status = QuoteStatusEnum.IN_PROGRESS;
		this.quoteService.create(this.quote).subscribe({
			next: (response: Quote): void => {
				this.quote = response;
				this.initializeQuote(response);
				this.quote.quoteNumber = response.quoteNumber;
				this.created = true;
				this.location.go(`project/${this.project.id}/quote/${this.quote.id}`);
				this.snackbar.open('Successfully created quote', SnackbarActionEnum.SUCCESS);
				this.quoteForm.markAsPristine();
				this.editMode = false;
			},
			error: (err): void => {
				this.snackbar.open('Failed to create quote', SnackbarActionEnum.ERROR);
				console.error(err);
				this.saving = false;
			},
			complete: (): boolean => (this.saving = false)
		});
	}

	updateQuote(): void {
		this.quoteService.update(this.quote).subscribe({
			next: (response: Quote): void => {
				this.quote = response;
				this.initializeQuote(response);
				this.quoteForm.markAsPristine();
				this.snackbar.open('Successfully updated quote', SnackbarActionEnum.SUCCESS);
				this.editMode = false;
			},
			error: (err): void => {
				this.snackbar.open('Failed to update quote', SnackbarActionEnum.ERROR);
				this.saving = false;
				console.error(err);
			},
			complete: (): boolean => (this.saving = false)
		});
	}

	requestApproval(): void {
		if (this.quoteForm.invalid) {
			this.quoteForm.markAllAsTouched();
			this.snackbar.open('Please fix errors and fill in required fields before request approval', SnackbarActionEnum.ALERT);
			return;
		}

		if (this.hasAddressFields({submitted: true})) {
			this.snackbar.open('Please return to the project page and fill in the required ship to address fields');
			return;
		}
		this.showPdf = true;
		this.buildQuotePdfData();
		this.openDialog(false);
	}

	resendQuote() {
		this.showPdf = true;
		this.buildQuotePdfData();
		this.openDialog(true);
	}

	openDialog(resend: boolean): void {
		const requestApprovalDialogRef: MatDialogRef<RequestApprovalDialogComponent> = this.dialog.open(RequestApprovalDialogComponent, {
			data: {
				form: this.contactForm,
				clientName: this.client.name,
				erpClientId: this.project.clientId,
				defaultSubject: `${this.project.name} ${this.project.city}, ${this.project.state} - MMI Quote Approval Request`,
				resend: resend,
				quoteId: this.quote.id
			},
			width: '30vw'
		});

		requestApprovalDialogRef
			.afterClosed()
			.subscribe((result: {contacts: Contact[]; message: string; subject: string; resend: boolean}): void => {
				if (!!result) {
					this.requestingApproval = true;
					this.quoteApprovalRequest = {
						quote: this.quote,
						message: result.message,
						erpClientId: this.project.clientId,
						contacts: result.contacts,
						subject: result.subject
					};

					this.buildPdf().then((pdfBlob: Blob) => {
						this.showPdf = false;
						let fd: FormData = new FormData();
						fd.append('file', pdfBlob);
						fd.append('body', JSON.stringify(this.quoteApprovalRequest));

						// this.downloadBlob(pdfBlob);
						if (result.resend) {
							this.buildResendApprovalRequest(fd);
						} else {
							this.buildApprovalRequest(fd);
						}
					});
				} else {
					this.showPdf = false;
				}
			});
	}

	buildResendApprovalRequest(fd: FormData): void {
		this.quoteService.resendQuote(fd, this.quote.id!).subscribe({
			next: (): void => {
				this.snackbar.open('Successfully resubmitted quote.', SnackbarActionEnum.SUCCESS);
				this.requestingApproval = false;
			},
			error: (err: Error): void => {
				console.error(err);
				this.snackbar.open('Failed to resubmit quote, please try again.', SnackbarActionEnum.ERROR);
			}
		});
	}

	buildQuotePdfData(): void {
		const quote: Quote = this.buildQuoteForPdf();

		this.quotePdf = {
			quote,
			projectName: this.project?.name,
			pages: []
		};

		let lineCount: number = 0;
		let currentPageLines: QuoteLine[] = [];
		quote.lines?.forEach((line: QuoteLine) => {
			let newLineCount: number = lineCount + Math.ceil((line.description?.length ?? 0) / this.MAX_PDF_LINE_LENGTH);
			if (newLineCount >= this.MAX_LINES_PDF_PAGE) {
				this.quotePdf.pages.push({lines: currentPageLines});
				currentPageLines = [];
				newLineCount = 0;
			}
			lineCount = newLineCount;
			currentPageLines.push(line);
		});

		if (currentPageLines.length) {
			this.quotePdf.pages.push({lines: currentPageLines});
		}

		const commentLines: number = this.quoteForm.get('clientComment')?.value?.split(/\r\n|\r|\n/).length;

		if (commentLines + lineCount > this.MAX_LINES_PDF_PAGE) {
			this.quotePdf.pages.push({lines: []});
		}
	}

	buildQuoteForPdf(): Quote {
		let pdfQuoteLines: QuoteLine[] = [];

		// Mobile Manual Parts
		pdfQuoteLines = pdfQuoteLines.concat(this.getManualParts(true));

		// Material Cost Surcharge
		const materialSurchargeLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('materialCost');
		if (materialSurchargeLine) {
			pdfQuoteLines.push(materialSurchargeLine);
		}

		// Non-Mobile Manual Parts
		pdfQuoteLines = pdfQuoteLines.concat(this.getManualParts(false));

		// Rack Solid
		if (this.showFullPartList) {
			const rackSolidQuoteLines: QuoteLine[] | undefined = this.sortedRackSolidParts?.map((part: QuotePart): QuoteLine => {
				return {
					erpItemRef: part.modelNumber,
					description: part.partName,
					unitOfMeasure: part.unit,
					qty: part.qty,
					price: part.price
				};
			});
			if (rackSolidQuoteLines) {
				pdfQuoteLines = pdfQuoteLines.concat(rackSolidQuoteLines);
			}
		} else {
			const rackSolidLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('rackSolidLineItem');
			if (rackSolidLine) {
				pdfQuoteLines.push(rackSolidLine);
			}
		}

		// Misc Materials
		const miscMaterialsArray = this.quoteForm.get('miscMaterials')?.value;
		miscMaterialsArray.forEach((material: QuoteLine) => pdfQuoteLines.push(material));

		// Shelves
		if (!this.showFullPartList) {
			const wireGridLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('wireGridLineItem');
			if (wireGridLine && wireGridLine.price) {
				pdfQuoteLines.push(wireGridLine);
			}

			const particleBoardLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('particleBoardLineItem');
			if (particleBoardLine && particleBoardLine.price) {
				pdfQuoteLines.push(particleBoardLine);
			}

			const steelPerfLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('steelPerfLineItem');
			if (steelPerfLine && steelPerfLine.price) {
				pdfQuoteLines.push(steelPerfLine);
			}
		}

		// Freight
		let freightLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('freightOption');
		if (freightLine) {
			const weightString: string = this.quoteForm.get('freightOption')?.get('weight')?.value
				? ` - ${this.quoteForm.get('freightOption')?.get('weight')?.value}lbs`
				: '';
			freightLine.description = `${freightLine.description}${weightString}`;
			pdfQuoteLines.push(freightLine);
		}

		// Discount
		const discountLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('discount');
		if (discountLine) {
			pdfQuoteLines.push(discountLine);
		}

		// Associated Costs
		let associatedCosts: QuoteLine[] = this.associatedCostFormArray.value;
		associatedCosts = associatedCosts
			.filter((line: QuoteLine) => line.type !== 'stockPermitReq' && parseFloat('' + line.price) !== 0)
			.map((line: QuoteLine) => {
				line.description = this.titleCasePipe.transform(this.underscoreRemove.transform(line.description ?? ''));
				if (line.price) {
					line.price = parseFloat(this.format.transform(line.price.toString(), true));
				}
				return line;
			});
		pdfQuoteLines = pdfQuoteLines.concat(associatedCosts);

		// Install
		if (this.calculated) {
			const installLine: QuoteLine | undefined = this.getQuoteLineWithGetBacks('installLineItem');
			if (installLine) {
				pdfQuoteLines.push(installLine);
				//Install Comment
				const installComment = this.quoteForm.get('installComment')?.value;
				if (installComment) {
					installComment.split('\n').forEach((description: string) => pdfQuoteLines.push({description}));
				}
			}
		}

		const formValue = Object.assign(JSON.parse(JSON.stringify(this.quote)), this.quoteForm.getRawValue());
		delete formValue.associatedCosts;
		formValue.lines = pdfQuoteLines;

		const pdfQuote: Quote = formValue;

		pdfQuote.totalSalesTax = this.moneyStringToNumber('totalSalesTax');
		pdfQuote.amountSubjectToTax = this.moneyStringToNumber('amountSubjectToTax');
		pdfQuote.amountExemptFromTax = this.moneyStringToNumber('amountExemptFromTax');

		pdfQuote.subTotal = this.subTotal;
		pdfQuote.grandTotal = this.grandTotal;

		return pdfQuote;
	}

	public async buildPdf(): Promise<Blob> {
		const doc: JSPDF = new JSPDF('p', 'px', 'letter');

		return html2canvas(this.quotePreview.nativeElement, {scale: 2}).then((canvas) => {
			this.quoteApprovalRequest.quote = this.quote;

			const imgWidth: 440 = 440;
			const imgHeight: number = 600 * this.quotePdf.pages.length;
			const contentDataUrl: string = canvas.toDataURL('image/png');
			this.quotePdf.pages.forEach((page: QuotePdfPage, index: number) => {
				doc.addImage(contentDataUrl, 'png', 0, index * -590, imgWidth, imgHeight);
				if (index !== this.quotePdf.pages.length - 1) {
					doc.addPage();
				}
			});

			const pdfData: Blob = doc.output('blob');

			return new Blob([pdfData], {type: 'application/pdf'});
		});
	}

	downloadBlob(blob: Blob, name = 'file.pdf') {
		// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
		const blobUrl = URL.createObjectURL(blob);

		// Create a link element
		const link = document.createElement('a');

		// Set link's href to point to the Blob URL
		link.href = blobUrl;
		link.download = name;

		// Append link to the body
		document.body.appendChild(link);

		// Dispatch click event on the link
		// This is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(
			new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			})
		);

		// Remove link from body
		document.body.removeChild(link);
	}

	buildApprovalRequest(fd: FormData): void {
		this.quoteService.requestApproval(fd, this.quote.id!).subscribe({
			next: (response: Quote): void => {
				this.quote = response;
				this.snackbar.open('Successfully submitted approval request', SnackbarActionEnum.SUCCESS);
				this.requestingApproval = false;
			},
			error: (err): void => {
				console.error(err);
				this.snackbar.open('Failed to submit approval request please try again', SnackbarActionEnum.ERROR);
			}
		});
	}

	openChangeRequestDialog(): void {
		this.dialog.open(ChangeRequestInfoComponent, {
			data: {
				changeRequestEmail: this.quote.changeReqEmail,
				changeRequestReason: this.quote.changeReqReason,
				changeRequestDescription: this.quote.changeReqDescription
			}
		});
	}

	createOrder(ngForm: any): void {
		if (this.quote.id) {
			if (this.quoteForm.invalid) {
				this.snackbar.open(
					'Please fix errors and fill in required fields before request approval. You may need to edit the quote.',
					SnackbarActionEnum.ERROR
				);
				this.quoteForm.markAllAsTouched();
				return;
			}

			if (this.hasAddressFields({submitted: true})) {
				this.snackbar.open('Please return to the project page and fill in the required ship to address fields.', SnackbarActionEnum.ERROR);
				ngForm.submitted = true;
				return;
			}

			this.creatingSalesOrder = true;

			const salesOrderCreate: SalesOrderCreate = {
				poNumber: this.salesOrderForm.get('po')?.value,
				salesOrderNo: this.salesOrderForm.get('salesOrderNo')?.value,
				installDate: new Date(this.salesOrderForm.get('installDate')?.value).toISOString().substring(0, 10),
				shipDate: new Date(this.salesOrderForm.get('shipDate')?.value).toISOString().substring(0, 10),
				transitDays: this.salesOrderForm.get('transitDays')?.value,
				shipToName: this.project.name
			};

			this.quoteService.approveQuote(this.quote.id, salesOrderCreate).subscribe({
				next: (response: SalesOrderResponse): void => {
					this.dialog.open(CreateOrderResponseComponent, {
						disableClose: true,
						panelClass: 'w-1/2',
						data: {
							isSuccessful: true,
							salesOrder: response
						}
					});
					this.quote.salesOrder = response.id;
					this.creatingSalesOrder = false;
				},
				error: (response: any): void => {
					this.dialog.open(CreateOrderResponseComponent, {
						panelClass: 'w-1/2',
						disableClose: true,
						data: {
							isSuccessful: false,
							error: response.error
						}
					});
					this.creatingSalesOrder = false;
				}
			});
		} else {
			this.snackbar.open('Please Create a Quote first.', SnackbarActionEnum.ERROR);
			this.creatingSalesOrder = false;
		}
	}

	get salesOrderCreated(): boolean {
		return !!this.quote.salesOrder || this.creatingSalesOrder;
	}

	get hasChangeRequest(): boolean {
		return this.quote.status === QuoteStatusEnum.CHANGE_REQUESTED;
	}

	reloadParts(): void {
		this.partService.findAllFromErp(this.project.clientId, this.quote.date, true);
	}

	getCreateSalesOrderTooltipMessage(): string {
		if (!this.creatingSalesOrder) {
			if (this.quoteForm.dirty && !this.salesOrderCreated) {
				return 'Please save quote before creating sales order';
			} else if (this.salesOrderCreated) {
				return 'Sales order has already been created for this quote';
			}
		}
		return '';
	}

	get hasStockroomPermitFee(): boolean {
		return this.associatedCostFormArray.controls.some((control) => control.value.type === 'stockPermitReq');
	}

	getPriceOfControl(formGroupType: string): number {
		const control = this.getControlFromFormArray(formGroupType);
		let cost: number = 0;
		if (control) {
			const price: string = control.get('price')?.value;
			if (price) {
				cost = parseFloat(price.toString().replaceAll(',', ''));
			}
		}
		return cost;
	}

	getControlFromFormArray(formGroupType: string) {
		return this.associatedCostFormArray.controls.find((control) => control.value.type === formGroupType);
	}

	getFormArray(arrayName: string): FormArray {
		return this.quoteForm.controls[arrayName] as FormArray;
	}

	hasAddressFields(ngForm: any): boolean {
		return ngForm.submitted && (!this.quote.siteAddress1 || !this.quote.siteCity || !this.quote.siteZip || !this.quote.siteState);
	}
	protected readonly QuoteStatusEnum = QuoteStatusEnum;
	protected readonly FormArray = FormArray;
	protected readonly parseFloat = parseFloat;
}
