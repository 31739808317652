import {Injectable} from '@angular/core';
import {Template, TemplateUpdate} from '../models/template.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TemplatesService {
	baseUrl: string = `${environment.url}/templates`;

	constructor(private http: HttpClient) {}

	findTemplateEntity<T>(id: number): Observable<T> {
		return this.http.get<T>(`${this.baseUrl}/${id}/entity`);
	}

	create(template: TemplateUpdate): Observable<Template> {
		return this.http.post<Template>(this.baseUrl, template);
	}
}
