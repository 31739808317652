import {Component, OnInit} from '@angular/core';
import {CalendarService} from '../core/services/calendar.service';
import {Calendar} from '../core/models/calendar.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ProductionHour} from '../core/models/production-hours.interface';
import {ScheduleTask, ScheduleTaskProject, ScheduleTaskProjectHours, ScheduleTasks} from '../core/models/schedule-tasks.interface';
import {addWeeks, isWeekend, subWeeks} from 'date-fns';
import {
	ProductionHourOverrideDialogComponent,
	ProductionHourOverrideDialogResponse
} from '../shared/components/production-hour-override-dialog/production-hour-override-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TaskTypeService} from '../core/services/task-type.service';
import {LocalStorageService} from '../core/services/local-storage.service';
import {LocationService} from '../core/services/location.service';
import {Location} from '../core/models/location.interface';

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
	calendar: Calendar[] = [];

	showWeekends = false;
	expandDetails = true;

	selectedDate = new Date();
	previousDate: Date;
	nextDate: Date;

	filter = '';
	filterType = '';
	filterApproved = '';
	statusFilter: string[] = [];

	filterTypeList: string[] = [];
	statusDescriptionList: string[] = [];

	locations: Partial<Location>[] = [];
	locationFilter: Partial<Location>;

	busy = false;
	loadPrevious = false;

	constructor(
		private calendarService: CalendarService,
		private _snackBar: MatSnackBar,
		private dialog: MatDialog,
		private taskTypeService: TaskTypeService,
		private localStorageService: LocalStorageService,
		private locationService: LocationService
	) {}

	ngOnInit() {
		this.previousDate = subWeeks(this.selectedDate, 1);
		this.nextDate = addWeeks(this.selectedDate, 1);
		this.getCalendar(this.selectedDate);
		this.locationService.locationSubject.subscribe((response) => {
			this.locationFilter = {id: 0, name: 'All'};
			this.locations = [this.locationFilter, ...response];
			const storedLocation = this.localStorageService.getObject('calendarLocationFilter') as Location;
			if (storedLocation) {
				const locationFilter: Partial<Location> | undefined = this.locations.find((location) => location.id === storedLocation.id);
				if (locationFilter) {
					this.locationFilter = locationFilter;
				}
			}
		});
		this.locationService.findAll();
	}

	getCalendar(selectedDate: Date | null = null, atEnd: boolean = true) {
		this.busy = true;
		this.calendarService.getCalendar(selectedDate).subscribe({
			next: (calendar) => {
				for (let scheduleTask in calendar.schedule) {
					if (this.filterTypeList.indexOf(calendar.schedule[scheduleTask].taskTypeName) === -1) {
						this.filterTypeList.push(calendar.schedule[scheduleTask].taskTypeName);
					}
					for (let projectId in calendar.schedule[scheduleTask].project) {
						if (this.statusDescriptionList.indexOf(calendar.schedule[scheduleTask].project[projectId].statusDescription) === -1) {
							this.statusDescriptionList.push(calendar.schedule[scheduleTask].project[projectId].statusDescription);
						}
					}
				}
				for (let shipment in calendar.scheduleShipping) {
					if (this.statusDescriptionList.indexOf(calendar.scheduleShipping[shipment].statusDescription) === -1) {
						this.statusDescriptionList.push(calendar.scheduleShipping[shipment].statusDescription);
					}
				}
				this.statusDescriptionList.sort();
				if (atEnd) {
					this.calendar.push(calendar);
				} else {
					this.calendar.unshift(calendar);
				}

				if (!this.showWeekends) {
					for (let scheduleTask in calendar.schedule) {
						for (let scheduleTaskProject in calendar.schedule[scheduleTask].project) {
							const project = calendar.schedule[scheduleTask].project[scheduleTaskProject];
							if (
								(project.offset === 0 && project.hours[0].hours > 0) ||
								(project.offset + Object.keys(project.hours).length > 6 && project.hours[Object.keys(project.hours).length - 1].hours > 0)
							) {
								this._snackBar.open('Weekend Schedule found. Showing Weekends');
								this.showWeekends = true;
								break;
							}
						}
						if (this.showWeekends) {
							break;
						}
					}
				}
				if (!this.showWeekends) {
					calendar.scheduleShipping.forEach((shipment) => {
						if (!this.showWeekends && isWeekend(new Date(shipment.shipDate))) {
							this._snackBar.open('Weekend Schedule found. Showing Weekends');
							this.showWeekends = true;
						}
					});
				}
				this.busy = false;
				this.loadPrevious = false;
			},
			error: (err) => {
				this._snackBar.open('Get Calendar Failed: ' + err.error.message);
				this.busy = false;
				this.loadPrevious = false;
			}
		});
	}

	dateChange(e: MatDatepickerInputEvent<string>) {
		this.calendar = [];
		this.previousDate = subWeeks(this.selectedDate, 1);
		this.nextDate = addWeeks(this.selectedDate, 1);
		this.getCalendar(this.selectedDate);
	}

	getCalendarShipping(calendar: Calendar) {
		return this.filterType === '' || this.filterType === 'Shipping' ? calendar.scheduleShipping : null;
	}

	getCalendarTasks(calendar: Calendar): ScheduleTasks {
		return Object.fromEntries(
			Object.entries(calendar.schedule).filter((task) => this.filterType === '' || this.filterType === task[1].taskTypeName)
		);
	}

	getFilteredShippingProjects(calendar: Calendar) {
		return calendar.scheduleShipping.filter(
			(project) =>
				(this.filterApproved === '' ||
					(this.filterApproved === 'approved' && project.approved) ||
					(this.filterApproved === 'pending' && !project.approved)) &&
				(this.statusFilter.length === 0 || this.statusFilter.indexOf(project.statusDescription) > -1) &&
				(project.projectId.toString(10).toLowerCase().includes(this.filter.toLowerCase()) ||
					project.projectName.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.orderNo.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.projectLocation.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.statusDescription.toLowerCase().includes(this.filter.toLowerCase())) &&
				(!this.locationFilter.id ||
					(this.locationFilter.id && this.locationFilter.id <= 1 && project.locationId <= 1) ||
					(this.locationFilter.name && project.locationName.toLowerCase().includes(this.locationFilter.name.toLowerCase())))
		);
	}

	getFilteredTaskProjects(task: ScheduleTask): ScheduleTaskProject {
		return Object.fromEntries(
			Object.entries(task.project).filter(
				(project) =>
					(this.filterApproved === '' ||
						(this.filterApproved === 'approved' && project[1].approved) ||
						(this.filterApproved === 'pending' && !project[1].approved)) &&
					(this.statusFilter.length === 0 || this.statusFilter.indexOf(project[1].statusDescription) > -1) &&
					(project[0].toLowerCase().includes(this.filter.toLowerCase()) ||
						project[1].projectName.toLowerCase().includes(this.filter.toLowerCase()) ||
						project[1].orderNo.toLowerCase().includes(this.filter.toLowerCase()) ||
						project[1].projectLocation.toLowerCase().includes(this.filter.toLowerCase()) ||
						project[1].statusDescription.toLowerCase().includes(this.filter.toLowerCase())) &&
					(!this.locationFilter.id || (this.locationFilter.id && this.locationFilter.id <= 1))
			)
		);
	}

	showDay(i: number) {
		if (!this.showWeekends && (i === 0 || i === 6)) {
			return false;
		}
		return true;
	}

	previous() {
		this.loadPrevious = true;
		this.getCalendar(this.previousDate, false);
		this.previousDate = subWeeks(this.previousDate, 1);
	}

	next() {
		if (!this.busy) {
			this.getCalendar(this.nextDate);
			this.nextDate = addWeeks(this.nextDate, 1);
		}
	}

	getProductionComment(day: ProductionHour, taskId: string) {
		const taskTypeId = parseInt(taskId, 10);
		if (taskTypeId) {
			return day[taskTypeId].comment;
		}
		return '';
	}

	getProductionHours(day: ProductionHour, taskId: string) {
		const taskTypeId = parseInt(taskId, 10);
		if (taskTypeId) {
			return day[taskTypeId].hours;
		}
		return 0;
	}

	getProductionSchedule(day: ProductionHour, taskId: string) {
		const taskTypeId = parseInt(taskId, 10);
		if (taskTypeId) {
			return day[taskTypeId].scheduled;
		}
		return 0;
	}

	weekendHide(hours: ScheduleTaskProjectHours, offset: number) {
		let length = Object.keys(hours).length;
		if (!this.showWeekends && (offset === 0 || offset === 6) && length <= 1) {
			return true;
		}
		return false;
	}

	shipmentHide(offset: number) {
		if (!this.showWeekends && (offset === 0 || offset === 6)) {
			return true;
		}
		return false;
	}

	showHours(projectOffset: number, hoursOffset: string) {
		if (!this.showWeekends) {
			if (projectOffset === 0 && hoursOffset === '0') {
				return false;
			}
			if (projectOffset + parseInt(hoursOffset, 10) >= 6) {
				return false;
			}
		}
		return true;
	}

	projectDays(hours: ScheduleTaskProjectHours) {
		return Object.keys(hours).length;
	}

	projectStart(offset: number) {
		let start = this.showWeekends ? offset + 1 : offset;
		if (start === 0) {
			start++;
		}
		return start;
	}

	projectHours(hours: ScheduleTaskProjectHours, offset: number) {
		let length = Object.keys(hours).length;
		if (!this.showWeekends) {
			if (offset === 0) {
				length--;
			}
			if (offset + length > 6) {
				length--;
			}
		}
		return length;
	}

	projectAdjust(offset: number, hours: ScheduleTaskProjectHours) {
		let adjust = this.showWeekends ? 1 : 0;
		if (!this.showWeekends) {
			if (offset + Object.keys(hours).length > 6) {
				adjust--;
			}
		}
		return adjust;
	}

	gridPad() {
		return this.showWeekends ? 1 : 0;
	}

	highlight(projectId: string | number) {
		if (typeof projectId === 'number') {
			projectId = projectId.toString(10);
		}
		this.calendar.forEach((calendar) => {
			for (let scheduleTask in calendar.schedule) {
				for (let scheduleTaskProject in calendar.schedule[scheduleTask].project) {
					if (scheduleTaskProject === projectId) {
						calendar.schedule[scheduleTask].project[scheduleTaskProject].highlight = true;
					}
				}
			}
			calendar.scheduleShipping.forEach((shipment) => {
				if (shipment.projectId.toString(10) === projectId) {
					shipment.highlight = true;
				}
			});
		});
	}

	unHighlight() {
		this.calendar.forEach((calendar) => {
			for (let scheduleTask in calendar.schedule) {
				for (let scheduleTaskProject in calendar.schedule[scheduleTask].project) {
					calendar.schedule[scheduleTask].project[scheduleTaskProject].highlight = false;
				}
			}
			calendar.scheduleShipping.forEach((shipment) => {
				shipment.highlight = false;
			});
		});
	}

	hoursOffset(projectOffset: number, offset: string) {
		const hourOffset = parseInt(offset, 10);
		const pad = !this.showWeekends && projectOffset === 0 ? 0 : 1;
		return hourOffset + pad;
	}

	editProductionHourOverride(cI: number, date: string) {
		const override = this.dialog.open(ProductionHourOverrideDialogComponent, {
			data: {
				date: date
			}
		});
		override.afterClosed().subscribe((result: ProductionHourOverrideDialogResponse) => {
			if (result.success) {
				for (let taskTypeId in result.overrides[date]) {
					if (this.calendar[cI].productionHours[date][taskTypeId]) {
						this.calendar[cI].productionHours[date][taskTypeId].hours = result.overrides[date][taskTypeId].hours;
						this.calendar[cI].productionHours[date][taskTypeId].comment = result.overrides[date][taskTypeId].comment;
					}
				}
			}
		});
	}

	getClass(name: string): string {
		return this.taskTypeService.getClass(name);
	}

	navigateTo(type: string) {
		switch (type) {
			case 'shipping':
				return 'freight';
			case 'PICKLIST':
				return 'picklist';
			default:
				return 'scheduling';
		}
	}

	selectLocation() {
		this.localStorageService.setObject('calendarLocationFilter', this.locationFilter);
	}
}
