<form [formGroup]="form">
	<h2>Project Site</h2>
	<!--	<mat-form-field appearance="outline" class="w-full">-->
	<!--		<mat-label>Select Project Site</mat-label>-->
	<!--		<mat-select>-->
	<!--			<mat-option disabled>Client has no sites yet.</mat-option>-->
	<!--		</mat-select>-->
	<!--	</mat-form-field>-->

	<mat-form-field class="w-full" appearance="outline">
		<mat-label>Name</mat-label>
		<input matInput formControlName="name" />
		<mat-error *ngIf="form.get('name')?.hasError('required')"> Name is required </mat-error>
		<mat-error *ngIf="form.get('name')?.hasError('maxlength')">
			{{ this.form.get('name')?.value.length }} / 100 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>

	<mat-form-field class="w-full" appearance="outline">
		<mat-label>Site Address</mat-label>
		<input matInput formControlName="address1" />
		<mat-error *ngIf="form.get('address1')?.hasError('maxlength')">
			{{ this.form.get('address1')?.value.length }} / 50 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>

	<mat-form-field class="w-full" appearance="outline">
		<mat-label>Site Address 2</mat-label>
		<input matInput formControlName="address2" />
		<mat-error *ngIf="form.get('address2')?.hasError('maxlength')">
			{{ this.form.get('address2')?.value.length }} / 50 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>

	<div class="flex gap-4">
		<mat-form-field class="w-1/2" appearance="outline">
			<mat-label>City</mat-label>
			<input matInput formControlName="city" />
			<mat-error *ngIf="form.get('city')?.hasError('required')"> City is required </mat-error>
			<mat-error *ngIf="form.get('city')?.hasError('maxlength')">
				{{ this.form.get('city')?.value.length }} / 100 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>

		<mat-form-field class="w-1/6" appearance="outline">
			<mat-label>State</mat-label>
			<input matInput formControlName="state" />
			<mat-error *ngIf="form.get('state')?.hasError('required')"> State is required </mat-error>
			<mat-error *ngIf="form.get('state')?.hasError('maxlength')">
				{{ this.form.get('state')?.value.length }} / 10 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>

		<mat-form-field class="w-1/6" appearance="outline">
			<mat-label>Zip</mat-label>
			<input matInput formControlName="postal" />
		</mat-form-field>
		<mat-form-field class="w-1/6" appearance="outline">
			<mat-label>Country</mat-label>
			<input matInput formControlName="country" />
			<mat-error *ngIf="form.get('country')?.hasError('maxlength')">
				{{ this.form.get('country')?.value.length }} / 10 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
	</div>

	<h2>Job Details</h2>
	<mat-form-field appearance="outline" class="w-full">
		<mat-label>Load Project Template</mat-label>
		<mat-select [(ngModel)]="selectedTemplate" [ngModelOptions]="{standalone: true}" (selectionChange)="templateSelection($event.value)">
			<mat-option *ngFor="let template of templateOptions" [value]="template">{{ template.description }}</mat-option>
			<mat-option *ngIf="templateOptions.length === 0" disabled>No Templates for Client</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="outline">
		<mat-label>Warehouse Location</mat-label>
		<mat-select formControlName="locationId">
			<mat-option *ngFor="let location of warehouseLocations" [value]="location.id">{{ location.name }}</mat-option>
		</mat-select>
		<mat-error *ngIf="form.get('location')?.hasError('required')"> Location is required </mat-error>
	</mat-form-field>
	<mat-checkbox class="ml-2" color="primary" formControlName="transfer">Transfer Required</mat-checkbox>
	<div>
		<ng-container *ngIf="!existingEstimate">
			<mat-form-field class="w-3/4" appearance="outline">
				<mat-label>Install Date</mat-label>
				<input matInput [matDatepicker]="installPicker" formControlName="installDate" />
				<mat-error>Please enter or select a valid date</mat-error>
				<mat-datepicker-toggle matIconSuffix [for]="installPicker"></mat-datepicker-toggle>
				<mat-datepicker #installPicker></mat-datepicker>
			</mat-form-field>
		</ng-container>

		<ng-container *ngIf="existingEstimate">
			<mat-form-field class="w-3/4" appearance="outline">
				<mat-label>Estimated Date</mat-label>
				<mat-select [compareWith]="compareDates" formControlName="estimateDate">
					<mat-option *ngFor="let option of dateOptions" [value]="option.date">{{ option.description }}</mat-option>
				</mat-select>
				<mat-error *ngIf="form.get('installDate')?.hasError('required')"> Estimate Date is required </mat-error>
			</mat-form-field>
		</ng-container>
		<mat-checkbox
			class="w-1/4 m-auto"
			*ngIf="!project?.orderNo"
			[checked]="existingEstimate"
			(change)="updateEstimateCheck($event)"
			color="primary"
			#estimatedInstallCheckbox
			>Estimated Install Date
		</mat-checkbox>
	</div>
	<div class="flex gap-4">
		<mat-form-field class="w-1/2" appearance="outline">
			<mat-label>Ready For Quote Date</mat-label>
			<input matInput [matDatepicker]="rfqPicker" formControlName="lastRfqDate" />
			<mat-error>Please enter or select a valid date</mat-error>
			<mat-datepicker-toggle matIconSuffix [for]="rfqPicker"></mat-datepicker-toggle>
			<mat-datepicker #rfqPicker></mat-datepicker>
		</mat-form-field>

		<mat-form-field class="w-1/2" appearance="outline">
			<mat-label>Drafting Last Contact</mat-label>
			<input matInput [matDatepicker]="lcdPicker" formControlName="lastDraftingContactDate" />
			<mat-error>Please enter or select a valid date</mat-error>
			<mat-datepicker-toggle matIconSuffix [for]="lcdPicker"></mat-datepicker-toggle>
			<mat-datepicker #lcdPicker></mat-datepicker>
		</mat-form-field>
	</div>
	<mat-divider></mat-divider>

	<div class="flex flex-col flex-wrap">
		<mat-checkbox class="w-1/2" color="primary" formControlName="stockPermitReq" (change)="stockroomSeismicCheck()"
			>Stockroom Permit</mat-checkbox
		>

		<div class="flex">
			<mat-checkbox class="w-1/2" color="primary" formControlName="seismic" (click)="seismicOrNonSeismic('seismic')">Seismic</mat-checkbox>
			<mat-checkbox
				class="w-1/2"
				color="primary"
				#nonSeismicCheckbox
				[checked]="nonSeismicDefault"
				(click)="seismicOrNonSeismic('non-seismic')"
				>Non-Seismic</mat-checkbox
			>
		</div>
		<div *ngIf="form.controls['seismic'].value" class="ml-12 bg-gray-200 p-1 rounded">
			<mat-checkbox class="w-1/2" color="primary" formControlName="seismicCalcs">Seismic Calcs</mat-checkbox>
			<mat-checkbox class="w-1/2" color="primary" formControlName="specialInspection">Special Inspection</mat-checkbox>
		</div>
		<div *ngIf="nonSeismicCheckbox.checked" class="ml-12 bg-gray-200 p-1 rounded">
			<mat-checkbox class="w-1/2" color="primary" formControlName="structuralCalcs">Structural Calcs</mat-checkbox>
		</div>

		<mat-checkbox
			class="w-1/2"
			color="primary"
			formControlName="includeInstall"
			(click)="removeIncludeInstallOptions()"
			matTooltip="Warning! If this box is checked, unchecking will cause the associated quotes to have zero install cost and a false grand total on the quote list until saved."
			>Include Install</mat-checkbox
		>
		<div *ngIf="form.controls['includeInstall'].value" class="ml-12 bg-gray-200 p-1 rounded">
			<span class="italic text-gray-600">Union</span><br />
			<mat-checkbox class="w-1/3" color="primary" (click)="unionOrWage('non-union')" formControlName="nonUnion">Non-Union</mat-checkbox>
			<mat-checkbox class="w-1/3" color="primary" (click)="unionOrWage('union')" ngDefaultControl formControlName="union"
				>Union</mat-checkbox
			>
			<mat-checkbox class="w-1/3" color="primary" (click)="unionOrWage('wage')" ngDefaultControl formControlName="prevailingWage"
				>Prevailing Wage</mat-checkbox
			>
			<mat-divider></mat-divider>
			<span class="italic text-gray-600">Day/Night Work</span><br />
			<mat-checkbox class="w-1/3" color="primary" ngDefaultControl formControlName="nightWork" (click)="dayOrNight('night')"
				>Night</mat-checkbox
			>
			<mat-checkbox
				class="w-1/3"
				color="primary"
				ngDefaultControl
				(click)="dayOrNight('day')"
				#dayCheckbox
				[checked]="!form.controls['nightWork'].value"
				>Day</mat-checkbox
			>
		</div>
		<mat-checkbox class="w-1/2" color="primary" formControlName="demo">Demolition</mat-checkbox>
		<mat-checkbox class="w-1/2" color="primary" formControlName="demoRemoval">Discard</mat-checkbox>
		<mat-checkbox class="w-1/2" color="primary" formControlName="skidding">Skidding</mat-checkbox>
		<mat-checkbox class="w-1/2" color="primary" formControlName="thirdPartyFreight">3rd Party Freight</mat-checkbox>
		<mat-checkbox class="w-1/2" color="primary" formControlName="survey">Survey</mat-checkbox>
		<!--    Survey File Upload (maxFileLength = 1, subType = survey)-->
		<app-project-file-upload
			*ngIf="form.controls['survey'].value"
			[maxFileLength]="1"
			[type]="{id: 0, code: '', name: 'Survey File', active: 0}"
			subType="Survey"
			[uploadedFiles]="project?.documents"
		></app-project-file-upload>
	</div>

	<mat-divider class="mb-2"></mat-divider>

	<p id="install-locations">Install Locations</p>
	<div formArrayName="locations" class="w-1/2">
		<div *ngFor="let location of locations.controls; index as i" class="flex">
			<div>
				<mat-form-field class="w-full" appearance="outline">
					<mat-label>Select an option</mat-label>
					<input [matAutocomplete]="auto" matInput [formControlName]="i" #locationInput />

					<mat-autocomplete [displayWith]="displayFn" #auto>
						<mat-option *ngFor="let location of filteredLocations[i] | async" [value]="location">{{ location.name }}</mat-option>
						<mat-option (onSelectionChange)="openCreateInstallModal(locationInput.value)">Create New...</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
			<button *ngIf="i > 0" (click)="removeLocation(i)" type="button" class="ml-2"><mat-icon>delete</mat-icon></button>
		</div>
	</div>
	<button mat-mini-fab color="primary" type="button" (click)="addNewLocationInput()">
		<mat-icon>add</mat-icon>
	</button>
</form>
