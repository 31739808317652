import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	setItem(key: string, value: any) {
		localStorage.setItem(key, value);
	}

	getItem(key: string): any {
		return localStorage.getItem(key);
	}

	setBool(key: string, value: boolean) {
		localStorage.setItem(key, String(value));
	}

	getBool(key: string, notFound: boolean | null = null): boolean {
		const val = localStorage.getItem(key);
		if (val === null && notFound !== null) {
			return notFound;
		}
		return val === 'true';
	}

	setObject(key: string, value: object | null) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	getObject(key: string): object {
		const obj = localStorage.getItem(key);
		return obj ? JSON.parse(obj) : null;
	}
}
