import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GlCodeService} from '../../../core/services/gl-code.service';
import {GlCode} from '../../../core/models/gl-code.interface';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {Code} from '../../../core/models/code.interface';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {TitleCasePipe} from '@angular/common';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
	selector: 'app-gl-codes-list-detail',
	templateUrl: './gl-codes-list-detail.component.html',
	styleUrls: ['./gl-codes-list-detail.component.scss']
})
export class GlCodesListDetailComponent implements OnInit {
	@Input() code: GlCode;
	@Input() codeTypes: Code[];
	@Input() categories: string[];
	multiLine = false;
	form: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private glCodeService: GlCodeService,
		private snackbar: MatSnackBar,
		private titlecasePipe: TitleCasePipe
	) {
		this.form = this.formBuilder.group({
			id: [''],
			glNumber: ['', Validators.required],
			description: ['', Validators.required],
			type: ['', Validators.required],
			category: ['', Validators.required]
		});
	}
	ngOnInit() {
		this.form.patchValue(this.code);
		if (this.code.description?.includes('\n')) {
			this.multiLine = true;
		}
		this.form.disable();
	}

	onSubmit() {
		const formValue = this.form.getRawValue();
		const id = this.form.get('id')?.value;
		if (!this.multiLine) {
			formValue.description = formValue.description.replace(/\r?\n|\r/g, ' ');
		}
		this.glCodeService.update(id, formValue).subscribe(
			(response) => {
				this.snackbar.open('GL Code Updated!', SnackbarActionEnum.SUCCESS);
				this.glCodeService.subjectFindAll();
				this.form.disable();
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}

	openConfirmDeleteModal(code: GlCode) {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				title: 'Confirm Delete',
				message: 'Are you sure you want to delete the ' + this.titlecasePipe.transform(code.description) + ' GL code?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.deleteCode(code.id!);
			}
		});
	}

	deleteCode(id: number) {
		this.glCodeService.remove(id).subscribe(
			(response) => {
				this.glCodeService.subjectFindAll();
				this.snackbar.open('GL Code Deleted!', SnackbarActionEnum.SUCCESS);
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}

	updateMultiLine(change: MatCheckboxChange) {
		this.form.markAsDirty();
		this.multiLine = change.checked;
	}
}
