import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DecimalPipe, NgOptimizedImage, TitleCasePipe} from '@angular/common';
import {ProjectDetailComponent} from './project-detail/project-detail.component';
import {ProjectRackSolidComponent} from './project-rack-solid/project-rack-solid.component';
import {ProjectFreightComponent} from './project-freight/project-freight.component';
import {ShipmentComponent} from './project-freight/shipment/shipment.component';
import {ProjectsComponent} from './projects.component';
import {ProjectsListComponent} from './project-list/projects-list.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDateFnsModule} from '@angular/material-date-fns-adapter';
import {enUS} from 'date-fns/locale';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {QuoteConfigComponent} from './project-rack-solid/quote-config/quote-config.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {ProjectQuoteComponent} from './project-quote/project-quote.component';
import {FreightEstimatorComponent} from './project-quote/freight-estimator/freight-estimator.component';
import {SharedModule} from '../shared/shared.module';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {PartListComponent} from './shared/part-list/part-list.component';
import {BrokerComponent} from './project-freight/broker/broker.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProjectQuickStatusComponent} from './project-quick-status/project-quick-status.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {FreightInstallComponent} from './project-quote/freight-install/freight-install.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RequestApprovalDialogComponent} from './project-quote/dialogs/request-approval-dialog/request-approval-dialog.component';
import {QuickStatusEditComponent} from './project-quick-status/project-quick-status-edit/quick-status-edit.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ManualPartEntryComponent} from './project-quote/manual-parts-entry/manual-part-entry.component';
import {ManualPartsInputComponent} from './project-quote/manual-parts-entry/manual-parts-input/manual-parts-input.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ProjectSchedulingComponent} from './project-scheduling/project-scheduling.component';
import {ProjectEstimateComponent} from './project-estimate/project-estimate.component';
import {AssociatedCostsComponent} from './project-quote/associated-costs/associated-costs.component';
import {ApprovalInfoComponent} from './project-quote/approval-info/approval-info.component';
import {AdditionalInfoComponent} from './project-quote/additional-info/additional-info.component';
import {CreateOrderResponseComponent} from './project-quote/dialogs/create-order-response/create-order-response.component';
import {QuoteModule} from '../quote/quote.module';
import {MaintenanceModule} from '../maintenance/maintenance.module';
import {UnderscoreRemove} from '../shared/pipes/underscore-remove.pipe';
import {CostAdjustmentsComponent} from './project-quote/cost-adjustments/cost-adjustments.component';
import {CostAdjustmentDialogComponent} from './project-quote/cost-adjustments/cost-adjustment-dialog/cost-adjustment-dialog.component';
import {ChangeRequestInfoComponent} from './project-quote/change-request-info/change-request-info.component';
import {LcdChangeModalComponent} from './project-detail/lcd-change-modal/lcd-change-modal.component';
import {MatMultiSortModule} from 'ngx-mat-multi-sort';
import {MiscMaterialsComponent} from './project-quote/misc-materials/misc-materials.component';
import {SaveQuoteChangesDialogComponent} from './project-quote/dialogs/save-quote-changes-dialog/save-quote-changes-dialog.component';
import {ErrorDialogComponent} from './project-quote/dialogs/error-dialog/error-dialog.component';
import {ProjectPicklistComponent} from './project-picklist/project-picklist.component';
import {NgxPrintModule} from 'ngx-print';
@NgModule({
	declarations: [
		ProjectsComponent,
		ProjectsListComponent,
		ProjectDetailComponent,
		ProjectRackSolidComponent,
		ProjectFreightComponent,
		QuoteConfigComponent,
		ShipmentComponent,
		BrokerComponent,
		ProjectQuoteComponent,
		FreightEstimatorComponent,
		PartListComponent,
		ProjectQuickStatusComponent,
		RequestApprovalDialogComponent,
		FreightInstallComponent,
		QuickStatusEditComponent,
		ManualPartEntryComponent,
		ManualPartsInputComponent,
		ProjectSchedulingComponent,
		ProjectEstimateComponent,
		AssociatedCostsComponent,
		CreateOrderResponseComponent,
		AssociatedCostsComponent,
		AssociatedCostsComponent,
		ApprovalInfoComponent,
		AdditionalInfoComponent,
		CostAdjustmentsComponent,
		CostAdjustmentDialogComponent,
		ChangeRequestInfoComponent,
		LcdChangeModalComponent,
		SaveQuoteChangesDialogComponent,
		MiscMaterialsComponent,
		ErrorDialogComponent,
		ProjectPicklistComponent
	],
	imports: [
		CommonModule,
		MatExpansionModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatButtonModule,
		MatSortModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		MatInputModule,
		MatStepperModule,
		MatTableModule,
		MatCardModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		MatRadioModule,
		MatDatepickerModule,
		MatDateFnsModule,
		SharedModule,
		MatSnackBarModule,
		FormsModule,
		MatDividerModule,
		MatDialogModule,
		MatAutocompleteModule,
		MatTooltipModule,
		MatChipsModule,
		RouterModule,
		MatSlideToggleModule,
		QuoteModule,
		MaintenanceModule,
		NgOptimizedImage,
		MatMultiSortModule,
		NgxPrintModule
	],
	exports: [ReactiveFormsModule, PartListComponent, ProjectFreightComponent],
	providers: [
		{
			provide: MAT_DATE_LOCALE,
			useValue: enUS
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {duration: 3500}
		},
		TitleCasePipe,
		DecimalPipe,
		CurrencyPipe,
		UnderscoreRemove
	]
})
export class ProjectsModule {}
