<div class="m-4 w-3/5">
	<app-project-details
		[selectedTemplate]="selectedTemplate"
		[templateOptions]="templateOptions"
		[existingEstimate]="existingEstimate"
		[project]="project"
		[form]="form"
	></app-project-details>
	<div class="mt-4">
		<app-team-members (addUser)="addUser($event)" (removeUser)="removeUser($event)"></app-team-members>
	</div>
	<app-drafters-notes [form]="form"></app-drafters-notes>
	<div class="w-full pr-4 flex justify-end items-end">
		<button [disabled]="updatingProject" (click)="saveTemplate()" class="mt-2 mr-4" mat-button>SAVE AS TEMPLATE</button>
		<button [disabled]="form.invalid || updatingProject" (click)="updateProjectPreflight(true)" class="mt-2 mr-4" mat-flat-button color="primary">
			SAVE CHANGES & RETURN
		</button>
		<button [disabled]="form.invalid || updatingProject" (click)="updateProjectPreflight(false)" class="mt-2" mat-flat-button color="primary">
			SAVE CHANGES
		</button>
	</div>
</div>
