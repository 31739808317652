<div class="max-w-6xl m-4">
	<h1>Create Project</h1>
	<app-client-search (selectedClientEmitter)="selectClient($event)" (prospectSelected)="prospectSelected($event)"></app-client-search>
	<mat-expansion-panel [expanded]="true" id="detail-panel">
		<mat-expansion-panel-header>
			<mat-panel-title> Project Details </mat-panel-title>
		</mat-expansion-panel-header>
		<app-project-details
			[templateOptions]="templateOptions"
			[selectedTemplate]="selectedTemplate"
			[client]="selectedClient"
			[form]="form"
			[existingEstimate]="true"
			[projectDocumentDtos]="projectDocumentDtos"
		></app-project-details>
	</mat-expansion-panel>
	<app-project-file-upload
		*ngFor="let location of locations.controls; index as i"
		[type]="location.value"
		(fileAddEmitter)="handleFileAddEmit($event)"
		(fileRemoveEmitter)="handleFileRemoveEmit($event)"
		(documentDtoEmitter)="handleDocumentDtoEmit($event)"
	></app-project-file-upload>
	<app-team-members [createMode]="true" (addUser)="addUser($event)" (removeUser)="removeUser($event)"></app-team-members>
	<app-drafters-notes [form]="form"></app-drafters-notes>
	<div class="w-full pr-4 flex justify-end items-end">
		<button (click)="saveTemplate()" [disabled]="!selectedClient" class="mt-2 mr-4" mat-button>SAVE AS TEMPLATE</button>
		<button
			(click)="this.form.get('lastRfqDate')?.value ? confirmRfqStatus(true) : createProject(false, true)"
			[disabled]="submitDisabled()"
			class="mt-2 mr-4"
			mat-flat-button
			color="primary"
		>
			CREATE & RETURN
		</button>
		<button
			(click)="this.form.get('lastRfqDate')?.value ? confirmRfqStatus(false) : createProject(false, false)"
			[disabled]="submitDisabled()"
			class="mt-2"
			mat-flat-button
			color="primary"
		>
			CREATE
		</button>
	</div>
	<div id="spinner-container" *ngIf="isLoading">
		<mat-spinner></mat-spinner>
	</div>
</div>
