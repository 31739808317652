import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Contact} from '../models/contact.model';

@Injectable({
	providedIn: 'root'
})
export class ContactService {
	baseUrl = `${environment.url}/contact`;
	contactsSubject: Subject<Contact[]> = new Subject();

	constructor(private http: HttpClient) {}

	create(contact: Contact): Observable<Contact> {
		return this.http.post<Contact>(this.baseUrl, contact);
	}

	createContactWithXref(contact: Contact, refType: string, refId: string): Observable<Contact> {
		return this.http.post<Contact>(`${this.baseUrl}/${refType}/${refId}`, contact);
	}

	deleteContactAssociation(contactId: number, refId: string, refType: string) {
		return this.http.delete(`${this.baseUrl}/${contactId}/${refType}/${refId}`);
	}

	findAll(): Observable<Contact[]> {
		this.http.get<Contact[]>(this.baseUrl).subscribe(
			(contacts) => {
				this.contactsSubject.next(contacts);
			},
			(error) => {
				throw new Error(error);
			}
		);
		return this.contactsSubject.asObservable();
	}

	findContactsByType(id: string, type: string): Observable<Contact[]> {
		return this.http.get<Contact[]>(`${this.baseUrl}/${type}/${id}`);
	}

  findContactsForQuoteResend(clientId: string, quoteId: number): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${this.baseUrl}/quote/${quoteId}/client/${clientId}`);
  }

	findOne(id: number): Observable<Contact> {
		return this.http.get<Contact>(`${this.baseUrl}/${id}`);
	}

	update(contactWithId: Contact): Observable<Contact> {
		return this.http.put<Contact>(`${this.baseUrl}/${contactWithId.id}`, contactWithId);
	}

	patch(contactWithId: Contact): Observable<Contact> {
		return this.http.patch<Contact>(`${this.baseUrl}/${contactWithId.id}`, contactWithId);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}

	getInternal(): Observable<Contact[]> {
		return this.http.get<any>(`${this.baseUrl}/internal`);
	}

	findByType(type: string, typeId: number): Observable<Contact[]> {
		return this.http.get<any>(`${this.baseUrl}/type/${type.toUpperCase()}/${typeId}`);
	}

	findByClient(id: number, siteOnly: boolean): Observable<Contact[]> {
		return this.http.get<any>(`${this.baseUrl}/client/${id}/${siteOnly ? 'site' : ''}`);
	}
}
