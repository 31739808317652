import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FreightBroker} from '../models/freight-broker.interface';
import {FreightBid} from '../models/freight-bid.interface';

@Injectable({
	providedIn: 'root'
})
export class FreightService {
	baseUrl: string = `${environment.url}/freight`;
	constructor(private http: HttpClient) {}

	findAllBrokersByShipmentIds(ids: number[]): Observable<FreightBroker[]> {
		return this.http.post<FreightBroker[]>(`${this.baseUrl}/brokers-shipments`, ids);
	}

	requestRates(ids: number[], freightBrokers: FreightBroker[], projectId: number | undefined): Observable<FreightBroker[]> {
		let params = {
			params: {}
		};
		if (projectId) {
			const activityDesc = freightBrokers.map((broker) => (broker.name ? `${broker.name}` : '')).join(', ');
			params.params = {
				activityType: 'FREIGHT_RATE',
				projectId: projectId,
				description: `request rates from ${activityDesc}`
			};
		}
		return this.http.post<FreightBroker[]>(
			`${this.baseUrl}/rates`,
			{
				shipmentIds: ids,
				brokers: freightBrokers
			},
			params
		);
	}

	bookFreight(bid: FreightBid, projectId: number | undefined): Observable<FreightBid> {
		let params = {
			params: {}
		};
		if (projectId) {
			params.params = {
				activityType: 'FREIGHT_BID',
				projectId: projectId,
				description: `request to book a shipment with ${bid.brokerName ? bid.brokerName : bid.carrierName}`
			};
		}
		return this.http.get(`${this.baseUrl}/book/${bid.id}`, params);
	}

	getBol(bidId: number): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/book/bol/${bidId}`);
	}
}
