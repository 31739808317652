<div class="m-4" infinite-scroll [immediateCheck]="true" (scrolled)="next()">
	<div class="flex mb-4 justify-between">
		<div>
			<mat-form-field appearance="outline" class="mr-2" subscriptSizing="dynamic">
				<mat-label class="font-medium">Date</mat-label>
				<input matInput [matDatepicker]="datepicker" (dateChange)="dateChange($event)" [(ngModel)]="selectedDate" />
				<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
				<mat-datepicker #datepicker> </mat-datepicker>
				<mat-error>Install Date <strong>required</strong>.</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" class="mr-2" subscriptSizing="dynamic">
				<mat-label class="font-medium">Filter</mat-label>
				<input matInput [(ngModel)]="filter" />
			</mat-form-field>
			<mat-button-toggle-group [(ngModel)]="filterType">
				<mat-button-toggle value="">All</mat-button-toggle>
				<mat-button-toggle value="scheduled">Scheduled</mat-button-toggle>
				<mat-button-toggle value="unscheduled">Unscheduled</mat-button-toggle>
				<mat-button-toggle value="union"><mat-icon>handshake</mat-icon>Union</mat-button-toggle>
				<mat-button-toggle value="nonunion">Non-Union</mat-button-toggle>
				<mat-button-toggle value="day"><mat-icon>light_mode</mat-icon>Day</mat-button-toggle>
				<mat-button-toggle value="night"><mat-icon>dark_mode</mat-icon>Night</mat-button-toggle>
				<mat-button-toggle value="weekend"><mat-icon>weekend</mat-icon>Weekend</mat-button-toggle>
				<mat-button-toggle value="permit"><mat-icon>fact_check</mat-icon>Permit</mat-button-toggle>
				<mat-button-toggle value="overbooked"><mat-icon>warning</mat-icon>Overbooked</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div>
			<button mat-icon-button [mat-menu-trigger-for]="calendarOptions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #calendarOptions>
				<button mat-menu-item (click)="previous()" [disabled]="busy">Load Previous</button>
				<div mat-menu-item>
					<mat-checkbox [(ngModel)]="expandDetails">Expand Jobs</mat-checkbox>
				</div>
				<div mat-menu-item>
					<mat-checkbox [(ngModel)]="showWeekends">Show Weekends</mat-checkbox>
				</div>
			</mat-menu>
		</div>
	</div>
	<div class="m-8" *ngIf="loadPrevious">
		<mat-spinner></mat-spinner>
	</div>
	<div
		class="flex flex-col self-start rounded-lg calendar"
		*ngFor="let calendar of installCalendar; let cI = index"
		[class.no-weekend]="!showWeekends"
	>
		<mat-divider class="w-full"></mat-divider>
		<div class="grid grid-rows-1 grid-cols-x sticky top-0">
			<ng-container *ngFor="let head of calendar.dates; let i = index">
				<div
					class="justify-self-stretch text-center font-bold header pb-2 pt-2 cursor-pointer"
					[ngClass]="head | date : 'E'"
					[style.grid-column-start]="i + gridPad()"
					[style.grid-column-end]="i + gridPad() + 1"
					*ngIf="showDay(i)"
				>
					{{ head | date : 'EEEEEE' }}
					<br />
					{{ head | date : 'M/d' }}
				</div>
			</ng-container>
		</div>
		<div class="grid grid-rows-1 grid-cols-x mb-4 pt-1">
			<div
				*ngFor="let project of getFilteredProjects(calendar)"
				class="p-1"
				[style.grid-column-start]="projectStart(project.offset)"
				[style.grid-column-end]="projectEnd(project.offset, project.days, project.installWeekend)"
				[style.grid-row-start]="project.row > 0 ? project.row : ''"
				[hidden]="projectHide(project.offset, project.days)"
			>
				<div
					(click)="updateProject(project)"
					[class.highlight]="project.highlight"
					[class.mat-elevation-z8]="project.highlight"
					class="border-2 row-start-1"
					[style.background]="project.installColor"
					(mouseenter)="highlight(project.id)"
					(mouseleave)="unHighlight()"
				>
					<div class="flex flex-col m-2">
						<div class="flex justify-between font-bold">
							<div class="flex flex-col">
								<span>{{ project.orderNo }} {{ project.name }}</span>
								<span class="text-xs">{{ project.city }}, {{ project.state }} {{ project.postal }}</span>
								<span *ngIf="project.installContactId > 0" class="text-xs"
									><mat-icon class="mat-icon no-pad-icon text-sm">engineering</mat-icon
									><span class="ml-2">{{ project.contactName }}</span></span
								>
							</div>
							<div class="flex flex-col items-start items-end">
								<div class="flex text-xs flex-wrap justify-end">
									<mat-icon *ngIf="project.resumes" class="mat-icon no-pad-icon text-xs">west</mat-icon>
									<span>{{ project.installDate | date : 'shortDate' }} - </span
									><span *ngIf="project.installEndDate"> {{ project.installEndDate | date : 'shortDate' }}</span>
									<span *ngIf="!project.installEndDate"> ?</span>
									<mat-icon *ngIf="project.continues" class="mat-icon no-pad-icon text-xs">east</mat-icon>
								</div>
								<span class="flex justify-end flex-wrap">
									<mat-icon *ngIf="project.stockPermitReq" [ngClass]="{'not-complete': !project.installPermitPickup}" class="text-sm"
										>fact_check</mat-icon
									>
									<mat-icon *ngIf="project.specialInspection" [ngClass]="{'not-complete': !project.installInspection}" class="text-sm"
										>verified</mat-icon
									>
									<mat-icon *ngIf="project.installWeekend" class="text-sm">weekend</mat-icon>
									<mat-icon *ngIf="project.union" class="text-sm">handshake</mat-icon>
									<mat-icon *ngIf="project.nightWork" class="text-sm">dark_mode</mat-icon>
									<mat-icon *ngIf="!project.nightWork" class="text-sm">light_mode</mat-icon>
								</span>
								<mat-icon *ngIf="project.overbooked">warning</mat-icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<mat-divider class="w-full"></mat-divider>
	</div>
	<div class="m-8" *ngIf="busy && !loadPrevious">
		<mat-spinner></mat-spinner>
	</div>
	<button class="my-4" *ngIf="!busy" mat-raised-button color="primary" (click)="next()">Load More</button>
</div>
