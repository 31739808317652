import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PriceLevelService} from '../../../core/services/price-level.service';
import {MatSort} from '@angular/material/sort';
import {CreatePriceLevelComponent} from '../create-price-level/create-price-level.component';
import {MatDialog} from '@angular/material/dialog';
import {RolesService} from '../../../core/services/roles.service';
import {PriceLevel} from '../../../core/models/price-level.interface';
import {startOfDay} from 'date-fns';
import {PriceLevelDiscount} from '../../../core/models/price-level-discount';

@Component({
	selector: 'app-price-level-list',
	templateUrl: './price-level-list.component.html',
	styleUrls: ['./price-level-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class PriceLevelListComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	columnsToDisplay: string[] = ['name', 'levelNumber'];
	isLoadingPriceLevels: boolean = true;
	constructor(private priceLevelService: PriceLevelService, private dialog: MatDialog, private rolesService: RolesService) {}

	ngOnInit() {
		this.dataSource = new MatTableDataSource();
		this.priceLevelService.priceLevels.subscribe((response: PriceLevel[]) => {
			// Store the expanded state of each row before updating the data source
			const expandedStates: boolean[] = [];
			for (let i = 0; i < this.dataSource.data.length; i++) {
				expandedStates[i] = this.dataSource.data[i].expanded;
			}
			// Update the data source with new data
			for (const updatedRow of response) {
				const index = this.dataSource.data.findIndex((row) => row.id === updatedRow.id);
				if (index !== -1) {
					// Preserve the expanded state of the row before updating it
					updatedRow.expanded = this.dataSource.data[index].expanded;
					this.dataSource.data[index] = updatedRow;
				} else {
					// Add the new row to the data source
					this.dataSource.data.push(updatedRow);
				}
			}
			// Restore the expanded state of each row after updating the data source
			for (let i = 0; i < this.dataSource.data.length; i++) {
				this.dataSource.data[i].expanded = expandedStates[i];
			}
			// Refresh the data source to update the table
			this.dataSource.data = [...this.dataSource.data];
			this.isLoadingPriceLevels = false;
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreatePriceLevelModal() {
		const dialogRef = this.dialog.open(CreatePriceLevelComponent, {
			width: '70vw',
			maxWidth: '800px',
			disableClose: true
		});
		// dialogRef.componentInstance.roles = this.roles;
	}

	getCurrentDiscount(priceLevel: PriceLevel): number {
		const today: Date = startOfDay(new Date());

		let discount: PriceLevelDiscount;
		priceLevel.priceLevelDiscounts?.forEach((plDiscount: PriceLevelDiscount) => {
			const effectiveDate: Date = startOfDay(new Date(plDiscount.effectiveDate));
			if (today >= effectiveDate) {
				const discountEffectiveDate: Date = startOfDay(new Date(discount?.effectiveDate));
				if (!discount || effectiveDate > discountEffectiveDate) {
					discount = plDiscount;
				}
			}
		});

		// @ts-ignore
		return discount?.levelDiscount ?? 1;
	}
}
