import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {Contact} from '../../../core/models/contact.model';
import {ContactService} from '../../../core/services/contact.service';
import {distinctUntilChanged, Subject, Subscription, takeUntil, throttleTime} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactComponentContextEnum} from '../../../core/enums/contact-component.enum';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy, OnChanges {
	@Input() componentFormGroupName: string = '';
	@Input() context: ContactComponentContextEnum = ContactComponentContextEnum.DEFAULT;
	@Input() contextId: number = 0;
	@Input() contextIdField: string = '';
	@Input() contactId: string = 'contactId';
	@Input() contactName: string = 'contactName';
	@Input() contactEmail: string = 'contactEmail';
	@Input() contactPhone: string = 'contactPhone';
	@Input() contactPhoneExt: string = 'contactPhoneExt';
	@Input() allowCreate: boolean = true;
	@Input() allowModify: boolean = true;
	@Input() readOnly: boolean = false;
	@Input() disableSelection: boolean = false;

	private destroy$ = new Subject();

	busy: boolean = false;

	contactForm: FormGroup | undefined; // = new FormGroup({});

	contacts: Contact[] = [];
	selectedContact: Contact = {};

	contactSubscription: Subscription;

	constructor(private rootFormGroup: FormGroupDirective, private contactService: ContactService, private _snackBar: MatSnackBar) {}

	ngOnInit(): void {
		this.contactForm = this.rootFormGroup.form as FormGroup;
		if (this.componentFormGroupName > '') {
			this.contactForm = this.contactForm.get(this.componentFormGroupName) as FormGroup;
		}
		if (this.contactForm && this.contextIdField && this.contextIdField > '') {
			this.contactForm
				.get(this.contextIdField)
				?.valueChanges.pipe(distinctUntilChanged(), throttleTime(500), takeUntil(this.destroy$))
				.subscribe((val) => {
					this.contextId = this.contactForm?.get(this.contextIdField)?.value;
					this.getContacts();
				});
		}
		this.getContacts();
	}

	ngOnDestroy() {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['contextId'] && this.contextId > 0) {
			this.getContacts();
		}
	}

	getContacts() {
		switch (this.context) {
			case ContactComponentContextEnum.INTERNAL:
				this.busy = true;
				if (this.contactSubscription) {
					this.contactSubscription.unsubscribe();
				}
				this.contactSubscription = this.contactService.getInternal().subscribe({
					next: (contacts) => {
						this.contacts = this.sortContacts(contacts);
						this.setSelectedContact();
						this.busy = false;
					}
				});
				break;
			case ContactComponentContextEnum.CLIENT:
			case ContactComponentContextEnum.SITE:
				if (this.contextId > 0) {
					this.disableSelection = false;
					this.busy = true;
					if (this.contactSubscription) {
						this.contactSubscription.unsubscribe();
					}
					this.contactSubscription = this.contactService.findContactsByType(this.contextId.toString(), this.context).subscribe({
						next: (contacts) => {
							this.contacts = this.sortContacts(contacts);
							this.setSelectedContact();
							this.busy = false;
						}
					});
				} else {
					this.disableSelection = true;
					this.contacts = [];
				}
				break;
			case ContactComponentContextEnum.CLIENT_ALL:
				this.busy = true;
				if (this.contactSubscription) {
					this.contactSubscription.unsubscribe();
				}
				this.contactSubscription = this.contactService.findByClient(this.contextId, false).subscribe({
					next: (contacts) => {
						this.contacts = this.sortContacts(contacts);
						this.setSelectedContact();
						this.busy = false;
					}
				});
				break;
			case ContactComponentContextEnum.INSTALL:
				this.busy = true;
				if (this.contactSubscription) {
					this.contactSubscription.unsubscribe();
				}
				this.contactSubscription = this.contactService.findContactsByType(this.contextId.toString(), this.context).subscribe({
					next: (contacts) => {
						this.contacts = this.sortContacts(contacts);
						this.setSelectedContact();
						this.busy = false;
					}
				});
				break;
			default:
				this.contacts = [];
		}
	}

	updateContact(contact: Contact) {
		this.contactForm?.patchValue({
			[this.contactId]: contact.id ? contact.id : 0,
			[this.contactName]: contact.name ? contact.name : '',
			[this.contactEmail]: contact.email ? contact.email : '',
			[this.contactPhone]: contact.phone ? contact.phone : '',
			[this.contactPhoneExt]: contact.phoneExt ? contact.phoneExt : ''
		});
	}

	sortContacts(contacts: Contact[]): Contact[] {
		return contacts.sort((a, b) => (a.name! > b.name! ? 1 : -1));
	}

	clearContact() {
		this.contactForm?.patchValue({
			[this.contactId]: 0,
			[this.contactName]: '',
			[this.contactEmail]: '',
			[this.contactPhone]: '',
			[this.contactPhoneExt]: ''
		});
	}

	setSelectedContact() {
		const selectedContact = this.contacts.find(
			(contact) =>
				contact.name === this.contactForm?.controls[this.contactName].value ||
				(contact.email && contact.email === this.contactForm?.controls[this.contactEmail].value)
		);
		if (selectedContact) {
			this.selectedContact = selectedContact;
		}
	}

	selectContact(event: MatSelectChange) {
		const contact = event.value;
		if (contact) {
			this.selectedContact = contact;
			this.updateContact(contact);
		} else {
			this.selectedContact = {};
			this.clearContact();
		}
		this.contactForm?.markAsDirty();
	}

	createContact() {
		const newContact: Contact = {
			name: this.contactForm?.get(this.contactName)?.value.trim(),
			email: this.contactForm?.get(this.contactEmail)?.value.trim(),
			phone: this.contactForm?.get(this.contactPhone)?.value.trim(),
			phoneExt: this.contactForm?.get(this.contactPhoneExt)?.value.trim()
		};
		this.busy = true;
		// for now saving as client contact but will have option to save as site contact later
		this.contactService.createContactWithXref(newContact, this.context.toUpperCase(), this.contextId.toString()).subscribe({
			next: (contact) => {
				this.contacts.push(contact);
				this.selectedContact = contact;
				this.busy = false;
			},
			error: (err) => {
				this._snackBar.open('Create Contact Failed: ' + err.error.message);
				this.busy = false;
			}
		});
	}

	modifyContact() {
		const modifiedContact: Contact = {
			id: this.selectedContact.id,
			name: this.contactForm?.get(this.contactName)?.value.trim(),
			email: this.contactForm?.get(this.contactEmail)?.value.trim(),
			phone: this.contactForm?.get(this.contactPhone)?.value.trim(),
			phoneExt: this.contactForm?.get(this.contactPhoneExt)?.value.trim()
		};
		this.busy = true;
		this.contactService.update(modifiedContact).subscribe({
			next: (contact) => {
				this.contacts = this.contacts.filter((contact) => contact.id !== modifiedContact.id);
				this.contacts.push(contact);
				this.selectedContact = contact;
				this.busy = false;
			},
			error: (err) => {
				this._snackBar.open('Modify Contact Failed: ' + err.error.message);
				this.busy = false;
			}
		});
	}

	revertContact() {
		this.updateContact(this.selectedContact);
	}

	get canCreate(): boolean {
		return (
			this.context !== ContactComponentContextEnum.INTERNAL &&
			this.context !== ContactComponentContextEnum.CLIENT_ALL &&
			this.contextId > 0 &&
			this.allowCreate
		);
	}

	get canModify(): boolean {
		return this.allowModify;
	}

	get createDisabled(): boolean {
		return (
			this.contactForm?.get(this.contactName)?.value.trim() === '' &&
			this.contactForm?.get(this.contactEmail)?.value.trim() === '' &&
			this.contactForm?.get(this.contactPhone)?.value.trim() === '' &&
			this.contactForm?.get(this.contactPhoneExt)?.value.trim() === ''
		);
	}

	get modifyDisabled(): boolean {
		return (
			this.contactForm?.get(this.contactName)?.value.trim() === this.selectedContact.name &&
			this.contactForm?.get(this.contactEmail)?.value.trim() === this.selectedContact.email &&
			this.contactForm?.get(this.contactPhone)?.value.trim() === this.selectedContact.phone &&
			this.contactForm?.get(this.contactPhoneExt)?.value.trim() === this.selectedContact.phoneExt
		);
	}
}
