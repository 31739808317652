import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {BrokersService} from '../../../core/services/brokers.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';
import {BrokerInfoComponent} from '../broker-info/broker-info.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Broker} from '../../../core/models/broker.interface';

@Component({
	selector: 'app-brokers-list',
	templateUrl: './brokers-list.component.html',
	styleUrls: ['./brokers-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class BrokersListComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	columnsToDisplay: string[] = ['name', 'scac', 'email', 'phone', 'phoneExt', 'active'];
	isLoadingBrokers: boolean = true;
	brokers: Broker[] = [];
	toggleForm: FormGroup = new FormGroup({
		toggleInactive: new FormControl(false)
	});

	constructor(private brokersService: BrokersService, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.dataSource = new MatTableDataSource();
		this.brokersService.findAll();
		this.brokersService.brokersSubject.subscribe((response) => {
			this.brokers = response;
			// Store the expanded state of each row before updating the data source
			const expandedStates: boolean[] = [];
			for (let i = 0; i < this.dataSource.data.length; i++) {
				expandedStates[i] = this.dataSource.data[i].expanded;
			}
			// Update the data source with new data
			for (const updatedRow of response) {
				const index = this.dataSource.data.findIndex((row) => row.id === updatedRow.id);
				if (index !== -1) {
					// Preserve the expanded state of the row before updating it
					updatedRow.expanded = this.dataSource.data[index].expanded;
					this.dataSource.data[index] = updatedRow;
				} else {
					// Add the new row to the data source
					this.dataSource.data.push(updatedRow);
				}
			}
			// Restore the expanded state of each row after updating the data source
			for (let i = 0; i < this.dataSource.data.length; i++) {
				this.dataSource.data[i].expanded = expandedStates[i];
			}
			// Refresh the data source to update the table
			this.dataSource.data = [...this.dataSource.data];
			this.isLoadingBrokers = false;
			this.dataSource.data = this.brokers.filter((broker: Broker) => broker.active === 1);
		});

		this.toggleForm.get('toggleInactive')?.valueChanges.subscribe((value) => {
			if (!value) {
				this.dataSource.data = this.brokers.filter((broker: Broker) => broker.active === 1);
			} else {
				this.dataSource.data = this.brokers;
			}
		});
	}

	applyFilter(event: Event): void {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreateBrokerModal(): void {
		this.dialog.open(BrokerInfoComponent);
	}
}
