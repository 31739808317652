import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Quote} from '../models/quote.interface';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {QuoteTemplateCreate} from '../models/quote-template-create.interface';
import {Template} from '../models/template.interface';
import {SalesOrderResponse} from '../models/sales-order-response.model';
import {QuoteHeader} from '../models/quote-header.interface';
import {SalesOrderCreate} from '../models/sales-order-create.interface';
import {MagicLinkLog} from '../models/magic-link-log.interface';

@Injectable({
	providedIn: 'root'
})
export class QuoteService {
	baseUrl: string = `${environment.url}/quote`;

	constructor(private http: HttpClient) {}

	create(quote: Quote): Observable<Quote> {
		return this.http.post<Quote>(this.baseUrl, quote);
	}

	createTemplate(id: number, quoteTemplateCreate: QuoteTemplateCreate): Observable<Template> {
		return this.http.post<Template>(`${this.baseUrl}/${id}/template`, quoteTemplateCreate);
	}

	updateTemplate(id: number, quoteTemplateCreate: QuoteTemplateCreate): Observable<Template> {
		return this.http.put<Template>(`${this.baseUrl}/${id}/template`, quoteTemplateCreate);
	}

	findAll(): Observable<Quote[]> {
		return this.http.get<Quote[]>(this.baseUrl);
	}

	findAllQuotes(): Observable<QuoteHeader[]> {
		return this.http.get<QuoteHeader[]>(`${this.baseUrl}/headers`);
	}

	findOne(id: number): Observable<Quote> {
		return this.http.get<Quote>(`${this.baseUrl}/${id}`);
	}

	update(quoteWithId: Quote): Observable<Quote> {
		return this.http.put<Quote>(`${this.baseUrl}/${quoteWithId.id}`, quoteWithId);
	}

	patch(quoteWithId: Quote): Observable<Quote> {
		return this.http.patch<Quote>(`${this.baseUrl}/${quoteWithId.id}`, quoteWithId);
	}

	remove(id: number, hardDelete?: boolean): Observable<void> {
		let params: HttpParams = new HttpParams();

		if (hardDelete) {
			params = params.append('hard', 'true');
		}

		return this.http.delete<void>(`${this.baseUrl}/${id}`, {params});
	}

	requestApproval(body: any, id: number): Observable<Quote> {
		return this.http.put(`${this.baseUrl}/${id}/request-approval`, body);
	}

	approveQuote(id: number, salesOrderCreate: SalesOrderCreate): Observable<SalesOrderResponse> {
		return this.http.post(`${this.baseUrl}/${id}/approve`, salesOrderCreate);
	}

	resendQuote(body: any, id: number): Observable<Quote> {
		return this.http.put(`${this.baseUrl}/${id}/resend`, body);
	}

	getPoUpload(id: number): Observable<{url: string}> {
		return this.http.get<{url: string}>(`${this.baseUrl}/${id}/po`);
	}

	findAllSalesOrdersFromErp(forceRefresh: boolean = false): Observable<SalesOrderResponse[]> {
		let params: HttpParams = new HttpParams();
		params = params.append('forceRefresh', forceRefresh);

		return this.http.get<SalesOrderResponse[]>(`${this.baseUrl}/erp`, {params});
	}

	getSalesOrdersFromErp(id: string, projectId: number | null = null): Observable<SalesOrderResponse> {
		let params: HttpParams = new HttpParams();
		if (projectId) {
			params = params.append('projectId', projectId);
		}
		return this.http.get<SalesOrderResponse>(`${this.baseUrl}/erp/${id}`, {params});
	}

	findOneQuoteDocument(id: number, documentId: number): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/${id}/documents/${documentId}`);
	}

	getEmailHistory(id: number): Observable<MagicLinkLog[]> {
		return this.http.get<MagicLinkLog[]>(`${this.baseUrl}/${id}/email`);
	}
}
