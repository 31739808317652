<div class="m-4 flex flex-col w-full justify-between">
	<div class="flex justify-between">
		<div class="flex flex-col">
			<div *ngIf="project?.shipDate"><span class="font-medium">Ship Date: </span>{{ project.shipDate | date : 'mediumDate' }}</div>
			<div [ngClass]="{'mat-mdc-form-field-error': navShipWarn()}" *ngIf="project?.navShipDate">
				<span class="font-medium" *ngIf="project.navShipDate">Nav Ship: </span>{{ project.navShipDate | date : 'mediumDate' }}
			</div>
		</div>
		<div class="flex justify-end items-center">
			<mat-spinner *ngIf="busy" class="spinner m-2" diameter="20"></mat-spinner>
			<button
				mat-raised-button
				color="primary"
				ngxPrint
				printSectionId="picklist-print"
				[printTitle]="picklistTitle()"
				[useExistingCss]="true"
				[disabled]="busy || !order"
			>
				<mat-icon>print</mat-icon>
				Print
			</button>
		</div>
	</div>

	<div class="p-2 flex justify-center flex-1">
		<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
			<mat-label>Search</mat-label>
			<input
				id="user-searchbar"
				type="text"
				(keyup)="applyFilter()"
				placeholder="What are you looking for?"
				matInput
				#searchInput
				[(ngModel)]="filterText"
			/>
		</mat-form-field>
	</div>
	<div class="flex justify-between mb-2" *ngIf="!busy && order">
		<div class="mb-2">
			<p class="text-sm address-text">{{ order.shipToName | titlecase }}</p>
			<p class="text-sm address-text">{{ order.shipToAddress }}</p>
			<p class="text-sm address-text" *ngIf="order.shipToAddress2">{{ order.shipToAddress2 }}</p>
			<p class="text-sm address-text">{{ order.shipToCity }}&nbsp;{{ order.shipToState }},&nbsp;{{ order.shipToPostCode }}</p>
		</div>
		<div>
			<b class="text-sm">Sales Order No. {{ order.id }}</b>
			<p class="text-sm">Shipment Date: {{ project.shipDate | date : 'shortDate' }}</p>
			<p class="text-sm" *ngIf="!multiWarehouse">Pick From: {{ projectLocationName }}</p>
			<p *ngIf="multiWarehouse">
				<mat-button-toggle-group [(ngModel)]="locationFilter" (change)="filterLocation()">
					<mat-button-toggle [value]="projectLocationId">{{ projectLocationName }}</mat-button-toggle>
					<mat-button-toggle [value]="projectOtherLocationId">{{ projectOtherLocationName }}</mat-button-toggle>
				</mat-button-toggle-group>
			</p>
		</div>
	</div>
	<div id="picklist-print" class="w-[75vw] border border-gray-700 border-2 rounded overflow-auto">
		<table class="w-full">
			<!--Print Header-->
			<thead>
				<tr>
					<td>
						<div class="header-space">
							<div class="text-xl mat-mdc-form-field-error p-2" *ngIf="!project?.approved">Not Approved</div>
							<div class="flex justify-between mb-2 hidden print:flex" id="print-header" *ngIf="!busy && order">
								<div>
									<p class="text-sm">{{ order.shipToName | titlecase }}</p>
									<p class="text-sm">{{ order.shipToAddress }}</p>
									<p class="text-sm" *ngIf="order.shipToAddress2">{{ order.shipToAddress2 }}</p>
									<p class="text-sm">{{ order.shipToCity }}&nbsp;{{ order.shipToState }},&nbsp;{{ order.shipToPostCode }}</p>
								</div>
								<div>
									<b class="text-sm">Sales Order No. {{ order.id }}</b>
									<p class="text-sm">Shipment Date: {{ project.shipDate | date : 'shortDate' }}</p>
									<p class="text-sm">
										Pick From: {{ locationFilter !== projectLocationId ? projectOtherLocationName : projectLocationName }}
									</p>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</thead>
			<!--Print Body-->
			<tbody>
				<tr>
					<td>
						<div class="content">
							<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort>
								<!--Item Number Column-->
								<ng-container matColumnDef="erpItemRef">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Item Number">Item No.</th>
									<td mat-cell *matCellDef="let detail">
										{{ detail.erpItemRef }}
									</td>
								</ng-container>

								<!--Description Column-->
								<ng-container matColumnDef="item">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Description</th>
									<td mat-cell *matCellDef="let detail">
										{{ detail.item ? detail.item : detail.glNumber }}
									</td>
								</ng-container>
								<!--Quantity Column-->
								<ng-container matColumnDef="qty">
									<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Quantity">Quantity</th>
									<td mat-cell *matCellDef="let detail">
										<div class="flex justify-end">{{ detail.qty }}</div>
									</td>
								</ng-container>

								<ng-container matColumnDef="check">
									<th mat-header-cell *matHeaderCellDef class="hidden print:table-cell"></th>
									<td mat-cell *matCellDef="let detail" class="hidden print:table-cell">
										<div class="flex">
											<div class="mx-2 h-8 w-10 border-b-2 border-black"></div>
											<div class="mx-2 h-8 w-10 border-b-2 border-black"></div>
											<div class="mx-2 h-8 w-10 border-b-2 border-black"></div>
										</div>
									</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
								<tr
									mat-row
									*matRowDef="let detail; let i = dataIndex; columns: columnsToDisplay"
									class="log-row cursor-pointer avoid-split"
								></tr>
								<!-- Row shown when there is no matching data. -->
								<tr class="mat-row flex" *matNoDataRow>
									<td class="mat-cell p-4" colspan="4" *ngIf="!busy && order">No data matching the filter "{{ searchInput.value }}"</td>
									<td class="mat-cell p-4" colspan="4" *ngIf="!busy && !order">There is no order for this project yet!</td>
								</tr>
							</table>
							<div class="h-full w-full flex justify-center align-middle items-center p-2" *ngIf="busy">
								<mat-spinner></mat-spinner>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
