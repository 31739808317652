import {Component, Inject} from '@angular/core';
import {ProjectDocument} from '../../../core/models/project-document.interface';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ProjectFileArchiveDialogData {
	documents: ProjectDocument[];
}

export interface ProjectFileArchiveDialogResponse {
	success: boolean;
	documentIds: number[];
}

@Component({
	selector: 'app-project-file-archive-dialog',
	templateUrl: './project-file-archive-dialog.component.html',
	styleUrls: ['./project-file-archive-dialog.component.scss']
})
export class ProjectFileArchiveDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ProjectFileArchiveDialogData,
		private dialogRef: MatDialogRef<ProjectFileArchiveDialogComponent>
	) {
		data.documents.forEach((document) => (document.selected = true));
	}

	get selectedDocuments() {
		return this.data.documents.filter((document) => document.selected);
	}

	confirmArchive() {
		const response: ProjectFileArchiveDialogResponse = {
			success: true,
			documentIds: this.selectedDocuments.map((file) => file.id) as number[]
		};
		this.dialogRef.close(response);
	}
}
