import {Injectable} from '@angular/core';
import {WeightUnitEnum} from '../enums/weight-unit.enum';
import {DimensionUnitEnum} from '../enums/dimension-unit.enum';
import {HandlingUnit} from '../models/handling-unit.model';
import {FormGroup} from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class FreightDetailCalculationService {
	constructor() {}

	weightConversion: any = {
		[WeightUnitEnum.POUND]: {
			[WeightUnitEnum.POUND]: 1,
			[WeightUnitEnum.KILOGRAM]: 0.453592
		},
		[WeightUnitEnum.KILOGRAM]: {
			[WeightUnitEnum.POUND]: 2.205,
			[WeightUnitEnum.KILOGRAM]: 1
		}
	};

	dimensionConversion: any = {
		[DimensionUnitEnum.FOOT]: {
			[DimensionUnitEnum.FOOT]: 1,
			[DimensionUnitEnum.INCH]: 12,
			[DimensionUnitEnum.METER]: 0.3048,
			[DimensionUnitEnum.CENTIMETER]: 30.48
		},
		[DimensionUnitEnum.INCH]: {
			[DimensionUnitEnum.FOOT]: 0.0833333,
			[DimensionUnitEnum.INCH]: 1,
			[DimensionUnitEnum.METER]: 0.0254,
			[DimensionUnitEnum.CENTIMETER]: 2.54
		},
		[DimensionUnitEnum.METER]: {
			[DimensionUnitEnum.FOOT]: 3.28084,
			[DimensionUnitEnum.INCH]: 39.3701,
			[DimensionUnitEnum.METER]: 1,
			[DimensionUnitEnum.CENTIMETER]: 100
		},
		[DimensionUnitEnum.CENTIMETER]: {
			[DimensionUnitEnum.FOOT]: 0.0328084,
			[DimensionUnitEnum.INCH]: 0.393701,
			[DimensionUnitEnum.METER]: 0.01,
			[DimensionUnitEnum.CENTIMETER]: 1
		}
	};

	palletWidth = 48;
	palletWidthUnit = DimensionUnitEnum.INCH;

	palletLength = 48;
	palletLengthUnit = DimensionUnitEnum.INCH;

	rowSize = 2;

	getTotalLinearDimensions(handlingUnitsGroup: FormGroup[], unit: string | null): number {
		let handlingUnits: HandlingUnit[] = [];
		if (unit) {
			handlingUnitsGroup.forEach((handlingUnitGroup) => {
				const handlingUnit = handlingUnitGroup.value as HandlingUnit;
				if (handlingUnit.length && handlingUnit.width && handlingUnit.height && handlingUnit.wUnit && handlingUnit.qty) {
					handlingUnits.push(handlingUnit);
				}
			});
			return this.calculateLinearDimension(handlingUnits, unit);
		}
		return 0;
	}

	getTotalWeight(handlingUnits: FormGroup[], unit: string | null): number {
		let totalWeight = 0;
		if (unit) {
			handlingUnits.forEach((handlingUnitGroup) => {
				const handlingUnit = handlingUnitGroup.value as HandlingUnit;
				if (handlingUnit.weight && handlingUnit.wUnit && handlingUnit.qty) {
					totalWeight += this.convertWeight(handlingUnit.weight, handlingUnit.wUnit, unit) * handlingUnit.qty;
				}
			});
		}
		return totalWeight;
	}

	getTotalSkids(handlingUnits: FormGroup[]): number {
		let totalSkids = 0;
		let singleLength = 0;
		let wideLength = 0;
		handlingUnits.forEach((handlingUnitGroup) => {
			const handlingUnit = handlingUnitGroup.value as HandlingUnit;
			if (handlingUnit.length && handlingUnit.width && handlingUnit.dUnit && handlingUnit.qty) {
				if (this.convertDimension(handlingUnit.width, handlingUnit.dUnit, this.palletWidthUnit) > this.palletWidth) {
					wideLength += handlingUnit.qty * this.convertDimension(handlingUnit.length, handlingUnit.dUnit, this.palletLengthUnit);
				} else {
					singleLength += handlingUnit.qty * this.convertDimension(handlingUnit.length, handlingUnit.dUnit, this.palletLengthUnit);
				}
			}
		});
		totalSkids += 2 * Math.ceil(wideLength / this.palletLength);
		totalSkids += Math.ceil(singleLength / this.palletLength);
		return totalSkids;
	}

	convertWeight(value: number, fromUnit: string, toUnit: string): number {
		return value * this.weightConversion[fromUnit][toUnit];
	}

	convertDimension(value: number, fromUnit: string, toUnit: string): number {
		return value * this.dimensionConversion[fromUnit][toUnit];
	}

	calculateLinearDimension(handlingUnits: HandlingUnit[], dimensionUOM: string): number {
		return (
			this.calculateSingleRowLinearDimension(this.getSingleRows(handlingUnits), dimensionUOM) +
			this.calculateStackedRowLinearDimension(this.getStackedRows(handlingUnits), dimensionUOM)
		);
	}

	/**
	 * calculate linear dimensions
	 * the rough equation for how to handle it in the code is as follows
	 * total units = math.ceil(sum of units quantity / number of items per row)
	 * create an array of length, convert the lengths to the correct dimension
	 * sort lengths largest first, slice the total number of pallets, find the sum
	 * return the sum found
	 * if no length then return 0
	 * @param handlingUnits
	 * @param dimensionUOM
	 * @param itemsPerRow
	 * @returns
	 */
	calculateStackedRowLinearDimension(handlingUnits: HandlingUnit[], dimensionUOM: string, itemsPerRow: number = this.rowSize): number {
		// get the total number of handling units for stacked rows
		const totalPieces: number = Math.ceil(
			handlingUnits
				// if there is no total pieces we are going to assume there is 1
				.map((handlingUnit: HandlingUnit) => (handlingUnit.qty ? handlingUnit.qty : 1))
				.reduce((previousDimension, currentDimension) => previousDimension + currentDimension, 0) / itemsPerRow
		);
		// figure out the total number of pieces that we can use to calculate the liner dimension
		// basically if there is an odd number we want to add 1 to it
		const lengths: number[] = [];
		// extract the lengths
		// this is a bit more tricky since we need to get the total lengths, and if there is more than one handling unit
		// in the qty then we have to handle that here too
		handlingUnits.forEach((handlingUnit: HandlingUnit) => {
			// if there is no length then we do not want to assume what the length is
			if (handlingUnit.length && handlingUnit.dUnit) {
				const amount = handlingUnit.qty ? handlingUnit.qty : 1;

				// for each qty of the handling unit add it to the array
				for (let numb = 0; numb < amount; numb++) {
					// convert the handling unit
					lengths.push(this.convertDimension(handlingUnit.length, handlingUnit.dUnit, dimensionUOM));
				}
			}
		});
		let totalLength = 0;
		// get stacked row lengths
		if (lengths.length > 0) {
			// sort length
			totalLength = lengths
				.sort((lengthA: number, lengthB: number) => lengthB - lengthA)
				// find the correct amount of lengths we need to reduce on
				.slice(0, totalPieces)
				// reduce
				.reduce((previousLength: number, currentLength: number) => previousLength + currentLength, 0);
		}
		return totalLength;
	}

	calculateSingleRowLinearDimension(handlingUnits: HandlingUnit[], dimensionUOM: string): number {
		let totalLength = 0;
		handlingUnits.forEach((handlingUnit) => {
			if (handlingUnit.length && handlingUnit.dUnit) {
				totalLength += this.convertDimension(handlingUnit.length, handlingUnit.dUnit, dimensionUOM);
			}
		});
		return totalLength;
	}

	getSingleRows(handlingUnits: HandlingUnit[]): HandlingUnit[] {
		return handlingUnits.filter((handlingUnit) => {
			if (handlingUnit.width && handlingUnit.dUnit) {
				return this.convertDimension(handlingUnit.width, handlingUnit.dUnit, this.palletWidthUnit) > this.palletWidth;
			} else {
				return false;
			}
		});
	}

	getStackedRows(handlingUnits: HandlingUnit[]): HandlingUnit[] {
		return handlingUnits.filter((handlingUnit) => {
			if (handlingUnit.width && handlingUnit.dUnit) {
				return this.convertDimension(handlingUnit.width, handlingUnit.dUnit, this.palletWidthUnit) <= this.palletWidth;
			} else {
				return false;
			}
		});
	}
}
