import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuoteHeaderListComponent} from './quote-header-list/quote-header-list.component';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {QuoteComponent} from './quote.component';
import {DatePipe} from '@angular/common';
import {ProjectsService} from '../core/services/projects.service';
import {ProjectSelectDialogComponent} from './project-select/project-select-dialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from "@angular/material/menu";
import { QuoteEmailListComponent } from './quote-email-list/quote-email-list.component';

@NgModule({
	declarations: [QuoteHeaderListComponent, QuoteComponent, ProjectSelectDialogComponent, QuoteEmailListComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        SharedModule,
        MatIconModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatDialogModule,
        MatRadioModule,
        MatAutocompleteModule,
        MatCardModule,
        MatTooltipModule,
        MatMenuModule
    ],
	exports: [QuoteHeaderListComponent],
	providers: [DatePipe, ProjectsService]
})
export class QuoteModule {}
