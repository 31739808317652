<ng-container *ngIf="accessorialOptions.length !== 0" [formGroup]="parentForm">
	<mat-form-field class="w-full">
		<mat-label> Accessorials </mat-label>
		<input matInput type="text" [matAutocomplete]="auto" [formControl]="accessorialControl" placeholder="Search" />
		<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelection($event.option.value)">
			<mat-option *ngFor="let accessorial of filteredAccessorials | async" [value]="accessorial">
				{{ accessorial.name }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>

	<mat-chip-listbox *ngIf="displayMode === AccessorialsComponentDisplayModes.CHIP" selectable="false">
		<mat-chip-option
			*ngFor="let selected of selectedAccessorialArray"
			(removed)="remove(selected)"
			selected
			color="primary"
			[disabled]="submitted"
		>
			{{ selected.name }}
			<mat-icon *ngIf="!submitted" matChipRemove>cancel</mat-icon>
		</mat-chip-option>
	</mat-chip-listbox>
	<div class="flex flex-col" *ngIf="displayMode === AccessorialsComponentDisplayModes.LIST">
		<div class="flex items-center justify-between border-solid border-2 mb-2 p-4 rounded" *ngFor="let selected of selectedAccessorialArray">
			<span>{{ selected.name }}</span>
			<button mat-button (click)="remove(selected)">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</div>
</ng-container>
