import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PriceLevelService} from 'src/app/core/services/price-level.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {PriceLevel} from '../../../core/models/price-level.interface';

@Component({
	selector: 'app-create-price-level',
	templateUrl: './create-price-level.component.html',
	styleUrls: ['./create-price-level.component.scss']
})
export class CreatePriceLevelComponent implements OnInit {
	priceLevelTypeOptions: string[] = ['external', 'internal', 'admin'];
	form: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private priceLevelService: PriceLevelService,
		public dialogRef: MatDialogRef<CreatePriceLevelComponent>,
		private snackbar: MatSnackBar
	) {
		this.form = this.formBuilder.group({
			name: ['', [Validators.required, Validators.maxLength(100)]],
			levelNumber: ['', Validators.required]
		});
	}
	ngOnInit() {}
	createPriceLevel() {
		this.priceLevelService.create(this.form.getRawValue()).subscribe(
			(response: PriceLevel) => {
				this.dialogRef.close();
				this.snackbar.open('Price Level Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.priceLevelService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
}
