import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {QuickQuoteConfig} from '../models/quick-quote-config.interface';
import {QuickQuote} from '../models/quick-quote.interface';

@Injectable({
	providedIn: 'root'
})
export class QuickQuoteService {
	baseUrl: string = `${environment.url}/quick-quote`;

	constructor(private http: HttpClient) {}

	getQuickQuote(quoteConfig: QuickQuoteConfig | {}): Observable<QuickQuote> {
		return this.http.post<QuickQuote>(this.baseUrl, quoteConfig);
	}
}
