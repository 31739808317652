<h2 mat-dialog-title>Archive Files</h2>
<mat-dialog-content class="mat-typography">
	<div class="font-medium">Select the documents to Archive:</div>
	<mat-checkbox class="m-2" color="primary" *ngFor="let document of data.documents" [(ngModel)]="document.selected">
		{{ document.documentName }}
	</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Cancel</button>
	<button mat-raised-button color="primary" [disabled]="!selectedDocuments.length" (click)="confirmArchive()">Save</button>
</mat-dialog-actions>
