export enum QuoteLineCategoryEnum {
	MOBILE_PART = 'MOBILE SYS',
	GENERAL_PART = 'PART',
	ASSOCIATED_COST = 'ASSOCIATED',
	GENERAL_LEDGER = 'GL',
	RACK_SOLID = 'RS',
	MISC = 'MISC',
	AG = 'AG',
  GET_BACK = 'GET BACK'
}
