<div class="m-4" *ngIf="projectScheduleForm" [formGroup]="projectScheduleForm">
	<div class="flex">
		<div class="flex justify-between schedule-header sticky left-4">
			<mat-form-field appearance="outline" class="mr-2 h-14 min-date" subscriptSizing="dynamic">
				<mat-label class="font-medium">Install Date</mat-label>
				<input matInput [min]="minDate" [matDatepicker]="datepicker" (dateChange)="changeInstallDate()" formControlName="installDate" />
				<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
				<mat-datepicker #datepicker> </mat-datepicker>
				<mat-error>Install Date <strong>required</strong>.</mat-error>
			</mat-form-field>
			<div class="flex flex-col font-bold">
				<span>{{ projectLocation }}</span>
				<span>Estimated Transit: {{ estimatedTransitTime }} day{{ estimatedTransitTime > 1 ? 's' : '' }}</span>
			</div>
			<div class="flex flex-col">
				<div class="flex">
					<mat-checkbox class="ml-2 mr-2" [(ngModel)]="autoSchedule" [ngModelOptions]="{standalone: true}">Auto-Schedule</mat-checkbox>
					<button
						mat-raised-button
						color="primary"
						[disabled]="projectScheduleForm.invalid || busy || (!initWarn && !warnChanges())"
						(click)="saveSchedule()"
					>
						Save
					</button>
				</div>
				<div class="flex justify-end mat-mdc-form-field-error">
					<ng-container *ngIf="initWarn">
						<mat-icon class="mr-2">warning</mat-icon><span class="align-top leading-8">Not saved to calendar.</span>
					</ng-container>
					<ng-container *ngIf="warnChanges()">
						<mat-icon class="mr-2">warning</mat-icon><span class="align-top leading-8">Changes have not been saved.</span>
					</ng-container>
					<ng-container *ngIf="!warnChanges() && autoWarn">
						<span class="align-top"
							>Dates have changed which would prohibit saving with the previous schedule. Don't worry - this job is still on the calendar.
							Make changes and save *only* if you want to move this job on the calendar.</span
						>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<div class="m-8" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>
	<div class="m-8" *ngIf="!loading && projectTasks.controls.length === 0">
		No tasks <a class="underline" [routerLink]="'../estimate'">Estimated</a>.
	</div>
	<div class="flex flex-col self-start mat-elevation-z4 m-4 overflow-y-auto" *ngIf="!loading && projectTasks.controls.length > 0">
		<mat-divider [ngStyle]="{width: cellWidth(visibleHeaderLength)}"></mat-divider>
		<div class="flex justify-end calendar-bg" [ngStyle]="{width: cellWidth(visibleHeaderLength)}">
			<ng-container *ngFor="let head of header; let i = index">
				<div
					(click)="editProductionHourOverride(i)"
					class="grow-0 shrink-0 text-center font-bold header cursor-pointer"
					[ngStyle]="{width: cellWidth(1)}"
					[ngClass]="head.value | date : 'E'"
					*ngIf="head.show"
				>
					{{ head.value | date : 'EEEEEE' }}
					<br />
					{{ head.value | date : 'M/d' }}
				</div>
			</ng-container>
		</div>
		<mat-divider [ngStyle]="{width: cellWidth(visibleHeaderLength)}"></mat-divider>
		<div class="flex justify-end calendar-bg" [ngStyle]="{width: cellWidth(visibleHeaderLength)}">
			<div class="flex flex-col mt-2 mb-2" [ngStyle]="{width: cellWidth(transitTotal + shippingBuffer + 1)}">
				<div class="flex">
					<div class="text-center" [ngStyle]="{width: cellWidth(1)}">
						<mat-icon>inventory_2</mat-icon>
					</div>
					<div *ngIf="!transfer" [ngStyle]="{width: cellWidth(shippingBuffer - 1)}"></div>
					<div *ngIf="transfer" [ngStyle]="{width: cellWidth(transferBeforeBuffer - 1)}"></div>
					<div *ngIf="transfer" class="text-center" [ngStyle]="{width: cellWidth(1)}">
						<mat-icon>warehouse</mat-icon>
					</div>
					<div *ngIf="transfer" [ngStyle]="{width: cellWidth(transferAfterBuffer - 1)}"></div>
					<div class="text-center" [ngStyle]="{width: cellWidth(1)}">
						<mat-icon>local_shipping</mat-icon>
					</div>
					<div [ngStyle]="{width: cellWidth(transitTotal - 1)}"></div>
					<div class="text-center" [ngStyle]="{width: cellWidth(1)}">
						<mat-icon>place</mat-icon>
					</div>
				</div>
				<div class="flex">
					<div class="shipping-buffer" [ngStyle]="{width: cellWidth(shippingBuffer)}"></div>
					<div class="flex justify-end transit-time" [ngStyle]="{width: cellWidth(transitTotal + 1)}">
						<button *ngIf="transitTotal > 1" mat-icon-button (click)="decreaseTransit()">
							<mat-icon class="text-sm">remove_circle</mat-icon>
						</button>
						<div class="flex flex-col justify-center items-center" [class.mr-2]="this.transitDays >= 10">
							<span class="text-xs">Transit:</span>
							<span class="font-bold">{{ transitDays }} day{{ transitDays > 1 ? 's' : '' }}</span>
						</div>
						<button mat-icon-button *ngIf="this.transitDays < 10" (click)="increaseTransit()">
							<mat-icon class="text-sm">add_circle</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
		<ng-container formArrayName="projectTasks">
			<ng-container *ngFor="let task of projectTasks.controls; let i = index">
				<mat-divider [ngStyle]="{width: cellWidth(visibleHeaderLength)}"></mat-divider>
				<div class="flex justify-end calendar-bg pb-1" [ngStyle]="{width: cellWidth(visibleHeaderLength)}">
					<ng-container *ngFor="let day of productionHours | keyvalue; let pI = index">
						<div
							(click)="editProductionHourOverride(pI)"
							class="grow-0 shrink-0 text-center font-bold text-xs header pt-1 pb-1 cursor-pointer"
							[ngClass]="[getClass(task.value.name) + '-hours', header[pI].value | date : 'E']"
							[ngStyle]="{width: cellWidth(1)}"
							[matTooltip]="getProductionComment(header[pI].value, task.value)"
							*ngIf="header[pI].show"
						>
							{{ getProductionSchedule(header[pI].value, task.value) }}/{{ getProductionHours(header[pI].value, task.value) }}
						</div>
					</ng-container>
				</div>
				<div class="flex justify-end pb-4 calendar-bg" [ngStyle]="{width: cellWidth(visibleHeaderLength)}" [formGroupName]="i">
					<div
						class="flex flex-col"
						[ngStyle]="{width: cellWidth(1)}"
						*ngIf="projectTaskSchedule(task).length + projectTaskPadSize(task).value < visibleHeaderLength"
					>
						<div class="flex flex-col items-end">
							<button mat-icon-button (click)="shiftTaskBack(task)">
								<mat-icon class="text-sm">arrow_back</mat-icon>
							</button>
							<button mat-icon-button (click)="addDayBefore(task)">
								<mat-icon class="text-sm">add_circle</mat-icon>
							</button>
						</div>
					</div>
					<div
						class="flex flex-col bg-opacity-10 pb-2 min-h-24"
						[ngClass]="getClass(task.value.name)"
						[ngStyle]="{width: cellWidth(projectTaskSchedule(task).length)}"
					>
						<div class="flex font-bold p-2 min-h-8 items-center flex-wrap">
							<div class="overflow-hidden overflow-ellipsis">{{ task.value.name }}</div>
							<div
								class="ml-2 overflow-hidden overflow-ellipsis"
								[matTooltip]="taskHours(task)"
								[class.hours-scheduled-invalid]="task.get('hoursScheduled')?.invalid"
							>
								{{ taskHours(task) }}
							</div>
							<mat-error *ngIf="task.invalid" class="ml-2 align-top">
								<mat-icon>warning</mat-icon>
							</mat-error>
						</div>
						<div class="flex">
							<ng-container formArrayName="schedule" *ngIf="projectTaskSchedule(task)">
								<div
									class="flex flex-col items-center"
									*ngFor="let schedule of projectTaskSchedule(task).controls; let sI = index"
									[ngStyle]="{width: cellWidth(1)}"
									[formGroupName]="sI"
								>
									<!-- div>
										{{ schedule.value.date | date : 'M/d' }}
									</div -->
									<input
										matInput
										type="number"
										step=".5"
										formControlName="hours"
										(focus)="inputFocused($event)"
										(change)="updateHours(task)"
										class="w-14 border-2 p-2 rounded text-center hours"
										[ngClass]="schedule.value.date | date : 'E'"
									/>
									<div *ngIf="schedule.invalid" class="flex flex-col items-start text-xs font-bold hours-scheduled-invalid">
										<span *ngIf="showError(schedule, 'hours')"><span class="max-label">Max:</span> {{ maxHours(i, schedule) }}</span>
										<mat-icon *ngIf="showError(schedule, 'date')" class="self-center">event_busy</mat-icon>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
					<div class="flex justify-start" [ngStyle]="{width: cellWidth(projectTaskPadSize(task).value)}">
						<div class="flex flex-col" *ngIf="projectTaskPadSize(task).value > transitTotal + shippingBuffer + 1">
							<button mat-icon-button (click)="shiftTaskForward(task)">
								<mat-icon class="text-sm">arrow_forward</mat-icon>
							</button>
							<button mat-icon-button (click)="addDayAfter(task)">
								<mat-icon class="text-sm">add_circle</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<mat-divider [ngStyle]="{width: cellWidth(visibleHeaderLength)}"></mat-divider>
	</div>
</div>
