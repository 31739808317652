<h2 mat-dialog-title>Save Template</h2>
<mat-dialog-content class="mat-typography">
	<mat-form-field appearance="outline" class="w-80 pt-2">
		<mat-label>Update Existing Template</mat-label>
		<mat-select (selectionChange)="templateSelected($event.value)" [(ngModel)]="selectedTemplate">
			<mat-option *ngFor="let template of templateOptions" [value]="template">{{ template.description }}</mat-option>
			<mat-option *ngIf="templateOptions.length === 0" disabled>No Templates for Selected Client</mat-option>
		</mat-select>
	</mat-form-field>
	<div>OR</div>
	<mat-form-field class="pt-4 w-80">
		<mat-label>New Template Name</mat-label>
		<input cdkFocusInitial matInput (keyup)="templateNameChange()" [(ngModel)]="templateName" />
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close>Cancel</button>
	<button mat-raised-button color="primary" [disabled]="!templateName && !selectedTemplate" (click)="saveTemplate()">Save</button>
</mat-dialog-actions>
