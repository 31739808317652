<div class="flex flex-col" [formGroup]="shippingLogForm">
	<div class="flex">
		<div class="flex flex-col font-medium">
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label>Account Manager</mat-label>
					<input matInput formControlName="accountManager" />
					<mat-error>Account Manager <strong>required</strong>.</mat-error>
				</mat-form-field>
			</div>
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label class="font-medium">Invoice Received Date</mat-label>
					<input matInput [matDatepicker]="datepickerInvoice" formControlName="invoiceDate" />
					<mat-datepicker-toggle matIconSuffix [for]="datepickerInvoice"></mat-datepicker-toggle>
					<mat-datepicker #datepickerInvoice></mat-datepicker>
				</mat-form-field>
				<button
					class="mr-2"
					mat-icon-button
					(click)="shippingLogForm.controls.invoiceDate.setValue(null)"
					*ngIf="shippingLogForm.controls.invoiceDate.value"
				>
					<mat-icon>cancel</mat-icon>
				</button>
			</div>
		</div>
		<div class="flex flex-col">
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label>Budget</mat-label>
					<input matInput formControlName="budget" />
					<mat-error>Budget <strong>required</strong>.</mat-error>
				</mat-form-field>
			</div>
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label>Cost</mat-label>
					<input matInput formControlName="cost" />
					<mat-error>Cost <strong>required</strong>.</mat-error>
				</mat-form-field>
			</div>
		</div>
		<div class="flex flex-col">
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label>Additional Cost</mat-label>
					<input matInput formControlName="additionalCost" />
				</mat-form-field>
			</div>
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label>Additional Cost Reason</mat-label>
					<mat-select formControlName="additionalCostReason">
						<mat-option [value]="code.code" *ngFor="let code of codes">{{ code.codeDescription }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="flex flex-col">
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label class="font-medium">Actual Delivery Date</mat-label>
					<input matInput [matDatepicker]="datepicker" formControlName="deliveryDate" />
					<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
					<mat-datepicker #datepicker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="m-2">
				<mat-form-field appearance="outline">
					<mat-label>Actual Delivery Time</mat-label>
					<mat-select formControlName="deliveryTime">
						<mat-option [value]="time.value" *ngFor="let time of timeList">{{ time.description }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
	</div>
	<div class="flex justify-end">
		<button
			*ngIf="!busy"
			[disabled]="shippingLogForm.invalid || !shippingLogForm.dirty"
			mat-flat-button
			color="primary"
			type="button"
			(click)="onSubmit()"
			class="my-2"
		>
			Save
		</button>
		<div class="m-8" *ngIf="busy">
			<mat-spinner diameter="20"></mat-spinner>
		</div>
	</div>
</div>
