import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UserService} from '../../core/services/user.service';
import {User} from '../../core/models/user.interface';
import {AuthService} from '../../core/services/auth.service';
import {ProjectsService} from '../../core/services/projects.service';
import {Subscription} from "rxjs";

@Component({
	selector: 'app-team-members',
	templateUrl: './team-members.component.html',
	styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit, OnDestroy {
	users: User[];
	filteredList: User[] = [];
	searchTerm: string;
	@Output() addUser = new EventEmitter<User>();
	@Output() removeUser = new EventEmitter<number>();
	@Input() createMode: boolean = false;
	teamMembers: User[];
  projectSubscription: Subscription;
	constructor(private userService: UserService, private authService: AuthService, private projectService: ProjectsService) {}



	ngOnInit() {
		this.userService.findAll(true);
		this.userService.usersSubject.subscribe((response) => {
			this.users = response.sort((a: User, b: User) => {
				if (a.role && b.role) {
					const roleComparison = a.role.name.localeCompare(b.role.name);
					if (roleComparison === 0) {
						// If the roles are the same, sort alphabetically by first name
						return a.firstName.localeCompare(b.firstName);
					} else {
						return roleComparison;
					}
				} else if (!a.role && b.role) {
					// All cases below are for if a user has no role, put them at the end of the sorted list
					return 1;
				} else if (a.role && !b.role) {
					return -1;
				} else {
					return 0;
				}
			});

			if (this.createMode) {
				//auto check the checkbox of the logged-in user.
				this.authService.getCurrentUser().subscribe((response) => {
					const currentUser = this.users.find((user) => user.id === response.user.id);
					if (currentUser) {
						this.teamMembers = [currentUser];
						this.userChecked(currentUser);
					}
					this.filteredList = this.users;
				});
			} else {
				this.projectSubscription = this.projectService.project.subscribe((response) => {
					if (response) {
						this.users.forEach((user) => (user.checked = false));
						this.teamMembers = response.members.map((member) => member.userInfo);
						this.teamMembers.forEach((member) => {
							const foundUser = this.users.find((user) => user.id === member.id);
							if (foundUser) {
								foundUser.checked = true;
							}
						});
						this.filteredList = this.users;
					}
				});
			}
		});
	}

  ngOnDestroy(): void {
    this.projectSubscription?.unsubscribe();
  }

	filterUsers() {
		//Matches on if either users name includes the searched term
		if (!this.searchTerm) {
			this.filteredList = this.users;
			return;
		}
		// Filter the users array based in event value
		this.filteredList = this.filteredList.filter((user) => {
			//for each user clients[] entry, check the name field
			const fullUsername = user.firstName.toLowerCase() + user.lastName.toLowerCase();
			return fullUsername.includes(this.searchTerm.toLowerCase());
		});
	}

	userIsChecked(user: User): boolean {
		return this.teamMembers?.some((selectedUser: User) => user.id === selectedUser.id);
	}

	userChecked(user: User) {
		this.addUser.emit(user);
		user.checked = true;
	}

	userUnchecked(id: number) {
		let user = this.filteredList.find((f: User) => f.id === id);
		user!.checked = false;
		this.removeUser.emit(id);
	}
}
