import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {PriceConfigurationService} from '../../../core/services/price-configuration.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PriceConfigurationDialogComponent} from '../price-configuration-dialog/price-configuration-dialog.component';
import {PriceConfigType} from '../../../core/models/price-config-types.interface';

@Component({
	selector: 'app-price-configuration-list',
	templateUrl: './price-configuration-list.component.html',
	styleUrls: ['./price-configuration-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class PriceConfigurationListComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	columnsToDisplay: string[] = ['name', 'currentCharge', 'lastEffectiveDate'];
	priceConfigTypes: PriceConfigType[];
	isLoadingPriceConfigs: boolean = true;

	constructor(private priceConfigService: PriceConfigurationService, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.dataSource = new MatTableDataSource();
		this.priceConfigService.findAllPriceConfigTypes().subscribe((response: PriceConfigType[]) => {
			this.priceConfigTypes = response;
			this.dataSource.data = this.priceConfigTypes;
			this.isLoadingPriceConfigs = false;
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openPriceConfigDialog(editMode: boolean): void {
		const dialogRef: MatDialogRef<PriceConfigurationDialogComponent> = this.dialog.open(PriceConfigurationDialogComponent, {
			data: {
				editMode: editMode,
        priceConfigTypes: this.priceConfigTypes
			}
		});

		dialogRef.afterClosed().subscribe((success: boolean): void => {
			if (success) {
				this.isLoadingPriceConfigs = true;
				this.refreshPriceConfigs();
			}
		});
	}

  refreshPriceConfigs(): void {
    this.priceConfigService.findAllPriceConfigTypes().subscribe((response: PriceConfigType[]): void => {
      this.priceConfigTypes = response;
      this.dataSource.data = this.priceConfigTypes;
      this.isLoadingPriceConfigs = false;
    });
  }
}
