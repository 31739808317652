import {Component, OnInit, ViewChild} from '@angular/core';
import {ProjectsService} from '../../core/services/projects.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProjectInstallShip} from '../../core/models/project-install-ship.interface';
import {OrderDetail} from '../../core/models/order-detail.model';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SalesOrderResponse} from '../../core/models/sales-order-response.model';
import {LocationService} from '../../core/services/location.service';

@Component({
	selector: 'app-project-picklist',
	templateUrl: './project-picklist.component.html',
	styleUrls: ['./project-picklist.component.scss']
})
export class ProjectPicklistComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<OrderDetail> = new MatTableDataSource<OrderDetail>();
	columnsToDisplay: string[] = ['erpItemRef', 'item', 'qty', 'check'];

	projectId: number | undefined;

	order: SalesOrderResponse;
	project: ProjectInstallShip;
	picklist: OrderDetail[];

	filterText = '';

	busy: boolean = false;

	projectLocationId = 0;
	projectLocationName = '';
	multiWarehouse = false;
	projectOtherLocationId: number | undefined = 0;
	projectOtherLocationName: string | undefined = '';
	locationFilter = 0;

	constructor(
		private projectService: ProjectsService,
		private locationService: LocationService,
		private route: ActivatedRoute,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit() {
		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe((params) => {
			if (params['projectId']) {
				this.projectId = params['projectId'];
				if (this.projectId) {
					this.busy = true;
					this.projectService.getProjectEstimates(this.projectId).subscribe({
						next: (projectEstimate) => {
							this.project = projectEstimate.project;
							this.order = projectEstimate.order;
							this.projectLocationId = projectEstimate.project.locationId;
							this.projectLocationName = projectEstimate.project.locationName;
							this.multiWarehouse = false;
							this.locationFilter = this.projectLocationId;
							if (projectEstimate.order?.salesLines) {
								this.picklist = projectEstimate.order.salesLines.filter((line) => line.pickList);
								const otherLocationFound = this.picklist.findIndex((line) => line.locationId !== this.projectLocationId);
								if (otherLocationFound > -1) {
									this.projectOtherLocationName = this.picklist[otherLocationFound].locationName;
									this.projectOtherLocationId = this.picklist[otherLocationFound].locationId;
									this.multiWarehouse = true;
								}
								this.dataSource = new MatTableDataSource<OrderDetail>(this.picklist);
								this.filterLocation();
							}
							this.busy = false;
						},
						error: (err) => {
							this._snackBar.open('Get Picklist Failed: ' + err.error.message);
							this.busy = false;
						}
					});
				}
			}
		});
	}

	applyFilter() {
		let filter = this.filterText;
		if (filter.length === 0) {
			filter = ' ';
		}
		this.dataSource.filter = filter;
		/*this.cdRef.detectChanges();*/
	}

	navShipWarn() {
		if (this.project.shipDate && this.project.navShipDate) {
			return (
				new Date(this.project.shipDate).toISOString().substring(0, 10) !== new Date(this.project.navShipDate).toISOString().substring(0, 10)
			);
		}
		return true;
	}

	picklistTitle() {
		let title = 'Picklist for: ';
		if (this.project) {
			title += this.project.orderNo + ' ' + this.project.name + ' - ' + this.project.location;
		}
		return title;
	}

	filterLocation() {
		this.dataSource.data = this.picklist.filter((orderDetail) => {
			return (
				(this.locationFilter > 1 && this.locationFilter === orderDetail.locationId) ||
				(this.locationFilter <= 1 && (!orderDetail.locationId || orderDetail.locationId <= 1))
			);
		});
	}
}
