import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TitleCasePipe} from '@angular/common';
import {ContactService} from '../../../core/services/contact.service';
import {Contact} from '../../../core/models/contact.interface';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-contacts-list-detail',
	templateUrl: './contacts-list-detail.component.html',
	styleUrls: ['./contacts-list-detail.component.scss']
})
export class ContactsListDetailComponent implements OnInit {
	@Input() contact: Contact;
	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private contactService: ContactService,
		private snackbar: MatSnackBar,
		private titlecasePipe: TitleCasePipe
	) {
		this.form = this.formBuilder.group({
			id: [''],
			email: ['', Validators.required],
			name: ['', Validators.required],
			phone: [''],
			phoneExt: [''],
			active: ['']
		});
	}
	ngOnInit() {
		this.form.patchValue(this.contact);
		this.form.disable();
	}
	onSubmit() {
		const formValue = this.form.getRawValue();
		this.contactService.update(formValue).subscribe(
			(response) => {
				this.snackbar.open('User Info Updated!', SnackbarActionEnum.SUCCESS);
				this.contactService.findAll();
				this.form.disable();
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}

	showToggleInactiveModal(activeColor: string) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				message:
					activeColor == 'green'
						? 'Are you sure you want to deactivate ' + this.titlecasePipe.transform(this.contact.name) + '?'
						: 'Are you sure you want to activate ' + this.titlecasePipe.transform(this.contact.name) + '?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				const active = this.form.get('active');
				if (active?.value) {
					this.form.get('active')?.setValue(0);
					this.onSubmit();
				} else {
					this.form.get('active')?.setValue(1);
					this.onSubmit();
				}
			} else {
				return;
			}
		});
	}
}
