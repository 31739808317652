import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ShipmentService} from '../../../core/services/shipment.service';
import {Shipment} from '../../../core/models/shipment.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ShipmentReference} from '../../../core/models/shipment-reference.model';
import {ShipmentHandlingUnit} from '../../../core/models/shipment-handling-unit.model';
import {ShippingCodesService} from '../../../core/services/shipping-codes.service';
import {Code} from '../../../core/models/shipping-code.model';
import {Accessorial} from '../../../core/models/accessorial.interface';
import {ShipmentAccessorial} from '../../../core/models/shipment-accessorial.model';
import {ClientSitesComponentContextEnum} from '../../../core/enums/client-sites-component.enum';
import {ContactComponentContextEnum} from '../../../core/enums/contact-component.enum';
import {AccessorialsComponentDisplayModesEnum} from '../../../core/enums/accessorials-component.enum';
import {formatNumber} from '@angular/common';
import {DimensionUnitEnum} from '../../../core/enums/dimension-unit.enum';
import {WeightUnitEnum} from '../../../core/enums/weight-unit.enum';
import {FreightDetailCalculationService} from '../../../core/services/freight-detail-calculation.service';
import {BrokerComponent} from '../broker/broker.component';
import {ProjectsService} from '../../../core/services/projects.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {QuoteService} from '../../../core/services/quote.service';
import {SalesOrderResponse} from '../../../core/models/sales-order-response.model';
import {map, Observable, startWith} from 'rxjs';
import {ValidatorRegexEnum} from '../../../core/enums/validator-regex.enum';
import {DefaultHandlingUnit} from '../../../shared/components/handling-unit/handling-unit.component';

@Component({
	selector: 'app-shipment',
	templateUrl: './shipment.component.html',
	styleUrls: ['./shipment.component.scss']
})
export class ShipmentComponent implements OnInit {
	@ViewChild('brokerCarrier') brokerCarrier: BrokerComponent;

	equipmentTypeList: Code[] = [];
	shipmentTermsList: Code[] = [];
	shipmentDirectionList: Code[] = [];
	huTypeList: Code[] = [];
	timeList: Code[] = [];
	timeZoneList: Code[] = [];
	dUnitList = Object.values(DimensionUnitEnum);
	wUnitList = Object.values(WeightUnitEnum);
	defaultHuType: string | undefined = '';
	defaultDUnit: string = this.dUnitList[0];
	defaultWUnit: string = this.wUnitList[0];
	defaultTime: string | undefined = '';
	defaultTimeZone: string | undefined = '';

	freightForm = new FormGroup({
		shipmentInfoForm: new FormGroup({
			description: new FormControl(''),
			shipmentOrderNo: new FormControl('')
		}),
		equipmentTypeForm: new FormGroup({
			equipmentType: new FormControl('')
		}),
		originForm: new FormGroup({
			originSiteCode: new FormControl(''),
			originName: new FormControl('', [Validators.required]),
			originAddress1: new FormControl('', [Validators.required]),
			originAddress2: new FormControl(''),
			originCity: new FormControl('', [Validators.required]),
			originState: new FormControl('', [Validators.required]),
			originPostal: new FormControl('', [Validators.required]),
			originCountry: new FormControl('', [Validators.required]),
			originContactName: new FormControl('', [Validators.required]),
			originContactEmail: new FormControl('', [Validators.pattern(ValidatorRegexEnum.EMAIL)]),
			originContactPhone: new FormControl('', [Validators.required, Validators.pattern(ValidatorRegexEnum.PHONE)]),
			originContactPhoneExt: new FormControl('')
		}),
		destinationForm: new FormGroup({
			destinationSiteCode: new FormControl(''),
			destinationName: new FormControl('', [Validators.required]),
			destinationAddress1: new FormControl('', [Validators.required]),
			destinationAddress2: new FormControl(''),
			destinationCity: new FormControl('', [Validators.required]),
			destinationState: new FormControl('', [Validators.required]),
			destinationPostal: new FormControl('', [Validators.required]),
			destinationCountry: new FormControl('', [Validators.required]),
			destinationContactName: new FormControl('', [Validators.required]),
			destinationContactEmail: new FormControl('', [Validators.pattern(ValidatorRegexEnum.EMAIL)]),
			destinationContactPhone: new FormControl('', [Validators.required, Validators.pattern(ValidatorRegexEnum.PHONE)]),
			destinationContactPhoneExt: new FormControl('')
		}),
		schedulingForm: new FormGroup({
			deliveryDateTime: new FormControl(new Date(), [Validators.required]),
			deliveryDate: new FormControl(new Date(), [Validators.required]),
			deliveryTime: new FormControl('', [Validators.required]),
			deliveryTimeZone: new FormControl('', [Validators.required])
		}),
		accessorialForm: new FormGroup({
			accessorials: this.fb.array([])
		}),
		specialServicesForm: new FormGroup({
			shipmentAccessorials: this.fb.array([])
		}),
		freightDetailsForm: new FormGroup({
			shipmentHandlingUnits: this.fb.array([]),
			totalLinearDimension: new FormControl(Number(undefined), [Validators.required, Validators.min(0.1)]),
			dUnit: new FormControl(this.defaultDUnit, [Validators.required]),
			totalWeight: new FormControl(Number(undefined), [Validators.required, Validators.min(1)]),
			wUnit: new FormControl(this.defaultWUnit, [Validators.required]),
			totalSkids: new FormControl(Number(undefined), [Validators.required, Validators.min(1)])
		}),
		referenceForm: new FormGroup({
			shipmentReferences: this.fb.array([]),
			instructions: new FormControl('')
		})
	});

	expandValid = {
		shipmentInfo: true,
		equipmentType: true,
		origin: true,
		destination: true,
		scheduling: true,
		specialServices: true,
		freightDetails: true,
		reference: true
	};

	busy: boolean = false;

	global = false;
	projectId: number | undefined;
	shipmentId: number | undefined;
	shipment: Shipment = {};
	locationId: number | undefined;

	selectedAccessorials: Accessorial[] = [];

	destId = 0;
	clientId = '';

	freightId = 0;

	ClientSiteComponentContext = ClientSitesComponentContextEnum;
	ContactComponentContext = ContactComponentContextEnum;
	AccessorialsComponentDisplayModes = AccessorialsComponentDisplayModesEnum;

	minDate = new Date();
	recalculateFreightDetails = false;

	salesOrderList: SalesOrderResponse[] = [];
	filteredSalesOrderList: Observable<SalesOrderResponse[]>;
	filterString = '';
	constructor(
		private route: ActivatedRoute,
		private shipmentService: ShipmentService,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		private codeService: ShippingCodesService,
		private freightDetailCalculationService: FreightDetailCalculationService,
		private projectService: ProjectsService,
		private router: Router,
		private dialog: MatDialog,
		private quoteService: QuoteService
	) {
		this.filterFunction();
	}

	ngOnInit() {
		this.equipmentTypeList = this.codeService.getCodes('ETYPE');
		this.shipmentTermsList = this.codeService.getCodes('STERM');
		this.shipmentDirectionList = this.codeService.getCodes('SDIR');
		this.huTypeList = this.codeService.getCodes('HUTYPE');
		this.defaultHuType = this.codeService.getDefault('HUTYPE');
		this.timeList = this.codeService.getCodes('TIME');
		this.timeZoneList = this.codeService.getCodes('TIMEZONE');
		this.defaultTime = this.codeService.getDefault('TIME');
		this.defaultTimeZone = this.codeService.getDefault('TIMEZONE');

		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe((params) => {
			if (params['shipmentId']) {
				this.shipmentId = params['shipmentId'];
				this.getShipment();
			} else {
				this.deleteDate();
			}
			if (params['projectId']) {
				this.projectId = params['projectId'];
				this.getProject();
			} else {
				this.projectId = 0;
				this.global = true;
			}
		});
		this.quoteService.findAllSalesOrdersFromErp(false).subscribe((response: SalesOrderResponse[]) => {
			this.salesOrderList = response;
		});
	}

	filterFunction(): void {
		this.filteredSalesOrderList = this.freightForm.controls.shipmentInfoForm.controls.shipmentOrderNo.valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : this.filterString)),
			map((filter) => this.filter(filter))
		);
	}

	filter(filter: string): SalesOrderResponse[] {
		this.filterString = filter;
		if (filter.length > 0) {
			return this.salesOrderList.filter((option: SalesOrderResponse) => {
				if (option.id) {
					return option.id.indexOf(filter) >= 0;
				} else {
					return;
				}
			});
		} else {
			return this.salesOrderList.slice();
		}
	}

	populateDestinationForm(salesOrder: SalesOrderResponse) {
		this.freightForm.controls.destinationForm.controls.destinationName.setValue(salesOrder.shipToName!);
		this.freightForm.controls.destinationForm.controls.destinationAddress1.setValue(salesOrder.shipToAddress!);
		this.freightForm.controls.destinationForm.controls.destinationAddress2.setValue(salesOrder.shipToAddress2!);
		this.freightForm.controls.destinationForm.controls.destinationCity.setValue(salesOrder.shipToCity!);
		this.freightForm.controls.destinationForm.controls.destinationState.setValue(salesOrder.shipToState!);
		this.freightForm.controls.destinationForm.controls.destinationPostal.setValue(salesOrder.shipToPostCode!);
		this.freightForm.controls.destinationForm.controls.destinationContactName.setValue(salesOrder.sellToContact!);
		if (salesOrder.id) {
			this.projectService.findByOrder(salesOrder.id).subscribe({
				next: (project) => {
					this.destId = project.siteId;
					this.clientId = project.clientId;
					this.projectId = project.id;
					this.locationId = project.locationId;
					if (!this.shipmentId) {
						if (!this.freightForm.controls.shipmentInfoForm.controls.description.value) {
							this.freightForm.controls.shipmentInfoForm.controls.description.setValue(project.name);
						}
						if (project.shipDate && !this.freightForm.controls.schedulingForm.controls.deliveryDate.value) {
							this.freightForm.controls.schedulingForm.controls.deliveryDate.setValue(project.shipDate);
						}
					}
				},
				error: (err) => {
					this._snackBar.open('Get Project Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	getProject() {
		if (this.projectId) {
			this.projectService.findOne(this.projectId).subscribe({
				next: (project) => {
					this.destId = project.siteId;
					this.clientId = project.clientId;
					this.projectId = project.id;
					this.locationId = project.locationId;
					if (!this.shipmentId) {
						this.deleteDate();
						this.freightForm.controls.destinationForm.controls.destinationSiteCode.setValue(this.destId.toString());
						this.freightForm.controls.destinationForm.controls.destinationName.setValue(project.name);
						this.freightForm.controls.destinationForm.controls.destinationAddress1.setValue(project.address1);
						this.freightForm.controls.destinationForm.controls.destinationAddress2.setValue(project.address2);
						this.freightForm.controls.destinationForm.controls.destinationCity.setValue(project.city);
						this.freightForm.controls.destinationForm.controls.destinationState.setValue(project.state);
						this.freightForm.controls.destinationForm.controls.destinationPostal.setValue(project.postal);
						this.freightForm.controls.destinationForm.controls.destinationCountry.setValue(project.country);
						this.freightForm.controls.shipmentInfoForm.controls.description.setValue(project.name);
						if (project.shipDate) {
							this.freightForm.controls.schedulingForm.controls.deliveryDate.setValue(project.shipDate);
						}
						if (project.orderNo) {
							this.freightForm.controls.shipmentInfoForm.controls.shipmentOrderNo.setValue(project.orderNo);
						}
					}
				},
				error: (err) => {
					this._snackBar.open('Get Project Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	getShipment() {
		if (this.shipmentId) {
			this.shipmentService.findOne(this.shipmentId).subscribe({
				next: (shipment) => {
					if (shipment.projectId) {
						this.projectId = shipment.projectId;
					}
					this.freightForm.controls.shipmentInfoForm.patchValue(shipment);
					this.freightForm.controls.equipmentTypeForm.patchValue(shipment);
					this.freightForm.controls.originForm.patchValue(shipment);
					this.freightForm.controls.destinationForm.patchValue(shipment);
					this.freightForm.controls.schedulingForm.patchValue(shipment);
					this.freightForm.controls.specialServicesForm.patchValue(shipment);
					this.freightForm.controls.freightDetailsForm.patchValue(shipment);
					this.freightForm.controls.referenceForm.patchValue(shipment);
					this.updateShipmentArrays(shipment);
					if (shipment.freightId) {
						this.freightId = shipment.freightId;
						if (this.freightId > 0) {
							this.freightForm.controls.shipmentInfoForm.controls.description.disable();
							this.freightForm.controls.shipmentInfoForm.controls.shipmentOrderNo.disable();
							this.freightForm.controls.equipmentTypeForm.controls.equipmentType.disable();
							this.freightForm.controls.schedulingForm.controls.deliveryDate.disable();
							this.freightForm.controls.schedulingForm.controls.deliveryTime.disable();
							this.freightForm.controls.schedulingForm.controls.deliveryTimeZone.disable();
							this.freightForm.controls.freightDetailsForm.controls.dUnit.disable();
							this.freightForm.controls.freightDetailsForm.controls.wUnit.disable();
						}
					}
					this.getExpandedSections();
				},
				error: (err) => {
					this._snackBar.open('Get Shipment Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	getExpandedSections() {
		if (this.freightId === 0) {
			//    this.expandValid.equipmentType = !this.freightForm.controls.equipmentTypeForm.valid;
			this.expandValid.origin = !this.freightForm.controls.originForm.valid;
			this.expandValid.destination = !this.freightForm.controls.destinationForm.valid;
			this.expandValid.scheduling = !this.freightForm.controls.schedulingForm.valid;
			this.expandValid.specialServices = !this.freightForm.controls.specialServicesForm.valid;
			this.expandValid.freightDetails = !this.freightForm.controls.freightDetailsForm.valid;
			this.expandValid.reference = !this.freightForm.controls.referenceForm.valid;
		}
	}

	saveShipment() {
		Object.assign(this.shipment, {
			...this.freightForm.controls.shipmentInfoForm.value,
			...this.freightForm.controls.equipmentTypeForm.value,
			...this.freightForm.controls.originForm.value,
			...this.freightForm.controls.destinationForm.value,
			...this.freightForm.controls.schedulingForm.value,
			...this.freightForm.controls.specialServicesForm.value,
			...this.freightForm.controls.freightDetailsForm.value,
			...this.freightForm.controls.referenceForm.value
		});
		this.shipment.projectId = this.projectId;
		this.busy = true;
		if (this.shipment.deliveryTime === '') {
			delete this.shipment.deliveryTime;
		}
		if (this.shipment.deliveryDate) {
			// @ts-ignore
			this.shipment.deliveryDate = new Date(this.shipment.deliveryDate).toISOString().substring(0, 10);
		}
		if (this.shipmentId) {
			this.shipment.id = this.shipmentId;
			this.shipmentService.update(this.shipment).subscribe({
				next: (shipment) => {
					this.updateShipmentArrays(shipment);
					this.busy = false;
					this.navigateToFreight();
				},
				error: (err) => {
					this._snackBar.open('Update Shipment Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		} else {
			this.shipmentService.create(this.shipment).subscribe({
				next: (shipment) => {
					this.shipmentId = shipment.id;
					this.updateShipmentArrays(shipment);
					this.busy = false;
					this.navigateToFreight();
				},
				error: (err) => {
					this._snackBar.open('Create Shipment Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	formatCity(city: string | null) {
		if (city) {
			return city + ',';
		}
		return '';
	}

	get selectedEquipmentType() {
		return this.equipmentTypeList.find(
			(equipmentType) => equipmentType.value === this.freightForm.controls.equipmentTypeForm.controls.equipmentType.value
		);
	}

	updateShipmentArrays(shipment: Shipment) {
		this.shipmentReferences.clear();
		shipment.shipmentReferences?.forEach((shipmentReference) => this.addShippingReference(shipmentReference));
		this.shipmentHandlingUnits.clear();
		shipment.shipmentHandlingUnits?.forEach((shipmentHandlingUnit) => this.addShippingHandlingUnit(shipmentHandlingUnit));
		this.shipmentAccessorials.clear();
		this.selectedAccessorials = [];
		shipment.shipmentAccessorials?.forEach((shipmentAccessorial) => {
			this.addShipmentAccessorial(shipmentAccessorial);
			this.selectedAccessorials.push({id: shipmentAccessorial.accessorialId});
		});
	}

	addShippingReference(shipmentReference: ShipmentReference | null = null): void {
		if (shipmentReference) {
			this.shipmentReferences.push(
				this.fb.group({
					id: [shipmentReference.id],
					source: [shipmentReference.source, [Validators.required]],
					referenceNo: [shipmentReference.referenceNo, [Validators.required]],
					shipmentId: [shipmentReference.shipmentId]
				})
			);
		} else {
			let shipmentReference: any = {
				source: ['', [Validators.required]],
				referenceNo: ['', [Validators.required]]
			};
			if (this.shipmentId) {
				shipmentReference.shipmentId = [this.shipmentId];
			}
			this.shipmentReferences.push(this.fb.group(shipmentReference));
		}
	}

	get shipmentReferences(): FormArray {
		return this.freightForm.controls.referenceForm.controls.shipmentReferences;
	}

	deleteShipmentReference(index: number): void {
		this.shipmentReferences.removeAt(index);
	}

	addShippingHandlingUnit(shipmentHandlingUnit: ShipmentHandlingUnit | null = null): void {
		if (shipmentHandlingUnit) {
			this.shipmentHandlingUnits.push(
				this.fb.group({
					id: [shipmentHandlingUnit.id],
					huType: [shipmentHandlingUnit.huType, [Validators.required]],
					length: [
						shipmentHandlingUnit.length ? formatNumber(shipmentHandlingUnit.length, 'en') : null,
						[Validators.required, Validators.min(0.1)]
					],
					width: [
						shipmentHandlingUnit.width ? formatNumber(shipmentHandlingUnit.width, 'en') : null,
						[Validators.required, Validators.min(0.1)]
					],
					height: [
						shipmentHandlingUnit.height ? formatNumber(shipmentHandlingUnit.height, 'en') : null,
						[Validators.required, Validators.min(0.1)]
					],
					dUnit: [shipmentHandlingUnit.dUnit, [Validators.required]],
					weight: [
						shipmentHandlingUnit.weight ? formatNumber(shipmentHandlingUnit.weight, 'en') : null,
						[Validators.required, Validators.min(1)]
					],
					wUnit: [shipmentHandlingUnit.wUnit, [Validators.required]],
					qty: [shipmentHandlingUnit.qty, [Validators.required, Validators.min(1)]],
					shipmentId: [shipmentHandlingUnit.shipmentId]
				})
			);
		} else {
			let shipmentHandlingUnit: any = {
				huType: [this.defaultHuType, [Validators.required]],
				length: [null, [Validators.required, Validators.min(0.1)]],
				width: [null, [Validators.required, Validators.min(0.1)]],
				height: [null, [Validators.required, Validators.min(0.1)]],
				dUnit: [this.defaultDUnit, [Validators.required]],
				weight: [null, [Validators.required, Validators.min(1)]],
				wUnit: [this.defaultWUnit, [Validators.required]],
				qty: [1, [Validators.required, Validators.min(1)]]
			};
			if (this.shipmentId) {
				shipmentHandlingUnit.shipmentId = this.shipmentId;
			}
			this.shipmentHandlingUnits.push(this.fb.group(shipmentHandlingUnit));
		}
	}

	get shipmentHandlingUnits(): FormArray {
		return this.freightForm.controls.freightDetailsForm.controls.shipmentHandlingUnits;
	}

	deleteShipmentHandlingUnit(index: number): void {
		this.shipmentHandlingUnits.removeAt(index);
		this.calculateFreightDetails();
	}

	addAccessorial(accessorial: Accessorial): void {
		this.addShipmentAccessorial(
			{
				name: accessorial.name,
				accessorialId: accessorial.id
			},
			true
		);
	}

	addShipmentAccessorial(shipmentAccessorial: ShipmentAccessorial, addNew: boolean = false): void {
		let accessorial: any = {
			name: [shipmentAccessorial.name],
			accessorialId: [shipmentAccessorial.accessorialId]
		};
		if (this.shipmentId) {
			accessorial.shipmentId = [this.shipmentId];
		}
		if (!addNew) {
			accessorial.id = shipmentAccessorial.id;
		}
		this.shipmentAccessorials.push(this.fb.group(accessorial));
	}

	get shipmentAccessorials(): FormArray {
		return this.freightForm.controls.specialServicesForm.controls.shipmentAccessorials;
	}

	deleteShipmentAccessorial(index: number): void {
		this.selectedAccessorials = this.selectedAccessorials.filter(
			(accessorial) => accessorial.id !== this.shipmentAccessorials.at(index).get('accessorialId')?.value
		);
		this.shipmentAccessorials.removeAt(index);
	}

	get deliveryDate(): string {
		const deliveryDate = this.freightForm.controls.schedulingForm.controls.deliveryDate.value;
		if (deliveryDate) {
			return new Date(deliveryDate).toLocaleDateString('us-EN');
		}
		return '';
	}

	get deliveryTime(): string {
		let deliveryTime = '';
		const time = this.timeList.find((time) => time.value === this.freightForm.controls.schedulingForm.controls.deliveryTime.value);
		if (time && time.description) {
			deliveryTime = time.description;
		}
		const timeZone = this.timeZoneList.find(
			(timeZone) => timeZone.value === this.freightForm.controls.schedulingForm.controls.deliveryTimeZone.value
		);
		if (timeZone && timeZone.description) {
			deliveryTime += ' ' + timeZone.description;
		}
		return deliveryTime;
	}

	calculateFreightDetails(defaultUnit: DefaultHandlingUnit | null = null) {
		if (defaultUnit) {
			if (defaultUnit.wUnit) {
				this.defaultWUnit = defaultUnit.wUnit;
			}
			if (defaultUnit.dUnit) {
				this.defaultDUnit = defaultUnit.dUnit;
			}
		}
		const handlingUnits = Object.assign(this.freightForm.controls.freightDetailsForm.controls.shipmentHandlingUnits.controls);

		this.recalculateFreightDetails = false;

		if (handlingUnits) {
			this.freightForm.controls.freightDetailsForm.controls.totalLinearDimension.setValue(
				this.freightDetailCalculationService.getTotalLinearDimensions(
					handlingUnits,
					this.freightForm.controls.freightDetailsForm.controls.dUnit?.value
				)
			);

			this.freightForm.controls.freightDetailsForm.controls.totalWeight.setValue(
				this.freightDetailCalculationService.getTotalWeight(
					handlingUnits,
					this.freightForm.controls.freightDetailsForm.controls.wUnit?.value
				)
			);

			this.freightForm.controls.freightDetailsForm.controls.totalSkids.setValue(
				this.freightDetailCalculationService.getTotalSkids(handlingUnits)
			);
		}
	}

	setFreightDetailsDirty() {
		this.recalculateFreightDetails = true;
	}

	cancel() {
		if (this.freightForm.dirty) {
			const confirm = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Leave Page',
					message: 'Any unsaved work will be lost. Are you sure?'
				}
			});
			confirm.afterClosed().subscribe((result) => {
				if (result) {
					this.navigateToFreight();
				}
			});
		} else {
			this.navigateToFreight();
		}
	}

	navigateToFreight() {
		if (!this.global) {
			this.router.navigate(['/project', this.projectId, 'freight']).then();
		} else {
			this.router.navigate(['/freight']).then();
		}
	}

	deleteDate() {
		this.freightForm.controls.schedulingForm.controls.deliveryDate.setValue(null);
	}

	get handlingUnitSummary(): {[key: string]: number} {
		let shipmentHandlingUnits: {[key: string]: number} = {};
		const handlingUnitArray = this.shipmentHandlingUnits as FormArray;
		handlingUnitArray.controls.forEach((handlingUnit) => {
			const handlingUnitGroup = handlingUnit as FormGroup;
			if (handlingUnitGroup.controls['huType'] && handlingUnitGroup.controls['qty']) {
				if (!shipmentHandlingUnits[handlingUnitGroup.controls['huType'].value]) {
					shipmentHandlingUnits[handlingUnitGroup.controls['huType'].value] = handlingUnitGroup.controls['qty'].value;
				} else {
					shipmentHandlingUnits[handlingUnitGroup.controls['huType'].value] += handlingUnitGroup.controls['qty'].value;
				}
			}
		});
		return shipmentHandlingUnits;
	}
}
