<h2 mat-dialog-title>Create Transit Time</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
	<div class="flex gap-4">
		<mat-form-field class="w-1/2 pt-2">
			<mat-label>State</mat-label>
			<input matInput formControlName="state" />
			<mat-error *ngIf="form.get('state')?.hasError('required')"> State is required </mat-error>
			<mat-error *ngIf="form.get('state')?.hasError('maxlength')">
				{{ this.form.get('state')?.value.length }} / 10 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
		<mat-form-field class="w-1/2 pt-2">
			<mat-label># of Days</mat-label>
			<input matInput formControlName="days" />
			<mat-error *ngIf="form.get('days')?.hasError('required')"> # of Days is required </mat-error>
			<mat-error *ngIf="form.get('days')?.hasError('pattern')"> Please enter a number less than 256 </mat-error>
		</mat-form-field>
	</div>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close="true" class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createTransitTime()">Create</button>
</div>
