import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SalesOrderResponse} from '../../../../core/models/sales-order-response.model';

@Component({
	selector: 'app-create-order-response',
	templateUrl: './create-order-response.component.html',
	styleUrls: ['./create-order-response.component.scss']
})
export class CreateOrderResponseComponent {
	isSuccessful: boolean;
	salesOrder: SalesOrderResponse;
	error: {statusCode: number; message: string};

	constructor(public dialogRef: MatDialogRef<CreateOrderResponseComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
		this.isSuccessful = data.isSuccessful;
		this.salesOrder = data.salesOrder;
		this.error = data.error;
	}

	closeModal() {
		this.dialogRef.close();
	}
}
