<div class="p-2 flex justify-center">
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="user-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, email or phone number."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openCreateGlCodeModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 h-[80vh] border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingCodes">
		<!--GL Number Column-->
		<ng-container matColumnDef="glNumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by GL number">GL #</th>
			<td mat-cell *matCellDef="let code">
				{{ code.glNumber }}
			</td>
		</ng-container>

		<!--Description Column-->
		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number of description">Description</th>
			<td mat-cell *matCellDef="let code">
				{{ code.description }}
			</td>
		</ng-container>

		<!--Type Column-->
		<ng-container matColumnDef="type">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number of type">Type</th>
			<td mat-cell *matCellDef="let code">
				{{ code.type }}
			</td>
		</ng-container>

		<!--Category Column-->
		<ng-container matColumnDef="category">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number of description">Category</th>
			<td mat-cell *matCellDef="let code">
				{{ code.category }}
			</td>
		</ng-container>

		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns. -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let code" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="code.expanded ? 'expanded' : 'collapsed'">
					<app-gl-codes-list-detail
						[code]="code"
						[codeTypes]="codeTypes"
						[categories]="categories"
						class="w-full"
					></app-gl-codes-list-detail>
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let code; let i = dataIndex; columns: columnsToDisplay"
			class="code-row cursor-pointer"
			(click)="code.expanded = !code.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingCodes">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingCodes">
		<mat-spinner></mat-spinner>
	</div>
</div>
