<div *ngIf="isLoading" class="w-full h-full flex justify-center m-2">
	<mat-spinner></mat-spinner>
</div>
<div class="p-4" *ngIf="!isLoading">
	<div class="flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">Project Details</span>
		<div
			[className]="
				'status-indicator p-3 rounded-full flex w-fit h-full text-white justify-center items-center ' +
				getPillBackgroundColor(project.createdTs)
			"
		>
			<h1 class="">{{ getDaysAtStatus(project.createdTs) }}</h1>
			<p class="text-xs">days at <br />current status</p>
		</div>
		<div
			[ngStyle]="{backgroundColor: project.active ? 'green' : 'red'}"
			class="py-1 px-2 ml-2 rounded-full text-white cursor-pointer flex justify-center items-center h-full"
			(click)="showToggleInactiveModal()"
			#pill
		>
			<p class="no-margin">{{ project.active ? 'Active' : 'Inactive' }}</p>
		</div>
		<div class="m-2 font-medium" *ngIf="!project.active">({{ codeDescription(project.inactiveReasonCode) }})</div>
	</div>
	<div class="flex">
		<div class="flex flex-wrap w-1/2 pt-3">
			<div class="detail">
				<b>Site Address</b>
				<p>
					{{ project.address1 | titlecase }}<br />
					{{ project.address2 ? (project.address2 | titlecase) : null }}<br *ngIf="project.address2" />
					{{ project.city | titlecase }},&nbsp;{{ project.state | uppercase }} &nbsp;{{ project.postal }}
				</p>
			</div>
			<div class="detail">
				<b>Project Creation Date</b>
				<p>{{ project.createdTs ? (project.createdTs | date) : 'N/A' }}</p>
			</div>
			<div class="detail relative">
				<b
					>Last Contacted Drafting
					<mat-icon
						(click)="openLcdModal()"
						class="cursor-pointer material-icons-outlined absolute top-[-.5rem]"
						[ngClass]="{invisible: !project.active}"
					>
						mode_edit_outline
					</mat-icon>
				</b>
				<p>{{ project.lastDraftingContactDate ? (project.lastDraftingContactDate | date) : 'N/A' }}</p>
			</div>
			<div class="detail">
				<b>Install Locations</b>
				<p *ngIf="!project.locations.length">None</p>
				<p *ngFor="let location of project.locations" class="location-text">{{ location.type.name | titlecase }} <br /></p>
			</div>
			<div class="detail grid">
				<b>Recent Activity</b>
				<span>{{ activitySorted.length >= 1 ? (activitySorted[0].description | titlecase) : 'None' }}</span>
				<i>{{ lastActivityDate ? (lastActivityDate | date) + ' at ' + (lastActivityDate | date : 'shortTime') : null }}</i>
			</div>
		</div>
		<app-project-quick-status [project]="project" class="h-full w-1/2"></app-project-quick-status>
	</div>
	<button
		class="font-semibold"
		mat-flat-button
		color="primary"
		(click)="navigateToProject()"
		[disabled]="!project.active || buttonDisabled"
	>
		View Project
		<mat-icon *ngIf="buttonDisabled"><mat-spinner diameter="18"></mat-spinner></mat-icon>
	</button>
</div>
