import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {QuoteApprovalInfo} from '../../../core/models/quote-approval-info.interface';
import {QuoteService} from '../../../core/services/quote.service';
import {Quote} from '../../../core/models/quote.interface';
import {format} from 'date-fns';

@Component({
	selector: 'app-approval-info',
	templateUrl: './approval-info.component.html',
	styleUrls: ['./approval-info.component.scss']
})
export class ApprovalInfoComponent implements OnInit {
	@Input() parentForm: FormGroup;
	@Input() approvals: QuoteApprovalInfo[];
	@Input() quoteId: number;
	@Input() salesOrderForm: FormGroup;
	@Input() quote: Quote;

	approval: QuoteApprovalInfo;
	poUrl: string;
	loading: boolean = true;
	existingPo: string | undefined;

	constructor(private quoteService: QuoteService) {}

	ngOnInit(): void {
		if (this.approvals) {
			this.approval = this.approvals[0];
			this.existingPo = this.approval?.po;
			if (this.approval?.documentName) {
				this.quoteService.getPoUpload(this.quoteId).subscribe({
					next: (response: any) => {
						this.poUrl = response.url;
						this.loading = false;
					},
					error: (err: any) => console.error(err)
				});
			} else {
				this.loading = false;
			}
		}
	}

	downloadPoUpload() {
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = this.poUrl;
		a.download = this.poUrl;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(a.href);
		document.body.removeChild(a);
	}

	getEstimateText(date: Date): string {
		if (!date) {
			return 'Unknown';
		} else {
			const dateConverted = new Date(date);
			return (+format(dateConverted, 'd') <= 15 ? 'Beginning of ' : 'End of ') + format(dateConverted, 'MMMM');
		}
	}
}
