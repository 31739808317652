<button (menuOpened)="setNotificationsAsRead()" mat-icon-button [matMenuTriggerFor]="notifications">
	<mat-icon matBadgeSize="medium" [matBadge]="notifBadge()" matBadgeColor="warn" [matBadgeHidden]="!notifBadge()">
		notification_important</mat-icon
	>
</button>
<mat-menu #notifications="matMenu">
	<div class="flex flex-col min-w-[25vw]">
		<div class="flex items-center mx-4 justify-between">
			<span class="text-lg">Notifications</span>
			<button mat-button (click)="clearAllNotifications()">Clear All</button>
		</div>
	</div>

	<div class="my-2">
		<mat-divider></mat-divider>
	</div>

	<div *ngIf="userNotifications?.length; else elseBlock">
		<div
			*ngFor="let notification of userNotifications"
			(click)="routeToRelatedPage(notification.projectId)"
			class="flex items-center mx-2 mb-1"
		>
			<div mat-menu-item class="rounded">
				<p class="notification-text">{{ notification.description }}</p>
				<i class="text-xs text-gray-400">{{ notification.createdTs | date : "MMM d, yyyy 'at' h:mm:ss a" }}</i>
			</div>
			<button mat-icon-button (click)="deleteNotification(notification, $event)">
				<mat-icon class="scale-75">close</mat-icon>
			</button>
		</div>
	</div>

	<ng-template #elseBlock>
		<span class="mx-4 text-gray-500">No notifications...</span>
	</ng-template>
</mat-menu>
