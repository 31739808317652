import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InstallCalendarComponent} from './install-calendar.component';
import {UpdateProjectInstallDialogComponent} from './update-project-install-dialog/update-project-install-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {SharedModule} from '../shared/shared.module';

@NgModule({
	declarations: [InstallCalendarComponent, UpdateProjectInstallDialogComponent],
	imports: [
		CommonModule,
		MatInputModule,
		MatDatepickerModule,
		MatButtonModule,
		MatDividerModule,
		MatCheckboxModule,
		FormsModule,
		RouterLink,
		MatIconModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatProgressSpinnerModule,
		InfiniteScrollModule,
		MatTooltipModule,
		MatDialogModule,
		ReactiveFormsModule,
		MatSelectModule,
		SharedModule
	],
	exports: [InstallCalendarComponent]
})
export class InstallCalendarModule {}
