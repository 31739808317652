import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormGroup} from '@angular/forms';
import {Code} from '../../../../core/models/code.interface';
import {CommaRemovalPipe} from '../../../../shared/pipes/comma-removal.pipe';
import {QuoteLine} from "../../../../core/models/quote-line.interface";

@Component({
	selector: 'app-cost-adjustment-dialog',
	templateUrl: './cost-adjustment-dialog.component.html',
	styleUrls: ['./cost-adjustment-dialog.component.scss']
})
export class CostAdjustmentDialogComponent {
	form: FormGroup;
	getBackOptions: QuoteLine[];
	getBackCategories: Code[];
	editMode: boolean;
  selected: QuoteLine = {};

	constructor(
		private dialogRef: MatDialogRef<CostAdjustmentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private format: CommaRemovalPipe
	) {
		this.form = data.formGroup;
		this.getBackOptions = data.getBackOptions;
		this.getBackCategories = data.getBackCategories;
		this.editMode = data.editMode;
	}

	onSelection(option: QuoteLine): void {
    this.selected = option;
    this.form.get('glNumber')?.setValue(option.glNumber);
    this.form.get('description')?.setValue(option.description);
    this.form.get('type')?.setValue(option.type);
	}

	checkForTrailingZero(): void {
		this.form.get('price')?.setValue(parseFloat(this.format.transform(this.form.get('price')?.value, true)).toFixed(2));
	}

	onSubmit(): void {
		if (this.form.valid) {
			this.dialogRef.close(this.form);
		}
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
