export enum AssociatedCostEnum {
	THIRD_PARTY_FREIGHT = 'thirdPartyFreight',
	SKIDDING = 'skidding',
	DEMOLITION = 'demo',
	DISCARD = 'demoRemoval',
	PREVAILING_WAGE = 'prevailingWage',
	STRUCTURAL_CALCS = 'structuralCalcs',
	SEISMIC_CALCS = 'seismicCalcs',
	SPECIAL_INSPECTION = 'specialInspection',
	STOCKROOM_PERMIT = 'stockPermitReq',
  SURVEY = 'survey'
}
