<div class="flex w-full justify-between">
	<div class="p-2 flex justify-center flex-1">
		<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
			<mat-label>Search</mat-label>
			<input id="user-searchbar" type="text" (keyup)="applyFilter($event)" placeholder="What are you looking for?" matInput #searchInput />
		</mat-form-field>
	</div>
	<div class="flex flex-col mr-16 justify-end">
		<div class="mx-2 font-medium text-right">Average Profit: {{ avgProfit | percent }}</div>
		<div class="m-2 font-medium text-right">Weighted Profit: {{ weightedProfit | percent }}</div>
	</div>
</div>

<div class="w-[90vw] h-[80vh] border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!loading">
		<!--Account Manager Column-->
		<ng-container matColumnDef="accountManager">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Account Manager">Account Manager</th>
			<td mat-cell *matCellDef="let log">
				{{ log.accountManager ? log.accountManager : 'N/A' }}
			</td>
		</ng-container>
		<!--Job Column-->
		<ng-container matColumnDef="job">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Job">Job</th>
			<td mat-cell *matCellDef="let log">
				<div class="flex flex-col">
					<div>{{ log.orderNo }} - {{ log.jobName }}</div>
					<div>{{ log.city }}, {{ log.state }} {{ log.postal }}</div>
				</div>
			</td>
		</ng-container>
		<!--Broker Column-->
		<ng-container matColumnDef="broker">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Broker">Broker</th>
			<td mat-cell *matCellDef="let log">
				<div class="flex flex-col">
					<div *ngIf="log.brokerName" class="flex">
						<div class="w-12 mr-2">Broker:</div>
						<div>{{ log.brokerName }}</div>
					</div>
					<div *ngIf="log.carrierName" class="flex">
						<div class="w-12 mr-2">Carrier:</div>
						<div>{{ log.carrierName }}</div>
					</div>
					<div *ngIf="log.proNo" class="flex">
						<div class="w-12 mr-2">Pro #:</div>
						<div>{{ log.proNo }}</div>
					</div>
				</div>
			</td>
		</ng-container>
		<!--Budget Column-->
		<ng-container matColumnDef="budget">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Budget">Budget</th>
			<td mat-cell *matCellDef="let log">
				<div class="flex flex justify-end">{{ log.budget | currency }}</div>
			</td>
		</ng-container>
		<!--Cost Column-->
		<ng-container matColumnDef="cost">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Cost">Cost</th>
			<td mat-cell *matCellDef="let log">
				<div class="flex flex-col">
					<div class="flex justify-end">{{ log.cost | currency }}</div>
					<div class="flex justify-end" *ngIf="log.additionalCost">
						{{ log.additionalCost | currency }}
					</div>
					<div class="flex justify-end" *ngIf="log.additionalCostReasonString">({{ log.additionalCostReasonString }})</div>
				</div>
			</td>
		</ng-container>
		<!--Profit $ Column-->
		<ng-container matColumnDef="profitDollar">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Profit $">Profit $</th>
			<td mat-cell *matCellDef="let log">
				<div class="flex flex justify-end">{{ log.profitDollar | currency }}</div>
			</td>
		</ng-container>
		<!--Profit % Column-->
		<ng-container matColumnDef="profitPercent">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Profit %">Profit %</th>
			<td mat-cell *matCellDef="let log">
				<div class="flex flex justify-end">{{ log.profitPercent | percent }}</div>
			</td>
		</ng-container>
		<!--Delivery Column-->
		<ng-container matColumnDef="delivery">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Delivery">Delivery</th>
			<td mat-cell *matCellDef="let log" class="flex">
				<div class="flex flex-col">
					<div class="flex items-center">
						<div class="mr-2">Pickup:</div>
						<div>{{ log.shipDate | date : 'mediumDate' }}</div>
					</div>
					<div class="flex items-center">
						<div class="mt-2 mr-2">Deliver:</div>
						<div class="mt-2">{{ log.originalDeliveryDate | date : 'mediumDate' }} {{ log.originalDeliveryTime }}</div>
						<mat-icon *ngIf="onTime(log)" class="text-green">done</mat-icon>
						<mat-icon *ngIf="!onTime(log)" class="text-red">close</mat-icon>
					</div>
					<div *ngIf="!showDelivery(log)" class="flex items-center">
						<div class="mr-2">Actual:</div>
						<div>{{ log.deliveryDate | date : 'mediumDate' }} {{ log.deliveryTime }}</div>
					</div>
				</div>
			</td>
		</ng-container>
		<!--Invoice Date Column-->
		<ng-container matColumnDef="invoiceDate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Invoice Date">Invoice Date</th>
			<td mat-cell *matCellDef="let log">
				{{ log.invoiceDate | date : 'shortDate' }}
			</td>
		</ng-container>
		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let log" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="log.expanded ? 'expanded' : 'collapsed'">
					<app-shipping-log-list-detail
						class="w-full"
						[log]="log"
						(updateLog)="updateLog($event)"
						[codes]="codes"
					></app-shipping-log-list-detail>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let log; let i = dataIndex; columns: columnsToDisplay"
			class="log-row cursor-pointer"
			(click)="log.expanded = !log.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!loading">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>
</div>
