import {Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ProjectPreview} from '../core/models/project-preview.interface';
import {Status} from '../core/models/status.interface';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProjectsService} from '../core/services/projects.service';
import {StatusService} from '../core/services/status.service';
import {SortDirection} from '@angular/material/sort';
import {format} from 'date-fns';
import {Subscription} from 'rxjs';
import {Code} from '../core/models/code.interface';
import {CodeService} from '../core/services/code.service';
import {CodeTypeEnum} from '../core/enums/code-type.enum';
import {UserService} from '../core/services/user.service';
import {LocalStorageService} from '../core/services/local-storage.service';
import {MatMultiSort, MatMultiSortHeaderComponent} from 'ngx-mat-multi-sort';
import {MultiSortDataSource} from '../core/lib/MultiSortDataSource';
import {LocationService} from '../core/services/location.service';

@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class ProjectsComponent implements OnInit, OnDestroy {
	dataSource: MultiSortDataSource<ProjectPreview> = new MultiSortDataSource<ProjectPreview>();
	//dataSource: MatTableDataSource<ProjectPreview> = new MatTableDataSource<ProjectPreview>();
	//dataSource: MatMultiSortTableDataSource<ProjectPreview> = new MatMultiSortTableDataSource<ProjectPreview>(new MatMultiSort());
	sort: MatMultiSort;
	statusSorts: {[key: string]: {actives: string[]; directions: SortDirection[]}} = {};
	@ViewChild(MatMultiSort) set matSort(ms: MatMultiSort) {
		const statusSort = this.localStorageService.getObject('statusSorts');
		if (statusSort) {
			this.statusSorts = statusSort as {[key: string]: {actives: string[]; directions: SortDirection[]}};
		}
		this.sort = ms;
		this.dataSource.sort = this.sort;
		this.changeSorts();

		this.sort.sortChange.subscribe(() => {
			let status = 'default';
			if (this.activeFilter) {
				status = this.activeFilter.description;
			}
			if (!this.statusSorts[status]) {
				this.statusSorts[status] = {actives: [], directions: []};
			} else {
				this.statusSorts[status].actives = this.sort.actives;
				this.statusSorts[status].directions = this.sort.directions;
			}
			this.localStorageService.setObject('statusSorts', this.statusSorts);
		});
	}
	isLoading: boolean = true;
	statuses: Status[];
	activeFilter: Status | null;
	nonZeroStatuses: Status[];
	activeInactiveBoolean: boolean = true;
	projectPreviews: ProjectPreview[] = [];
	colors: string[] = ['maroon', 'teal', 'purple', 'green', 'yellow', 'darkBlue', 'orange', 'lightPurple', 'brown'];
	columnsToDisplay = [
		'clientId',
		'orderNo',
		'name',
		'location',
		'stage',
		'project.workflowStatus.description',
		'installDate',
		'lastDraftingContactDate',
		'lastRfqDate',
		'warehouse',
		'members'
	];
	statusSubscription: Subscription;

	codes: Code[];

	locations: string[] = [];
	locationFilter: string[] = [];
	filterValue = '';

	busy = false;

	constructor(
		private projectsService: ProjectsService,
		private statusService: StatusService,
		private cdRef: ChangeDetectorRef,
		private codeService: CodeService,
		private userService: UserService,
		private localStorageService: LocalStorageService,
		private locationService: LocationService
	) {}

	ngOnInit() {
		this.activeFilter = this.localStorageService.getObject('activeFilter') as Status;
		this.activeInactiveBoolean = this.localStorageService.getBool('activeInactiveBoolean', true);
		this.filterValue = this.localStorageService.getItem('filterValue');
		if (!this.filterValue) {
			this.filterValue = '';
		}
		this.locationFilter = this.localStorageService.getObject('locationFilter') as string[];
		if (!this.locationFilter) {
			this.locationFilter = [];
		}

		this.statusSubscription = this.statusService.statuses.subscribe((statuses) => {
			this.statuses = statuses;
			if (statuses.length) this.getProjects();
			this.projectsService.projects.subscribe((projects) => {
				this.projectPreviews = projects;
				this.dataSource.data = this.projectPreviews;
				this.dataSource.sortingDataAccessor = (item: ProjectPreview, property: string) => {
					switch (property) {
						case 'project.workflowStatus.description':
							return item.workflowStatus.description;
						case 'installDate':
							return item.installDate ? new Date(item.installDate) : new Date(item.estimateDate);
						case 'location':
							return item.state;
						case 'stage':
							return this.getStage(item.workflowStatus.subParentId).toLowerCase();
						case 'lastDraftingContactDate':
							return item.lastDraftingContactDate ? new Date(item.lastDraftingContactDate) : 0;
						case 'lastRfqDate':
							return item.lastRfqDate ? new Date(item.lastRfqDate) : 0;
						case 'warehouse':
							return item.location ? item.location : '';
						default:
							return typeof item[property] === 'string' ? item[property]?.toLowerCase() : item[property];
					}
				};
				this.countStageQuantities();
				this.filterTable();
				this.isLoading = false;
				this.busy = false;
			});
		});
		this.codeService.findByType(CodeTypeEnum.PROJECT_INACTIVE_REASON).subscribe({
			next: (codes): void => {
				this.codes = codes;
			},
			error: (err: any): void => {}
		});
		this.locationService.locationSubject.subscribe((response) => {
			this.locations = response.map((location) => location.name);
		});
		this.locationService.findAll();
		this.applyFilter(null);
	}

	applyFilter(event: Event | null) {
		let filterValue;
		if (event) {
			filterValue = (event.target as HTMLInputElement).value;
		} else {
			filterValue = this.filterValue;
		}
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.filterPredicate = (data, filter) => {
			const installDate = data.installDate ? new Date(data.installDate) : null;
			const estimateDate = data.estimateDate ? new Date(data.estimateDate) : null;
			// Convert estimateDate/installDate to month name to compare it to the filter value.
			const estimateDateString = estimateDate ? format(estimateDate, 'MMMM').toLowerCase() : null;
			const installDateString = installDate ? format(installDate, 'MMMM').toLowerCase() : null;
			//Convert installDate to MM/DD/YYYY to compare it to the filter value.
			const installNumericDate = installDate ? format(installDate, 'MM/dd/yyyy').toString() : null;
			// Check if either "install date", "estimate date", "name", or "status" matches the filter value
			return (
				(!estimateDate && installDateString && installDateString.includes(filter)) ||
				(!installDate && estimateDateString && estimateDateString.includes(filter)) ||
				(installDate && estimateDate && installDateString && installDateString.includes(filter)) ||
				(installDate && installNumericDate && installNumericDate.includes(filter)) ||
				data.workflowStatus.description?.toLowerCase().includes(filter) ||
				data.name?.toLowerCase().includes(filter) ||
				data.clientId?.toLowerCase().includes(filter) ||
				data.city?.toLowerCase().includes(filter) ||
				data.state?.toLowerCase().includes(filter) ||
				data.postal?.includes(filter) ||
				(!installDate && !estimateDate && 'unknown'.includes(filter)) ||
				data.members.filter((member) => member.memberName?.toLowerCase().includes(filter)).length > 0 ||
				data.members.filter((member) => member.memberName && this.getInitials(member.memberName).toLowerCase().includes(filter)).length >
					0 ||
				data.orderNo?.includes(filter) ||
				data.location?.toLowerCase().includes(filter)
			);
		};
		this.cdRef.detectChanges();
		this.localStorageService.setItem('filterValue', filterValue.trim().toLowerCase());
	}

	setActiveFilter(status: Status) {
		if (this.activeFilter?.description === status.description) {
			this.activeFilter = null;
			this.dataSource.data = this.projectPreviews;
		} else {
			this.activeFilter = status;
		}
		this.localStorageService.setObject('activeFilter', this.activeFilter);
		this.changeSorts();
	}

	clearActiveFilter() {
		this.activeFilter = null;
		this.dataSource.data = this.projectPreviews;
		this.localStorageService.setObject('activeFilter', this.activeFilter);
		this.changeSorts();
	}

	selectLocation() {
		this.localStorageService.setObject('locationFilter', this.locationFilter);
		this.filterTable(true);
	}

	filterTable(locationChange: boolean = false): void {
		const locationFilter = this.locationFilter.length > 0;
		if (this.activeFilter) {
			this.dataSource.data = this.projectPreviews.filter((project) => {
				return (
					project.workflowStatus.subParentId === this.activeFilter?.id &&
					(!locationFilter || (!!project.location && this.locationFilter.indexOf(project.location) > -1))
				);
			});
		} else if (locationFilter || locationChange) {
			this.dataSource.data = this.projectPreviews.filter((project) => {
				return !locationFilter || (!!project.location && this.locationFilter.indexOf(project.location) > -1);
			});
		}
	}

	changeSorts() {
		const sort = this.dataSource.sort as MatMultiSort;
		let status = 'default';
		if (this.activeFilter) {
			status = this.activeFilter.description;
		}
		if (this.statusSorts[status]) {
			if (this.statusSorts[status].actives.length) {
				sort.active = this.statusSorts[status].actives[0];
				sort.actives = this.statusSorts[status].actives;
			}
			if (this.statusSorts[status].directions.length) {
				sort.direction = this.statusSorts[status].directions[0];
				sort.directions = this.statusSorts[status].directions;
			}
		} else {
			this.statusSorts[status] = {actives: [], directions: []};
			sort.active = '';
			sort.actives = [];
			sort.direction = '';
			sort.directions = [];
		}
		sort.sortables.forEach((col) => {
			const header = col as MatMultiSortHeaderComponent;
			if (this.statusSorts[status].actives.includes(header.id)) {
				header._setAnimationTransitionState({fromState: header._arrowDirection, toState: 'active'});
				header._updateArrowDirection();
			} else {
				header._setAnimationTransitionState({fromState: header._arrowDirection, toState: ''});
				header._updateArrowDirection();
			}
		});
		setTimeout(() => {
			sort.sortChange.emit();
		}, 0);
	}

	countStageQuantities() {
		this.statuses.forEach((status: Status) => (status.projectCount = 0));
		for (let project of this.projectPreviews) {
			let stage = this.statuses.find((status: Status) => {
				return status.id === project.workflowStatus.subParentId;
			});
			if (stage) {
				stage.projectCount!++;
			}
		}
		this.nonZeroStatuses = this.statuses.filter((status) => status.projectCount !== 0);
	}

	getStage(parentId: number): string {
		const stage = this.statuses.find((status: Status) => status.id === parentId);
		if (stage) {
			return stage.description;
		} else {
			return 'unknown';
		}
	}

	getWidth(status: Status, index: number): string {
		if (index === this.nonZeroStatuses.length - 1) {
			const width = Math.ceil((status.projectCount! / this.projectPreviews.length) * 100) - 1;
			return width.toString() + '%';
		} else {
			const width = Math.ceil((status.projectCount! / this.projectPreviews.length) * 100);
			return width.toString() + '%';
		}
	}

	getEstimateText(date: Date): string {
		if (!date) {
			return 'Unknown';
		} else {
			const dateConverted = new Date(date);
			return (+format(dateConverted, 'd') <= 15 ? 'Beginning of ' : 'End of ') + format(dateConverted, 'MMMM');
		}
	}

	activeInactiveToggle() {
		this.activeInactiveBoolean = !this.activeInactiveBoolean;
		this.getProjects();
		this.localStorageService.setBool('activeInactiveBoolean', this.activeInactiveBoolean);
	}

	getProjects() {
		this.busy = true;
		if (this.activeInactiveBoolean) {
			this.projectsService.findAllActive();
		} else {
			this.projectsService.findAllInactive();
		}
	}

	getColor(name: string): string {
		return this.userService.getUserColor(name);
	}

	getInitials(name: string): string {
		return name
			.split(' ')
			.map((n) => n[0])
			.join('')
			.toUpperCase();
	}

	ngOnDestroy() {
		this.statusSubscription.unsubscribe();
	}
}
