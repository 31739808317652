import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
	message: string;

	constructor(private dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: {message: string}) {
		this.message = data.message;
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
