<div class="m-4 flex flex-col w-full justify-between" [formGroup]="projectTaskForm" *ngIf="projectTaskForm">
	<div class="flex justify-between">
		<div class="flex">
			<div class="my-4 h-6" *ngIf="orderNoReadonly && hasOrder">
				<span class="font-medium mr-2">Order Number:</span>
				<span>{{ projectTaskForm.controls.shipmentOrderNo.value }}</span>
			</div>
			<mat-form-field class="h-14" appearance="outline" *ngIf="!orderNoReadonly || !hasOrder" subscriptSizing="dynamic">
				<mat-label [ngStyle]="{color: orderNoReadonly ? 'gray' : 'black'}">Order Number</mat-label>
				<input
					[ngStyle]="{color: orderNoReadonly ? 'gray' : 'black'}"
					matInput
					type="text"
					formControlName="shipmentOrderNo"
					[matAutocomplete]="auto"
				/>
			</mat-form-field>
			<button *ngIf="!busy && hasOrder" mat-icon-button color="none" (click)="toggleOrderNoReadOnly()">
				<mat-icon *ngIf="orderNoReadonly">edit</mat-icon>
				<mat-icon *ngIf="orderNoReadonly === false">close</mat-icon>
			</button>

			<mat-autocomplete #auto="matAutocomplete">
				<mat-option *ngFor="let data of filteredSalesOrderList | async" [value]="data.id" (click)="getOrderDetails(data)">
					<div *ngIf="salesOrderList.length">
						<span class="font-medium">{{ data.id }}</span>
					</div>
				</mat-option>
				<mat-option *ngIf="!salesOrderList.length" id="spinner-option">
					<mat-spinner class="scale-50"></mat-spinner>
				</mat-option>
			</mat-autocomplete>
		</div>
		<div class="flex">
			<app-install-ship-date [formGroup]="projectTaskForm" [navShipDate]="navShipDate"></app-install-ship-date>
		</div>
		<div class="flex flex-col justify-start">
			<div class="flex justify-end">
				<mat-spinner *ngIf="busy" class="spinner m-2" diameter="20"></mat-spinner>
				<button mat-icon-button class="mr-2" color="none" (click)="refreshEstimate()" [disabled]="busy">
					<mat-icon>refresh</mat-icon>
				</button>
				<button
					mat-raised-button
					color="primary"
					(click)="createOrUpdateOrder(); updateTasks()"
					[disabled]="busy || projectTaskForm.invalid"
				>
					Save
				</button>
			</div>
			<div class="flex justify-end mat-mdc-form-field-error flex-nowrap">
				<ng-container *ngIf="initWarn">
					<mat-icon class="mr-2">warning</mat-icon><span class="align-top leading-8">Hours have not been saved.</span>
				</ng-container>
				<ng-container *ngIf="warnChanges()">
					<mat-icon class="mr-2">warning</mat-icon><span class="align-top leading-8">Changes have not been saved.</span>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="p-2 flex justify-center flex-1">
		<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
			<mat-label>Search</mat-label>
			<input
				id="user-searchbar"
				type="text"
				(keyup)="applyFilter()"
				placeholder="What are you looking for?"
				matInput
				#searchInput
				formControlName="filter"
			/>
		</mat-form-field>
		<div class="flex text-sm font-medium">
			<mat-checkbox class="flex" formControlName="showPickList" (change)="applyFilter()"
				>Picklist <mat-icon class="text-sm max-h-4">fact_check</mat-icon></mat-checkbox
			>
		</div>
	</div>
	<div class="flex flex-col self-start rounded-lg my-4">
		<mat-button-toggle-group class="h-full" formControlName="taskTypeId" (change)="applyFilter()">
			<mat-button-toggle [value]="zero" class="flex flex-row">
				<div class="flex text-sm font-medium">All</div>
				<div class="flex text-xs font-medium">({{ totalLines(0) }})</div>
				<div class="flex text-xs font-medium">
					{{ totalValue | currency }}
				</div>
			</mat-button-toggle>
			<ng-container formArrayName="projectTasks">
				<mat-button-toggle
					class="flex flex-row"
					[value]="task.get('taskTypeId')?.value"
					[ngClass]="selectedTaskDescription(i)"
					*ngFor="let task of projectTasks.controls; let i = index"
					formGroupName="{{ i }}"
				>
					<div class="flex text-sm font-medium">
						<div class="mt-2">{{ taskDescription(i) }}</div>
					</div>
					<div class="flex text-xs font-medium">({{ totalLines(task.get('taskTypeId')?.value) }})</div>
					<mat-form-field subscriptSizing="dynamic" appearance="outline" class="mr-2 my-2 w-24 text-xs" *ngIf="hasMultiplier(task)">
						<mat-label class="text-xs">Value</mat-label>
						<input matInput type="text" formControlName="value" (change)="recalculateHours(i)" />
						<mat-error>Value <strong>required</strong>.</mat-error>
					</mat-form-field>
					<mat-form-field subscriptSizing="dynamic" appearance="outline" class="my-2 w-16 text-xs" *ngIf="hasMultiplier(task)">
						<mat-label class="text-xs">Hours</mat-label>
						<input matInput type="text" formControlName="hours" (change)="changeWarn = true" />
						<mat-error>Hours <strong>required</strong>.</mat-error>
					</mat-form-field>
					<div class="flex text-xs font-medium" *ngIf="!hasMultiplier(task)">
						{{ task.get('value')?.value | currency }}
					</div>
				</mat-button-toggle>
			</ng-container>
		</mat-button-toggle-group>
	</div>
	<div class="w-[75vw] border border-gray-700 border-2 rounded overflow-auto">
		<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!loading">
			<!--Select Column-->
			<ng-container matColumnDef="select">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Selected"></th>
				<td mat-cell *matCellDef="let detail" class="flex">
					<mat-checkbox
						[ngClass]="getTaskIdDescription(detail.taskTypeId)"
						(change)="$event ? toggleRow(detail) : null"
						[checked]="detail.taskTypeId > 0"
						[disabled]="taskTypeId === 0 && (!detail.taskTypeId || detail.taskTypeId < 1)"
					></mat-checkbox>
					<button class="mt-2" (click)="togglePickList(detail)">
						<mat-icon [ngClass]="{'not-picklist': !detail.pickList}">fact_check</mat-icon>
					</button>
					<div class="m-2" *ngIf="detail.locationId > 1 && detail.pickList">{{ detail.locationName }}</div>
					<!--mat-checkbox
						*ngIf="projectLocationId > 1 && detail.pickList"
						(change)="$event ? toggleLocation(detail) : null"
						[checked]="detail.locationId > 1"
						>{{ projectLocationName }}</mat-checkbox
					-->
				</td>
			</ng-container>
			<!--Item Number Column-->
			<ng-container matColumnDef="erpItemRef">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Item Number">Item</th>
				<td mat-cell *matCellDef="let detail">
					{{ detail.erpItemRef }}
				</td>
			</ng-container>
			<!--Description Column-->
			<ng-container matColumnDef="item">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">Description</th>
				<td mat-cell *matCellDef="let detail">
					{{ detail.item ? detail.item : detail.glNumber }}
				</td>
			</ng-container>
			<!--Quantity Column-->
			<ng-container matColumnDef="qty">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Quantity">Qty</th>
				<td mat-cell *matCellDef="let detail">
					<div class="flex flex justify-end">{{ detail.qty }}</div>
				</td>
			</ng-container>
			<!--Unit Price Column-->
			<ng-container matColumnDef="price">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Unit Price">Price</th>
				<td mat-cell *matCellDef="let detail">
					<div class="flex justify-end">{{ detail.price | currency }}</div>
				</td>
			</ng-container>
			<!--Total Column-->
			<ng-container matColumnDef="total">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Total">Total</th>
				<td mat-cell *matCellDef="let detail">
					<div class="flex flex justify-end">{{ detail.qty * detail.price | currency }}</div>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
			<tr mat-row *matRowDef="let detail; let i = dataIndex; columns: columnsToDisplay" class="log-row cursor-pointer"></tr>
			<!-- Row shown when there is no matching data. -->
			<tr class="mat-row flex" *matNoDataRow>
				<td class="mat-cell p-4" colspan="4" *ngIf="!loading">No data matching the filter "{{ searchInput.value }}"</td>
			</tr>
		</table>
		<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="loading">
			<mat-spinner></mat-spinner>
		</div>
	</div>
</div>
