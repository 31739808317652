import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactService} from '../../../core/services/contact.service';
import {Contact} from '../../../core/models/contact.interface';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {ValidatorRegexEnum} from '../../../core/enums/validator-regex.enum';

@Component({
	selector: 'app-create-contact',
	templateUrl: './create-contact.component.html',
	styleUrls: ['./create-contact.component.scss']
})
export class CreateContactComponent {
	form: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private contactService: ContactService,
		public dialogRef: MatDialogRef<CreateContactComponent>,
		private snackbar: MatSnackBar
	) {
		this.form = this.formBuilder.group({
			email: ['', [Validators.required, Validators.pattern(ValidatorRegexEnum.EMAIL), Validators.maxLength(254)]],
			name: ['', [Validators.required, Validators.maxLength(100)]],
			phone: ['', Validators.maxLength(40)],
			phoneExt: ['', Validators.maxLength(10)]
		});
	}

	createContact() {
		this.contactService.create(this.form.getRawValue()).subscribe(
			(response: Contact) => {
				this.dialogRef.close();
				this.snackbar.open('User Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.contactService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
}
