<b id="title" class="ml-8">
	{{ stage.description | titlecase }} Quick Status
	<mat-icon id="edit-button" (click)="openModal()" class="cursor-pointer material-icons-outlined" [ngClass]="{invisible: !project.active}">
		mode_edit_outline
	</mat-icon>
</b>
<div class="stepper-wrapper pt-6">
	<div
		*ngFor="let status of stage.statuses"
		class="stepper-item"
		[ngClass]="[
			project.workflowStatus.status > status.status ? 'completed' : '',
			project.workflowStatus.status === status.status ? 'active' : ''
		]"
	>
		<div class="step-counter">
			<mat-icon class="text-light" *ngIf="project.workflowStatus.status > status.status"> check_outline </mat-icon>
		</div>
		<div class="step-name text-center">{{ status.description }}</div>
	</div>
</div>
