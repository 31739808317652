import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ShippingLogService} from '../../core/services/shipping-log.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Shipment} from '../../core/models/shipment.model';
import {ShippingLog} from '../../core/models/shipping-log.model';
import {Code as ShippingCode} from '../../core/models/shipping-code.model';
import {ShippingCodesService} from '../../core/services/shipping-codes.service';
import {ProjectsService} from '../../core/services/projects.service';
import {Project} from '../../core/models/project.interface';
import {FreightListEntry} from '../../projects/project-freight/project-freight.component';
import {CodeService} from '../../core/services/code.service';
import {CodeTypeEnum} from '../../core/enums/code-type.enum';
import {Code} from '../../core/models/code.interface';

export interface CreateShippingLogDialogData {
	freight: FreightListEntry;
}
export interface CreateShippingLogDialogResponse {
	success: boolean;
}

@Component({
	selector: 'app-create-shipping-log-dialog',
	templateUrl: './create-shipping-log-dialog.component.html',
	styleUrls: ['./create-shipping-log-dialog.component.scss']
})
export class CreateShippingLogDialogComponent implements OnInit {
	timeList: ShippingCode[] = [];
	timeZoneList: ShippingCode[] = [];
	defaultTime: string | undefined = '';
	defaultTimeZone: string | undefined = '';

	shippingLogForm = new FormGroup({
		accountManager: new FormControl('', [Validators.required]),
		orderNo: new FormControl(''),
		jobName: new FormControl(''),
		city: new FormControl(''),
		state: new FormControl(''),
		postal: new FormControl(''),
		budget: new FormControl(0, [Validators.min(1)]),
		cost: new FormControl(0, [Validators.min(1)]),
		shipDate: new FormControl(new Date()),
		originalDeliveryDate: new FormControl(new Date()),
		originalDeliveryTime: new FormControl(''),
		deliveryDate: new FormControl(new Date(), [Validators.required]),
		deliveryTime: new FormControl('', [Validators.required]),
		bookingId: new FormControl(0),
		brokerId: new FormControl(0),
		brokerName: new FormControl(''),
		carrierName: new FormControl(''),
		proNo: new FormControl(''),
		invoiceDate: new FormControl(new Date()),
		additionalCost: new FormControl(0),
		additionalCostReason: new FormControl('')
	});

	deliveryTime: string = '';

	codes: Code[] = [];

	loading = false;
	busy = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: CreateShippingLogDialogData,
		private dialogRef: MatDialogRef<CreateShippingLogDialogComponent>,
		private shippingLogService: ShippingLogService,
		private shippingCodeService: ShippingCodesService,
		private projectsService: ProjectsService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar,
		private codeService: CodeService
	) {
		dialogRef.disableClose = true;
	}

	ngOnInit(): void {
		this.timeList = this.shippingCodeService.getCodes('TIME');
		this.timeZoneList = this.shippingCodeService.getCodes('TIMEZONE');
		this.defaultTime = this.shippingCodeService.getDefault('TIME');
		this.defaultTimeZone = this.shippingCodeService.getDefault('TIMEZONE');

		if (this.data.freight.shipments.length) {
			const shipment = this.data.freight.shipments[0];
			if (shipment.projectId) {
				this.busy = true;
				this.loading = true;
				this.projectsService.findOne(shipment.projectId).subscribe({
					next: (project) => {
						this.setFormData(shipment, project);
						this.busy = false;
						this.loading = false;
					},
					error: (err) => {
						this._snackBar.open('Get Project failed: ' + err.error.message);
						this.setFormData(shipment);
						this.busy = false;
						this.loading = false;
					}
				});
			} else {
				this.setFormData(shipment);
			}
		}
		this.codeService.findByType(CodeTypeEnum.SHIPPING_ADDITIONAL_COST_REASON).subscribe({
			next: (codes): void => {
				this.codes = codes;
			},
			error: (err: any): void => {}
		});
	}

	setFormData(shipment: Shipment, project: Project | null = null) {
		if (shipment.shipmentOrderNo) {
			this.shippingLogForm.controls.orderNo.setValue(shipment.shipmentOrderNo);
		}
		if (project) {
			this.shippingLogForm.controls.jobName.setValue(project.name);
		} else if (shipment.description) {
			this.shippingLogForm.controls.jobName.setValue(shipment.description);
		}
		if (this.data.freight.deliveryCity) {
			this.shippingLogForm.controls.city.setValue(this.data.freight.deliveryCity);
		}
		if (this.data.freight.deliveryState) {
			this.shippingLogForm.controls.state.setValue(this.data.freight.deliveryState);
		}
		if (this.data.freight.deliveryPostal) {
			this.shippingLogForm.controls.postal.setValue(this.data.freight.deliveryPostal);
		}
		this.shippingLogForm.controls.budget.setValue(null);
		if (this.data.freight.bookings?.length) {
			const booking = this.data.freight.bookings[0];
			if (booking.amount) {
				this.shippingLogForm.controls.cost.setValue(booking.amount);
			}
			if (booking.pickupDatetime) {
				this.shippingLogForm.controls.shipDate.setValue(booking.pickupDatetime);
			}
			if (booking.freightBooking?.id) {
				this.shippingLogForm.controls.bookingId.setValue(booking.freightBooking.id);
			}
			if (booking.brokerId) {
				this.shippingLogForm.controls.brokerId.setValue(booking.brokerId);
			}
			if (booking.brokerName) {
				this.shippingLogForm.controls.brokerName.setValue(booking.brokerName);
			}
			if (booking.carrierName) {
				this.shippingLogForm.controls.carrierName.setValue(booking.carrierName);
			}
			if (booking.freightBooking?.proNo) {
				this.shippingLogForm.controls.proNo.setValue(booking.freightBooking.proNo);
			} else if (booking.freightBooking?.puOrderNo) {
				this.shippingLogForm.controls.proNo.setValue(booking.freightBooking.puOrderNo);
			}
		}
		if (shipment.deliveryDate) {
			this.shippingLogForm.controls.originalDeliveryDate.setValue(shipment.deliveryDate);
			this.shippingLogForm.controls.deliveryDate.setValue(shipment.deliveryDate);
		}
		if (shipment.deliveryTime) {
			const deliveryTime = this.timeList.find((time) => time.value === shipment.deliveryTime);
			if (deliveryTime && deliveryTime.description) {
				this.deliveryTime = deliveryTime.description;
			}
			this.shippingLogForm.controls.originalDeliveryTime.setValue(shipment.deliveryTime);
			this.shippingLogForm.controls.deliveryTime.setValue(shipment.deliveryTime);
		}
		this.shippingLogForm.controls.invoiceDate.setValue(null);
	}

	saveShippingLog() {
		let shippingLog: ShippingLog = {};
		Object.assign(shippingLog, this.shippingLogForm.value);
		const shipmentIds = this.data.freight.shipments.filter((shipment) => shipment.id).map((shipment) => shipment.id) as number[];
		if (shipmentIds.length) {
			this.busy = true;
			this.shippingLogService.create(shippingLog, shipmentIds).subscribe({
				next: (shippingLog) => {
					const response: CreateShippingLogDialogResponse = {
						success: true
					};
					this.dialogRef.close(response);
				},
				error: (err) => {
					this._snackBar.open('Save Shipping Log failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}
}
