<div [formGroup]="contactForm" class="rounded flex flex-col p-4">
	<span class="mt-2 mb-4 text-lg"> {{ editMode ? 'Updated Contact Info' : 'New Contact Info' }} </span>

	<div class="flex flex-row space-x-2">
		<mat-form-field>
			<mat-label> First Name </mat-label>
			<input matInput formControlName="firstName" />
			<mat-error *ngIf="contactForm.get('firstName')?.touched && contactForm.get('firstName')?.hasError('required')">
				This is a required field
			</mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label> Last Name </mat-label>
			<input matInput formControlName="lastName" />
		</mat-form-field>
	</div>

	<div class="flex flex-row space-x-2">
		<mat-form-field>
			<mat-label> Phone </mat-label>
			<input matInput formControlName="phone" />
			<mat-error *ngIf="contactForm.get('phone')?.hasError('pattern')"> Please enter valid number </mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-label> Ext. </mat-label>
			<input matInput formControlName="phoneExt" />
		</mat-form-field>
	</div>

	<mat-form-field>
		<mat-label> Email </mat-label>
		<input matInput formControlName="email" />
		<mat-error *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.hasError('required')">
			This is a required field
		</mat-error>
		<mat-error *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.hasError('pattern')">
			Please enter a valid email address
		</mat-error>
	</mat-form-field>

	<div class="flex flex-row self-end space-x-2 mt-2">
		<button mat-flat-button (click)="close()">CANCEL</button>
		<button mat-flat-button color="primary" *ngIf="!editMode" (click)="createContact()">CREATE CONTACT</button>
		<button mat-flat-button color="primary" *ngIf="editMode" (click)="updateContact()">UPDATE CONTACT</button>
	</div>
</div>
