<div class="mt-3 ml-4">
	<mat-card-title>Create Broker</mat-card-title>
</div>

<mat-dialog-content class="w-full" [formGroup]="form">
	<mat-form-field class="w-full">
		<mat-label>Name</mat-label>
		<input matInput formControlName="name" />
		<mat-error *ngIf="form.get('name')?.hasError('required')"> Name is required </mat-error>
		<mat-error *ngIf="form.get('name')?.hasError('maxlength')">
			{{ this.form.get('name')?.value.length }} / 100 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>
	<div class="flex flex-row space-x-2">
		<mat-form-field>
			<mat-label>Email</mat-label>
			<input matInput formControlName="email" />
			<mat-error *ngIf="form.get('email')?.hasError('pattern')"> Please enter a valid email address </mat-error>
			<mat-error *ngIf="form.get('email')?.hasError('maxlength')">
				{{ this.form.get('email')?.value.length }} / 254 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
		<mat-form-field>
			<mat-label>SCAC</mat-label>
			<input matInput formControlName="scac" />
			<mat-error *ngIf="form.get('scac')?.hasError('maxlength')">
				{{ this.form.get('scac')?.value.length }} / 10 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
	</div>
	<div class="flex flex-row space-x-2">
		<mat-form-field>
			<mat-label>Phone No.</mat-label>
			<input matInput formControlName="phone" />
			<mat-error *ngIf="form.get('phone')?.hasError('maxlength')">
				{{ this.form.get('phone')?.value.length }} / 40 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Ext</mat-label>
			<input matInput formControlName="phoneExt" />
			<mat-error *ngIf="form.get('phoneExt')?.hasError('maxlength')">
				{{ this.form.get('phoneExt')?.value.length }} / 10 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
	</div>
</mat-dialog-content>
<div class="flex justify-end px-4 pb-4">
	<button mat-flat-button mat-dialog-close="true" class="mr-2">Cancel</button>
	<button *ngIf="!editMode" [disabled]="form.invalid" mat-flat-button color="primary" (click)="createBroker()">Create</button>
	<button *ngIf="editMode" [disabled]="form.invalid" mat-flat-button color="primary" (click)="updateBroker()">Update</button>
</div>
