import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PriceConfig} from '../../../core/models/price-config.interface';
import {PriceConfigType} from '../../../core/models/price-config-types.interface';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PriceConfigurationDialogComponent} from '../price-configuration-dialog/price-configuration-dialog.component';
import {PriceConfigurationService} from "../../../core/services/price-configuration.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
	selector: 'app-price-configuration-detail',
	templateUrl: './price-configuration-detail.component.html',
	styleUrls: ['./price-configuration-detail.component.scss']
})
export class PriceConfigurationDetailComponent {
	@Input() expanded: boolean;
	@Input() priceConfigType: PriceConfigType;
  @Input() priceConfigTypes: PriceConfigType[];

  @Output() refreshPartsEvent: EventEmitter<void> = new EventEmitter<void>();
	loading: boolean = true;

	constructor(private dialog: MatDialog,
              private priceConfigService: PriceConfigurationService,
              private snackbar: MatSnackBar) {}

	openPriceConfigDialog(editMode: boolean, priceConfig?: PriceConfig): void {
		const dialogRef: MatDialogRef<PriceConfigurationDialogComponent> = this.dialog.open(PriceConfigurationDialogComponent, {
			data: {
				editMode: editMode,
        priceConfig: priceConfig,
        priceConfigTypes: this.priceConfigTypes
			}
		});

    dialogRef.afterClosed().subscribe((result: boolean): void => {
      if (result) {
        this.refreshPartsEvent.emit();
      }
    });
	}

  deletePriceConfig(id: number): void {
    this.priceConfigService.remove(id).subscribe({
      next: (): void => {
        this.snackbar.open('Successfully deleted price configuration');
        this.refreshPartsEvent.emit();
      },
      error: (err): void => {
        console.error(err);
        this.snackbar.open('Failed to delete price configuration')
      }
    });
  }
}
