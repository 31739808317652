<div class="flex flex-col" *ngIf="contactForm" [formGroup]="contactForm">
	<div class="flex flex-row" *ngIf="!readOnly && !disableSelection">
		<mat-form-field appearance="outline" class="flex-1">
			<mat-label>Contact</mat-label>
			<mat-select [value]="selectedContact" (selectionChange)="selectContact($event)" [disabled]="contacts.length < 1">
				<mat-option></mat-option>
				<mat-option [value]="contact" *ngFor="let contact of contacts">{{ contact.name }}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-spinner *ngIf="busy" class="spinner m-2" diameter="20"></mat-spinner>
		<button mat-icon-button *ngIf="canCreate && !selectedContact.id" [disabled]="busy || createDisabled" (click)="createContact()">
			<mat-icon>add_circle</mat-icon>
		</button>
		<button mat-icon-button *ngIf="canModify && selectedContact.id" [disabled]="busy || modifyDisabled" (click)="modifyContact()">
			<mat-icon>save</mat-icon>
		</button>
		<button mat-icon-button *ngIf="canModify && selectedContact.id" [disabled]="busy || modifyDisabled" (click)="revertContact()">
			<mat-icon>restore</mat-icon>
		</button>
	</div>

	<div class="flex flex-row">
		<mat-form-field appearance="outline" class="w-1/2 mr-2">
			<mat-label>Contact Name</mat-label>
			<input matInput type="text" [formControlName]="contactName" [readonly]="readOnly" />
			<mat-error>Name <strong>required</strong>.</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-1/2">
			<mat-label>Email Address</mat-label>
			<input matInput type="text" [formControlName]="contactEmail" [readonly]="readOnly" />
			<mat-error>Email <strong>not valid</strong>.</mat-error>
		</mat-form-field>
	</div>

	<div class="flex flex-row">
		<mat-form-field appearance="outline" class="w-1/2 mr-2">
			<mat-label>Phone Number</mat-label>
			<input matInput type="text" [formControlName]="contactPhone" [readonly]="readOnly" />
			<mat-error *ngIf="contactForm.controls[contactPhone].invalid && !contactForm.controls[contactPhone].hasError('required')">
				Phone Number <strong>not valid</strong>.
			</mat-error>
			<mat-error *ngIf="contactForm.controls[contactPhone].hasError('required')"> Phone Number <strong>required</strong>. </mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="w-1/3">
			<mat-label>Extension</mat-label>
			<input matInput type="text" [formControlName]="contactPhoneExt" [readonly]="readOnly" />
		</mat-form-field>
	</div>
</div>
