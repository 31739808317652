<div class="ml-2 w-full flex flex-col">
  <div class="flex">
    <button mat-button (click)="backToQuoteList()" color="primary" class="ml-4 mt-2 mb-2">BACK TO QUOTE LIST</button>
  </div>

  <span class="text-xl ml-4 mb-2 self-center"> Quote Email History </span>
  <div class="w-full border-gray-700 border-2 rounded overflow-auto m-auto ml-2">
		<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingEmails">
			<!-- Name Column -->
			<ng-container matColumnDef="issuedName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Recipient</th>
				<td mat-cell *matCellDef="let magicLink">
					{{ magicLink.issuedName === '' ? 'Unavailable' : magicLink.issuedName }}
				</td>
			</ng-container>

			<!-- Email Column -->
			<ng-container matColumnDef="emailTo">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email">Email</th>
				<td mat-cell *matCellDef="let magicLink">
					{{ magicLink.emailTo }}
				</td>
			</ng-container>

			<!-- Initially Viewed Column -->
			<ng-container matColumnDef="formattedInitialViewedDateTime">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Initially Viewed</th>
				<td mat-cell *matCellDef="let magicLink">
					{{ magicLink.formattedInitialViewedDateTime ?? 'Unavailable' }}
				</td>
			</ng-container>

			<!-- Last Viewed Column -->
			<ng-container matColumnDef="formattedLastViewedDateTime">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last viewed">Last Viewed</th>
				<td mat-cell *matCellDef="let magicLink">
					{{ magicLink.formattedLastViewedDateTime ?? 'Unavailable' }}
				</td>
			</ng-container>

			<!-- Last Action Column -->
			<ng-container matColumnDef="lastAction">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last action">Last Action</th>
				<td mat-cell *matCellDef="let magicLink">
					{{ magicLink.lastAction === '' ? 'None' : magicLink.lastAction }}
				</td>
			</ng-container>

			<!-- Status Column -->
			<ng-container matColumnDef="tokenStatus">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
				<td mat-cell *matCellDef="let magicLink">
					{{ magicLink.tokenStatus }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
			<tr mat-row *matRowDef="let quote; let i = dataIndex; columns: columnsToDisplay" class="magic-row"></tr>

			<!-- Row shown when there is no matching data. -->
			<tr class="mat-row flex" *matNoDataRow>
				<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingEmails">No data available</td>
			</tr>
		</table>
		<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingEmails">
			<mat-spinner></mat-spinner>
		</div>
	</div>
</div>
