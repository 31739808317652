<mat-card class="p-4">
  <div class="flex justify-between">
    <mat-card-title>
    Change Request Info
    </mat-card-title>
    <mat-icon (click)="closeModal()" class="hover:cursor-pointer">close</mat-icon>
  </div>


  <div class="flex flex-col space-y-1 mx-2 my-2">
    <span><strong>Requested by:</strong> </span>
    <span>{{changeRequestEmail}}</span>
    <span><strong>Reason:</strong> </span>
    <span>{{changeRequestReason}}</span>
    <span><strong>Description:</strong> </span>
    <span>{{changeRequestDescription}}</span>
  </div>

  <div class="flex justify-end">
    <button mat-flat-button (click)="closeModal()" color="primary">OK</button>
  </div>

</mat-card>
