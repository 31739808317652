<div *ngIf="loading || clientLoading" class="w-[100vw] h-[100vh]">
	<div class="mt-4 ml-4">
		<mat-spinner></mat-spinner>
	</div>
</div>
<div class="flex justify-between">
	<button mat-button (click)="backToQuoteList()" color="primary" class="ml-4 mt-2 mb-0">BACK TO QUOTE LIST</button>
	<button mat-button *ngIf="quote?.changeReqReason" (click)="openChangeRequestDialog()" color="warn" class="mb-0 mt-2 mr-32">
		VIEW CHANGE REQUEST INFO
	</button>
	<label class="my-auto mr-32 text-base" *ngIf="quote?.status === QuoteStatusEnum.SALES_ORDER && quote?.salesOrder"
		>Sales Order: {{ quote.salesOrder }}</label
	>
	<div matTooltip="Please save before printing" [matTooltipDisabled]="!quoteForm.dirty && !saving">
		<button
			mat-raised-button
			color="primary"
			class="mt-2 mr-4"
			*ngIf="quote && !createOrderMode"
			[disabled]="quoteForm.dirty || saving"
			(click)="printQuotePdf()"
		>
			<span>Print</span>
			<mat-icon *ngIf="buildingPDF">
				<mat-spinner color="accent" diameter="18"></mat-spinner>
			</mat-icon>
			<mat-icon *ngIf="!buildingPDF"> print </mat-icon>
		</button>
	</div>
</div>

<form [formGroup]="quoteForm" (ngSubmit)="requestApproval()" #ngForm="ngForm">
	<div *ngIf="quote" class="flex 2xl:flex-row flex-col w-full">
		<div class="flex flex-col 2xl:w-5/12 w-[720px] py-4 pl-4 space-y-2">
			<app-approval-info
				*ngIf="createOrderMode"
				[parentForm]="quoteForm"
				[approvals]="this.quote.approvals ?? []"
				[quoteId]="quote.id!"
				[salesOrderForm]="salesOrderForm"
				[quote]="quote"
			></app-approval-info>
			<div class="flex flex-col space-y-2 {{ !createOrderMode || editMode ? '' : 'hidden' }}">
				<app-manual-part-entry
					*ngIf="calculated"
					[ngForm]="ngForm"
					[submitted]="submitted"
					[partsLoading]="(partsLoading | async) ?? false"
					(showPartListEvent)="showFullPartList = $event"
					(reloadParts)="reloadParts()"
					[quoteForm]="quoteForm"
					[materialCostTotal]="materialCostTotal"
				></app-manual-part-entry>
				<div class="flex flex-col mt-4">
					<mat-accordion>
						<mat-expansion-panel expanded="false" class="w-full">
							<mat-expansion-panel-header>
								<mat-panel-title> Rack Solid</mat-panel-title>
								<mat-label class="mr-4" *ngIf="totalUnits">Units: {{ totalUnits }}</mat-label>
								<mat-label class="mr-4">{{ rackSolidItemTotal | currency }}</mat-label>
							</mat-expansion-panel-header>
							<div class="flex flex-row items-center justify-between">
								<mat-slide-toggle
									class="w-2/5"
									color="primary"
									(toggleChange)="toggleShowPartsList()"
									[checked]="showFullPartList"
									[disabled]="submitted"
									>Show items in quote
								</mat-slide-toggle>
								<div class="w-2/5 text-right" *ngIf="!submitted">
									<span class="text-blue-600 cursor-pointer" (click)="goToRackSolid()"
										>{{ quote.salesOrder ? 'View' : 'Configure' }} Rack Solid</span
									>
								</div>
							</div>
							<app-part-list
								*ngIf="!loading && quote"
								[quote]="quote"
								[partsListUpdatedSubject]="partsListChangedSubject"
								[useReadOnlyView]="true"
								[manualPartForm]="manualPartForm"
							></app-part-list>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<app-misc-materials [quoteForm]="quoteForm" (weightChangedEvent)="calculateManuallyAddedPartWeightAndCost()"></app-misc-materials>
				<app-freight-estimator
					*ngIf="this.quote"
					[quote]="quote"
					[submitted]="submitted"
					[ngForm]="ngForm"
					[thirdPartyFreight]="project.thirdPartyFreight || hasThirdPartyFreightCost"
					[quoteForm]="quoteForm"
					(thirdPartyFreightSelected)="addThirdPartyFreightAssociatedCost($event)"
				></app-freight-estimator>
				<app-freight-install
					*ngIf="calculated"
					[submitted]="submitted"
					[itemTotal]="this.rackSolidItemTotal + this.manuallyAddedPartTotal + this.shelvesTotal"
					[itemTotalSubject]="itemTotalSubject"
					[quoteForm]="quoteForm"
					[installCheckedOnProject]="project.includeInstall"
				></app-freight-install>
				<app-associated-costs
					class="mt-4"
					(totalCostChanged)="this.associatedCostTotal = $event"
					[quoteForm]="quoteForm"
					[ngForm]="ngForm"
				></app-associated-costs>
				<app-cost-adjustments
					[quoteForm]="quoteForm"
					[costAdjustments]="costAdjustments"
					[ngForm]="ngForm"
					[(costAdjustmentTotal)]="costAdjustmentTotal"
				>
				</app-cost-adjustments>
				<app-additional-info [parentForm]="quoteForm" [ngForm]="ngForm" [totalCostOfMaterial]="totalCostOfMaterial"></app-additional-info>
			</div>
		</div>

		<div class="flex flex-col py-4 pr-4 w-[720px] min-w-[720px] max-w-[850px] 2xl:w-1/2 ml-4">
			<div class="font-selawik mat-elevation-z4" *ngIf="this.quoteForm">
				<div class="p-8 self-center">
					<div class="flex flex-row space-between w-full">
						<div class="flex flex-col w-1/2">
							<img ngSrc="./assets/mmi-logo.jpg" alt="MMI Logo" class="w-3/4" height="272" width="758" />
							<span class="ml-8">45 Turner Drive Suite 2A Middletown, NY 10941</span>
							<span class="ml-8">PH: (800) 784-8080 FX:</span>
						</div>
						<div class="flex flex-col font-bold w-1/2 mt-4">
							<span class="text-xl place-self-end"
								>SALES QUOTE
								<span class="font-normal" *ngIf="quote.quoteNumber || created">{{ quoteNumber }}</span>
							</span>
							<span class="place-self-end"
								>Sales Quote Date: <span class="font-normal">{{ this.quote.date ?? this.quoteDefaults.quoteDate | date }}</span></span
							>
						</div>
					</div>

					<div class="flex flex-row justify-around my-8">
						<div class="flex flex-row space-x-2">
							<span class="font-bold">Sell To: </span>
							<div class="flex flex-col" *ngIf="client">
								<span>{{ client.name }}</span>
								<span>{{ client.address1 }}</span>
								<span>{{ client.city }} , {{ client.state }} {{ client.postal }}</span>
							</div>
						</div>

						<div class="flex flex-row space-x-2 {{ hasAddressFields(ngForm) ? 'text-red' : '' }}">
							<span class="font-bold">Ship To: {{ hasAddressFields(ngForm) ? '*' : '' }}</span>
							<div class="flex flex-col">
								<span>{{ project.name }}</span>
								<span>{{ this.quote.siteAddress1 }}</span>
								<span *ngIf="this.quote.siteAddress2">{{ this.quote.siteAddress2 }}</span>
								<span>
									{{ this.quote.siteCity }},
									{{ this.quote.siteState }}
									{{ this.quote.siteZip }}</span
								>
							</div>
						</div>
					</div>

					<div class="flex flex-row justify-between mb-2">
						<span class="self-end font-bold"
							>Terms:
							<span class="font-normal">{{
								quoteForm.get('paymentTerms')?.value ? quoteForm.get('paymentTerms')?.value : quoteDefaults.quoteTerms + ' days'
							}}</span></span
						>
						<span class="self-end font-bold"
							>Project Weight: <span class="font-normal">{{ mobileItemsGrossWeight | number : '1.2-2' }} lbs</span></span
						>
						<div class="flex flex-row space-x-2">
							<div class="flex flex-col justify-end font-bold">
								<span
									>Sales Person: <span class="font-normal">{{ quoteForm.get('salesPerson')?.value }}</span></span
								>
								<span
									>Quoter: <span class="font-normal">{{ quoteForm.get('quoter')?.value }}</span></span
								>
								<span
									>Account Manager: <span class="font-normal">{{ quoteForm.get('accountManager')?.value }}</span></span
								>
							</div>
							<div class="flex flex-col justify-end"></div>
						</div>
					</div>

					<div class="flex flex-row justify-between font-bold my-2 py-2 border-y-2 border-black">
						<span class="w-1/3">Item No.</span>
						<span class="w-1/2">Description</span>
						<span class="w-1/12">Unit</span>
						<span class="w-1/12">Quantity</span>
						<span class="w-1/6 text-end">Unit Price</span>
						<span class="w-1/6 text-end">Total Price</span>
					</div>

					<div class="flex flex-col my-2">
						<!--Mobile Manually Added Parts-->
						<div class="flex flex-row justify-between space-x-2" *ngFor="let item of getManualParts(true)">
							<span class="w-1/3">{{ item.erpItemRef }}</span>
							<span class="w-1/2">{{ item.description }}</span>
							<span class="w-1/12">{{ item.unitOfMeasure }}</span>
							<span class="w-1/12 text-end">{{ item.qty }}</span>
							<span class="w-1/6 text-end">{{ item.price ?? 0 | currency }}</span>
							<span class="w-1/6 text-end">{{ (item.price ?? 0) * (item.qty ?? 0) | currency }}</span>
						</div>

						<!--Material Cost Surcharge Line Item-->
						<div
							class="flex flex-row justify-between space-x-2"
							*ngIf="quoteForm.get('materialCost')?.value['price'] && quoteForm.get('materialCost')?.value['price'] !== '0.00'"
						>
							<span class="w-1/3"></span>

							<span class="w-1/2">{{ quoteForm.get('materialCost')?.value['description'] }}</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('materialCost')?.value) | currency }}</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('materialCost')?.value) | currency }}</span>
						</div>

						<!--Non-mobile manually added parts-->
						<div class="flex flex-row justify-between space-x-2" *ngFor="let item of getManualParts(false)">
							<span class="w-1/3">{{ item.erpItemRef }}</span>
							<span class="w-1/2">{{ item.description }}</span>
							<span class="w-1/12">{{ item.unitOfMeasure }}</span>
							<span class="w-1/12 text-end">{{ item.qty }}</span>
							<span class="w-1/6 text-end">{{ item.price ?? 0 | currency }}</span>
							<span class="w-1/6 text-end">{{ (item.price ?? 0) * (item.qty ?? 0) | currency }}</span>
						</div>

						<!--Rack Solid Part List-->
						<ng-container *ngIf="showFullPartList">
							<div class="flex flex-row justify-between space-x-2" *ngFor="let item of sortedRackSolidParts">
								<span class="w-1/3">{{ item.description }}</span>
								<span class="w-1/2">{{ item.partName }}</span>
								<span class="w-1/12">{{ item.unit }}</span>
								<span class="w-1/12 text-end">{{ item.qty }}</span>
								<span class="w-1/6 text-end">{{ item.price ?? 0 | currency }}</span>
								<span class="w-1/6 text-end">{{ (item.price ?? 0) * (item.qty ?? 0) | currency }}</span>
							</div>
						</ng-container>

						<!-- Misc Materials -->
						<div *ngFor="let material of getFormArray('miscMaterials').controls">
							<div class="flex flex-row justify-between space-x-2">
								<span class="w-1/3"></span>
								<span class="w-1/2">{{ material.get('description')?.value }}</span>
								<span class="w-1/12"></span>
								<span class="w-1/12 text-end">1</span>
								<span class="w-1/6 text-end">{{ material.get('price')?.value | currency }}</span>
								<span class="w-1/6 text-end">{{ material.get('price')?.value | currency }}</span>
							</div>
						</div>

						<!--Rack Solid Line Item-->
						<div
							*ngIf="
								quoteForm.get('rackSolidLineItem')?.get('price')?.value &&
								quoteForm.get('rackSolidLineItem')?.get('price')?.value !== '0.00' &&
								!showFullPartList
							"
							class="flex flex-row justify-between space-x-2"
						>
							<span class="w-1/3"></span>
							<span class="w-1/2">{{ quoteForm.get('rackSolidLineItem')?.get('description')?.value }}</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('rackSolidLineItem')?.value) | currency }}</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('rackSolidLineItem')?.value) | currency }}</span>
						</div>

						<!--Wire Grid Line Item-->
						<div
							*ngIf="
								quoteForm.get('wireGridLineItem')?.get('price')?.value &&
								parseFloat(quoteForm.get('wireGridLineItem')?.get('price')?.value) !== 0 &&
								!showFullPartList
							"
							class="flex flex-row justify-between space-x-2"
						>
							<span class="w-1/3"></span>
							<span class="w-1/2">{{ quoteForm.get('wireGridLineItem')?.get('description')?.value }}</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('wireGridLineItem')?.value) | currency }}</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('wireGridLineItem')?.value) | currency }}</span>
						</div>

						<!--Particle Board Line Item-->
						<div
							*ngIf="
								quoteForm.get('particleBoardLineItem')?.get('price')?.value &&
								parseFloat(quoteForm.get('particleBoardLineItem')?.get('price')?.value) !== 0 &&
								!showFullPartList
							"
							class="flex flex-row justify-between space-x-2"
						>
							<span class="w-1/3"></span>
							<span class="w-1/2">{{ quoteForm.get('particleBoardLineItem')?.get('description')?.value }}</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('particleBoardLineItem')?.value) | currency }}</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('particleBoardLineItem')?.value) | currency }}</span>
						</div>

						<!--Steel Perf Line Item-->
						<div
							*ngIf="
								quoteForm.get('steelPerfLineItem')?.get('price')?.value &&
								parseFloat(quoteForm.get('steelPerfLineItem')?.get('price')?.value) !== 0 &&
								!showFullPartList
							"
							class="flex flex-row justify-between space-x-2"
						>
							<span class="w-1/3"></span>
							<span class="w-1/2">{{ quoteForm.get('steelPerfLineItem')?.get('description')?.value }}</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('steelPerfLineItem')?.value) | currency }}</span>
							<span class="w-1/6 text-end">{{ checkForGetBacks(quoteForm.get('steelPerfLineItem')?.value) | currency }}</span>
						</div>

						<!--Freight Cost Line Item-->
						<div
							class="flex flex-row justify-between space-x-2"
							*ngIf="
								quoteForm.get('freightOption')?.get('price')?.value !== 0 && quoteForm.get('freightOption')?.get('price')?.value !== '0.00'
							"
						>
							<span class="w-1/3"></span>
							<span class="w-1/2"
								>{{ quoteForm.get('freightOption')?.value['description'] }}
								<span *ngIf="quoteForm.get('freightOption')?.get('weight')?.value">
									&nbsp; -&nbsp;{{ quoteForm.get('freightOption')?.get('weight')?.value }}lbs
								</span>
							</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated"
								>{{ checkForGetBacks(quoteForm.get('freightOption')?.value) | currency }}
							</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated"
								>{{ checkForGetBacks(quoteForm.get('freightOption')?.value) | currency }}
							</span>
						</div>

						<!--Discount Line Item-->
						<div
							class="flex flex-row justify-between space-x-2"
							*ngIf="quoteForm.get('discount')?.value['price'] && quoteForm.get('discount')?.value['price'] !== '0.00'"
						>
							<span class="w-1/3"></span>
							<span class="w-1/2">{{ quoteForm.get('discount')?.value['description'] }}</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end">{{ quoteForm.get('discount')?.value['price'] | currency }}</span>
							<span class="w-1/6 text-end">{{ quoteForm.get('discount')?.value['price'] | currency }}</span>
						</div>

						<!--Associate Costs Line Items-->
						<div *ngFor="let associatedCost of associatedCostFormArray.controls">
							<div
								*ngIf="
									associatedCost.get('price')?.value &&
									associatedCost.get('price')?.value !== '0.00' &&
									associatedCost.get('type')?.value !== 'stockPermitReq'
								"
								class="flex flex-row justify-between space-x-2"
							>
								<span class="w-1/3"></span>
								<span class="w-1/2">{{ associatedCost.get('description')?.value | underscoreRemove | titlecase }}</span>
								<span class="w-1/12"></span>
								<span class="w-1/12 text-end">1</span>
								<span class="w-1/6 text-end" *ngIf="associatedCost.get('price')?.value">{{
									checkForGetBacks(associatedCost.value) | currency
								}}</span>
								<span class="w-1/6 text-end" *ngIf="associatedCost.get('price')?.value">{{
									checkForGetBacks(associatedCost.value) | currency
								}}</span>
								<span class="w-1/6" *ngIf="!associatedCost.get('price')?.value"></span>
								<span class="w-1/6" *ngIf="!associatedCost.get('price')?.value"></span>
							</div>
						</div>

						<!--Install Line Item-->
						<div class="flex flex-row justify-between space-x-2" *ngIf="quoteForm.get('installLineItem')?.get('price')?.value !== 0">
							<span class="w-1/3"> </span>
							<span class="w-1/2">Install for System & Shelving</span>
							<span class="w-1/12"></span>
							<span class="w-1/12 text-end">1</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated">{{
								checkForGetBacks(this.quoteForm.get('installLineItem')?.value) | currency
							}}</span>
							<span class="w-1/6 text-end" *ngIf="this.calculated">{{
								checkForGetBacks(this.quoteForm.get('installLineItem')?.value) | currency
							}}</span>
						</div>

						<!--Install Comment-->
						<div class="flex flex-row justify-between space-x-2" *ngIf="quoteForm.get('installComment')?.value">
							<span class="w-1/3"></span>
							<span class="w-2/3 break-words max-w-[47.5%]" [innerHTML]="quoteForm.get('installComment')?.value | whitespace"></span>
							<span class="w-1/12"></span>
							<span class="w-1/12"></span>
							<span class="w-1/12"></span>
							<span class="w-1/12"></span>
						</div>
					</div>

					<div class="flex flex-row mb-2">
						<div class="flex flex-col w-[37.5%] mt-2">
							<span class="w-5/6 h-4/5 border-b-2 border-black">Accepted: </span>
						</div>
						<div class="flex flex-col w-[62.5%] mt-2">
							<div class="flex flex-row">
								<span>Totals are in US Dollars</span>
							</div>
							<div class="flex flex-row">
								<span class="w-[63%]"></span>
								<span class="w-1/2 text-end">Subtotal: </span>
								<span class="w-1/3 text-end" *ngIf="this.calculated && subTotal > 0">{{ subTotal | currency }}</span>
								<span class="w-1/3 text-end" *ngIf="!subTotal">$0.00</span>
							</div>
						</div>
					</div>

					<div class="flex flex-row mb-2">
						<span class="w-5/12">Amount Subject to Sales Tax: </span>
						<span class="w-1/6 text-end" *ngIf="this.quoteForm.get('amountSubjectToTax')?.value"
							>${{ this.quoteForm.get('amountSubjectToTax')?.value }}</span
						>
						<span class="w-1/6 text-end" *ngIf="!this.quoteForm.get('amountSubjectToTax')?.value">$0.00</span>
						<span class="w-1/6"></span>
						<span class="w-1/4 text-end">Total Sales Tax: </span>
						<span class="w-1/6 text-end border-b-2 border-black" *ngIf="this.quoteForm.get('totalSalesTax')?.value"
							>${{ this.quoteForm.get('totalSalesTax')?.value }}</span
						>
						<span class="w-1/6 text-end border-b-2 border-black" *ngIf="!this.quoteForm.get('totalSalesTax')?.value">$0.00</span>
					</div>

					<div class="flex flex-row mt-2">
						<span class="w-5/12">Amount Exempt from Sales Tax: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated && this.quoteForm.get('amountExemptFromTax')?.value"
							>${{ this.quoteForm.get('amountExemptFromTax')?.value }}</span
						>
						<span class="w-1/6 text-end" *ngIf="this.calculated && !this.quoteForm.get('amountExemptFromTax')?.value">$0.00</span>
						<span class="w-1/6"></span>
						<span class="w-1/4 text-end">Total: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated && grandTotal > 0">{{ grandTotal | currency }}</span>
						<span class="w-1/6 text-end" *ngIf="!grandTotal">$0.00</span>
					</div>

					<div class="flex flex-row mt-2" *ngIf="hasStockroomPermitFee">
						<span class="w-5/12"></span>
						<span class="w-1/6"></span>
						<span class="w-[41.6%] text-end">Permitting - Not to Exceed:</span>
						<span class="w-1/6 text-end border-b-2 border-black">{{ getPriceOfControl('stockPermitReq') | currency }}</span>
					</div>

					<div class="flex flex-row mt-2" *ngIf="hasStockroomPermitFee">
						<span class="w-5/12"></span>
						<span class="w-1/6 text-end"></span>
						<span class="w-1/6"></span>
						<span class="w-1/4 text-end">Max Grand Total: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated && maxGrandTotal > 0">{{ maxGrandTotal | currency }}</span>
						<span class="w-1/6 text-end" *ngIf="!maxGrandTotal">$0.00</span>
					</div>

					<div *ngIf="quoteForm.get('clientComment')?.value" class="justify-self-center w-full mt-4">
						<span [innerHTML]="quoteForm.get('clientComment')?.value | whitespace"></span>
					</div>

					<div class="justify-self-center font-arial w-full mt-4">
						<span>{{ quoteDefaults.quoteText }}</span>
					</div>
				</div>
			</div>
			<div
				*ngIf="!hasChangeRequest"
				class="flex flex-row w-full place-content-end mt-2 space-x-2"
				[matTooltip]="isRefreshingPrices || (partsLoading | async) ? 'Prices are refreshing, please wait' : ''"
			>
				<button
					type="button"
					*ngIf="!createOrderMode && !submitted && (quote.status === QuoteStatusEnum.IN_PROGRESS || quote.status === undefined)"
					mat-flat-button
					(click)="saveQuote()"
					[disabled]="isRefreshingPrices || (partsLoading | async) || saving"
				>
					SAVE
				</button>
				<div
					matTooltip="Please save quote before requesting approval"
					matTooltipDisabled="{{ !quoteForm.dirty }}"
					*ngIf="this.quote.quoteNumber"
				>
					<button
						type="submit"
						*ngIf="!createOrderMode && quote.status === QuoteStatusEnum.IN_PROGRESS"
						mat-flat-button
						color="primary"
						[disabled]="quoteForm.dirty || isRefreshingPrices || (partsLoading | async) || saving"
					>
						<div *ngIf="!requestingApproval">REQUEST APPROVAL</div>
						<div *ngIf="requestingApproval" class="w-full h-full flex justify-center m-2">
							<mat-spinner></mat-spinner>
						</div>
					</button>
					<button
						mat-flat-button
						color="primary"
						type="button"
						*ngIf="quote.status === QuoteStatusEnum.APPROVAL_REQUESTED"
						(click)="resendQuote()"
					>
						<div *ngIf="!requestingApproval">RESEND</div>
						<div *ngIf="requestingApproval" class="w-full h-full flex justify-center m-2">
							<mat-spinner></mat-spinner>
						</div>
					</button>
				</div>
				<div *ngIf="createOrderMode" matTooltip="{{ getCreateSalesOrderTooltipMessage() }}" class="flex space-x-2">
					<button type="button" mat-flat-button (click)="cancelEdit()" *ngIf="editMode">CANCEL</button>
					<button type="button" mat-flat-button color="primary" (click)="toggleEdit()">{{ editMode ? 'SAVE QUOTE' : 'EDIT QUOTE' }}</button>
					<button
						type="button"
						disabled="{{ quoteForm.dirty || salesOrderCreated || isRefreshingPrices || (partsLoading | async) || salesOrderForm.invalid }}"
						mat-flat-button
						color="primary"
						(click)="createOrder(ngForm)"
					>
						<div *ngIf="!creatingSalesOrder">CREATE ORDER</div>
						<div *ngIf="creatingSalesOrder" class="w-full h-full flex justify-center m-2">
							<mat-spinner></mat-spinner>
						</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</form>

<!----------------PDF TEMPLATE----------------------->
<div
	class="flex flex-col leading-3 font-selawik font-normal text-[10px] py-4 pr-4 min-w-[720px] max-w-[720px] 2xl:w-1/2 ml-4 mt-24"
	[ngClass]="{hidden: !showPdf}"
>
	<div class="pdf-template mat-elevation-z4" *ngIf="quotePdf?.quote">
		<div class="p-8 self-center" id="quotePreview" #quotePreview>
			<div class="min-h-[932px] h-[932px]" *ngFor="let page of quotePdf.pages; let index = index">
				<div class="max-h-[33%] pb-1">
					<div class="flex flex-row space-between w-full">
						<div class="flex flex-col w-1/2">
							<img ngSrc="./assets/mmi-logo.jpg" alt="MMI Logo" class="w-3/4" height="272" width="758" />
							<span class="ml-8">45 Turner Drive Suite 2A Middletown, NY 10941</span>
							<span class="ml-8">PH: (800) 784-8080 FX:</span>
						</div>
						<div class="flex flex-col font-bold w-1/2 justify-self-end mt-4">
							<div class="flex w-full space-x-2 place-self-end text-xl">
								<span class="w-2/3 text-end">SALES QUOTE</span>
								<span class="w-1/3 font-normal" *ngIf="quotePdf.quote.quoteNumber || created">{{ quoteNumber }}</span>
							</div>
							<div class="flex w-full space-x-2 place-self-end">
								<span class="w-2/3 text-end">Sales Quote Date: </span>
								<span class="w-1/3 font-normal">
									{{ quotePdf.quote.date ?? this.quoteDefaults.quoteDate | date }}
								</span>
							</div>
							<div class="flex w-full space-x-2 place-self-end">
								<span class="w-2/3 text-end"> Page: </span>
								<span class="w-1/3 font-normal"> {{ index + 1 }} </span>
							</div>
						</div>
					</div>

					<div class="flex flex-row justify-around my-4">
						<div class="flex flex-row space-x-2">
							<span class="font-bold">Sell To: </span>
							<div class="flex flex-col" *ngIf="client">
								<span>{{ client.name }}</span>
								<span>{{ client.address1 }}</span>
								<span>{{ client.city }} , {{ client.state }} {{ client.postal }}</span>
							</div>
						</div>

						<div class="flex flex-row space-x-2">
							<span class="font-bold">Ship To:</span>
							<div class="flex flex-col">
								<span>{{ quotePdf.projectName }}</span>
								<span>{{ quotePdf.quote.siteAddress1 }}</span>
								<span *ngIf="quotePdf.quote.siteAddress2">{{ quotePdf.quote.siteAddress2 }}</span>
								<span>
									{{ quotePdf.quote.siteCity }},
									{{ quotePdf.quote.siteState }}
									{{ quotePdf.quote.siteZip }}</span
								>
							</div>
						</div>
					</div>

					<div class="flex flex-row justify-between mb-2">
						<span class="self-end font-bold"
							>Terms:
							<span class="font-normal">{{
								quoteForm.get('paymentTerms')?.value ? quoteForm.get('paymentTerms')?.value : quoteDefaults.quoteTerms + ' days'
							}}</span></span
						>
						<span class="self-end font-bold"
							>Project Weight: <span class="font-normal">{{ mobileItemsGrossWeight | number : '1.2-2' }} lbs</span></span
						>
						<div class="flex flex-row space-x-2">
							<div class="flex flex-col justify-end font-bold">
								<span
									>Sales Person: <span class="font-normal">{{ quoteForm.get('salesPerson')?.value }}</span></span
								>
								<span
									>Quoter: <span class="font-normal">{{ quoteForm.get('quoter')?.value }}</span></span
								>
								<span
									>Account Manager: <span class="font-normal">{{ quoteForm.get('accountManager')?.value }}</span></span
								>
							</div>
							<div class="flex flex-col justify-end"></div>
						</div>
					</div>
				</div>

				<div class="flex flex-row justify-between font-bold my-2 pb-4 border-y-2 border-black">
					<span class="w-1/5">Item No.</span>
					<span class="w-1/2">Description</span>
					<span class="w-1/12">Unit</span>
					<span class="w-1/12">Quantity</span>
					<span class="w-1/6 text-end">Unit Price</span>
					<span class="w-1/6 text-end">Total Price</span>
				</div>

				<div class="flex flex-col">
					<div class="flex flex-row justify-between space-x-2" *ngFor="let item of page.lines">
						<span class="w-1/5">{{ item.erpItemRef }}</span>
						<span class="w-1/2">{{ item.description }}</span>
						<span class="w-1/12">{{ item.unitOfMeasure }}</span>
						<span class="w-1/12 text-end">{{ item.qty }}</span>
						<span class="w-1/6 text-end">{{ item.price ? (item.price | currency) : '' }}</span>
						<span class="w-1/6 text-end">{{ item.price && item.qty ? (item.price * item.qty | currency) : '' }}</span>
					</div>
				</div>

				<div *ngIf="index === quotePdf.pages.length - 1" id="pdf-footer" class="flex flex-col max-h-[20%]">
					<div class="flex flex-row mb-2">
						<div class="flex flex-col w-[37.5%] mt-2">
							<span class="w-5/6 h-4/5 border-b-2 pb-4 border-black">Accepted: </span>
						</div>
						<div class="flex flex-col w-[62.5%] mt-2">
							<div class="flex flex-row">
								<span>Totals are in US Dollars</span>
							</div>
							<div class="flex flex-row">
								<span class="w-[63%]"></span>
								<span class="w-1/2 text-end">Subtotal: </span>
								<span class="w-1/3 text-end" *ngIf="this.calculated && subTotal > 0">{{ subTotal | currency }}</span>
								<span class="w-1/3 text-end" *ngIf="!subTotal">$0.00</span>
							</div>
						</div>
					</div>

					<div class="flex flex-row mb-2">
						<span class="w-5/12">Amount Subject to Sales Tax: </span>
						<span class="w-1/6 text-end" *ngIf="this.quoteForm.get('amountSubjectToTax')?.value"
							>${{ this.quoteForm.get('amountSubjectToTax')?.value }}</span
						>
						<span class="w-1/6 text-end" *ngIf="!this.quoteForm.get('amountSubjectToTax')?.value">$0.00</span>
						<span class="w-1/6"></span>
						<span class="w-1/4 text-end">Total Sales Tax: </span>
						<span class="w-1/6 text-end pb-2 border-b-2 border-black" *ngIf="this.quoteForm.get('totalSalesTax')?.value"
							>${{ this.quoteForm.get('totalSalesTax')?.value }}</span
						>
						<span class="w-1/6 text-end pb-2 border-b-2 border-black" *ngIf="!this.quoteForm.get('totalSalesTax')?.value">$0.00</span>
					</div>

					<div class="flex flex-row mt-2">
						<span class="w-5/12">Amount Exempt from Sales Tax: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated && this.quoteForm.get('amountExemptFromTax')?.value"
							>${{ this.quoteForm.get('amountExemptFromTax')?.value }}</span
						>
						<span class="w-1/6 text-end" *ngIf="this.calculated && !this.quoteForm.get('amountExemptFromTax')?.value">$0.00</span>
						<span class="w-1/6"></span>
						<span class="w-1/4 text-end">Total: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated && grandTotal > 0">{{ grandTotal | currency }}</span>
						<span class="w-1/6 text-end" *ngIf="!grandTotal">$0.00</span>
					</div>

					<div class="flex flex-row mt-2" *ngIf="hasStockroomPermitFee">
						<span class="w-5/12"></span>
						<span class="w-1/6"></span>
						<span class="w-[41.6%] text-end">Permitting - Not to Exceed:</span>
						<span class="w-1/6 text-end pb-2 border-b-2 border-black">{{ getPriceOfControl('stockPermitReq') | currency }}</span>
					</div>

					<div class="flex flex-row mt-2" *ngIf="hasStockroomPermitFee">
						<span class="w-5/12"></span>
						<span class="w-1/6 text-end"></span>
						<span class="w-1/6"></span>
						<span class="w-1/4 text-end">Max Grand Total: </span>
						<span class="w-1/6 text-end" *ngIf="this.calculated && maxGrandTotal > 0">{{ maxGrandTotal | currency }}</span>
						<span class="w-1/6 text-end" *ngIf="!maxGrandTotal">$0.00</span>
					</div>

					<div *ngIf="quoteForm.get('clientComment')?.value" class="justify-self-center w-full mt-2">
						<span [innerHTML]="quoteForm.get('clientComment')?.value | whitespace"></span>
					</div>

					<div class="font-arial justify-self-center w-full mt-2">
						<span>{{ quoteDefaults.quoteText }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
