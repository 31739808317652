import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ShippingLog} from '../models/shipping-log.model';

@Injectable({
	providedIn: 'root'
})
export class ShippingLogService {
	baseUrl: string = `${environment.url}/shipping-log`;
	constructor(private http: HttpClient) {}

	getShippingLog(selectedDate: Date | null = null): Observable<ShippingLog[]> {
		let params = new HttpParams();
		if (selectedDate) {
			params = params.append('selectedDate', selectedDate.toISOString().substring(0, 10));
		}
		return this.http.get<ShippingLog[]>(`${this.baseUrl}`, {params: params});
	}

	create(shippingLog: ShippingLog, shipmentIds: number[]): Observable<ShippingLog> {
		return this.http.post<ShippingLog>(`${this.baseUrl}`, {shippingLog: shippingLog, shipmentIds: shipmentIds});
	}

	update(shippingLog: Partial<ShippingLog>): Observable<ShippingLog> {
		return this.http.patch<ShippingLog>(`${this.baseUrl}/${shippingLog.id}`, shippingLog);
	}
}
