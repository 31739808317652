import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GlCodeTypeEnum} from '../../../core/enums/gl-code-type.enum';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-misc-materials',
	templateUrl: './misc-materials.component.html',
	styleUrls: ['./misc-materials.component.scss']
})
export class MiscMaterialsComponent {
	@Input() quoteForm: FormGroup;
  @Output() weightChangedEvent: EventEmitter<void> = new EventEmitter<void>();
	materialTotalSubscription: Subscription | undefined;

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		this.calculateMiscMaterialTotal();

		this.materialTotalSubscription = this.quoteForm.get('miscMaterials')?.valueChanges.subscribe((): void => {
			this.calculateMiscMaterialTotal();
      this.weightChangedEvent.emit();
		});
	}

	ngOnDestroy(): void {
		this.materialTotalSubscription?.unsubscribe();
	}

	get miscMaterials() {
		return this.quoteForm.controls['miscMaterials'] as FormArray;
	}

	addMiscMaterial(): void {
		const group = this.fb.group({
			price: [0, Validators.required],
			description: ['', Validators.required],
			glNumber: [43025],
      weight: null,
			qty: [1],
			type: [GlCodeTypeEnum.MISC_MAT]
		});

		this.miscMaterials.push(group);
	}

	removeMaterial(index: number): void {
		this.miscMaterials.removeAt(index);
	}

	calculateMiscMaterialTotal(): void {
		let totalCost: number = 0;
    let totalWeight: number = 0;
		(this.quoteForm.controls['miscMaterials'] as FormArray).controls.forEach((control: AbstractControl<any, any>): void => {
			totalCost += control.get('price')?.value;
      totalWeight += control.get('weight')?.value;
		});
		this.quoteForm.get('miscMaterialTotal')?.setValue(totalCost);
    this.quoteForm.get('miscMaterialWeight')?.setValue(totalWeight);
	}
}
