<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">{{ broker.name | titlecase }} Details</span>
		<div
			[ngStyle]="{backgroundColor: broker.active === 1 ? 'green' : 'red'}"
			class="py-1 px-2 ml-2 rounded-full text-center text-white cursor-pointer"
			(click)="showToggleInactiveModal(pill.style.backgroundColor)"
			#pill
		>
			<p class="no-margin">{{ broker.active === 1 ? 'Active' : 'Inactive' }}</p>
		</div>
		<button mat-icon-button (click)="form.enable()" [disabled]="form.enabled">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
	<div class="flex pt-4">
		<div class="w-1/3 mr-2">
			<mat-form-field class="w-full" (click)="form.enable()" tabindex="0">
				<mat-label>Name</mat-label>
				<input matInput formControlName="name" />
			</mat-form-field>
		</div>
		<div class="w-1/3 mr-2">
			<mat-form-field class="w-full" (click)="form.enable()" tabindex="0">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" />
			</mat-form-field>
		</div>
		<div class="w-1/3">
			<mat-form-field class="w-3/5 mr-2" (click)="form.enable()" tabindex="0">
				<mat-label>Phone No.</mat-label>
				<input matInput formControlName="phone" type="tel" />
			</mat-form-field>
			<mat-form-field class="w-20" (click)="form.enable()" tabindex="0">
				<mat-label>Ext</mat-label>
				<input matInput formControlName="phoneExt" />
			</mat-form-field>
		</div>
	</div>

	<div>
		<div class="flex flex-row">
			<span class="text-lg mt-2.5">Related Contacts</span>
			<button class="-translate-y-1." mat-icon-button (click)="openContactModal(false)">
				<mat-icon>add</mat-icon>
			</button>
		</div>

		<div *ngIf="loading"><mat-spinner class="scale-50"></mat-spinner></div>
		<div *ngIf="!loading && !relatedContacts.length">No associated contacts.</div>
		<div *ngIf="relatedContacts.length" class="w-full flex flex-row space-x-2">
			<div *ngFor="let contact of relatedContacts; index as i" class="mat-elevation-z2 rounded px-3 pb-2 flex flex-col">
				<div class="flex flex-row w-full justify-between">
					<div>
						<span class="text-lg">{{ contact.name }}</span>
						<button mat-icon-button class="scale-75 translate-y-1 -translate-x-1.5" (click)="openContactModal(true, contact)">
							<mat-icon>edit</mat-icon>
						</button>
					</div>
					<button mat-icon-button class="scale-75 translate-y-1 translate-x-3" (click)="removeContactAssociation(contact, i)">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
				<span><strong>Email: </strong>{{ contact.email }}</span>
				<span><strong>Phone:</strong> {{ contact.phone }}</span>
				<span><strong>Extension:</strong> {{ contact.phoneExt }}</span>
			</div>
		</div>
	</div>

	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
