import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ProjectsService} from '../../core/services/projects.service';
import {ActivatedRoute} from '@angular/router';
import {ProjectTask} from '../../core/models/project-task.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SalesOrderResponse} from '../../core/models/sales-order-response.model';
import {map, Observable, startWith} from 'rxjs';
import {QuoteService} from '../../core/services/quote.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {OrderDetail} from '../../core/models/order-detail.model';
import {ShippingLog} from '../../core/models/shipping-log.model';
import {TaskTypeService} from '../../core/services/task-type.service';
import {SalesOrderService} from 'src/app/core/services/sales-order.service';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {Quote} from '../../core/models/quote.interface';
import {UpdateProjectInstallShip} from '../../core/models/project-install-ship.interface';

@Component({
	selector: 'app-project-estimate',
	templateUrl: './project-estimate.component.html',
	styleUrls: ['./project-estimate.component.scss']
})
export class ProjectEstimateComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<OrderDetail> = new MatTableDataSource<OrderDetail>();
	columnsToDisplay: string[] = ['select', 'erpItemRef', 'item', 'qty', 'price', 'total'];

	navShipDate: Date;

	projectTaskForm = new FormGroup({
		shipmentOrderNo: new FormControl('', Validators.required),
		projectTasks: this.fb.array([]),
		orderHeader: new FormGroup({}),
		orderDetails: this.fb.array([]),
		taskTypeId: new FormControl(0),
		filter: new FormControl(''),
		showPickList: new FormControl(false),
		installDate: new FormControl(new Date(), Validators.required),
		shipDate: new FormControl(new Date(), Validators.required),
		transitDays: new FormControl(0, Validators.required),
		estimatedTransit: new FormControl(0),
		location: new FormControl('')
	});

	projectId: number | undefined;

	salesOrderList: SalesOrderResponse[] = [];
	filteredSalesOrderList: Observable<SalesOrderResponse[]>;
	filterString = '';
	quoteList: Quote[];

	totalValue: number = 0;

	zero: number = 0;

	salesOrder: SalesOrderResponse;

	pickListTaskId: Number = 0;

	loading: boolean = false;
	busy: boolean = false;

	initWarn = false;
	changeWarn = false;

	orderNoReadonly: boolean = true;
	hasOrder: boolean = false;

	projectLocationId = 0;
	projectLocationName = '';

	constructor(
		private projectService: ProjectsService,
		private route: ActivatedRoute,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		private quoteService: QuoteService,
		private cdRef: ChangeDetectorRef,
		private taskTypeService: TaskTypeService,
		private salesOrderService: SalesOrderService
	) {
		this.filterFunction();
	}

	ngOnInit() {
		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe((params) => {
			if (params['projectId']) {
				this.projectId = params['projectId'];
				this.getTasks();
			}
		});
		this.quoteService.findAllSalesOrdersFromErp(false).subscribe((response: SalesOrderResponse[]) => {
			this.salesOrderList = response;
		});
		this.quoteService
			.findAllQuotes()
			.subscribe((response) => (this.quoteList = response.filter((quote: Quote) => quote.projectId === this.projectId)));
	}

	toggleOrderNoReadOnly() {
		this.orderNoReadonly = !this.orderNoReadonly;
	}

	refreshEstimate() {
		this.busy = true;
		let orderNumber = this.projectTaskForm.get('shipmentOrderNo')?.value;
		if (orderNumber) {
			this.quoteService.getSalesOrdersFromErp(orderNumber, this.projectId).subscribe({
				next: (salesOrder) => {
					this.salesOrder = salesOrder;
					this.setData();
					this.calcTotals();
					this.applyFilter();
					this._snackBar.open('Order refreshed.', SnackbarActionEnum.SUCCESS);
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Order Refresh Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	createOrUpdateOrder() {
		this.busy = true;
		let orderNumber = this.projectTaskForm.get('shipmentOrderNo')?.value;

		if (orderNumber) {
			this.salesOrder.projectId = this.projectId;
			this.salesOrder.orderNo = orderNumber;
			this.salesOrder.id = undefined;

			this.salesOrderService.update(orderNumber, this.salesOrder).subscribe({
				next: (response) => {
					this._snackBar.open('Order updated successfully', SnackbarActionEnum.SUCCESS);
					this.busy = false;
				},
				error: (error) => {
					this._snackBar.open('Order Update Failed: ' + error.error.message);
					this.busy = false;
				}
			});
		}
	}

	setData() {
		this.totalValue = 0;
		if (this.salesOrder) {
			this.salesOrder?.salesLines?.forEach((line) => {
				if (line.qty && line.price) {
					this.totalValue += line.qty * line.price;
				}
			});
			this.dataSource = new MatTableDataSource<ShippingLog>(this.salesOrder?.salesLines);
			this.dataSource.filterPredicate = (data, filter) => {
				const showPickList = this.pickListTaskId === this.taskTypeId;
				let pickList = true;
				if (this.projectTaskForm.controls.showPickList.value) {
					if (data.pickList) {
						pickList = true;
					} else {
						pickList = false;
					}
				}

				return (
					Object.values(data).join(' ').toLowerCase().includes(filter?.trim().toLowerCase()) &&
					(this.taskTypeId === 0 || !data.taskTypeId || this.taskTypeId === data.taskTypeId || (showPickList && !!data.pickList)) &&
					pickList
				);
			};
			this.dataSource.sortingDataAccessor = (item: any, property) => {
				switch (property) {
					case 'item':
						return item.item ? item.item : item.glNumber ? item.glNumber : '';
					default:
						return item[property] ? item[property] : '';
				}
			};
		}
	}

	getTasks() {
		if (this.projectId) {
			this.busy = true;
			this.projectService.getProjectEstimates(this.projectId).subscribe({
				next: (projectEstimate) => {
					this.projectTasks.clear();
					let initialize = true;
					if (projectEstimate.project.orderNo) {
						this.hasOrder = true;
					}
					this.projectLocationId = projectEstimate.project.locationId;
					this.projectLocationName = projectEstimate.project.locationName;
					projectEstimate.tasks.forEach((projectTask) => {
						if (projectTask.taskType?.listType === 'PICKLIST' && projectTask.taskType.id) {
							this.pickListTaskId = projectTask.taskType.id;
						}
						if (projectTask.hours) {
							initialize = false;
						}
						this.addProjectTask(projectTask);
					});
					this.salesOrder = projectEstimate.order;
					if (this.salesOrder?.id) {
						this.projectTaskForm.get('shipmentOrderNo')?.setValue(this.salesOrder?.id.toString());
						this.orderNoReadonly = true;
					}

					this.setData();
					if (initialize) {
						this.calcTotals();
						this.initWarn = true;
					}
					this.initDisplayTasks();
					this.projectTaskForm.patchValue(projectEstimate.project);
					if (projectEstimate.project.navShipDate) {
						this.navShipDate = projectEstimate.project.navShipDate;
					}
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Get Project Tasks Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	updateTasks() {
		if (this.projectId) {
			let projectTasks: ProjectTask[] = this.projectTaskForm.controls.projectTasks.value as ProjectTask[];
			let projectInfo: UpdateProjectInstallShip = {};
			if (this.projectTaskForm.controls.installDate.value) {
				// @ts-ignore
				projectInfo.installDate = new Date(this.projectTaskForm.controls.installDate.value).toISOString().substring(0, 10);
			}
			if (this.projectTaskForm.controls.shipDate.value) {
				// @ts-ignore
				projectInfo.shipDate = new Date(this.projectTaskForm.controls.shipDate.value).toISOString().substring(0, 10);
			}
			if (this.projectTaskForm.controls.transitDays.value) {
				projectInfo.transitDays = this.projectTaskForm.controls.transitDays.value;
			}
			if (this.projectTaskForm.controls.shipmentOrderNo.value) {
				projectInfo.orderNo = this.projectTaskForm.controls.shipmentOrderNo.value;
			}
			this.busy = true;
			this.projectService.updateProjectTasks(this.projectId, projectTasks, projectInfo).subscribe({
				next: (projectTasks) => {
					this.projectTasks.clear();
					projectTasks.forEach((projectTask) => this.addProjectTask(projectTask));
					this.busy = false;
					this.initWarn = false;
					this.changeWarn = false;
					this.projectTaskForm.markAsPristine();
				},
				error: (err) => {
					this._snackBar.open('Update Project Tasks Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	get projectTasks(): FormArray {
		return this.projectTaskForm.controls.projectTasks;
	}

	get taskTypeId(): number {
		let taskTypeId = 0;
		if (this.projectTaskForm.controls.taskTypeId.value) {
			taskTypeId = this.projectTaskForm.controls.taskTypeId.value;
		}
		return taskTypeId;
	}

	addProjectTask(projectTask: ProjectTask) {
		this.projectTasks.push(
			this.fb.group({
				id: [projectTask.id],
				projectId: [projectTask.projectId],
				taskTypeId: [projectTask.taskTypeId],
				value: [projectTask.value],
				hours: [projectTask.hours],
				taskType: [projectTask.taskType]
			})
		);
	}

	taskDescription(i: number): string {
		return this.projectTasks.controls[i].value.taskType?.name;
	}

	selectedTaskDescription(i: number): string {
		if (this.taskTypeId === this.projectTasks.controls[i].value.taskTypeId) {
			return this.taskTypeService.getClass(this.projectTasks.controls[i].value.taskType?.name);
		}
		return '';
	}

	recalculateHours(i: number) {
		this.changeWarn = true;
		const projectTask = this.projectTasks.controls[i].value;
		projectTask.hours = Math.ceil((projectTask.value * projectTask.taskType.multiplier) / 0.5) * 0.5;
		this.projectTasks.controls[i].patchValue(projectTask);
	}

	filterFunction(): void {
		this.filteredSalesOrderList = this.projectTaskForm.controls.shipmentOrderNo.valueChanges.pipe(
			startWith(''),
			map((value) => (typeof value === 'string' ? value : this.filterString)),
			map((filter) => this.filter(filter))
		);
	}

	filter(filter: string): SalesOrderResponse[] {
		this.filterString = filter;
		if (filter.length > 0) {
			return this.salesOrderList.filter((option: SalesOrderResponse) => {
				if (option.id) {
					let existingSalesOrder = this.quoteList.find((quote) => quote.salesOrder === option.id);
					return (
						option.id.indexOf(filter) >= 0 &&
						option.sellToClientId === this.projectService.projectDataSource.value?.clientId &&
						!existingSalesOrder
					);
				} else {
					return;
				}
			});
		} else {
			return this.salesOrderList.filter((option) => {
				let existingSalesOrder = this.quoteList.find((quote) => quote.salesOrder === option.id);
				return option.sellToClientId === this.projectService.projectDataSource.value?.clientId && !existingSalesOrder;
			});
		}
	}

	applyFilter() {
		let filter = this.filterText;
		if (filter.length === 0) {
			filter = ' ';
		}
		this.dataSource.filter = filter;
		this.cdRef.detectChanges();
	}

	get filterText(): string {
		let filterText = '';
		if (this.projectTaskForm.controls.filter.value) {
			filterText = this.projectTaskForm.controls.filter.value?.trim().toLowerCase();
		}
		return filterText;
	}

	getOrderDetails(salesOrder: SalesOrderResponse) {
		this.busy = true;
		this.orderNoReadonly = true;
		this.hasOrder = true;
		this.changeWarn = true;

		if (salesOrder.id) {
			this.quoteService.getSalesOrdersFromErp(salesOrder.id, this.projectId).subscribe({
				next: (salesOrder) => {
					this.salesOrder = salesOrder;
					this.setData();
					this.calcTotals();
					this.busy = false;
				}
			});
		}
	}

	totalLines(taskTypeId: number) {
		let filtered = 0;
		let total = 0;
		if (this.salesOrder && this.salesOrder.salesLines) {
			total = this.salesOrder.salesLines.length;
			filtered = this.salesOrder.salesLines.filter(
				(detail) => detail.taskTypeId === taskTypeId || (taskTypeId === this.pickListTaskId && !!detail.pickList)
			).length;
		}
		if (taskTypeId === 0) {
			return total;
		}
		return filtered + ' / ' + total;
	}

	toggleRow(detail: OrderDetail) {
		this.changeWarn = true;
		if (detail.taskTypeId !== 0) {
			detail.taskTypeId = 0;
		} else {
			detail.taskTypeId = this.taskTypeId;
		}
		this.calcTotals();
		this.cdRef.detectChanges();
	}

	togglePickList(detail: OrderDetail) {
		this.changeWarn = true;
		detail.pickList = !detail.pickList;
		this.applyFilter();
		this.calcTotals();
		this.cdRef.detectChanges();
	}

	toggleLocation(detail: OrderDetail) {
		this.changeWarn = true;
		if (detail.locationId === this.projectLocationId) {
			detail.locationId = 0;
		} else {
			detail.locationId = this.projectLocationId;
		}
		//detail.pickList = !detail.pickList;
		//this.applyFilter();
		//this.calcTotals();
		//this.cdRef.detectChanges();
	}

	calcTotals() {
		this.projectTasks.controls.forEach((task) => {
			this.setTaskTotal(task);
		});
	}

	initDisplayTasks() {
		this.projectTasks.controls
			.filter((task) => !task.value.taskType.multiplier)
			.forEach((task) => {
				this.setTaskTotal(task);
			});
	}

	setTaskTotal(task: AbstractControl<any>) {
		const taskGroup = task as FormGroup;
		let total = 0;
		this.dataSource.data
			.filter(
				(detail) => detail.taskTypeId === task.value.taskTypeId || (task.value.taskTypeId === this.pickListTaskId && !!detail.pickList)
			)
			.forEach((detail) => {
				if (detail.price && detail.qty) {
					total += detail.qty * detail.price;
				}
			});
		total = Math.floor(total * 100) / 100;
		taskGroup.controls['value'].setValue(total);
		taskGroup.controls['hours'].setValue(Math.ceil((total * task.value.taskType.multiplier) / 0.5) * 0.5);
	}

	getTaskIdDescription(taskTypeId: number) {
		let taskType = this.projectTasks.controls.find((projectTask) => projectTask.value.taskTypeId === taskTypeId);
		if (taskType) {
			return this.taskTypeService.getClass(taskType.value.taskType.name);
		}
		return '';
	}

	hasMultiplier(task: any) {
		const taskGroup = task as FormGroup;
		return taskGroup.value.taskType.multiplier;
	}

	warnChanges() {
		return (
			!this.initWarn &&
			(this.changeWarn || this.projectTaskForm.controls.transitDays.dirty || this.projectTaskForm.controls.installDate.dirty)
		);
	}
}
