import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TransitTime} from '../../../core/models/transit-time.interface';
import {MatDialog} from '@angular/material/dialog';
import {TransitTimeService} from '../../../core/services/transit-time.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-transit-time-list-detail',
	templateUrl: './transit-time-list-detail.component.html',
	styleUrls: ['./transit-time-list-detail.component.scss']
})
export class TransitTimeListDetailComponent implements OnInit {
	@Input() transitTime: TransitTime;
	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private transitTimeService: TransitTimeService,
		private snackbar: MatSnackBar
	) {
		this.form = this.formBuilder.group({
			id: [''],
			state: ['', Validators.required],
			days: ['', Validators.required]
		});
	}
	ngOnInit() {
		this.form.patchValue(this.transitTime);
		this.form.disable();
	}

	onSubmit() {
		const formValue = this.form.getRawValue();
		this.transitTimeService.update(formValue).subscribe(
			(response) => {
				this.snackbar.open('Transit Time Updated!', SnackbarActionEnum.SUCCESS);
				this.transitTimeService.findAll();
				this.form.disable();
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}
}
