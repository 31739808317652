<h1 mat-dialog-title>Last Contacted Drafting Date Update</h1>
<div mat-dialog-content class="min-h-[100px]">
	<div class="pt-2">
		<form [formGroup]="form">
			<mat-form-field class="w-full" appearance="outline">
				<mat-label>Drafting Last Contact</mat-label>
				<input matInput [matDatepicker]="lcdPicker" formControlName="lastDraftingContactDate" />
				<mat-error>Please enter or select a valid date</mat-error>
				<mat-datepicker-toggle matIconSuffix [for]="lcdPicker"></mat-datepicker-toggle>
				<mat-datepicker #lcdPicker></mat-datepicker>
			</mat-form-field>
		</form>
	</div>
</div>
<div class="flex justify-end mt-4">
	<button class="mr-2" mat-button color="primary" [mat-dialog-close]="false">Cancel</button>
	<button mat-flat-button [disabled]="form.invalid" color="primary" class="mr-4 mb-4" [mat-dialog-close]="form.getRawValue()">
		Update
	</button>
</div>
