import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ContactService} from '../../../core/services/contact.service';
import {Contact} from '../../../core/models/contact.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactTypeEnum} from '../../../core/enums/contact-type.enum';
import {ValidatorRegexEnum} from '../../../core/enums/validator-regex.enum';

@Component({
	selector: 'app-contacts',
	templateUrl: './contacts.component.html',
	styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
	@Input() clientId: string;
	@Input() selectedContacts: Contact[] = [];
	@Input() parentContactForm: FormGroup;
  @Input() resend: boolean;
  @Input() quoteId: number;

	@Output() createContactEvent = new EventEmitter<boolean>();
	@Output() selectedContactsChanged = new EventEmitter<Contact[]>();

	contactForm: FormGroup;
	contactSubscription: Subscription;
	contactDeletedSubject: Subject<number> = new Subject<number>();
	contactCreatedSubject: Subject<Contact> = new Subject<Contact>();
	busy: boolean = true;
	contacts: Contact[] = [];
	createContact: boolean = false;
	parentForm: FormGroup;

	constructor(private contactService: ContactService, private fb: FormBuilder, private snackbar: MatSnackBar) {}

	ngOnInit(): void {
    if (!this.resend) {
      this.getContacts();
    } else {
      this.getContactsForQuoteResend();
    }

		this.buildContactForm();
	}

	ngOnDestroy(): void {
		this.contactSubscription.unsubscribe();
	}

	buildContactForm(): void {
		this.contactForm = this.fb.group({
			firstName: [null, Validators.required],
			lastName: [null],
			email: [null, [Validators.required, Validators.pattern(ValidatorRegexEnum.EMAIL)]],
			phone: [null, [Validators.pattern(ValidatorRegexEnum.PHONE)]],
			phoneExt: [null]
		});
	}

	getContacts(): void {
		this.contactSubscription = this.contactService.findContactsByType(this.clientId, ContactTypeEnum.CLIENT).subscribe({
			next: (contacts: Contact[]) => {
				this.contacts = contacts;
				this.busy = false;
			},
			error: (err: Error) => {
				console.error(err);
				this.snackbar.open('Failed to retrieve contacts for this client', SnackbarActionEnum.ERROR);
			}
		});
	}

  getContactsForQuoteResend() {
    this.contactSubscription = this.contactService.findContactsForQuoteResend(this.clientId, this.quoteId).subscribe({
      next: (contacts: Contact[]) => {
        this.contacts = contacts;
        this.busy = false;
      },
      error: (err: Error) => {
        console.error(err);
        this.snackbar.open('Failed to retrieve contacts for this client.')
      },
      complete: () => this.checkForPreviouslySelectedContacts()
    });
  }

  checkForPreviouslySelectedContacts(): void {
    this.contacts.forEach((contact: Contact): void => {
      if (contact.selected) {
        this.selectedContacts.push(contact);
        this.selectedContactsChanged.emit(this.selectedContacts);
      }
    });
  }

	selectChange(event: any): void {
		this.selectedContacts = [...event.data];
		this.selectedContactsChanged.emit(this.selectedContacts);
	}

	delete(contactToDelete: Contact): void {
		if (this.selectedContacts) {
			const index: number = this.selectedContacts.findIndex((contact: Contact) => contact === contactToDelete);
			if (index !== -1) {
				this.selectedContacts.splice(index, 1);
				this.selectedContactsChanged.emit(this.selectedContacts);
			}
			this.contactDeletedSubject.next(contactToDelete.id!);
		}
	}

	saveContact(): void {
		let contact: Contact = {};
		Object.assign(contact, this.contactForm.value);
		contact.name = this.contactForm.value.firstName?.trim();
		if (this.contactForm.get('lastName')?.value) {
			contact.name += ' ' + this.contactForm.get('lastName')?.value.trim();
		}
		this.contactService.createContactWithXref(contact, ContactTypeEnum.CLIENT, this.clientId).subscribe({
			next: (contact: Contact) => {
				this.getContacts();
				this.selectedContacts.push(contact);
				this.selectedContactsChanged.emit(this.selectedContacts);
				this.contactCreatedSubject.next(contact);
				this.buildContactForm();
				this.onCancelCreate();
				this.snackbar.open('Saved new contact', SnackbarActionEnum.SUCCESS);
			},
			error: (err: Error) => {
				this.snackbar.open('Failed to save new contact', SnackbarActionEnum.ERROR);
				console.error(err);
			}
		});
	}

	onCreateContact(): void {
		this.createContact = true;
		this.createContactEvent.emit(true);
	}

	onCancelCreate(): void {
		this.createContact = false;
		this.createContactEvent.emit(false);
	}
}
