<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">{{ contact.name ? (contact.name | titlecase) : 'Contact' }} Detail</span>
		<div
			[ngStyle]="{backgroundColor: contact.active === 1 ? 'green' : 'red'}"
			class="py-1 px-2 ml-2 rounded-full text-center text-white cursor-pointer"
			(click)="showToggleInactiveModal(pill.style.backgroundColor)"
			#pill
		>
			<p class="no-margin">{{ contact.active === 1 ? 'Active' : 'Inactive' }}</p>
		</div>
		<button mat-icon-button (click)="form.enable()" [disabled]="form.enabled">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
	<div class="flex pt-4">
		<div class="w-1/3 mr-2">
			<mat-form-field class="w-full" (click)="form.enable()" tabindex="0">
				<mat-label>Name</mat-label>
				<input matInput formControlName="name" />
			</mat-form-field>
		</div>
		<div class="w-1/3 mr-2">
			<mat-form-field class="w-full" (click)="form.enable()" tabindex="0">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" />
			</mat-form-field>
		</div>
		<div class="w-1/3">
			<mat-form-field class="w-3/5 mr-2" (click)="form.enable()" tabindex="0">
				<mat-label>Phone</mat-label>
				<input matInput formControlName="phone" type="tel" />
			</mat-form-field>
			<mat-form-field class="w-20" (click)="form.enable()" tabindex="0">
				<mat-label>Ext</mat-label>
				<input matInput formControlName="phoneExt" />
			</mat-form-field>
		</div>
	</div>
	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
