import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {QuoteLine} from '../../../core/models/quote-line.interface';
import {CommaRemovalPipe} from '../../../shared/pipes/comma-removal.pipe';
import {CodeService} from '../../../core/services/code.service';
import {Code} from '../../../core/models/code.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {PartData} from '../../../core/models/part-data.interface';

@Component({
	selector: 'app-manual-part-entry',
	templateUrl: './manual-part-entry.component.html',
	styleUrls: ['./manual-part-entry.component.scss']
})
export class ManualPartEntryComponent implements OnInit, OnChanges {
	@Input() startExpanded: boolean = false;
	@Input() quoteForm: FormGroup;
	@Input() submitted: boolean;
	@Input() ngForm: FormGroupDirective;
	@Input() partsLoading: boolean;
	@Input() materialCostTotal: number;

	@Output() showPartListEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() reloadParts: EventEmitter<void> = new EventEmitter<void>();

	partDeletedSubject: Subject<string> = new Subject<string>();
	filterMobilePartsSubject: Subject<boolean> = new Subject<boolean>();
	filterMobileParts: boolean = false;
	partType: string = '';
	percentageOptions: Code[] = [];
	percentageSelected: boolean = false;
	group: FormGroup;
	selected: Code;

	constructor(private format: CommaRemovalPipe, private codeService: CodeService, private snackbar: MatSnackBar) {}

	ngOnInit(): void {
		this.group = this.quoteForm.controls['materialCost'] as FormGroup;
		this.codeService.findByType('MATERIALCOSTPERCENTAGES').subscribe({
			next: (res: Code[]): void => {
				this.percentageOptions = res.sort((a, b) => {
					const val1: number = parseFloat(a.code ?? '');
					const val2: number = parseFloat(b.code ?? '');
					if (val1 > val2) {
						return 1;
					} else if (val1 < val2) {
						return -1;
					}
					return 0;
				});
				if (this.group.get('comment')?.value) {
					const percentage: Code | undefined = this.percentageOptions.find(
						(percentage: Code): boolean => percentage.code === this.group.get('comment')?.value
					);
					if (percentage) {
						this.percentageSelected = true;
						this.selected = percentage;
					}
				}
			},
			error: (err): void => {
				console.error(err);
				this.snackbar.open('Failed to get percentage options', SnackbarActionEnum.ERROR);
			}
		});
	}

	calculateMaterialCost(): void {
		if (this.percentageSelected && this.lineItemPartsFormArray?.length) {
			const value: number = this.materialCostTotal * this.group.get('comment')?.value;
			this.group.get('price')?.setValue(parseFloat(value.toFixed(2)));
		} else {
			this.group.get('price')?.setValue('0.00');
		}
	}

	get lineItemPartsFormArray() {
		return this.quoteForm.controls['lineItemParts'] as FormArray;
	}

	selectChange(event: PartData): void {
		this.quoteForm.markAsDirty();
		if (event.selected) {
			const array: QuoteLine[] = this.lineItemPartsFormArray.value;
			const found: QuoteLine | undefined = array.find(
				(selectedPart: QuoteLine): boolean => selectedPart.erpItemRef === event.part.erpItemRef
			);
			if (!found) {
				let lineItemPart: FormGroup = new FormGroup({
					item: new FormControl(event.part.item),
					erpItemRef: new FormControl(event.part.erpItemRef),
					category: new FormControl(event.part.category),
					description: new FormControl(event.part.description),
					price: new FormControl(event.part.price?.toFixed(4)),
					qty: new FormControl(event.part.qty ?? 1, [Validators.required, Validators.min(1)]),
					unitOfMeasure: new FormControl(event.part.unitOfMeasure),
					weight: new FormControl(event.part.weight),
					type: new FormControl(event.part.type)
				});
				this.lineItemPartsFormArray.push(lineItemPart);
			}
		} else {
			const array: QuoteLine[] = this.lineItemPartsFormArray.value;
			const index: number = array.findIndex((selectedPart: QuoteLine): boolean => selectedPart.erpItemRef === event.part.erpItemRef);
			this.lineItemPartsFormArray.removeAt(index);
		}
	}

	onPercentageSelected(percentage: Code): void {
		this.quoteForm.markAsDirty();
		this.percentageSelected = true;
		this.group.get('comment')?.setValue(percentage.code);
		this.group.get('description')?.setValue('Material Cost Surcharge ' + percentage.codeDescription + '%');
		const getBackArray: QuoteLine[] | undefined = this.quoteForm.get('getBacks')?.value;
		const index: number | undefined = getBackArray?.findIndex((getBack: QuoteLine) => getBack.description?.startsWith('Material'));
		if (index !== undefined) {
			(this.quoteForm.controls['getBacks'] as FormArray)
				.at(index!)
				?.get('description')
				?.setValue('Material Cost Surcharge ' + percentage.codeDescription + '%');
		}
		this.calculateMaterialCost();
	}

	delete(index: number): void {
		this.quoteForm.markAsDirty();
		this.partDeletedSubject.next(this.lineItemPartsFormArray.at(index).get('item')?.value ?? '');
		this.lineItemPartsFormArray.removeAt(index);
	}

	flipMobilePartsFilter() {
		this.filterMobileParts = !this.filterMobileParts;
		this.filterMobilePartsSubject.next(this.filterMobileParts);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ((this.percentageSelected && changes['materialCostTotal']) || changes['manualPartTotal']) {
			this.calculateMaterialCost();
		}
	}
}
