import {Component, OnInit} from '@angular/core';
import {InstallCalendar} from '../core/models/install-calendar.interface';
import {addWeeks, isWeekend, subWeeks} from 'date-fns';
import {InstallCalendarService} from '../core/services/install-calendar.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ProjectInstall} from '../core/models/project-install.interface';
import {
	UpdateProjectInstallDialogComponent,
	UpdateProjectInstallDialogResponse
} from './update-project-install-dialog/update-project-install-dialog.component';
import {ProjectsService} from '../core/services/projects.service';

@Component({
	selector: 'app-install-calendar',
	templateUrl: './install-calendar.component.html',
	styleUrls: ['./install-calendar.component.scss']
})
export class InstallCalendarComponent implements OnInit {
	installCalendar: InstallCalendar[] = [];

	showWeekends = false;
	expandDetails = true;

	selectedDate = new Date();
	previousDate: Date;
	nextDate: Date;

	filter = '';
	filterType = '';

	refreshCount = 0;

	busy = false;
	loadPrevious = false;

	constructor(
		private installCalendarService: InstallCalendarService,
		private _snackBar: MatSnackBar,
		private dialog: MatDialog,
		private projectsService: ProjectsService
	) {}

	ngOnInit() {
		this.previousDate = subWeeks(this.selectedDate, 1);
		this.nextDate = addWeeks(this.selectedDate, 1);
		this.getInstallCalendar(this.selectedDate);
	}

	getInstallCalendar(selectedDate: Date | null = null, atEnd: boolean = true) {
		this.busy = true;
		this.installCalendarService.getInstallCalendar(selectedDate).subscribe({
			next: (installCalendar) => {
				installCalendar.projects.forEach((project) => {
					project.row = project.installContactId * 10;
					if (project.row) {
						this.findConflicts(project, installCalendar.projects);
					}
					if (project.installColor) {
						const endDate = this.installCalendarService.calcEndDate(project.installDate, project.installDuration, project.installWeekend);
						if (endDate) {
							if (new Date(project.installEndDate).toISOString().substring(0, 10) !== endDate.toISOString().substring(0, 10)) {
								project.installEndDate = endDate;
								let projectInstall: ProjectInstall = <ProjectInstall>{};
								Object.assign(projectInstall, project);
								// @ts-ignore
								projectInstall.installEndDate = new Date(endDate).toISOString().substring(0, 10);
								this.projectsService.updateInstall(projectInstall).subscribe({});
							}
						}
					}
				});
				if (atEnd) {
					this.installCalendar.push(installCalendar);
				} else {
					this.installCalendar.unshift(installCalendar);
				}

				this.hasWeekend(installCalendar);

				this.busy = false;
				this.loadPrevious = false;
			},
			error: (err) => {
				this._snackBar.open('Get Install Calendar Failed: ' + err.error.message);
				this.busy = false;
				this.loadPrevious = false;
			}
		});
	}

	refreshCalendar(calendar: InstallCalendar) {
		const selectedDate = new Date(calendar.dates[0]);
		this.installCalendarService.getInstallCalendar(selectedDate).subscribe({
			next: (installCalendar) => {
				installCalendar.projects.forEach((project) => {
					project.row = project.installContactId * (calendar.projects.length + 1);
					if (project.row) {
						this.findConflicts(project, installCalendar.projects);
					}
				});
				Object.assign(calendar, installCalendar);

				this.hasWeekend(installCalendar);

				this.refreshCount--;
				if (this.refreshCount <= 0) {
					this.busy = false;
				}
			},
			error: (err) => {
				this._snackBar.open('Get Install Calendar Failed: ' + err.error.message);
				this.refreshCount--;
				if (this.refreshCount <= 0) {
					this.busy = false;
				}
			}
		});
	}

	hasWeekend(calendar: InstallCalendar) {
		if (!this.showWeekends) {
			calendar.projects.forEach((project) => {
				if (
					!this.showWeekends &&
					(project.offset === 0 ||
						isWeekend(new Date(project.installDate)) ||
						(project.installWeekend && project.offset + project.days > 5))
				) {
					this._snackBar.open('Weekend Install found. Showing Weekends');
					this.showWeekends = true;
				}
			});
		}
	}

	dateChange(e: MatDatepickerInputEvent<string>) {
		this.installCalendar = [];
		this.previousDate = subWeeks(this.selectedDate, 1);
		this.nextDate = addWeeks(this.selectedDate, 1);
		this.getInstallCalendar(this.selectedDate);
	}

	previous() {
		this.loadPrevious = true;
		this.getInstallCalendar(this.previousDate, false);
		this.previousDate = subWeeks(this.previousDate, 1);
	}

	next() {
		if (!this.busy) {
			this.getInstallCalendar(this.nextDate);
			this.nextDate = addWeeks(this.nextDate, 1);
		}
	}

	showDay(i: number) {
		if (!this.showWeekends && (i === 0 || i === 6)) {
			return false;
		}
		return true;
	}

	gridPad() {
		return this.showWeekends ? 1 : 0;
	}

	getFilteredProjects(calendar: InstallCalendar) {
		return calendar.projects.filter(
			(project) =>
				(this.filterType === '' ||
					(this.filterType === 'unscheduled' && project.installDuration < 1) ||
					(this.filterType === 'scheduled' && project.installDuration > 0) ||
					(this.filterType === 'union' && project.union) ||
					(this.filterType === 'nonunion' && !project.union) ||
					(this.filterType === 'day' && !project.nightWork) ||
					(this.filterType === 'night' && project.nightWork) ||
					(this.filterType === 'weekend' && project.installWeekend) ||
					(this.filterType === 'permit' && project.stockPermitReq) ||
					(this.filterType === 'overbooked' && project.overbooked)) &&
				(project.id.toString(10).toLowerCase().includes(this.filter.toLowerCase()) ||
					project.name?.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.orderNo?.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.city?.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.state?.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.postal?.toLowerCase().includes(this.filter.toLowerCase()) ||
					project.contactName?.toLowerCase().includes(this.filter.toLowerCase()))
		);
	}

	projectHide(offset: number, duration: number) {
		if (!this.showWeekends && ((offset === 0 && duration < 2) || offset === 6)) {
			return true;
		}
		return false;
	}

	updateProject(project: ProjectInstall) {
		if (!this.busy) {
			const confirm = this.dialog.open(UpdateProjectInstallDialogComponent, {
				width: '950px',
				maxWidth: '90vw',
				data: {
					project: project
				}
			});
			confirm.afterClosed().subscribe((result) => {
				if (result.success) {
					this.refreshCount = this.installCalendar.length;
					if (this.refreshCount > 0) {
						this.busy = true;
						this.installCalendar.forEach((calendar) => this.refreshCalendar(calendar));
					}
				}
			});
		}
	}

	highlight(projectId: number) {
		this.installCalendar.forEach((calendar) => {
			calendar.projects.forEach((project) => {
				if (project.id === projectId) {
					project.highlight = true;
				}
			});
		});
	}

	unHighlight() {
		this.installCalendar.forEach((calendar) => {
			calendar.projects.forEach((project) => {
				project.highlight = false;
			});
		});
	}

	projectStart(offset: number) {
		let start = this.showWeekends ? offset + 1 : offset;
		if (start === 0) {
			start++;
		}
		return start;
	}

	projectEnd(offset: number, days: number, weekendWork: boolean) {
		let start = this.showWeekends ? offset + 1 : offset;
		if (start === 0) {
			start++;
			if (!this.showWeekends) {
				days--;
			}
		}
		let adjust = start + days;
		let lastDay = 6;
		if (this.showWeekends) {
			if (weekendWork) {
				lastDay = 8;
			} else {
				lastDay = 7;
			}
		}
		if (adjust > lastDay) {
			adjust = lastDay - start + 1;
		}
		return adjust;
	}

	findConflicts(project: ProjectInstall, installProjects: ProjectInstall[]) {
		const installerProjects = installProjects.filter(
			(installerProject) =>
				installerProject.installContactId === project.installContactId && installerProject.id !== project.id && installerProject.row > 0
		);
		installerProjects.sort((a, b) => {
			if (a.row > b.row) {
				return 1;
			} else if (a.row < b.row) {
				return -1;
			}
			return 0;
		});
		installerProjects.forEach((installerProject) => {
			if (
				installerProject.row === project.row &&
				project.offset + (project.days - 1) >= installerProject.offset &&
				project.offset <= installerProject.offset + (installerProject.days - 1)
			) {
				installerProject.overbooked = true;
				project.overbooked = true;
				project.row++;
			} else if (
				installerProject.installContactId === project.installContactId &&
				project.offset + (project.days - 1) >= installerProject.offset &&
				project.offset <= installerProject.offset + (installerProject.days - 1)
			) {
				installerProject.overbooked = true;
				project.overbooked = true;
			}
		});
	}
}
