import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Project, ProjectUpdate} from '../models/project.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../enums/snackbar-action.enum';
import {ProjectTask} from '../models/project-task.model';
import {ProjectSchedule, ProjectScheduleUpdate} from '../models/project-schedule.interface';
import {ProjectActivity} from '../models/project-activity.interface';
import {QuoteHeader} from '../models/quote-header.interface';
import {ProjectEstimate} from '../models/project-estimate.interface';
import {ProjectInstall} from '../models/project-install.interface';
import {Quote} from '../models/quote.interface';
import {Router} from '@angular/router';
import {ProjectInstallShip, UpdateProjectInstallShip} from '../models/project-install-ship.interface';

@Injectable({
	providedIn: 'root'
})
export class ProjectsService {
	baseUrl: string = `${environment.url}/project`;

	private projectsDataSource: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);
	readonly projects: Observable<Project[]> = this.projectsDataSource.asObservable();

	public projectDataSource: BehaviorSubject<Project | null> = new BehaviorSubject<Project | null>(null);
	readonly project: Observable<Project | null> = this.projectDataSource.asObservable();

  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

	private dateRangeOptions: any[] = [];

	constructor(private http: HttpClient, private snackbar: MatSnackBar, private router: Router) {
		this.createDateRangeOptions();
	}

	findAllActive(): void {
		this.http.get<any>(this.baseUrl).subscribe((projects: Project[]) => {
			this.projectsDataSource.next(projects);
		});
	}

	findAllInactive(): void {
		this.http.get<any>(`${this.baseUrl}/inactive`).subscribe((projects: Project[]) => {
			this.projectsDataSource.next(projects);
		});
	}

  isProjectActive(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/${id}/active`);
  }

	findAllChangeRequestDocumentsByQuote(projectId: number): Observable<Quote[]> {
		return this.http.get<Quote[]>(`${this.baseUrl}/${projectId}/quote-documents`);
	}

	findQuotesAssociatedWithProject(id: number): Observable<QuoteHeader[]> {
		return this.http.get<QuoteHeader[]>(`${this.baseUrl}/${id}/quotes`);
	}

	findOne(id: number): Observable<Project> {
		return this.http.get<any>(`${this.baseUrl}/${id}`);
	}

	findByOrder(id: string): Observable<Project> {
		return this.http.get<any>(`${this.baseUrl}/order/${id}`);
	}

	clearProject(): void {
		this.projectDataSource.next(null);
	}

	setProject(id: number) {
		this.http.get<any>(`${this.baseUrl}/${id}`).subscribe((response) => {
			this.projectDataSource.next(response);
		});
	}

	update(project: ProjectUpdate, rfqStatusChange: boolean, returnToProjects: boolean, showSnackbarMessage?: string) {
    this.loadingSubject.next(true);
		if (project.installDate) {
			// @ts-ignore
			project.installDate = new Date(project.installDate).toISOString().substring(0, 10);
		}

		if (project.estimateDate && Object.keys(project.estimateDate).length > 0) {
			// @ts-ignore
			project.estimateDate = new Date(project.estimateDate).toISOString().substring(0, 10);
		}
		const params = new HttpParams().set('rfqStatusChange', rfqStatusChange.toString());
		this.http.put<any>(`${this.baseUrl}/${project.id}`, project, {params}).subscribe(
			(updateProject) => {
				{
					//Update the projects with the newly updated project
					const projects: Project[] = this.projectsDataSource.getValue();
					let projectToUpdate: number = projects.findIndex((project: Project) => {
						return project.id === updateProject.id;
					});
					if (projectToUpdate !== -1) {
						//Merge original and updated project to maintain any UI set fields
						projects[projectToUpdate] = {...projects[projectToUpdate], ...updateProject};
						this.projectsDataSource.next(projects);
					}
					this.projectDataSource.next(updateProject);
					this.snackbar.open(showSnackbarMessage ? showSnackbarMessage : 'Project Updated', SnackbarActionEnum.SUCCESS);
					if (returnToProjects) this.router.navigate(['/projects']);
				}
			},
			(error): void => {
				console.error(error);
				this.snackbar.open('Error Updating Project', SnackbarActionEnum.ERROR);
			},
      () => this.loadingSubject.next(false)
		);
	}

	updateOnly(project: ProjectUpdate): Observable<Project> {
		if (project.installDate) {
			// @ts-ignore
			project.installDate = new Date(project.installDate).toISOString().substring(0, 10);
		}
		if (project.estimateDate) {
			// @ts-ignore
			project.estimateDate = new Date(project.estimateDate).toISOString().substring(0, 10);
		}
		return this.http.put<any>(`${this.baseUrl}/${project.id}`, project);
	}

	create(project: FormData | Project, rfqStatus: boolean): Observable<Project> {
		const params = new HttpParams().set('rfqStatus', rfqStatus.toString());
		return this.http.post<Project>(`${this.baseUrl}`, project, {params});
	}

	getDateRangeOptions(): any[] {
		return this.dateRangeOptions;
	}

	createDateRangeOptions() {
		let now = new Date();
		const currentMonth = now.getMonth();
		//If the date is > 15, set it to the 1st so that first half of month still an option
		now.setHours(0, 0, 0, 0);
		if (now.getUTCDate() < 15) {
			now.setDate(1);
		}

		for (let i = currentMonth; i < currentMonth + 12; i++) {
			const targetMonth = i % 12;
			const targetYear = now.getFullYear() + Math.floor(i / 12);

			const firstOfMonth = new Date(targetYear, targetMonth, 1);
			const fifteenthOfMonth = new Date(targetYear, targetMonth, 16);

			if (now <= firstOfMonth) {
				this.dateRangeOptions.push({
					date: firstOfMonth,
					description: firstOfMonth.toLocaleString('default', {month: 'long'}) + ' - First Half'
				});
			}
			if (now <= fifteenthOfMonth) {
				this.dateRangeOptions.push({
					date: fifteenthOfMonth,
					description: firstOfMonth.toLocaleString('default', {month: 'long'}) + ' - Second Half'
				});
			}
		}
		this.dateRangeOptions.push({
			date: {},
			description: 'Unknown'
		});
	}

	getProjectEstimates(id: number): Observable<ProjectEstimate> {
		return this.http.get<ProjectEstimate>(`${this.baseUrl}/${id}/estimates`);
	}

	getProjectInstallShip(id: number): Observable<ProjectInstallShip> {
		return this.http.get<ProjectInstallShip>(`${this.baseUrl}/${id}/install-ship`);
	}

	getProjectTasks(id: number): Observable<ProjectTask[]> {
		return this.http.get<ProjectTask[]>(`${this.baseUrl}/${id}/tasks`);
	}

	// TODO: this shouldn't be exposed, calculation should happen on the backend when order is created
	calculateProjectTasks(id: number): Observable<ProjectTask[]> {
		return this.http.get<ProjectTask[]>(`${this.baseUrl}/${id}/calculate-tasks`);
	}

	updateProjectTasks(id: number, projectTasks: ProjectTask[], projectInfo: UpdateProjectInstallShip): Observable<ProjectTask[]> {
		return this.http.put<ProjectTask[]>(`${this.baseUrl}/${id}/tasks`, {projectTasks: projectTasks, projectInfo: projectInfo});
	}

	getProjectSchedule(id: number, installDate: Date | null = null): Observable<ProjectSchedule> {
		let params = new HttpParams();
		if (installDate) {
			params = params.append('installDate', installDate.toISOString().substring(0, 10));
		}
		return this.http.get<ProjectSchedule>(`${this.baseUrl}/${id}/schedule`, {params: params});
	}

	updateProjectSchedule(id: number, projectSchedule: ProjectScheduleUpdate): Observable<ProjectSchedule> {
		return this.http.put<ProjectSchedule>(`${this.baseUrl}/${id}/schedule`, projectSchedule);
	}

	getProjectActivities(id: number): Observable<ProjectActivity[]> {
		return this.http.get<ProjectActivity[]>(`${this.baseUrl}/${id}/activities`);
	}

	updateInstall(project: ProjectInstall): Observable<ProjectInstall> {
		return this.http.put<any>(`${this.baseUrl}/${project.id}/install`, project);
	}
}
