import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ProjectsService} from '../services/projects.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../enums/snackbar-action.enum';
import {lastValueFrom, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProjectActiveGuard implements CanActivate {

  constructor(private router: Router, private projectsService: ProjectsService, private snackbar: MatSnackBar) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const projectId = Number(next.paramMap.get('projectId'));
    //Any negative or 0 projectId get bounced back to projects list
    if (+projectId <= 0) {
      this.router.navigate(['/projects']).then();
      this.snackbar.open('The project you tried to visit does not exist!', SnackbarActionEnum.ERROR);
    }

    const isActive: boolean = await this.getIsActive(projectId);
    if (isActive === null) {
      this.router.navigate(['/projects']).then();
      this.snackbar.open('The project you tried to visit does not exist.', SnackbarActionEnum.ERROR);
      return false;
    }
    else if (!isActive) {
      this.router.navigate(['/projects']).then();
      this.snackbar.open('The project you tried to visit is inactive. To view it, please change it to active!', SnackbarActionEnum.ERROR);
      return false;
    }
    return true;
  }

  async getIsActive(id: number): Promise<boolean> {
    const isActive$: Observable<boolean> = this.projectsService.isProjectActive(id);
    return await lastValueFrom(isActive$);
  }
}
