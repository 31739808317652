<h1 mat-dialog-title>Quick Status Update</h1>
<div mat-dialog-content class="min-h-[250px]">
	<div class="pt-2">
		<form class="example-form">
			<mat-form-field appearance="outline">
				<mat-label>Department</mat-label>
				<mat-select [(value)]="currentDepartmentId">
					<mat-option *ngFor="let department of allStatuses | sort : 'asc' : 'status'" [value]="department.id">{{
						department.description
					}}</mat-option>
				</mat-select>
			</mat-form-field>
		</form>
		<mat-radio-group [(ngModel)]="currentStatusId" aria-label="Select an option" class="grid">
			<div class="">Status</div>
			<mat-radio-button color="primary" *ngFor="let status of filteredStatusOptions?.statuses" mat-radio-button [value]="status.id">{{
				status.description
			}}</mat-radio-button>
		</mat-radio-group>
	</div>
</div>
<div class="flex justify-end mt-4">
	<button (click)="closeModal()" class="mr-2" mat-button color="primary">Cancel</button>
	<button (click)="updateProject()" [disabled]="isDepartmentChild()" mat-flat-button color="primary" class="mr-4 mb-4">Update</button>
</div>
