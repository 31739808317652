import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PriceConfig} from '../models/price-config.interface';
import {PriceConfigType} from '../models/price-config-types.interface';

@Injectable({
	providedIn: 'root'
})
export class PriceConfigurationService {
	baseUrl: string = `${environment.url}/core/price-config`;
	priceConfigSubject: Subject<PriceConfig[]> = new Subject();

	constructor(private http: HttpClient) {}

	findAll(): Observable<PriceConfig[]> {
		return this.http.get<PriceConfig[]>(`${this.baseUrl}`);
	}

	findAllPriceConfigTypes(): Observable<PriceConfigType[]> {
		return this.http.get<PriceConfigType[]>(`${this.baseUrl}/types`);
	}

	findPriceConfigsByType(type: string, date?: string): Observable<PriceConfig[]> {
		let params: HttpParams = new HttpParams();

		if (date) {
			params = params.append('date', date);
		}

		return this.http.get<PriceConfig[]>(`${this.baseUrl}/type/${type}`, {params});
	}

	findAllPriceConfigCodeTypes(): Observable<PriceConfigType[]> {
		return this.http.get<PriceConfigType[]>(`${this.baseUrl}/code`);
	}

	findOne(id: number): Observable<PriceConfig> {
		return this.http.get<PriceConfig>(`${this.baseUrl}${id}`);
	}
	update(id: number, priceConfig: Partial<PriceConfig>): Observable<PriceConfig> {
		return this.http.patch<PriceConfig>(`${this.baseUrl}/${id}`, priceConfig);
	}
	create(priceConfig: Partial<PriceConfig>): Observable<PriceConfig> {
		return this.http.post<PriceConfig>(`${this.baseUrl}`, priceConfig);
	}

  remove(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
