<mat-expansion-panel [formGroup]="quoteForm">
	<mat-expansion-panel-header>
		<mat-panel-title *ngIf="(ngForm.submitted && quoteForm.get('getBacks')?.invalid) || (ngForm.submitted && quoteForm.get('discount')?.invalid)"
			>Cost Adjustments</mat-panel-title
		>
		<mat-panel-title *ngIf="!ngForm.submitted || (quoteForm.get('discount')?.valid && quoteForm.get('getBacks')?.valid)"
			>Cost Adjustments</mat-panel-title
		>
    <mat-label *ngIf="this.costAdjustmentTotal || quoteForm.get('discount')?.value['price']" class="mr-4 text-red">{{ quoteForm.get('discount')?.value['price'] | currency }}</mat-label>
		<mat-label *ngIf="this.costAdjustmentTotal || quoteForm.get('discount')?.value['price']" class="mr-4 text-lightGreen">+{{ this.costAdjustmentTotal | currency }}</mat-label>
    <mat-label *ngIf="!this.costAdjustmentTotal && !quoteForm.get('discount')?.value['price']" class="mr-4">$0.00</mat-label>
	</mat-expansion-panel-header>

	<div class="flex flex-col" formGroupName="discount">
		<span class="text-lg mb-2">Discount</span>
		<div class="flex space-x-2">
			<mat-form-field class="w-1/2">
				<span matPrefix class="ml-3 text-gray-500">$</span>
				<mat-label>Amount</mat-label>
				<input matInput formControlName="price" type="number"/>
        <mat-error *ngIf="quoteForm.get('discount')?.get('price')?.touched && quoteForm.get('discount')?.get('price')?.invalid">Please enter discount as a negative</mat-error>
			</mat-form-field>

			<mat-form-field class="w-1/2">
				<mat-label>Discount Reason</mat-label>
				<mat-select formControlName="category">
					<mat-option *ngFor="let category of getBackCategories" [value]="category.codeDescription">
						{{ category.codeDescription }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<mat-form-field class="w-full">
			<mat-label>Comment</mat-label>
			<textarea matInput formControlName="comment"></textarea>
		</mat-form-field>
	</div>

	<div class="mb-2">
		<mat-divider></mat-divider>
	</div>

	<span class="text-lg mr-4 mb-4"> Get Back's </span>
	<button mat-icon-button type="button" class="-translate-x-4 translate-y-1" (click)="openCostAdjustmentModal(false, {})">
		<mat-icon>add</mat-icon>
	</button>

	<div formArrayName="getBacks" *ngIf="getBacks.length; else elseBlock" class="w-full my-2">
		<div *ngFor="let getBack of getBacks.controls; index as i" class="flex">
			<div [formGroupName]="i" class="w-full p-4 border border-gray-500 rounded mt-2">
				<div class="flex justify-between">
					<div class="flex">
						<span class="text-lg translate-y-2">GL{{ getBack.get('glNumber')?.value }} - {{ getBack.get('description')?.value }}</span>
						<button
							type="button"
							mat-icon-button
							class="-translate-y-0.5"
							(click)="openCostAdjustmentModal(true, getBack.value, i, getBack)"
						>
							<mat-icon>edit</mat-icon>
						</button>
					</div>
					<button type="button" mat-icon-button (click)="removeCostAdjustment(i)" class="-translate-y-0.5">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
				<div class="flex flex-col ml-2">
					<span class="text-xs font-semibold">Amount</span>
					<span class="">${{ getBack.get('price')?.value ?? 'N/A' }}</span>
					<span class="text-xs font-semibold">Comment</span>
					<span class="">{{ getBack.get('comment')?.value ?? 'N/A' }}</span>
				</div>
			</div>
		</div>
	</div>

	<ng-template #elseBlock>
		<div class="mt-2">No Get Back's to display</div>
	</ng-template>
</mat-expansion-panel>
