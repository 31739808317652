import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PriceConfigurationService} from '../../../core/services/price-configuration.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CodeService} from '../../../core/services/code.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {isBefore, startOfDay} from 'date-fns';
import {Code} from '../../../core/models/code.interface';
import {PriceConfig} from "../../../core/models/price-config.interface";
import {PriceConfigType} from "../../../core/models/price-config-types.interface";

@Component({
	selector: 'app-price-configuration-dialog',
	templateUrl: './price-configuration-dialog.component.html',
	styleUrls: ['./price-configuration-dialog.component.scss']
})
export class PriceConfigurationDialogComponent {
	form: FormGroup;
	codeOptions: Code[] = [];
  editMode: boolean = false;

  priceConfiguration: PriceConfig;
  priceConfigTypes: PriceConfigType[];

	constructor(
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<PriceConfigurationDialogComponent>,
		private priceConfigService: PriceConfigurationService,
		private codeService: CodeService,
		private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
	) {
    this.form = this.formBuilder.group({
      effectiveDate: new FormControl(new Date().toISOString(), [Validators.required, this.dateValidator]),
      type: new FormControl('', [Validators.required]),
      isPercent: new FormControl(false, [Validators.required]),
      charge: new FormControl('', [Validators.required]),
    });

    if (data.priceConfigTypes) {
      this.priceConfigTypes = data.priceConfigTypes;
    }

    this.editMode = data.editMode;

    if (this.editMode) {
      this.priceConfiguration = data.priceConfig;
      this.form.patchValue(data.priceConfig);
      if (data.priceConfig.isPercent) {
        this.form.get('charge')?.setValue(this.form.get('charge')?.value * 100)
      }

    }
	}

	ngOnInit(): void {
		this.codeService.findPriceConfigCodes().subscribe({
			next: (response: any[]) => (this.codeOptions = response),
			error: (error: any): void => {
				console.error(error);
				this.snackbar.open('Error loading code options', SnackbarActionEnum.ERROR);
			}
		});
	}

	dateValidator(control: FormControl): {[s: string]: boolean} | null {
    if (control.value) {
			const date: Date = startOfDay(new Date(control.value));
			const today: Date = startOfDay(new Date());
			if (isBefore(date, today)) {
				return {invalidDate: true};
			}
    }
		return null;
	}

	close(): void {
		this.dialogRef.close(false);
	}

	createPriceConfig(): void {
    if (this.isThereEffectiveDateConflict()) {
      return;
    }

    this.formatPriceConfig();

		this.priceConfigService.create(this.form.getRawValue()).subscribe({
			next: (): void => {
				this.snackbar.open('Price Configuration created successfully', SnackbarActionEnum.SUCCESS);
				this.dialogRef.close(true);
			},
			error: (err): void => {
				this.snackbar.open(err.error.error, SnackbarActionEnum.ERROR);
			}
		});
  }
  editPriceConfig(): void {
    if (this.isThereEffectiveDateConflict()) {
      return;
    }

    this.formatPriceConfig();

    this.priceConfigService.update(this.priceConfiguration.id!, this.form.getRawValue()).subscribe({
      next: (): void => {
        this.snackbar.open('Price configuration has been updated successfully', SnackbarActionEnum.SUCCESS);
        this.dialogRef.close(true);
      },
      error: (err): void => {
        this.snackbar.open(err.error.error, SnackbarActionEnum.ERROR)
      }
    });
  }

  isThereEffectiveDateConflict(): boolean {
    let dateConflict: boolean = false;
    const priceConfigType: PriceConfigType | undefined = this.priceConfigTypes.find((priceConfigType: PriceConfigType): boolean => priceConfigType.name === this.form.get('type')?.value)
    priceConfigType?.priceConfigs?.forEach((priceConfig: PriceConfig): void => {
      const dateAsString: string = new Date(this.form.get('effectiveDate')?.value).toISOString().split('T')[0];
      if (this.editMode) {
        if (priceConfig.effectiveDate! === dateAsString && priceConfig.id !== this.priceConfiguration.id!) {
          dateConflict = true;
        }
      } else if (priceConfig.effectiveDate! === dateAsString) {
        dateConflict = true;
      }
    });

    if (dateConflict) {
      this.snackbar.open('There is already a price configuration with this effective date', SnackbarActionEnum.ALERT);
      return true;
    }
    return false;
  }

  formatPriceConfig(): void {
    this.form.get('effectiveDate')?.setValue(new Date(this.form.get('effectiveDate')?.value).toISOString().split('T')[0]);
    if (this.form.get('isPercent')?.value) {
      this.form.get('charge')?.setValue(this.form.get('charge')?.value / 100);
    }
  }
}
