<div class="ml-4">
	<div class="p-2 flex justify-between">
		<div class="flex justify-center flex-1">
			<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
				<mat-label>Search</mat-label>
				<input
					id="quote-searchbar"
					type="text"
					(keyup)="applyFilter($event)"
					placeholder="Search by quote number, client, location, or date."
					matInput
					#searchInput
				/>
			</mat-form-field>
			<button
				mat-mini-fab
				color="primary"
				class="absolute left-0 top-0 mt-2 ml-2"
				(click)="openProjectSelectDialog()"
				[disabled]="this.projectId && createNewQuoteDisabled"
			>
				<mat-icon>add</mat-icon>
			</button>
		</div>
		<button mat-raised-button color="primary" [routerLink]="'/quick-quote'">Quick Quote</button>
	</div>

	<div class="{{ projectId ? 'w-[70vw]' : 'w-[85vw]' }} border-gray-700 border-2 rounded overflow-auto m-auto">
		<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingQuotes">
			<!--Quote Number Column-->
			<ng-container matColumnDef="quoteNumber">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by quote number">Quote No.</th>
				<td mat-cell *matCellDef="let quote">
					{{ getQuoteNumber(quote) ?? 'N/A' }}
				</td>
			</ng-container>

			<!--Quote Date Column-->
			<ng-container matColumnDef="quoteDate">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by quote date">Quote Date</th>
				<td mat-cell *matCellDef="let quote">
					{{ quote.date ? (quote.date | date : 'MM/dd/y') : 'Not Submitted' }}
				</td>
			</ng-container>

			<!--Status Column-->
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
				<td mat-cell *matCellDef="let quote">
					{{ quote.status?.replace('_', ' ') | titlecase }}
				</td>
			</ng-container>

			<!-- Email Column -->
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let quote">
					<button
						matTooltip="Click to view email history"
						mat-icon-button
						*ngIf="quote.status !== QuoteStatusEnum.IN_PROGRESS"
						(click)="goToEmailList(quote)"
					>
						<mat-icon>mail_outline</mat-icon>
					</button>
				</td>
			</ng-container>

			<!--Client/Project Column-->
			<ng-container matColumnDef="project.name">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by project name">Project Name</th>
				<td mat-cell *matCellDef="let quote">
					{{ quote.project.name | titlecase }}
				</td>
			</ng-container>

			<!--Client/Project Column-->
			<ng-container matColumnDef="project.clientId">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by client">Client</th>
				<td mat-cell *matCellDef="let quote">
					{{ quote.project.clientId | titlecase }}
				</td>
			</ng-container>

			<!--Location Column-->
			<ng-container matColumnDef="location">
				<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by location">Location</th>
				<td mat-cell *matCellDef="let quote">
					{{ quote.project.city ? (quote.project.city | titlecase) + ',' : null }}&nbsp;{{
						quote.project.state ? (quote.project.state | uppercase) : null
					}}&nbsp;{{ quote.project.postal ? quote.project.postal : null }}
				</td>
			</ng-container>

			<ng-container matColumnDef="salesOrder">
				<th mat-header-cell *matHeaderCellDef>Sales Order</th>
				<td mat-cell *matCellDef="let quote">{{ quote.salesOrder !== '' ? quote.salesOrder : 'Not Created' }}</td>
			</ng-container>

			<ng-container matColumnDef="poNumber">
				<th mat-header-cell *matHeaderCellDef>PO Number</th>
				<td mat-cell *matCellDef="let quote">{{ quote.poNumber ? quote.poNumber : 'Not available' }}</td>
			</ng-container>

			<ng-container matColumnDef="grandTotal">
				<th mat-header-cell *matHeaderCellDef>Grand Total</th>
				<td mat-cell *matCellDef="let quote">{{ quote.grandTotal | currency }}</td>
			</ng-container>

			<ng-container matColumnDef="quote-actions">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let quote">
					<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button
							mat-menu-item
							*ngIf="quote.status === QuoteStatusEnum.CHANGE_REQUESTED"
							routerLink="/project/{{ quote.projectId }}/quote/{{ quote.id }}/requote"
						>
							<mat-icon>keyboard_return</mat-icon>
							<span>Re-quote</span>
						</button>
						<button
							mat-menu-item
							*ngIf="
								quote.status !== QuoteStatusEnum.SALES_ORDER && quote.status !== QuoteStatusEnum.CHANGE_REQUESTED && !quote.project.orderNo
							"
							(click)="goToOrder(quote)"
						>
							<mat-icon>launch</mat-icon>
							<span>Create Order</span>
						</button>
						<button mat-menu-item routerLink="/project/{{ quote.projectId }}/quote/{{ quote.id }}/copy">
							<mat-icon>file_copy</mat-icon>
							<span>Copy Quote</span>
						</button>
						<button mat-menu-item routerLink="/project/{{ quote.projectId }}/quote/{{ quote.id }}/revision">
							<mat-icon>note_add</mat-icon>
							<span>Make Quote Revision</span>
						</button>
						<button mat-menu-item (click)="deleteQuote(quote)">
							<mat-icon>delete</mat-icon>
							<span>Delete Quote</span>
						</button>
					</mat-menu>
				</td>
			</ng-container>

			<ng-container matColumnDef="view-quote">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let quote">
					<button mat-stroked-button color="primary" (click)="navigateToQuote(quote)" [disabled]="viewQuoteButtonDisabled">
						VIEW QUOTE
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let quote">
					<button [disabled]="busy" (click)="$event.stopPropagation(); deleteQuote(quote)" type="button">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
			<tr mat-row *matRowDef="let quote; let i = dataIndex; columns: columnsToDisplay" class="quote-row"></tr>

			<!-- Row shown when there is no matching data. -->
			<tr class="mat-row flex" *matNoDataRow>
				<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingQuotes">No data matching the filter "{{ searchInput.value }}"</td>
			</tr>
		</table>
		<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingQuotes">
			<mat-spinner></mat-spinner>
		</div>
	</div>
</div>
