<h2 mat-dialog-title>Create Status</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
	<div class="flex gap-4">
		<mat-form-field class="w-1/2 pt-2">
			<mat-label>Status #</mat-label>
			<input matInput formControlName="status" />
			<mat-error *ngIf="form.get('status')?.hasError('required')"> Status is required </mat-error>
			<mat-error *ngIf="form.get('status')?.hasError('maxlength')">
				{{ this.form.get('status')?.value.length }} / 100 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
		<mat-form-field class="w-1/2 pt-2">
			<mat-label>Description</mat-label>
			<input matInput formControlName="description" />
			<mat-error *ngIf="form.get('description')?.hasError('required')"> Description is required </mat-error>
			<mat-error *ngIf="form.get('description')?.hasError('maxlength')">
				{{ this.form.get('description')?.value.length }} / 256 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
	</div>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close="true" class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createStatus()">Create</button>
</div>
