<div class="m-4" infinite-scroll [immediateCheck]="true" (scrolled)="next()">
	<div class="flex mb-2 justify-between">
		<div>
			<mat-form-field appearance="outline" class="mr-2 w-44" subscriptSizing="dynamic">
				<mat-label class="font-medium">Date</mat-label>
				<input matInput [matDatepicker]="datepicker" (dateChange)="dateChange($event)" [(ngModel)]="selectedDate" />
				<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
				<mat-datepicker #datepicker> </mat-datepicker>
				<mat-error>Install Date <strong>required</strong>.</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline" class="mr-2" subscriptSizing="dynamic">
				<mat-label class="font-medium">Filter</mat-label>
				<input matInput [(ngModel)]="filter" />
			</mat-form-field>
			<mat-button-toggle-group [(ngModel)]="filterType" class="mr-2">
				<mat-button-toggle value="">All</mat-button-toggle>
				<mat-button-toggle [value]="filterType" *ngFor="let filterType of filterTypeList">
					{{ filterType }}
				</mat-button-toggle>
				<mat-button-toggle value="Shipping">Shipping</mat-button-toggle>
			</mat-button-toggle-group>
			<mat-button-toggle-group [(ngModel)]="filterApproved" class="mr-2">
				<mat-button-toggle value="">All</mat-button-toggle>
				<mat-button-toggle value="approved">Approved</mat-button-toggle>
				<mat-button-toggle value="pending">Pending</mat-button-toggle>
			</mat-button-toggle-group>
			<mat-form-field appearance="outline" class="mr-2">
				<mat-label>Status</mat-label>
				<mat-select multiple [(ngModel)]="statusFilter">
					<mat-option [value]="statusDescription" *ngFor="let statusDescription of statusDescriptionList">
						{{ statusDescription }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-button-toggle-group *ngIf="locations.length" [(ngModel)]="locationFilter" (change)="selectLocation()">
				<!--mat-button-toggle value="">All</mat-button-toggle-->
				<mat-button-toggle *ngFor="let location of locations" [value]="location">{{ location.name }}</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div>
			<button mat-icon-button [mat-menu-trigger-for]="calendarOptions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #calendarOptions>
				<button mat-menu-item (click)="previous()" [disabled]="busy">Load Previous</button>
				<div mat-menu-item>
					<mat-checkbox [(ngModel)]="expandDetails">Expand Jobs</mat-checkbox>
				</div>
				<div mat-menu-item>
					<mat-checkbox [(ngModel)]="showWeekends">Show Weekends</mat-checkbox>
				</div>
			</mat-menu>
		</div>
	</div>
	<div class="m-8" *ngIf="loadPrevious">
		<mat-spinner></mat-spinner>
	</div>
	<div
		class="flex flex-col self-start rounded-lg calendar"
		*ngFor="let calendar of calendar; let cI = index"
		[class.no-weekend]="!showWeekends"
	>
		<mat-divider class="w-full"></mat-divider>
		<div class="grid grid-rows-1 grid-cols-x sticky top-0">
			<ng-container *ngFor="let head of calendar.productionHours | keyvalue; let i = index">
				<div
					(click)="editProductionHourOverride(cI, head.key)"
					class="justify-self-stretch text-center font-bold header pb-2 pt-2 cursor-pointer"
					[ngClass]="head.key | date : 'E'"
					[style.grid-column-start]="i + gridPad()"
					[style.grid-column-end]="i + gridPad() + 1"
					*ngIf="showDay(i)"
				>
					{{ head.key | date : 'EEEEEE' }}
					<br />
					{{ head.key | date : 'M/d' }}
				</div>
			</ng-container>
		</div>
		<div class="mb-2">
			<mat-divider class="w-full"></mat-divider>
		</div>
		<ng-container *ngFor="let task of getCalendarTasks(calendar) | keyvalue; let i = index">
			<mat-divider class="w-full"></mat-divider>
			<div
				class="flex w-full font-bold p-2 justify-between"
				(click)="task.value.expand = !task.value.expand"
				[ngClass]="[getClass(task.value.taskTypeName) + '-hours', getClass(task.value.taskTypeName)]"
			>
				<span class="mt-2">{{ task.value.taskTypeName }}</span>
				<span>
					<mat-icon *ngIf="task.value.expand">expand_less</mat-icon>
					<mat-icon *ngIf="!task.value.expand">expand_more</mat-icon>
				</span>
			</div>
			<div class="grid grid-rows-1 grid-cols-x mt-2 mb-2" *ngIf="!task.value.expand">
				<ng-container *ngFor="let day of calendar.productionHours | keyvalue; let pI = index">
					<div
						class="justify-self-center text-center font-bold cursor-pointer"
						(click)="editProductionHourOverride(cI, day.key)"
						[ngClass]="getClass(task.value.taskTypeName) + '-hours'"
						[matTooltip]="getProductionComment(day.value, task.key)"
						*ngIf="showDay(pI)"
					>
						{{ getProductionSchedule(day.value, task.key) }} / {{ getProductionHours(day.value, task.key) }}
					</div>
				</ng-container>
			</div>
			<mat-divider class="w-full"></mat-divider>
			<div class="grid grid-rows-1 grid-cols-x mb-4 pt-1" *ngIf="!task.value.expand">
				<div
					*ngFor="let project of getFilteredTaskProjects(task.value) | keyvalue"
					class="p-1"
					[ngClass]="{'not-approved': !project.value.approved}"
					[hidden]="weekendHide(project.value.hours, project.value.offset)"
					[style.grid-column-end]="
						project.value.offset + projectDays(project.value.hours) + projectAdjust(project.value.offset, project.value.hours)
					"
					[style.grid-column-start]="projectStart(project.value.offset)"
				>
					<div
						[routerLink]="['/', 'project', project.key, navigateTo(task.value.taskListType)]"
						[class.highlight]="project.value.highlight"
						[class.mat-elevation-z8]="project.value.highlight"
						[ngClass]="getClass(task.value.taskTypeName)"
						class="border-2 row-start-1"
						(mouseenter)="highlight(project.key)"
						(mouseleave)="unHighlight()"
					>
						<div class="flex justify-between m-2 font-bold">
							<div class="flex flex-col">
								<span>{{ project.value.orderNo }} {{ project.value.projectName }}</span>
								<span class="text-xs">{{ project.value.projectLocation }}</span>
							</div>
							<div class="flex items-start text-xs">
								<mat-icon class="text-xs">local_shipping</mat-icon>
								<div class="flex flex-col">
									{{ project.value.shipDate | date : 'shortDate' }}
									<span class="text-xs text-right">{{ project.value.statusDescription }}</span>
								</div>
							</div>
						</div>
						<div
							class="grid grid-rows-1 mt-2 mb-2"
							*ngIf="expandDetails"
							[ngStyle]="{
								'grid-template-columns': 'repeat(' + projectHours(project.value.hours, project.value.offset) + ', minmax(0, 1fr))'
							}"
						>
							<ng-container *ngFor="let hours of project.value.hours | keyvalue">
								<div
									class="justify-self-center text-center"
									*ngIf="showHours(project.value.offset, hours.key)"
									[style.grid-column-start]="hoursOffset(project.value.offset, hours.key)"
								>
									{{ hours.value.hours > 0 ? hours.value.hours : '' }}
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<mat-divider class="w-full"></mat-divider>
		<ng-container *ngIf="getCalendarShipping(calendar) && calendar.scheduleShipping.length">
			<div
				class="flex w-full font-bold p-2 justify-between"
				[ngClass]="[getClass('Shipping') + '-hours', getClass('Shipping')]"
				(click)="calendar.expandShipping = !calendar.expandShipping"
			>
				<span class="mt-2">Shipping</span>
				<span>
					<mat-icon *ngIf="calendar.expandShipping">expand_less</mat-icon>
					<mat-icon *ngIf="!calendar.expandShipping">expand_more</mat-icon>
				</span>
			</div>
			<mat-divider class="w-full"></mat-divider>
			<div class="grid grid-rows-1 grid-cols-x mb-4 pt-1" *ngIf="!calendar.expandShipping">
				<div
					*ngFor="let shipment of getFilteredShippingProjects(calendar)"
					class="p-1"
					[ngClass]="{'not-approved': !shipment.approved}"
					[style.grid-column-start]="shipment.offset + gridPad()"
					[hidden]="shipmentHide(shipment.offset)"
				>
					<div
						[routerLink]="['/', 'project', shipment.projectId, navigateTo('shipping')]"
						[class.highlight]="shipment.highlight"
						[class.mat-elevation-z8]="shipment.highlight"
						class="border-2 row-start-1"
						[ngClass]="getClass('Shipping')"
						(mouseenter)="highlight(shipment.projectId)"
						(mouseleave)="unHighlight()"
					>
						<div class="flex justify-between m-2 font-bold">
							<div class="flex flex-col">
								<span>{{ shipment.orderNo }} {{ shipment.projectName }}</span>
								<span class="text-xs">{{ shipment.projectLocation }}</span>
							</div>
							<div class="flex items-start text-xs">
								<mat-icon class="text-xs">local_shipping</mat-icon>
								<div class="flex flex-col">
									{{ shipment.shipDate | date : 'shortDate' }}
									<span class="text-xs text-right">{{ shipment.statusDescription }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<mat-divider class="w-full"></mat-divider>
	</div>
	<div class="m-8" *ngIf="busy && !loadPrevious">
		<mat-spinner></mat-spinner>
	</div>
	<button class="my-2" *ngIf="!busy" mat-raised-button color="primary" (click)="next()">Load More</button>
</div>
