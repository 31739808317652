import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GlCode} from '../../../core/models/gl-code.interface';
import {GlCodeService} from '../../../core/services/gl-code.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateGlCodeComponent} from '../create-gl-code/create-gl-code.component';
import {Code} from '../../../core/models/code.interface';
import {CodeService} from '../../../core/services/code.service';
import {CodeTypeEnum} from '../../../core/enums/code-type.enum';
import {map} from 'rxjs';

@Component({
	selector: 'app-gl-codes-list',
	templateUrl: './gl-codes-list.component.html',
	styleUrls: ['./gl-codes-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class GlCodesListComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	columnsToDisplay: string[] = ['glNumber', 'description', 'type', 'category'];
	isLoadingCodes: boolean = true;
	codes: GlCode[];
	codeTypes: Code[] = [];
	categories: string[] = [];

	constructor(private glCodeService: GlCodeService, private codeService: CodeService, private dialog: MatDialog) {}
	ngOnInit() {
		this.glCodeService.subjectFindAll();
		this.glCodeService.glCodeSubject
			.pipe(map((codes: GlCode[]) => codes.sort((a: GlCode, b: GlCode) => +a.glNumber! - +b.glNumber!)))
			.subscribe((response) => {
				// Store the expanded state of each row before updating the data source
				const expandedStates: boolean[] = [];
				for (let i = 0; i < this.dataSource.data.length; i++) {
					expandedStates[i] = this.dataSource.data[i].expanded;
				}
				//Remove any deleted codes.
				for (const [i, removedRow] of this.dataSource.data.entries()) {
					const index = response.findIndex((row) => row.id === removedRow.id);
					if (index === -1) {
						this.dataSource.data.splice(i, 1);
					}
				}
				// Update the data source with new data
				for (const updatedRow of response) {
					const index = this.dataSource.data.findIndex((row) => row.id === updatedRow.id);
					if (index !== -1) {
						// Preserve the expanded state of the row before updating it
						updatedRow.expanded = this.dataSource.data[index].expanded;
						this.dataSource.data[index] = updatedRow;
					} else {
						// Add the new row to the data source
						this.dataSource.data.push(updatedRow);
					}
				}
				// Restore the expanded state of each row after updating the data source
				for (let i = 0; i < this.dataSource.data.length; i++) {
					this.dataSource.data[i].expanded = expandedStates[i];
				}
				// Refresh the data source to update the table
				this.dataSource.data = [...this.dataSource.data];

				//create or update categories array
				response.forEach((code: GlCode) => {
					if (!this.categories.includes(code.category?.trim()!)) {
						this.categories.push(code.category?.trim()!);
					}
				});
				this.isLoadingCodes = false;
			});

		this.codeService.findByType(CodeTypeEnum.GL_CODE_TYPE).subscribe((response) => {
			this.codeTypes = this.codeTypes.concat(response);
		});

		this.codeService.findByType(CodeTypeEnum.TAX_JURISDICTION).subscribe((response) => {
			this.codeTypes = this.codeTypes.concat(response);
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreateGlCodeModal() {
		const dialogRef = this.dialog.open(CreateGlCodeComponent, {
			width: '70vw',
			maxWidth: '800px',
			disableClose: true
		});
		dialogRef.componentInstance.codeTypes = this.codeTypes;
	}
}
