import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {TaskType} from '../models/task-type.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TaskTypeService {
	baseUrl: string = `${environment.url}/core/task-type`;
	taskTypeSubject: Subject<TaskType[]> = new Subject<TaskType[]>();
	constructor(private http: HttpClient) {}

	findAll(): void {
		this.http.get<TaskType[]>(this.baseUrl).subscribe((response) => {
			this.taskTypeSubject.next(response);
		});
	}

	findOne(id: number): Observable<TaskType> {
		return this.http.get<TaskType>(`${this.baseUrl}/${id}`);
	}

	create(taskType: TaskType): Observable<TaskType> {
		return this.http.post<TaskType>(this.baseUrl, taskType);
	}

	update(id: number, glCode: TaskType): Observable<TaskType> {
		return this.http.put<TaskType>(`${this.baseUrl}/${id}`, glCode);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}

	getClass(name: string): string {
		switch (name) {
			case 'Production':
				return 'task-type-1';
			case 'Wood':
				return 'task-type-2';
			case 'Warehouse':
				return 'task-type-3';
			case 'Shipping':
				return 'task-type-4';
			default:
				return 'task-type-0';
		}
	}
}
