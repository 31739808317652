import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactService} from '../../../../core/services/contact.service';
import {ContactsInputComponent} from '../contacts-input/contacts-input.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarActionEnum} from '../../../../core/enums/snackbar-action.enum';
import {Contact} from '../../../../core/models/contact.interface';
import {ValidatorRegexEnum} from '../../../../core/enums/validator-regex.enum';

@Component({
	selector: 'app-contact-info',
	templateUrl: './contact-info.component.html',
	styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent {
	contactForm: FormGroup;
	refType: string;
	refId: number;
	contact: Contact;
	editMode: boolean = false;

	constructor(
		private dialogRef: MatDialogRef<ContactsInputComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private contactService: ContactService,
		private fb: FormBuilder,
		private snackbar: MatSnackBar
	) {
		this.refId = data.refId;
		this.refType = data.refType;
		this.editMode = data.editMode;

		this.contact = data.contact ?? {};

		this.buildContactForm(this.contact);
	}

	buildContactForm(contact: Contact): void {
		let firstName = null;
		let lastName = null;

		if (contact) {
			firstName = contact.name?.split(' ')[0];
			lastName = contact.name?.split(' ')[1];
		}

		this.contactForm = this.fb.group({
			firstName: [firstName ?? '', Validators.required],
			lastName: [lastName ?? ''],
			email: [contact.email, [Validators.required, Validators.pattern(ValidatorRegexEnum.EMAIL)]],
			phone: [contact.phone, [Validators.pattern(ValidatorRegexEnum.PHONE)]],
			phoneExt: [contact.phoneExt]
		});
	}

	close() {
		this.dialogRef.close();
	}

	createContact(): void {
		this.concatenateName();
		Object.assign(this.contact, this.contactForm.value);

		this.contactService.createContactWithXref(this.contact, this.refType, this.refId.toString()).subscribe({
			next: (contact) => {
				this.snackbar.open('Contact created', SnackbarActionEnum.SUCCESS);
				this.dialogRef.close(contact);
			},
			error: (err) => {
				console.error(err);
				this.snackbar.open('Error creating contact', SnackbarActionEnum.ERROR);
			}
		});
	}

	updateContact(): void {
		this.concatenateName();
		Object.assign(this.contact, this.contactForm.value);

		this.contactService.update(this.contact).subscribe({
			next: (contact) => {
				this.snackbar.open('Contact updated', SnackbarActionEnum.SUCCESS);
				this.dialogRef.close(contact);
			},
			error: (err) => {
				console.error(err);
				this.snackbar.open('Error updating contact', SnackbarActionEnum.ERROR);
			}
		});
	}

	concatenateName(): void {
		this.contact.name = this.contactForm.get('firstName')?.value;
		if (this.contactForm.get('lastName')?.value) {
			this.contact.name += ' ' + this.contactForm.get('lastName')?.value.trim();
		}
	}
}
