import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InstallLocationService} from '../../../core/services/install-location.service';
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-create-install-location',
	templateUrl: './create-install-location.component.html',
	styleUrls: ['./create-install-location.component.scss']
})
export class CreateInstallLocationComponent implements OnInit {
	form: FormGroup;
	selectorInputValue: string;

	constructor(
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		private installLocationService: InstallLocationService,
		private dialogRef: MatDialogRef<CreateInstallLocationComponent>
	) {
		this.form = this.formBuilder.group({
			name: ['', Validators.required]
		});
	}
	ngOnInit() {
		this.form.controls['name'].setValue(this.selectorInputValue);
	}

	createLocation() {
		this.installLocationService.create(this.form.getRawValue()).subscribe(
			(response) => {
				this.dialogRef.close();
				this.snackbar.open('Install Location Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.installLocationService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
}
