import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {InstallLocation} from '../../core/models/install-location.interface';
import {ProjectDocumentService} from '../../core/services/project-document.service';
import {ProjectDraftingDeleteComponent} from '../../project/project-drafting/project-drafting-delete/project-drafting-delete.component';
import {MatDialog} from '@angular/material/dialog';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {ProjectsService} from '../../core/services/projects.service';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ProjectFileArchiveDialogComponent} from './project-file-archive-dialog/project-file-archive-dialog.component';

@Component({
	selector: 'app-project-file-upload',
	templateUrl: './project-file-upload.component.html',
	styleUrls: ['./project-file-upload.component.scss']
})
export class ProjectFileUploadComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() projectId: number;
	@Input() type: InstallLocation;
	@Input() departmentId: string;
	@Input() uploadedFiles: ProjectDocument[] | undefined;
	@Input() fileChanges: ProjectDocument[] = [];
	@Input() maxFileLength: number;
	@Input() subType: string;
	@Output('fileAddEmitter') fileAddEmitter = new EventEmitter<File[]>();
	@Output('fileRemoveEmitter') fileRemoveEmitter = new EventEmitter<File>();
	@Output('documentDtoEmitter') documentDtoEmitter = new EventEmitter<ProjectDocument[]>();
	files: File[] = [];
	projectDocumentDtos: ProjectDocument[] = [];
	surveyFileUploadSubscription: Subscription;
	archivedFiles: ProjectDocument[] | undefined;

	constructor(
		private projectDocumentsService: ProjectDocumentService,
		private projectsService: ProjectsService,
		private dialog: MatDialog,
		private snackbar: MatSnackBar,
		private cdRef: ChangeDetectorRef
	) {}
	ngOnInit() {}
	ngAfterViewInit() {
		if (this.maxFileLength == 1) {
			this.uploadedFiles = this.uploadedFiles ? this.uploadedFiles.filter((file) => file.subType == 'Survey') : this.uploadedFiles;
			this.surveyFileUploadSubscription = this.projectDocumentsService.surveyFileUploadedSubject.subscribe((response) => {
				if (!this.uploadedFiles) this.uploadedFiles = [];
				this.uploadedFiles.push(this.projectDocumentDtos[0]);
				this.files = [];
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.uploadedFiles) {
			this.uploadedFiles = this.uploadedFiles.filter((each: ProjectDocument) => {
				return each.installLocationId === this.type.id;
			});
		}
		if (this.fileChanges.length) {
			this.files = this.files.filter((each: File, i: number) => {
				let fileMatch = this.fileChanges.find((doc) => {
					return doc.documentName === each.name;
				});
				return !fileMatch;
			});
		}
	}

	//this any type is here because this is a custom event and the docs provide no actual type for it.
	onFilesAdded(event: any) {
    let fileNameLengthExceeded: boolean = false;
    event.addedFiles.forEach((file: File) => {
      if (file.name.length > 100) {
        fileNameLengthExceeded = true;
      }
    });

    if (fileNameLengthExceeded) {
      this.snackbar.open('Length of file name cannot exceed 100 characters, including extension.', SnackbarActionEnum.ERROR, {duration:4800});
      return;
    }

		if (!this.type) {
			this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Cannot Upload File',
					message: "Please choose a location in the 'Project Details' before uploading files!"
				}
			});
			return;
		}
		const fileArray: File[] = [];
		fileArray.push(...event.addedFiles);
		fileArray.forEach((each: File, i: number): void => {
			if (!this.files.find((file) => file.name == each.name)) {
				this.files.push(fileArray[i]);
				this.projectDocumentDtos.push({
					documentName: fileArray[i].name,
					type: fileArray[i].type,
					subType: this.subType ? this.subType : '',
					version: '',
					installLocationId: this.type.id,
					workflowStatusId: this.departmentId ? +this.departmentId : 0,
					documentHash: '',
					versionSeq: 1,
					archived: false,
					projectId: this.projectId ? +this.projectId : 0,
					userId: 0
				});
			} else {
				const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
					data: {
						title: 'Cannot Upload File',
						message: 'This file has already been added!'
					}
				});
			}
		});

		this.fileAddEmitter.emit(fileArray);
		this.documentDtoEmitter.emit(this.projectDocumentDtos);
		if (this.maxFileLength == 1 && this.files.length) {
			this.projectDocumentsService.surveyFileSubject.next(fileArray[0]);
			this.projectDocumentsService.surveyDtoSubject.next(this.projectDocumentDtos[0]);
		}
	}

	onRemove(file: File) {
		if (this.maxFileLength == 1) {
			this.projectDocumentsService.surveyFileRemoveSubject.next(file);
			this.projectDocumentsService.surveyDtoRemoveSubject.next(this.projectDocumentDtos[0]);
			this.projectDocumentDtos = [];
			this.uploadedFiles = [];
		} else {
			this.fileRemoveEmitter.emit(file);
		}
		this.files.splice(this.files.indexOf(file), 1);
	}

	downloadFile(event: Event, fileName: string) {
		event.stopPropagation();
		this.projectDocumentsService.findOne(this.projectId, fileName).subscribe((response) => {
			//window.open() was opening then closing the tab, this way just simply downloads the file.
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = response;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(a.href);
			document.body.removeChild(a);
		});
	}

	stopPropagation(event: Event) {
		event.stopPropagation();
	}

	public deleteFile(dto: ProjectDocument): Promise<void> {
		return new Promise((resolve, reject) => {
			const toDeleteFile = this.files[0];
			this.projectDocumentsService.delete(dto.id!).then(
				(response: void) => {
					if (this.maxFileLength == 1) {
						this.projectDocumentsService.surveyFileRemoveSubject.next(toDeleteFile);
						this.projectDocumentsService.surveyDtoRemoveSubject.next(this.projectDocumentDtos[0]);
						this.uploadedFiles = [];
						this.projectDocumentDtos = [];
					}

					// update projectService Project Subject
					this.projectsService.setProject(dto.projectId!);

					//remove deleted file from this.uploadedFiles
					this.uploadedFiles = this.uploadedFiles?.filter((document: ProjectDocument) => document.id !== dto.id);
					//new
					this.projectDocumentDtos = this.projectDocumentDtos?.filter(
						(document: ProjectDocument) => document.documentName !== dto.documentName
					);

					this.fileChanges = this.uploadedFiles!;
					this.cdRef.detectChanges();
					this.snackbar.open('Document Deleted', SnackbarActionEnum.SUCCESS);
					resolve();
				},
				(error) => {
					this.snackbar.open('Failed to Delete Document', SnackbarActionEnum.ERROR);
					reject(error);
				}
			);
		});
	}

	openDeleteModal(dto: ProjectDocument) {
		const dialogRef = this.dialog.open(ProjectDraftingDeleteComponent, {
			width: '300px',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'delete') {
				this.deleteFile(dto);
			} else {
				return;
			}
		});
	}

	openArchiveModal() {
		if (this.uploadedFiles) {
			const documents = this.uploadedFiles.filter((doc) => !doc.archiveTs);

			const dialogRef = this.dialog.open(ProjectFileArchiveDialogComponent, {
				width: '300px',
				disableClose: true,
				data: {
					documents: documents
				}
			});
			dialogRef.afterClosed().subscribe((result) => {
				if (result.success) {
					this.projectDocumentsService.archive(this.projectId, result.documentIds).subscribe({
						next: (projectDocuments) => {
							if (this.uploadedFiles) {
								projectDocuments.forEach((document) => {
									const doc = this.uploadedFiles?.find((doc) => doc.id === document.id);
									if (doc) {
										doc.archiveTs = document.archiveTs;
									}
								});
								this.uploadedFiles = [...this.uploadedFiles];
							}
							this.snackbar.open('Archive Complete', SnackbarActionEnum.SUCCESS);
						},
						error: (err) => {
							this.snackbar.open('Failed to Archive', SnackbarActionEnum.ERROR);
						}
					});
				} else {
					return;
				}
			});
		}
	}
}
