import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateUserComponent} from './users/create-user/create-user.component';
import {UsersListComponent} from './users/users-list/users-list.component';
import {UsersListDetailComponent} from './users/users-list-detail/users-list-detail.component';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {PriceLevelListComponent} from './price-level/price-level-list/price-level-list.component';
import {CreatePriceLevelComponent} from './price-level/create-price-level/create-price-level.component';
import {PriceLevelListDetailComponent} from './price-level/price-level-list-detail/price-level-list-detail.component';
import {BrokersListComponent} from './brokers/brokers-list/brokers-list.component';
import {BrokersListDetailComponent} from './brokers/brokers-list-detail/brokers-list-detail.component';
import {ContactsListDetailComponent} from './contacts/contacts-list-detail/contacts-list-detail.component';
import {CreateContactComponent} from './contacts/create-contact/create-contact.component';
import {ContactsListComponent} from './contacts/contacts-list/contacts-list.component';
import {TransitTimeListComponent} from './transit-time/transit-time-list/transit-time-list.component';
import {TransitTimeListDetailComponent} from './transit-time/transit-time-list-detail/transit-time-list-detail.component';
import {CreateTransitTimeComponent} from './transit-time/create-transit-time/create-transit-time.component';
import {BrokerInfoComponent} from './brokers/broker-info/broker-info.component';
import {MatCardModule} from '@angular/material/card';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {RolesListComponent} from './roles/roles-list/roles-list.component';
import {RolesListDetailComponent} from './roles/roles-list-detail/roles-list-detail.component';
import {CreateRoleComponent} from './roles/create-role/create-role.component';
import {PriceConfigurationListComponent} from './price-configuration/price-configuration-list/price-configuration-list.component';
import {PriceConfigurationDialogComponent} from './price-configuration/price-configuration-dialog/price-configuration-dialog.component';
import {PriceConfigurationDetailComponent} from './price-configuration/price-configuration-detail/price-configuration-detail.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {StatusListComponent} from './statuses/status-list/status-list.component';
import {StatusListDetailComponent} from './statuses/status-list-detail/status-list-detail.component';
import {CreateStatusComponent} from './statuses/create-status/create-status.component';
import {CodesListComponent} from './codes/codes-list/codes-list.component';
import {CodesDialogComponent} from './codes/codes-dialog/codes-dialog.component';
import {CreateInstallLocationComponent} from './install-location/create-install-location/create-install-location.component';
import {GlCodesListComponent} from './gl-codes/gl-codes-list/gl-codes-list.component';
import {GlCodesListDetailComponent} from './gl-codes/gl-codes-list-detail/gl-codes-list-detail.component';
import {CreateGlCodeComponent} from './gl-codes/create-gl-code/create-gl-code.component';
import {TaskTypeListComponent} from './task-type/task-type-list/task-type-list.component';
import {TaskTypeListDetailComponent} from './task-type/task-type-list-detail/task-type-list-detail.component';
import {CreateTaskTypeComponent} from './task-type/create-task-type/create-task-type.component';

@NgModule({
	declarations: [
		CreateUserComponent,
		UsersListComponent,
		UsersListDetailComponent,
		PriceLevelListComponent,
		CreatePriceLevelComponent,
		PriceLevelListDetailComponent,
		BrokersListComponent,
		BrokersListDetailComponent,
		ContactsListComponent,
		ContactsListDetailComponent,
		CreateContactComponent,
		TransitTimeListComponent,
		TransitTimeListDetailComponent,
		CreateTransitTimeComponent,
		CreateContactComponent,
		BrokerInfoComponent,
		RolesListComponent,
		RolesListDetailComponent,
		CreateRoleComponent,
		PriceConfigurationListComponent,
		PriceConfigurationDialogComponent,
		PriceConfigurationDetailComponent,
		StatusListComponent,
		StatusListDetailComponent,
		CreateStatusComponent,
		CreateInstallLocationComponent,
		CreateStatusComponent,
		CodesListComponent,
		CodesDialogComponent,
		GlCodesListComponent,
		GlCodesListDetailComponent,
		CreateGlCodeComponent,
		TaskTypeListComponent,
		TaskTypeListDetailComponent,
		CreateTaskTypeComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatTableModule,
		MatPaginatorModule,
		MatSnackBarModule,
		MatSortModule,
		SharedModule,
		MatRadioModule,
		MatDialogModule,
		MatSelectModule,
		MatCardModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatDividerModule,
		MatCheckboxModule
	],
	exports: [
		CreateUserComponent,
		UsersListComponent,
		UsersListDetailComponent,
		PriceLevelListComponent,
		CreatePriceLevelComponent,
		PriceLevelListDetailComponent,
		BrokersListComponent,
		BrokersListDetailComponent,
		ContactsListComponent,
		ContactsListDetailComponent,
		CreateContactComponent,
		BrokerInfoComponent
	]
})
export class MaintenanceModule {}
