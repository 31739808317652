import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {Location} from '../models/location.interface';

@Injectable({
	providedIn: 'root'
})
export class LocationService {
	baseUrl: string = `${environment.url}/location`;
	locationSubject: Subject<Location[]> = new Subject<Location[]>();
	constructor(private http: HttpClient) {}

	create(location: Location): Observable<Location> {
		return this.http.post<Location>(this.baseUrl, location);
	}

	findAll(): void {
		this.http.get<Location[]>(this.baseUrl).subscribe((response) => {
			this.locationSubject.next(response);
		});
	}

	findOne(id: number): Observable<Location> {
		return this.http.get<Location>(`${this.baseUrl}/${id}`);
	}

	update(location: Location): Observable<Location> {
		return this.http.put<Location>(`${this.baseUrl}/${location.id}`, location);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}
}
