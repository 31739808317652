<h2 mat-dialog-title>Create Transit Time</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
	<div class="pt-4">
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">
				<mat-label>Name</mat-label>
				<input matInput formControlName="name" />
			</mat-form-field>
			<mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">
				<mat-label>Multiplier</mat-label>
				<input matInput formControlName="multiplier" />
			</mat-form-field>
			<mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">
				<mat-label>List Type</mat-label>
				<input matInput formControlName="listType" />
			</mat-form-field>
		</div>
		<!--    <div class="w-full flex gap-4 justify-center">-->
		<!--      <mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">-->
		<!--        <mat-label>GL List</mat-label>-->
		<!--        <input matInput formControlName="glList" />-->
		<!--      </mat-form-field>-->
		<!--      <mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">-->
		<!--        <mat-label>Cat List</mat-label>-->
		<!--        <input matInput formControlName="catList" />-->
		<!--      </mat-form-field>-->
		<!--    </div>-->
	</div>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close="true" class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createTaskType()">Create</button>
</div>
