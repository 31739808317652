import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-project-stop-modal',
	templateUrl: './project-stop-modal.component.html',
	styleUrls: ['./project-stop-modal.component.scss']
})
export class ProjectStopModalComponent {
	haltReason: string;

	constructor(public dialogRef: MatDialogRef<ProjectStopModalComponent>) {}

	ngOnInit() {}

	closeModal() {
		this.dialogRef.close();
	}

	haltProject() {
		this.dialogRef.close({reason: this.haltReason});
	}
}
