<div class="flex flex-col">
	<div class="flex flex-row">
		<span class="my-4 font-bold ml-4">Associated Contacts</span>
		<button mat-icon-button type="button" (click)="openContactModal(false)"><mat-icon>add</mat-icon></button>
	</div>

	<div *ngIf="relatedContacts.length; else elseBlock">
		<div *ngFor="let contact of relatedContacts" class="flex flex-row w-full ml-6">
			<button type="button" mat-icon-button (click)="openContactModal(true, contact)" class="-translate-y-1">
				<mat-icon>edit</mat-icon>
			</button>
			<span class="mt-2 mr-2 w-1/4">{{ contact.name }}</span>
			<div class="flex flex-col w-1/3">
				<span class="{{ !contact.phone ? 'mt-2' : '' }}">{{ contact.email }}</span>
				<div class="flex" *ngIf="contact.phone">
					<span *ngIf="contact.phone">{{ contact.phone | phone }}</span>
					<span *ngIf="contact.phoneExt">, Ext:{{ contact.phoneExt }}</span>
				</div>
			</div>
		</div>
	</div>

	<ng-template #elseBlock>
		<div class="ml-10 mb-2">
			<span>No associated contacts</span>
		</div>
	</ng-template>
</div>
