import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatMultiSort} from 'ngx-mat-multi-sort';

export class MultiSortDataSource<T> extends MatTableDataSource<T> {
	override sortData = (data: T[], sort: MatSort) => {
		const mSort = sort as MatMultiSort;
		const actives = mSort.actives;
		const directions = mSort.directions;
		if (!actives?.length || !directions?.length) {
			return data;
		}

		const sorted = data.sort((a, b) => {
			return this._multiSort(a, b, actives, directions);
		});

		return sorted;
	};

	_multiSort(a: T, b: T, actives: string[], directions: string[]): number {
		const active = actives[0];
		const direction = directions[0];
		const valueA = this.sortingDataAccessor(a, active);
		const valueB = this.sortingDataAccessor(b, active);

		if (valueA > valueB) {
			return direction === 'asc' ? 1 : -1;
		} else if (valueA < valueB) {
			return direction === 'asc' ? -1 : 1;
		} else {
			if (actives.length > 1) {
				actives = actives.slice(1, actives.length);
				directions = directions.slice(1, directions.length);
				return this._multiSort(a, b, actives, directions);
			} else {
				return 0;
			}
		}
	}
}
