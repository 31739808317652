import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {InstallLocation} from '../models/install-location.interface';

@Injectable({
	providedIn: 'root'
})
export class InstallLocationService {
	baseUrl: string = `${environment.url}/core/install-locations`;
	installLocationSubject: Subject<InstallLocation[]> = new Subject<InstallLocation[]>();
	constructor(private http: HttpClient) {}

	findAll(): void {
		this.http.get<InstallLocation[]>(this.baseUrl).subscribe((response) => {
			this.installLocationSubject.next(response);
		});
	}

	findOne(id: number): Observable<InstallLocation> {
		return this.http.get<InstallLocation>(`${this.baseUrl}/${id}`);
	}

	create(installLocation: InstallLocation): Observable<InstallLocation> {
		return this.http.post<InstallLocation>(this.baseUrl, installLocation);
	}

	update(installLocation: InstallLocation): Observable<InstallLocation> {
		return this.http.put<InstallLocation>(this.baseUrl, installLocation);
	}

	delete(id: number): void {
		this.http.delete<InstallLocation>(`${this.baseUrl}/${id}`);
	}
}
