import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {TaskTypeService} from '../../../core/services/task-type.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-create-task-type',
	templateUrl: './create-task-type.component.html',
	styleUrls: ['./create-task-type.component.scss']
})
export class CreateTaskTypeComponent {
	form: FormGroup;
	constructor(
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialogRef: MatDialogRef<CreateTaskTypeComponent>,
		private taskTypeService: TaskTypeService
	) {
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			glList: [''],
			catList: [''],
			multiplier: ['', Validators.required],
			listType: ['']
		});
	}

	createTaskType() {
		this.taskTypeService.create(this.form.getRawValue()).subscribe(
			(response) => {
				this.dialogRef.close();
				this.snackbar.open('Transit Time Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.taskTypeService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
}
