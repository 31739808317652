<div class="flex" [ngClass]="{'flex-col': column}" *ngIf="installDate && installShipDateForm" [formGroup]="installShipDateForm">
	<div class="flex flex-col mx-2">
		<div><span class="font-medium">Ship Date: </span>{{ this.installShipDateForm.controls['shipDate'].value | date : 'mediumDate' }}</div>
		<div [ngClass]="{'mat-mdc-form-field-error': navShipWarn()}">
			<span class="font-medium" *ngIf="this.navShipDate">Nav Ship: </span>{{ this.navShipDate | date : 'mediumDate' }}
		</div>
	</div>
	<div class="flex flex-col mx-2" [ngClass]="{'mt-2': column}">
		<div class="font-medium">{{ this.installShipDateForm.controls['location'].value }}</div>
		<div><span class="font-medium">Est Transit: </span>{{ this.installShipDateForm.controls['estimatedTransit'].value }}</div>
	</div>
	<div class="flex mx-2">
		<span class="font-medium mt-3">Transit:</span>
		<button [disabled]="disableDecrease()" type="button" mat-icon-button (click)="decreaseTransit()">
			<mat-icon class="text-sm">remove_circle</mat-icon>
		</button>
		<div class="mt-3">{{ this.installShipDateForm.controls['transitDays'].value }}</div>
		<button mat-icon-button type="button" (click)="increaseTransit()">
			<mat-icon class="text-sm">add_circle</mat-icon>
		</button>
	</div>
	<mat-form-field appearance="outline" [ngClass]="{'w-40': !column}">
		<mat-label class="font-medium">Install Date</mat-label>
		<input matInput [matDatepicker]="datepicker" [formControlName]="installDate" (dateChange)="adjustShippingTime()" />
		<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
		<mat-datepicker #datepicker> </mat-datepicker>
		<mat-error>Install Date <strong>required</strong>.</mat-error>
	</mat-form-field>
</div>
