import {Component, OnInit} from '@angular/core';
import {QuickQuoteService} from '../core/services/quick-quote.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationDialogComponent} from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

export interface QuickQuoteSummaryDisplay {
	desc: string;
	count: number;
	error: boolean;
}

@Component({
	selector: 'app-quick-quote',
	templateUrl: './quick-quote.component.html',
	styleUrls: ['./quick-quote.component.scss']
})
export class QuickQuoteComponent implements OnInit {
	quoteForm = new FormGroup({
		rooms: this.fb.array([]),
		install: this.fb.control(false)
	});

	totalRooms: number = 0;
	roomList: QuickQuoteSummaryDisplay[] = [];

	carriagePartList: QuickQuoteSummaryDisplay[] = [];
	trackPartList: QuickQuoteSummaryDisplay[] = [];
	trackHwPartList: QuickQuoteSummaryDisplay[] = [];
	tierList: QuickQuoteSummaryDisplay[] = [];
	shelfList: QuickQuoteSummaryDisplay[] = [];

	shelfCount: number = 0;
	mobileTotal: number = 0;
	shelvingTotal: number = 0;
	airFlowTotal: number = 0;
	trayTotal: number = 0;
	lightingTotal: number = 0;
	mezzanineTotal: number = 0;
	installTotal: number = 0;
	totalEstimate: number = 0;

	carriageErrors: boolean = false;
	shelfErrors: boolean = false;

	constructor(
		private fb: FormBuilder,
		private dialog: MatDialog,
		private quickQuoteService: QuickQuoteService,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.addRoom();
	}

	get rooms(): FormArray {
		return this.quoteForm.controls.rooms;
	}

	deleteRoom(index: number): void {
		let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '300px',
			disableClose: true,
			data: {
				title: 'Confirm Delete',
				message: 'Are you sure that you want to delete this room configuration?'
			}
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.rooms.removeAt(index);
				this.generateSummary();
			}
		});
	}

	addRoom(): void {
		this.rooms.push(
			this.fb.group({
				room: ['Room'],
				count: [1],
				units: [''],
				length: [Number(40), [Validators.required, Validators.min(8), Validators.max(80)]],
				lengthFraction: [Number(0), [Validators.required, Validators.min(0), Validators.max(11)]],
				width: [Number(30), [Validators.required, Validators.min(8), Validators.max(80)]],
				widthFraction: [Number(0), [Validators.required, Validators.min(0), Validators.max(11)]],
				height: [Number(8), [Validators.required, Validators.min(8)]],
				aisle: [Number(48), [Validators.required, Validators.min(36), Validators.max(48)]],
				mainAisle: [Number(36), [Validators.required, Validators.min(36)]],
				backAisle: [Number(6), [Validators.required, Validators.min(0)]],
				tiers: [1, [Validators.required, Validators.min(0)]],
				orientation: ['OPTIMIZE'],
				zoom: [2],
				airFlow: [false],
				tray: [false],
				lighting: [false],
				mezzanine: [false],
				install: [false],
				estimate: [null]
			})
		);
	}

	room(i: number): FormGroup | null {
		const rooms = this.rooms as FormArray;
		if (rooms.at(i)) {
			const room = rooms.at(i) as FormGroup;
			return room;
		}
		return null;
	}

	roomName(i: number): string {
		const room = this.room(i);
		if (room && room.controls['room']) {
			return room.controls['room'].value;
		}
		return '';
	}

	roomCount(i: number): string {
		const room = this.room(i);
		if (room && room.controls['count']) {
			return room.controls['count'].value;
		}
		return '';
	}

	roomTotal(i: number): number {
		const room = this.room(i);
		if (room) {
			const count = room.controls['count'].value;
			const estimate = room.value.estimate;
			if (estimate) {
				let total = 0;
				if (estimate.shelfCost) {
					total = estimate.shelfCost + estimate.carriageCost;
					if (room.controls['airFlow'].value) {
						total += (estimate.shelfCost + estimate.carriageCost) * estimate.airFlowPct;
					}
					if (room.controls['tray'].value && room.controls['tray'].enabled) {
						total += (estimate.shelfCost + estimate.carriageCost) * estimate.trayPct;
					}
					if (room.controls['lighting'].value) {
						total += (estimate.shelfCost + estimate.carriageCost) * estimate.lightingPct;
					}
					if (room.controls['mezzanine'].value && room.controls['mezzanine'].enabled) {
						total += (estimate.shelfCost + estimate.carriageCost) * estimate.mezzaninePct;
					}
					if (room.controls['install'].value) {
						total += (estimate.shelfCost + estimate.carriageCost) * estimate.installPct;
					}
					return total * count;
				}
			}
		}
		return 0;
	}

	roomError(i: number): boolean {
		let error = false;
		const room = this.room(i);
		if (room && room.value.estimate) {
			return (
				room.value.estimate.shelfError ||
				room.value.estimate.extraShelfError ||
				room.value.estimate.carriageError ||
				room.value.estimate.trackError ||
				room.value.estimate.trackHardwareError ||
				room.value.estimate.tierComponentError ||
				room.value.estimate.extraTierComponentError
			);
		}
		return error;
	}
	get hasErrors(): boolean {
		return this.carriageErrors || this.shelfErrors;
	}

	generateSummary() {
		let totalRooms: number = 0;
		let roomList: QuickQuoteSummaryDisplay[] = [];

		let carriagePartList: QuickQuoteSummaryDisplay[] = [];
		let trackPartList: QuickQuoteSummaryDisplay[] = [];
		let trackHwPartList: QuickQuoteSummaryDisplay[] = [];
		let tierList: QuickQuoteSummaryDisplay[] = [];
		let shelfList: QuickQuoteSummaryDisplay[] = [];

		let shelfCount: number = 0;
		let mobileTotal: number = 0;
		let shelvingTotal: number = 0;
		let airFlowTotal: number = 0;
		let trayTotal: number = 0;
		let lightingTotal: number = 0;
		let mezzanineTotal: number = 0;
		let installTotal: number = 0;
		let totalEstimate: number = 0;

		let carriageErrors: boolean = false;
		let shelfErrors: boolean = false;

		const rooms = this.rooms as FormArray;
		rooms.controls.forEach((r) => {
			const room = r as FormGroup;
			const value = r.value;
			const count = room.controls['count'].value;
			totalRooms += count;
			let error = false;

			this.addPart(carriagePartList, value.estimate.carriagePart, value.estimate.carriageQty, value.estimate.carriageError, count);
			if (value.estimate.carriageError) {
				error = true;
				carriageErrors = true;
			}

			this.addPart(trackPartList, value.estimate.trackPart, value.estimate.trackQty, value.estimate.trackError, count);
			if (value.estimate.trackError) {
				error = true;
				carriageErrors = true;
			}

			this.addPart(
				trackHwPartList,
				value.estimate.trackHardwarePart,
				value.estimate.trackHardwareQty,
				value.estimate.trackHardwareError,
				count
			);
			if (value.estimate.trackHardwareError) {
				error = true;
				carriageErrors = true;
			}

			this.addPart(tierList, value.estimate.tierComponentPart, value.estimate.tierComponentQty, value.estimate.tierComponentError, count);
			if (value.estimate.tierComponentError) {
				error = true;
				shelfErrors = true;
			}
			if (value.estimate.extraTierComponentPart) {
				this.addPart(
					tierList,
					value.estimate.extraTierComponentPart,
					value.estimate.extraTierComponentQty,
					value.estimate.extraTierComponentError,
					count
				);
				if (value.estimate.extraTierComponentError) {
					error = true;
					shelfErrors = true;
				}
			}

			this.addPart(shelfList, value.estimate.shelfPart, value.estimate.shelfCount, value.estimate.shelfError, count);
			if (value.estimate.shelfError) {
				error = true;
				shelfErrors = true;
			}
			if (value.estimate.extraShelfPart) {
				this.addPart(shelfList, value.estimate.extraShelfPart, value.estimate.extraShelfCount, value.estimate.extraShelfError, count);
				if (value.estimate.extraShelfError) {
					error = true;
					shelfErrors = true;
				}
			}

			shelfCount += (value.estimate.shelfCount + value.estimate.extraShelfCount) * count;
			mobileTotal += value.estimate.carriageCost * count;
			shelvingTotal += value.estimate.shelfCost * count;
			let partTotal = value.estimate.carriageCost * count + value.estimate.shelfCost * count;
			totalEstimate += value.estimate.carriageCost * count + value.estimate.shelfCost * count;
			if (room.controls['airFlow'].value) {
				airFlowTotal += partTotal * value.estimate.airFlowPct;
				totalEstimate += partTotal * value.estimate.airFlowPct;
			}
			if (room.controls['tray'].value && room.controls['tray'].enabled) {
				trayTotal += partTotal * value.estimate.trayPct;
				totalEstimate += partTotal * value.estimate.trayPct;
			}
			if (room.controls['lighting'].value) {
				lightingTotal += partTotal * value.estimate.lightingPct;
				totalEstimate += partTotal * value.estimate.lightingPct;
			}
			if (room.controls['mezzanine'].value && room.controls['mezzanine'].enabled) {
				mezzanineTotal += partTotal * value.estimate.mezzaninePct;
				totalEstimate += partTotal * value.estimate.mezzaninePct;
			}
			if (this.quoteForm.controls['install'].value) {
				installTotal += partTotal * value.estimate.installPct;
				totalEstimate += partTotal * value.estimate.installPct;
			}
			roomList.push({desc: value.room, count: count, error: error});
		});
		this.totalRooms = totalRooms;
		this.roomList = roomList;

		this.carriagePartList = carriagePartList.sort((a, b) => (a.desc > b.desc ? 1 : -1));
		this.trackPartList = trackPartList;
		this.trackHwPartList = trackHwPartList.sort((a, b) => (a.desc > b.desc ? 1 : -1));
		this.tierList = tierList.sort((a, b) => (a.desc > b.desc ? 1 : -1));
		this.shelfList = shelfList.sort((a, b) => (a.desc > b.desc ? 1 : -1));

		this.shelfCount = shelfCount;
		this.mobileTotal = mobileTotal;
		this.shelvingTotal = shelvingTotal;
		this.airFlowTotal = airFlowTotal;
		this.trayTotal = trayTotal;
		this.lightingTotal = lightingTotal;
		this.mezzanineTotal = mezzanineTotal;
		this.installTotal = installTotal;
		this.totalEstimate = totalEstimate;

		this.carriageErrors = carriageErrors;
		this.shelfErrors = shelfErrors;
	}

	addPart(partList: QuickQuoteSummaryDisplay[], partNo: string, qty: number, error: boolean, count: number) {
		let i = partList.findIndex((part) => part.desc === partNo);
		if (i < 0) {
			partList.push({
				desc: partNo,
				count: qty * count,
				error: error
			});
		} else {
			partList[i].count += qty * count;
		}
	}
}
