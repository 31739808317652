export enum PartCategoryEnum {
	POSTS = 'posts',
	CAPS = 'caps',
	PLATES = 'plates',
	HANG_RODS = 'hang rods',
	REBAR = 'rebar',
	HARDWARE = 'hardware',
	BEAMS = 'beams',
	SHELVES = 'shelves'
}
