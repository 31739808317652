import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ProjectsService} from '../../core/services/projects.service';
import {Project} from '../../core/models/project.interface';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProjectDocumentService} from '../../core/services/project-document.service';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {TemplateCreateDialogComponent} from '../../shared/components/template-create-dialog/template-create-dialog.component';
import {Template, TemplateUpdate} from '../../core/models/template.interface';
import {TemplatesService} from '../../core/services/templates.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ClientService} from '../../core/services/client.service';
import {TemplateTypeEnum} from '../../core/enums/template-type.enum';
import {ProjectTypeEnum} from '../../core/enums/project-type.enum';
import {User} from '../../core/models/user.interface';
import {RfqConfirmDialogComponent} from '../../shared/components/rfq-confirm-dialog/rfq-confirm-dialog.component';
import {StatusService} from '../../core/services/status.service';

@Component({
	selector: 'app-project-overview',
	templateUrl: './project-overview.component.html',
	styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
	form: FormGroup;
	project: Project | null;
	projectId: number;
	existingEstimate: boolean = false;
	surveyFile?: File;
	surveyFileDto: ProjectDocument[] = [];
	teamMembers: User[] = [];
	surveyFileSubscription: Subscription;
	surveyFileDtoSubscription: Subscription;
	surveyFileRemoveSubscription: Subscription;
	surveyDtoRemoveSubscription: Subscription;
	templateOptions: Template[] = [];
	selectedTemplate: Template;
	membersSelected: boolean = false;
	updatingProject: boolean = false;
	loadingSubscription: Subscription;
	projectSubscription: Subscription;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private projectsService: ProjectsService,
		private projectDocumentService: ProjectDocumentService,
		private snackbar: MatSnackBar,
		private clientService: ClientService,
		private templatesService: TemplatesService,
		private dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private statusService: StatusService
	) {}

	ngOnInit() {
		this.form = this.fb.group(
			{
				name: ['', [Validators.required, Validators.maxLength(100)]],
				installDate: [''],
				estimateDate: [''],
				lastRfqDate: [''],
				lastDraftingContactDate: [''],
				address1: ['', Validators.maxLength(50)],
				address2: ['', Validators.maxLength(50)],
				city: ['', [Validators.required, Validators.maxLength(100)]],
				state: ['', [Validators.required, Validators.maxLength(10)]],
				postal: [''],
				country: ['', Validators.maxLength(10)],
				union: [false],
				nonUnion: [false],
				stockPermitReq: [false],
				prevailingWage: [false],
				seismic: [false],
				includeInstall: [false],
				structuralCalcs: [false],
				seismicCalcs: [false],
				specialInspection: [false],
				nightWork: [false],
				survey: [false],
				skidding: [false],
				thirdPartyFreight: [false],
				demo: [false],
				demoRemoval: [false],
				locations: this.fb.array([]),
				locationId: [1, [Validators.required]],
				transfer: [false],
				note: ['', Validators.maxLength(500)]
			},
			{validator: this.installOrEstDateReq}
		);
		this.projectSubscription = this.projectsService.project.subscribe((project: Project | null): void => {
			if (project) {
				//Check if the estimateDate field has a value, if it does set existingEstimate to true
				this.existingEstimate = !project.installDate;
				this.projectId = project.id;
				this.project = project;
				this.teamMembers = project.members.map((member) => member.userInfo);
				const locationsToReplace = project.locations?.map((each) => each.type);
				this.form.patchValue(project);
				this.form.setControl('locations', this.fb.array(locationsToReplace));
				//Based on the projects clientId, load templates.
				this.clientService.findAllTemplatesForClient(project.clientId, 'project').subscribe(
					(response: Template[]): void => {
						this.templateOptions = response;
					},
					(error): void => {
						this.snackbar.open('Failed to Load Project Templates', SnackbarActionEnum.ERROR);
						console.error(error);
					}
				);
			}
		});
		this.surveyFileDtoSubscription = this.projectDocumentService.surveyDtoSubject.subscribe((response) => {
			this.surveyFileDto.push(response);
		});
		this.surveyFileSubscription = this.projectDocumentService.surveyFileSubject.subscribe((response) => {
			this.surveyFile = response;
		});
		this.surveyFileRemoveSubscription = this.projectDocumentService.surveyFileRemoveSubject.subscribe((file) => {
			this.handleSurveyFileRemoveEmit(file);
		});
		this.surveyDtoRemoveSubscription = this.projectDocumentService.surveyDtoRemoveSubject.subscribe((dto) => {
			this.handleSurveyDtoRemoveEmit(dto);
		});

		this.loadingSubscription = this.projectsService.loading$.subscribe((loading: boolean) => (this.updatingProject = loading));
	}
	addUser(user: User) {
		this.teamMembers.push(user);
	}
	removeUser(id: number) {
		const index = this.teamMembers.findIndex((user: Partial<User>) => user.id === id);
		this.teamMembers.splice(index, 1);
	}
	installOrEstDateReq(form: FormGroup) {
		if (form.value.installDate === '' && form.value.estimateDate === '') {
			return {installEstimateDateReq: true};
		}
		return null;
	}
	handleSurveyDtoRemoveEmit(document: ProjectDocument) {
		this.surveyFileDto = this.surveyFileDto.filter((doc) => document.documentName !== doc.documentName);
	}
	handleSurveyFileRemoveEmit(file: any) {
		let index = this.project?.documents.findIndex((doc) => doc.subType === 'Survey');
		if (typeof index !== 'undefined' && index !== null) {
			this.project?.documents.splice(index, 1);
		}
		this.surveyFile = undefined;
	}
	confirmRfqStatus(returnToProjects: boolean) {
		const dialogRef: MatDialogRef<RfqConfirmDialogComponent> = this.dialog.open(RfqConfirmDialogComponent, {disableClose: true});
		dialogRef.afterClosed().subscribe((response) => {
			if (response) {
				this.updateProject(true, returnToProjects);
			} else {
				this.updateProject(false, returnToProjects);
			}
		});
	}

	updateProjectPreflight(returnToProjects: boolean) {
		const statuses = this.statusService.statusDataSource.value;
		const draftingStage = statuses.find((status) => status.description === 'Drafting');
		const inDrafting = draftingStage!.statuses.filter((status) => status.id == this.project?.workflowStatus.id).length > 0;

		this.form.get('lastRfqDate')?.value && !this.project?.lastRfqDate && inDrafting
			? this.confirmRfqStatus(returnToProjects)
			: this.updateProject(false, returnToProjects);
	}

	updateProject(rfqStatusChange: boolean, returnToProjects: boolean) {
		if (this.surveyFileDto && this.surveyFile) {
			this.surveyFileDto[0].projectId = this.projectId;
			const surveyFileForm = new FormData();
			surveyFileForm.append('files', this.surveyFile, this.surveyFile.name);
			surveyFileForm.append('body', JSON.stringify(this.surveyFileDto));
			this.projectDocumentService.create(surveyFileForm, this.projectId).subscribe(
				(response) => {
					//no success snackbar here bc the projects service opens it up upon successful call
					//just want to catch the error for the file upload
					this.projectDocumentService.surveyFileUploadedSubject.next(true);
					this.project?.documents.push(response[0]);
					const mergedProject = {...this.project, ...this.form.getRawValue()};
					delete mergedProject.activity;
					this.projectsService.update(mergedProject, rfqStatusChange, returnToProjects);
				},
				(error): void => {
					this.snackbar.open('Something went wrong uploading the Survey File!', SnackbarActionEnum.ERROR);
					console.error(error);
				}
			);
		} else {
			//resolve team members
			this.project!.members = this.teamMembers.map((user: User) => {
				return {userInfo: user};
			});
			const mergedProject = {...this.project, ...this.form.getRawValue()};
			delete mergedProject.activity;
			if (mergedProject.estimateDate && JSON.stringify(mergedProject.estimateDate) !== '{}') {
				mergedProject.estimateDate = new Date(mergedProject.estimateDate).toISOString().substring(0, 10);
			}
			this.projectsService.update(mergedProject, rfqStatusChange, returnToProjects);
		}
	}

	ngOnDestroy(): void {
		this.surveyFileDtoSubscription.unsubscribe();
		this.surveyFileSubscription.unsubscribe();
		this.loadingSubscription?.unsubscribe();
		this.projectSubscription?.unsubscribe();
	}

	saveTemplate() {
		const dialog = this.dialog.open(TemplateCreateDialogComponent);
		dialog.componentInstance.templateOptions = this.templateOptions;

		dialog.afterClosed().subscribe((result) => {
			if (result?.templateName) {
				//We're creating a new template and project
				let project: Project = {...this.project, ...this.form.getRawValue()};
				project.type = ProjectTypeEnum['TEMPLATE'];

				if (project.installDate) {
					// @ts-ignore
					project.installDate = new Date(project.installDate).toISOString().substring(0, 10);
				}
				if (project.estimateDate) {
					// @ts-ignore
					project.estimateDate = new Date(project.estimateDate).toISOString().substring(0, 10);
				}
				//remove id and activity before creating new template
				project.id = 0;
				project.activity = [];

				const projectForm: FormData = new FormData();
				projectForm.append('body', JSON.stringify(project));
				this.projectsService.create(projectForm, false).subscribe(
					(response: Project): void => {
						// Create template record to save
						const template: TemplateUpdate = {
							description: result?.templateName,
							projectId: response.id,
							clientId: this.project?.clientId,
							type: TemplateTypeEnum['PROJECT']
						};

						this.templatesService.create(template).subscribe(
							(newTemplate: Template): void => {
								this.snackbar.open('Template Saved', SnackbarActionEnum.SUCCESS);
								this.templateOptions.push(newTemplate);
								this.selectedTemplate = newTemplate;
							},
							(err: Error): void => {
								console.error(err);
								this.snackbar.open('Failed to Save Template', SnackbarActionEnum.ERROR);
							}
						);
					},
					(err: Error): void => {
						console.error(err);
						this.snackbar.open('Failed to Save Project for Template', SnackbarActionEnum.ERROR);
					}
				);
				return;
			}
			if (result?.selectedTemplate) {
				// Updating an existing project template
				const project = this.form.getRawValue();
				project.id = result.selectedTemplate.projectId;
				project.type = ProjectTypeEnum['TEMPLATE'];
				this.projectsService.update(project, false, false, 'Project Template Updated');
				this.selectedTemplate = result.selectedTemplate;
			}
		});
	}
}
