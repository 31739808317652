<mat-sidenav-container [autosize]="autosize" id="sidenav-container">
	<mat-sidenav
		[@openClose]="isExpanded ? 'open' : 'closed'"
		id="sidenav"
		class="bg-dark overflow-y-visible menu"
		[ngClass]="{'p-4': isExpanded}"
		mode="side"
		opened
		#sidenav
	>
		<div id="sidenav-top-container">
			<!--
      <img src="/assets/icons/MMI.svg" alt="MMI Logo" class="m-auto" *ngIf="!isExpanded" [ngClass]="{'pt-4': !isExpanded}">
-->
			<img src="/assets/mmi-logo.jpg" alt="MMI Logo" class="m-auto" *ngIf="isExpanded" />
		</div>

		<!--<button [@buttonMoved]="isExpanded ? 'open' : 'closed'" (click)="toggleSidenav()"  class="bg-secondary-500 rounded-full p-2 mt-4 absolute animated-sidebar"  id="sidenav-toggle-button">
      <mat-icon  *ngIf="!isExpanded">chevron_right</mat-icon>
      <mat-icon  *ngIf="isExpanded">chevron_left</mat-icon>
    </button>-->

		<div
			id="sidenav-bottom-container"
			class="mt-20 flex flex-col justify-between h-2/5"
			[ngClass]="{'items-center': !isExpanded, 'items-start': isExpanded}"
		>
			<div (click)="navigate(data)" *ngFor="let data of navOptions" class="hover:bg-gray rounded p-2 cursor-pointer">
				<div class="flex items-center">
					<mat-icon [ngStyle]="{color: 'white'}" class="cursor-pointer">{{ data.icon }}</mat-icon>
					<span *ngIf="isExpanded" class="text-white text-md md:text-lg ml-2">{{ data.text }}</span>
				</div>
			</div>
		</div>
	</mat-sidenav>

	<mat-sidenav-content>
		<app-main-toolbar></app-main-toolbar>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
