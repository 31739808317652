<div class="p-2 flex justify-center">
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="user-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, email or phone number."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openCreateStatusModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 h-[80vh] border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingStatuses">
		<!--Status Column-->
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
			<td mat-cell *matCellDef="let status">
				{{ status.status }}
			</td>
		</ng-container>

		<!--Days Column-->
		<ng-container matColumnDef="description">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number of department">Department</th>
			<td mat-cell *matCellDef="let status">
				{{ status.description }}
			</td>
		</ng-container>

		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let status" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="status.expanded ? 'expanded' : 'collapsed'">
					<app-status-list-detail class="w-full" [status]="status" [projects]="projects"></app-status-list-detail>
				</div>
			</td>
			status
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let status; let i = dataIndex; columns: columnsToDisplay"
			class="status-row cursor-pointer"
			(click)="status.expanded = !status.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingStatuses">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingStatuses">
		<mat-spinner></mat-spinner>
	</div>
</div>
