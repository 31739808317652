import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProjectsService} from '../../core/services/projects.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {addDays, differenceInDays, isWeekend, startOfDay, subDays} from 'date-fns';
import {Schedule} from '../../core/models/schedule.model';
import {ProjectTask} from '../../core/models/project-task.model';
import {ProductionHour, ProductionHours} from '../../core/models/production-hours.interface';
import {ProjectSchedule, ProjectScheduleUpdate} from '../../core/models/project-schedule.interface';
import {MatDialog} from '@angular/material/dialog';
import {
	ProductionHourOverrideDialogComponent,
	ProductionHourOverrideDialogResponse
} from '../../shared/components/production-hour-override-dialog/production-hour-override-dialog.component';
import {TaskTypeService} from '../../core/services/task-type.service';

@Component({
	selector: 'app-project-scheduling',
	templateUrl: './project-scheduling.component.html',
	styleUrls: ['./project-scheduling.component.scss']
})
export class ProjectSchedulingComponent implements OnInit, AfterViewChecked {
	projectScheduleForm = new FormGroup({
		installDate: new FormControl(new Date(), [Validators.required]),
		transitTime: new FormControl(0),
		projectTasks: this.fb.array([])
	});

	projectId: number | undefined;

	minDate = new Date();
	busy: boolean = false;
	saving: boolean = false;
	loading: boolean = false;

	projectInstallDate = new Date();

	header: {value: string; show: boolean}[] = [];

	transitTotal = 1;
	transitDays = 1;
	transitWeekends = 0;
	shippingBufferDefault = 2;
	transferTime = 3;
	transfer = false;
	shippingBuffer = this.shippingBufferDefault;

	transferBeforeBuffer = this.transferTime;
	transferAfterBuffer = this.shippingBufferDefault;

	shipDate: Date;
	productionEndDate: Date;
	firstCalendarDate: Date;
	tomorrow = startOfDay(addDays(new Date(), 1));

	productionHours: ProductionHours = {};

	estimatedTransitTime = 0;
	projectLocation = '';

	scheduleDayMaxHours = 8;
	scheduleDayMinHours = 2;

	singleCellWidth = '4rem';

	showDate: Date;

	autoSchedule = true;

	initWarn = true;
	changeWarn = false;
	autoWarn = false;

	constructor(
		private projectService: ProjectsService,
		private route: ActivatedRoute,
		private _snackBar: MatSnackBar,
		private fb: FormBuilder,
		private changeDetector: ChangeDetectorRef,
		private dialog: MatDialog,
		private taskTypeService: TaskTypeService
	) {}

	ngOnInit() {
		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe((params) => {
			if (params['projectId']) {
				this.projectId = params['projectId'];
				this.getScheduleDetails();
			}
		});
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	getScheduleDetails(installDate: Date | null = null) {
		if (this.projectId) {
			this.busy = true;
			this.loading = true;
			this.projectService.getProjectSchedule(this.projectId, installDate).subscribe({
				next: (projectSchedule) => {
					this.buildSchedule(projectSchedule, installDate);
					this.busy = false;
					this.loading = false;
				},
				error: (err) => {
					this._snackBar.open('Get Schedule Failed: ' + err.error.message);
					this.busy = false;
					this.loading = false;
				}
			});
		}
	}

	changeInstallDate() {
		this.getScheduleDetails(this.projectScheduleForm.controls.installDate.value);
	}

	buildSchedule(projectSchedule: ProjectSchedule, installDate: Date | null) {
		this.projectLocation = projectSchedule.projectLocation;
		this.estimatedTransitTime = projectSchedule.estimateTransitTime;
		this.transfer = projectSchedule.transfer;
		if (projectSchedule.transitTime && projectSchedule.installDate) {
			this.transitDays = projectSchedule.transitTime;
			if (installDate) {
				this.projectInstallDate = startOfDay(installDate);
			} else {
				if (projectSchedule.installDate) {
					this.projectInstallDate = startOfDay(new Date(projectSchedule.installDate));
				}
			}
			this.adjustShippingTime();
			this.showDate = this.productionEndDate;
			this.firstCalendarDate = startOfDay(new Date(projectSchedule.firstDate));
			this.projectScheduleForm.patchValue(projectSchedule);
			this.productionHours = projectSchedule.productionHours;
			this.projectTasks.clear();
			if (projectSchedule.projectTasks) {
				projectSchedule.projectTasks.forEach((projectTask) => this.addProjectTask(projectTask));
			}
			this.header = [];
			for (let productionDate in projectSchedule.productionHours) {
				this.header.push({
					value: productionDate,
					show: differenceInDays(startOfDay(new Date(productionDate)), this.showDate) >= -2
				});
			}
		} else {
			this.projectScheduleForm.controls.installDate.updateValueAndValidity();
		}
		this.updateFormValidity(this.projectScheduleForm);
		if (this.projectScheduleForm.invalid && !this.initWarn) {
			this.autoWarn = true;
		}
	}

	addProjectTask(projectTask: ProjectTask): void {
		if (projectTask.taskType?.name && projectTask.taskType.name !== 'Shipping') {
			let minHours = 0;
			if (projectTask.hours) {
				minHours = projectTask.hours;
			}
			const newTask = this.fb.group({
				id: [projectTask.id],
				projectId: [projectTask.projectId],
				taskTypeId: [projectTask.taskTypeId],
				hours: [projectTask.hours],
				hoursScheduled: [0, [Validators.min(minHours)]],
				value: [projectTask.value],
				name: [projectTask.taskType.name],
				scheduledHours: this.fb.array([]),
				schedule: this.fb.array([]),
				padSize: [this.transitTotal + 3]
			});

			let hoursScheduled = 0;
			if (projectTask.hours) {
				let setDefaultHours = true;
				let lastTaskDate = subDays(this.projectInstallDate, this.transitTotal + 3);
				if (projectTask.schedule?.length) {
					this.initWarn = false;
					projectTask.schedule.sort((a, b) => {
						if (!b.date) {
							return -1;
						}
						if (!a.date) {
							return 1;
						}
						return a.date > b.date ? 1 : -1;
					});

					projectTask.schedule.forEach((schedule) => {
						if (schedule.date) {
							schedule.date = this.dateOnly(schedule.date);
						}
						if (
							schedule.date &&
							differenceInDays(new Date(schedule.date), this.firstCalendarDate) > 0 &&
							differenceInDays(new Date(schedule.date), this.productionEndDate) < 0
						) {
							if (schedule.hours) {
								hoursScheduled += schedule.hours;
							}
							lastTaskDate = new Date(schedule.date);
							this.showDates(lastTaskDate);
							this.addProjectTaskSchedule(
								{
									id: schedule.id,
									projectTaskId: projectTask.id,
									date: lastTaskDate,
									hours: schedule.hours
								},
								newTask.controls.schedule
							);
							setDefaultHours = false;
						}
					});
				}
				if (!setDefaultHours) {
					newTask.controls.padSize.setValue(differenceInDays(this.projectInstallDate, new Date(lastTaskDate)));
					newTask.controls.hoursScheduled.setValue(hoursScheduled);
				} else {
					let scheduleDate = this.productionEndDate;
					let unscheduledHours = projectTask.hours;
					let schedule: Schedule[] = [];
					if (projectTask.taskTypeId) {
						schedule = this.getScheduledTasks(projectTask.taskTypeId, scheduleDate, unscheduledHours, false);
					}
					let firstTask = true;
					schedule.forEach((day) => {
						this.showDates(day.date);
						this.addProjectTaskSchedule(
							{
								projectTaskId: projectTask.id,
								date: day.date,
								hours: day.hours
							},
							newTask.controls.schedule,
							false
						);
						if (firstTask) {
							newTask.controls.padSize.setValue(differenceInDays(this.projectInstallDate, new Date(day.date)));
							firstTask = false;
						}
						hoursScheduled += day.hours;
					});
					newTask.controls.hoursScheduled.setValue(hoursScheduled);
				}
				this.projectTasks.push(newTask);
			}
		}
	}

	getScheduledTasks(taskTypeId: number, scheduleDate: Date, unscheduledHours: number, scheduleForward: boolean): Schedule[] {
		let reverseDirection = false;
		let tryOtherDirection = true;
		const originalScheduleDate = scheduleDate;
		let rescheduleDirection = scheduleForward;
		let inRange = scheduleForward
			? differenceInDays(scheduleDate, this.productionEndDate) < -1
			: differenceInDays(scheduleDate, this.firstCalendarDate) > 0;
		let schedule: Schedule[] = [];
		while (schedule.length === 0 && tryOtherDirection) {
			if (reverseDirection) {
				tryOtherDirection = false;
				rescheduleDirection = !rescheduleDirection;
				scheduleDate = originalScheduleDate;
				if (rescheduleDirection) {
					scheduleDate = addDays(scheduleDate, 1);
				} else {
					scheduleDate = subDays(scheduleDate, 1);
				}
				inRange = rescheduleDirection
					? differenceInDays(scheduleDate, this.productionEndDate) < -1
					: differenceInDays(scheduleDate, this.firstCalendarDate) > 0;
			}
			while (schedule.length === 0 && inRange) {
				schedule = this.scheduleTasks(taskTypeId, scheduleDate, unscheduledHours, scheduleForward);
				if (rescheduleDirection) {
					scheduleDate = addDays(scheduleDate, 1);
				} else {
					scheduleDate = subDays(scheduleDate, 1);
				}
				inRange = rescheduleDirection
					? differenceInDays(scheduleDate, this.productionEndDate) < -1
					: differenceInDays(scheduleDate, this.firstCalendarDate) > 0;
			}
			reverseDirection = true;
		}
		if (schedule.length === 0) {
			this._snackBar.open('Auto-Schedule Failed, switching to Manual Scheduling');
			schedule = this.scheduleTasks(taskTypeId, scheduleDate, unscheduledHours, scheduleForward, false);
			this.autoSchedule = false;
		}
		return schedule;
	}

	scheduleTasks(
		taskTypeId: number,
		scheduleDate: Date,
		unscheduledHours: number,
		scheduleForward: boolean,
		autoScheduleOverride: boolean = true
	): Schedule[] {
		const schedule: Schedule[] = [];
		let firstTask = true;
		let inRange = scheduleForward
			? differenceInDays(scheduleDate, this.productionEndDate) < -1
			: differenceInDays(scheduleDate, this.firstCalendarDate) > 0;
		while (unscheduledHours > 0 && inRange) {
			if (scheduleForward) {
				scheduleDate = addDays(scheduleDate, 1);
			} else {
				scheduleDate = subDays(scheduleDate, 1);
			}
			inRange = scheduleForward
				? differenceInDays(scheduleDate, this.productionEndDate) < -1
				: differenceInDays(scheduleDate, this.firstCalendarDate) > 0;
			let availableHours = this.scheduleDayMaxHours;
			availableHours = this.getAvailableHours(scheduleDate, taskTypeId);
			if (this.autoSchedule && autoScheduleOverride) {
				if (
					this.productionHours[scheduleDate.toISOString().substring(0, 10)][taskTypeId].hours > 0 &&
					availableHours < this.scheduleDayMinHours
				) {
					return [];
				}
			}
			let scheduleHours;
			scheduleHours = Math.round(Math.min(availableHours, unscheduledHours) * 100) / 100;
			if (scheduleHours > 0 || !firstTask) {
				this.showDates(scheduleDate);
				schedule.push({
					date: scheduleDate,
					hours: scheduleHours
				});
				if (firstTask) {
					firstTask = false;
				}
			}
			unscheduledHours -= scheduleHours;
		}

		if (this.autoSchedule && unscheduledHours > 0) {
			return [];
		}
		return schedule;
	}

	get projectTasks(): FormArray {
		return this.projectScheduleForm.controls.projectTasks;
	}

	projectTaskPadSize(projectTask: AbstractControl<any>): FormControl {
		return (projectTask as FormGroup).controls['padSize'] as FormControl;
	}

	projectTaskSchedule(projectTask: AbstractControl<any>): FormArray {
		return (projectTask as FormGroup).controls['schedule'] as FormArray;
	}

	addProjectTaskSchedule(schedule: Schedule, scheduleArray: FormArray, atEnd: boolean = true): void {
		const newSchedule: any = {
			projectTaskId: [schedule.projectTaskId],
			date: [schedule.date, (control: AbstractControl) => this.validateMinDate(control)],
			hours: [schedule.hours, (control: AbstractControl) => this.validateHours(control, this.productionHours)]
		};
		if (schedule.id) {
			newSchedule.id = [schedule.id];
		}
		if (atEnd) {
			scheduleArray.push(this.fb.group(newSchedule));
		} else {
			scheduleArray.insert(0, this.fb.group(newSchedule));
		}
	}

	cellWidth(size: number) {
		return 'calc(' + this.singleCellWidth + '*' + size + ')';
	}

	autoShiftTaskBack(task: FormGroup, scheduleArray: FormArray): boolean {
		this.changeWarn = true;
		const taskTypeId = task.controls['taskTypeId'].value;
		const hours = task.controls['hours'].value;
		const lastSchedule = scheduleArray.controls.at(-1) as FormGroup;
		const projectTaskId = lastSchedule.controls['projectTaskId'].value;
		const scheduleDate = lastSchedule.controls['date'].value;
		let hoursScheduled = 0;
		let lastDate: Date = this.projectInstallDate;
		let firstSchedule = true;
		const schedule = this.getScheduledTasks(taskTypeId, scheduleDate, hours, false);
		if (schedule.length) {
			scheduleArray.clear();
			schedule.forEach((day) => {
				this.showDates(day.date);
				if (firstSchedule) {
					lastDate = day.date;
					firstSchedule = false;
				}
				this.addProjectTaskSchedule(
					{
						projectTaskId: projectTaskId,
						date: day.date,
						hours: day.hours
					},
					scheduleArray,
					false
				);
				hoursScheduled += day.hours;
			});
			task.controls['padSize'].setValue(differenceInDays(this.projectInstallDate, lastDate));
			task.controls['hoursScheduled'].setValue(hoursScheduled);
			return false;
		} else {
			return true;
		}
	}

	shiftTaskBack(t: AbstractControl<any>) {
		this.changeWarn = true;
		const task = t as FormGroup;
		const scheduleArray = task.controls['schedule'] as FormArray;
		let manualSchedule = !this.autoSchedule;
		if (this.autoSchedule) {
			manualSchedule = this.autoShiftTaskBack(task, scheduleArray);
		}
		if (manualSchedule) {
			scheduleArray.controls.forEach((d) => {
				const day = d as FormGroup;
				if (day.controls['date']) {
					day.controls['date'].setValue(subDays(day.controls['date'].value, 1));
					this.showDates(day.controls['date'].value);
					day.controls['hours'].updateValueAndValidity();
				}
			});
			task.controls['padSize'].setValue(task.controls['padSize'].value + 1);
		}
	}

	autoShiftTaskForward(task: FormGroup, scheduleArray: FormArray): boolean {
		this.changeWarn = true;
		const taskTypeId = task.controls['taskTypeId'].value;
		const hours = task.controls['hours'].value;
		const firstSchedule = scheduleArray.controls.at(0) as FormGroup;
		const projectTaskId = firstSchedule.controls['projectTaskId'].value;
		const scheduleDate = firstSchedule.controls['date'].value;
		let hoursScheduled = 0;
		let lastDate: Date = this.projectInstallDate;
		const schedule = this.getScheduledTasks(taskTypeId, scheduleDate, hours, true);
		if (schedule.length) {
			scheduleArray.clear();
			schedule.forEach((day) => {
				this.showDates(day.date);
				lastDate = day.date;
				this.addProjectTaskSchedule(
					{
						projectTaskId: projectTaskId,
						date: day.date,
						hours: day.hours
					},
					scheduleArray,
					true
				);
				hoursScheduled += day.hours;
			});
			task.controls['padSize'].setValue(differenceInDays(this.projectInstallDate, lastDate));
			task.controls['hoursScheduled'].setValue(hoursScheduled);
			return false;
		} else {
			return true;
		}
	}

	shiftTaskForward(t: AbstractControl<any>) {
		this.changeWarn = true;
		const task = t as FormGroup;
		const scheduleArray = task.controls['schedule'] as FormArray;
		let manualSchedule = !this.autoSchedule;
		if (this.autoSchedule) {
			manualSchedule = this.autoShiftTaskForward(task, scheduleArray);
		}
		if (manualSchedule) {
			scheduleArray.controls.forEach((d) => {
				const day = d as FormGroup;
				if (day.controls['date']) {
					day.controls['date'].setValue(addDays(day.controls['date'].value, 1));
					day.controls['hours'].updateValueAndValidity();
				}
			});
			task.controls['padSize'].setValue(task.controls['padSize'].value - 1);
		}
	}

	addDayBefore(t: AbstractControl<any>) {
		const task = t as FormGroup;
		let firstDate = this.getFirstDate(task);
		const taskTypeId = task.controls['taskTypeId'].value;
		let add = true;
		if (firstDate) {
			while (add && differenceInDays(firstDate, this.firstCalendarDate) > 0) {
				this.showDates(subDays(firstDate, 1));
				(task.controls['schedule'] as FormArray).insert(
					0,
					this.fb.group({
						projectTaskId: task.controls['projectId'].value,
						date: [subDays(firstDate, 1), (control: AbstractControl) => this.validateMinDate(control)],
						hours: [0, (control: AbstractControl) => this.validateHours(control, this.productionHours)]
					})
				);
				firstDate = subDays(firstDate, 1);
				if (this.productionHours[firstDate.toISOString().substring(0, 10)][taskTypeId].hours > 0) {
					add = false;
				}
			}
		}
	}

	addDayAfter(t: AbstractControl<any>) {
		const task = t as FormGroup;
		let lastDate = this.getLastDate(task);
		const taskTypeId = task.controls['taskTypeId'].value;
		let add = true;
		let padAdjust = 0;
		if (lastDate) {
			while (add && differenceInDays(lastDate, this.productionEndDate) < -1) {
				(task.controls['schedule'] as FormArray).push(
					this.fb.group({
						projectTaskId: task.controls['projectId'].value,
						date: [addDays(lastDate, 1), (control: AbstractControl) => this.validateMinDate(control)],
						hours: [0, (control: AbstractControl) => this.validateHours(control, this.productionHours)]
					})
				);
				padAdjust++;
				lastDate = addDays(lastDate, 1);
				if (this.productionHours[lastDate.toISOString().substring(0, 10)][taskTypeId].hours > 0) {
					add = false;
				}
			}
			task.controls['padSize'].setValue(task.controls['padSize'].value - padAdjust);
		}
	}

	showDates(date: Date) {
		if (differenceInDays(date, this.showDate) < 0) {
			this.showDate = date;
			this.header.filter((head) => !head.show && differenceInDays(new Date(head.value), date) >= -1).forEach((head) => (head.show = true));
		}
	}

	increaseTransit() {
		this.changeWarn = true;
		this.transitDays++;
		this.adjustShippingTime();
		let manualSchedule = !this.autoSchedule;
		this.projectTasks.controls.forEach((pTask) => {
			const projectTask = pTask as FormGroup;
			const lastDate = this.getLastDate(projectTask);
			if (lastDate) {
				const dayAdjust = differenceInDays(lastDate, subDays(this.productionEndDate, 1));
				if (dayAdjust > 0) {
					if (this.autoSchedule) {
						manualSchedule = this.autoShiftTaskBack(projectTask, this.projectTaskSchedule(projectTask));
					}
					if (manualSchedule) {
						const removeList: number[] = [];
						this.projectTaskSchedule(projectTask).controls.forEach((d, index, task) => {
							const day = d as FormGroup;
							const newDate = subDays(day.controls['date'].value, dayAdjust);
							if (differenceInDays(newDate, this.firstCalendarDate) >= 0) {
								day.controls['date'].setValue(newDate);
								day.controls['hours'].updateValueAndValidity();
								this.showDates(day.controls['date'].value);
							} else {
								projectTask.controls['hoursScheduled'].setValue(projectTask.controls['hoursScheduled'].value - day.controls['hours'].value);
								removeList.push(index);
							}
						});
						removeList.forEach((i) => this.projectTaskSchedule(projectTask).controls.splice(i, 1));
						projectTask.controls['padSize'].setValue(projectTask.controls['padSize'].value + dayAdjust);
					}
				}
			}
		});
	}

	decreaseTransit() {
		this.changeWarn = true;
		if (this.transitDays > 1) {
			this.transitDays--;
			this.adjustShippingTime();
		}
	}

	getLastDate(task: FormGroup) {
		const schedule = task.controls['schedule'] as FormArray;
		return (schedule.controls[schedule.controls.length - 1] as FormGroup).controls['date'].value;
	}

	getFirstDate(task: FormGroup) {
		return ((task.controls['schedule'] as FormArray).controls[0] as FormGroup).controls['date'].value;
	}

	get visibleHeaderLength() {
		return this.header.filter((header) => header.show).length;
	}

	saveSchedule() {
		let projectTasks: ProjectTask[] = [];
		Object.assign(projectTasks, ...[this.projectTasks.value]);
		projectTasks.forEach((projectTask) => {
			if (projectTask.schedule?.length) {
				let hours = projectTask.schedule[0].hours;
				while (projectTask.schedule.length && (!hours || hours <= 0)) {
					projectTask.schedule.splice(0, 1);
					hours = projectTask.schedule[0].hours;
				}
				hours = projectTask.schedule[projectTask.schedule.length - 1].hours;
				while (projectTask.schedule.length && (!hours || hours <= 0)) {
					projectTask.schedule.splice(-1);
					hours = projectTask.schedule[projectTask.schedule.length - 1].hours;
				}
			}
		});
		if (this.projectId) {
			const projectScheduleUpdate: ProjectScheduleUpdate = {
				installDate: this.projectInstallDate.toISOString().substring(0, 10),
				shipDate: this.shipDate.toISOString().substring(0, 10),
				transitTime: this.transitDays,
				projectTasks: projectTasks
			};
			this.busy = true;
			this.saving = true;
			this.projectService.updateProjectSchedule(this.projectId, projectScheduleUpdate).subscribe({
				next: (projectSchedule) => {
					this._snackBar.open('Schedule Saved.');
					this.busy = false;
					this.saving = false;
					this.initWarn = false;
					this.changeWarn = false;
					this.autoWarn = false;
					this.projectScheduleForm.markAsPristine();
				},
				error: (err) => {
					this.productionHours = err.error.schedule.productionHours;
					this.updateFormValidity(this.projectScheduleForm);
					this._snackBar.open('Save Schedule Failed: ' + err.error.message);
					this.busy = false;
					this.saving = false;
				}
			});
		}
	}

	updateFormValidity(form: FormGroup | FormArray): void {
		Object.keys(form.controls).forEach((key) => {
			const control = form.get(key);

			if (control instanceof FormGroup || control instanceof FormArray) {
				this.updateFormValidity(control);
			} else {
				if (control) {
					control.updateValueAndValidity();
				}
			}
		});
	}

	getProductionHours(date: string, task: ProjectTask) {
		if (task.taskTypeId) {
			return this.productionHours[date][task.taskTypeId].hours;
		}
		return 0;
	}

	getProductionSchedule(date: string, task: ProjectTask) {
		let scheduledHours = 0;
		if (task.taskTypeId) {
			scheduledHours = this.productionHours[date][task.taskTypeId].scheduled;
			const schedule = task.schedule?.find((schedule) => schedule.date && schedule.date.toISOString().substring(0, 10) === date);
			if (schedule && schedule.hours) {
				scheduledHours += schedule.hours;
			}
		}
		return Math.round(scheduledHours * 10) / 10;
	}

	getProductionComment(date: string, task: ProjectTask): string {
		if (task.taskTypeId) {
			return this.productionHours[date][task.taskTypeId].comment;
		}
		return '';
	}

	updateHours(t: AbstractControl<any>) {
		const task = t as FormGroup;
		let hoursScheduled = 0;
		(task.controls['schedule'] as FormArray).controls.forEach((d) => {
			const day = d as FormGroup;
			if (day.controls['hours']) {
				hoursScheduled += day.controls['hours'].value;
			}
		});
		task.controls['hoursScheduled'].setValue(hoursScheduled);
	}

	taskHours(t: AbstractControl<any>) {
		const task = t as FormGroup;
		return '(' + Math.round(task.value.hoursScheduled * 10) / 10 + '/' + task.value.hours + ')';
	}

	validateHours(hours: AbstractControl, productionHours: ProductionHours) {
		let invalid = true;
		const scheduleForm = hours.parent as FormGroup;
		if (scheduleForm) {
			const scheduleArray = scheduleForm.parent as FormArray;
			const date = new Date(scheduleForm.controls['date'].value);
			if (scheduleArray) {
				const projectTaskForm = scheduleArray.parent as FormGroup;
				if (projectTaskForm) {
					const taskTypeId = projectTaskForm.controls['taskTypeId'].value;
					invalid =
						hours.value > 0 &&
						productionHours[date.toISOString().substring(0, 10)][taskTypeId].hours <
							productionHours[date.toISOString().substring(0, 10)][taskTypeId].scheduled + hours.value;
				}
			}
		}
		return invalid ? {hours} : null;
	}

	validateMinDate(day: AbstractControl) {
		let invalid = true;
		const firstDate = differenceInDays(this.tomorrow, this.firstCalendarDate) > 0 ? this.tomorrow : this.firstCalendarDate;
		invalid = differenceInDays(this.tomorrow, startOfDay(new Date(day.value))) > 0;
		return invalid ? {day} : null;
	}

	getAvailableHours(date: Date, taskTypeId: number) {
		return Math.max(
			0,
			Math.min(
				this.scheduleDayMaxHours,
				this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].hours -
					this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].scheduled
			)
		);
	}

	maxHours(taskIndex: number, s: AbstractControl<any>) {
		const scheduleForm = s as FormGroup;
		const date = scheduleForm.controls['date'].value;
		const taskForm = this.projectTasks.controls[taskIndex] as FormGroup;
		const taskTypeId = taskForm.controls['taskTypeId'].value;
		return Math.max(
			0,
			this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].hours -
				this.productionHours[date.toISOString().substring(0, 10)][taskTypeId].scheduled
		);
	}

	inputFocused(e: Event) {
		const input = e.target as HTMLInputElement;
		input.select();
	}

	calcWeekends(startDate: Date, days: number) {
		let weekendCount = 0;
		if (isWeekend(startDate)) {
			weekendCount++;
			days++;
		}
		while (days > 0) {
			startDate = subDays(startDate, 1);
			if (isWeekend(startDate)) {
				weekendCount++;
			} else {
				days--;
			}
		}
		return weekendCount;
	}

	adjustShippingTime() {
		this.transitWeekends = this.calcWeekends(this.projectInstallDate, this.transitDays);
		this.transitTotal = this.transitDays + this.transitWeekends;
		this.shipDate = subDays(this.projectInstallDate, this.transitTotal);
		if (this.transfer) {
			this.shippingBuffer =
				this.shippingBufferDefault + this.transferTime + this.calcWeekends(this.shipDate, this.shippingBufferDefault + this.transferTime);
			if (this.calcWeekends(this.shipDate, this.shippingBufferDefault) > 0) {
				this.transferBeforeBuffer = this.transferTime;
				this.transferAfterBuffer = this.shippingBufferDefault + 2;
			} else {
				this.transferAfterBuffer = this.shippingBufferDefault;
				if (this.calcWeekends(this.shipDate, this.shippingBufferDefault + this.transferTime) > 0) {
					this.transferBeforeBuffer = this.transferTime + 2;
				} else {
					this.transferBeforeBuffer = this.transferTime;
				}
			}
		} else {
			this.shippingBuffer = this.shippingBufferDefault + this.calcWeekends(this.shipDate, this.shippingBufferDefault);
		}
		this.productionEndDate = subDays(this.shipDate, this.shippingBuffer);
	}

	editProductionHourOverride(i: number) {
		const date = this.header[i].value;
		const override = this.dialog.open(ProductionHourOverrideDialogComponent, {
			data: {
				date: this.header[i].value
			}
		});
		override.afterClosed().subscribe((result: ProductionHourOverrideDialogResponse) => {
			if (result.success) {
				for (let taskTypeId in result.overrides[date]) {
					this.productionHours[date][taskTypeId].hours = result.overrides[date][taskTypeId].hours;
					this.productionHours[date][taskTypeId].comment = result.overrides[date][taskTypeId].comment;
				}
				this.updateFormValidity(this.projectScheduleForm);
			}
		});
	}

	getClass(name: string): string {
		return this.taskTypeService.getClass(name);
	}

	dateOnly(date: string | number | Date): Date {
		const dateStr = new Date(date).toISOString().substring(0, 10) + startOfDay(new Date()).toISOString().substring(10);
		return new Date(dateStr);
	}

	warnChanges() {
		return (
			!this.initWarn &&
			(this.changeWarn || this.projectScheduleForm.controls.installDate.dirty || this.projectScheduleForm.controls.projectTasks.dirty)
		);
	}

	showError(schedule: AbstractControl, control: string) {
		const scheduleForm = schedule as FormGroup;
		return scheduleForm.controls[control].invalid;
	}
}
