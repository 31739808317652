import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Broker} from '../models/broker.interface';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class BrokersService {
	brokersSubject: Subject<Broker[]> = new Subject();
	constructor(private http: HttpClient) {}
	findAll(): void {
		this.http.get<any>(`${environment.url}/broker`).subscribe((brokers) => {
			this.brokersSubject.next(brokers);
		});
	}
	findOne(id: number): Observable<any> {
		return this.http.get<any>(`${environment.url}/broker/${id}`);
	}
	update(id: number, broker: Partial<Broker>): Observable<any> {
		return this.http.patch<any>(`${environment.url}/broker/${broker.id}`, broker);
	}
	create(broker: Partial<Broker>): Observable<Broker> {
		return this.http.post<any>(`${environment.url}/broker`, broker);
	}
}
