import {Component} from '@angular/core';
import {AuthService} from './core/services/auth.service';
import {User} from './core/models/user.interface';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'mmi-ui';
	user: User | null = null;
	reset: boolean;

	constructor(private authService: AuthService) {
		this.authService.user.subscribe((value) => {
			this.user = value;
		});
		this.authService.resetPassword.subscribe((value) => {
			this.reset = value;
		});
	}
}
