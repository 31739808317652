import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CalendarComponent} from './calendar.component';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';
import {RouterLink} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
	declarations: [CalendarComponent],
	imports: [
		CommonModule,
		MatInputModule,
		MatDatepickerModule,
		MatButtonModule,
		MatDividerModule,
		MatCheckboxModule,
		FormsModule,
		RouterLink,
		MatIconModule,
		MatMenuModule,
		MatButtonToggleModule,
		MatProgressSpinnerModule,
		InfiniteScrollModule,
		MatTooltipModule,
		MatOptionModule,
		MatSelectModule
	],
	exports: [CalendarComponent]
})
export class CalendarModule {}
