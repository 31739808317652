import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, FormGroupDirective} from '@angular/forms';
import {ConfigType} from '../../../core/models/type-options.model';
import {Part} from '../../../core/models/part.interface';
import {QuoteConfigShelf} from '../../../core/models/quote-config-shelf.interface';

@Component({
	selector: 'app-quote-config',
	templateUrl: './quote-config.component.html',
	styleUrls: ['./quote-config.component.scss']
})
export class QuoteConfigComponent implements OnInit {
	@Input() index: number = 0;
	@Input() formArrayName: string = '';
	@Input() typeOptions: ConfigType[] = [];
	@Input() partArray: Part[] = [];

	@Output() copyConfigEvent: EventEmitter<number> = new EventEmitter<number>();
	@Output() deleteConfigEvent: EventEmitter<number> = new EventEmitter<number>();

	configForm: FormGroup = new FormGroup({});

	constructor(private rootFormGroup: FormGroupDirective, private fb: FormBuilder) {}

	ngOnInit(): void {
		this.initializeForm();

		if (!this.quoteConfigShelves.length) {
			this.addShelfConfig();
		}
	}

	initializeForm(): void {
		this.configForm = (this.rootFormGroup.control.controls[this.formArrayName] as FormArray).controls[this.index] as FormGroup;
	}

	get configFormControl() {
		return ((this.rootFormGroup.control.controls['configs'] as FormArray).controls[this.index] as FormGroup).controls;
	}

	get dimensions(): Part[] {
		return this.partArray
			.filter((part) => part.type === this.configFormControl['type'].value)
			.sort((a, b) => (a.description ?? '').localeCompare(b.description ?? ''));
	}

	get totalUnits(): number {
		let totalStarters: number = 0;
		let totalAdders: number = 0;
		this.quoteConfigShelves?.getRawValue()?.forEach((shelf: QuoteConfigShelf) => {
			totalStarters += shelf.starters ?? 0;
			totalAdders += shelf.adders ?? 0;
		});

		return totalStarters + totalAdders;
	}

	addShelfConfig(): void {
		const quoteConfigShelf: FormGroup = this.fb.group({
			partId: [null],
			starters: [{value: null, disabled: true}],
			adders: [{value: null, disabled: true}]
		});

		this.quoteConfigShelves.push(quoteConfigShelf);

		this.quoteConfigShelves.valueChanges.subscribe((value) => {
			for (let i = 0; i < value.length; i++) {
				if (value[i]['partId'] !== null) {
					(this.quoteConfigShelves.at(i) as FormGroup).controls['starters'].enable({emitEvent: false});
					(this.quoteConfigShelves.at(i) as FormGroup).controls['adders'].enable({emitEvent: false});
				}
			}
		});
	}

	get quoteConfigShelves(): FormArray {
		return ((this.rootFormGroup.control.controls[this.formArrayName] as FormArray).controls[this.index] as FormGroup).controls[
			'quoteConfigShelves'
		] as FormArray;
	}

	deleteShelfConfig(index: number): void {
		this.quoteConfigShelves.removeAt(index);
		this.rootFormGroup.control.markAsDirty();
	}

	deleteConfig(): void {
		this.deleteConfigEvent.emit(this.index);
		this.rootFormGroup.control.markAsDirty();
	}

	copyConfig(): void {
		this.copyConfigEvent.emit(this.index);
		this.rootFormGroup.control.markAsDirty();
	}
}
