<mat-form-field class="w-full" appearance="outline" subscriptSizing="dynamic">
	<input matInput type="text" [placeholder]="placeholder" [matAutocomplete]="auto" [formControl]="selectControl" />
</mat-form-field>

<mat-autocomplete #auto [displayWith]="displayFn">
	<mat-option *ngFor="let data of filteredData | async">
		<div (click)="optionClicked($event, data)">
			<mat-checkbox [checked]="data.selected" (change)="toggleSelection(data)" (click)="$event.stopPropagation()">
				<span class="font-bold">{{ data.contact.name ?? '' }}</span
				><br />{{ data.contact.email ?? '' }}
			</mat-checkbox>
		</div>
	</mat-option>
</mat-autocomplete>
