import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ProductionDayHourOverride} from '../models/production-day-hour-override.model';
import {ProductionHours} from '../models/production-hours.interface';

@Injectable({
	providedIn: 'root'
})
export class ProductionDayHourService {
	baseUrl: string = `${environment.url}/production-day-hours`;

	constructor(private http: HttpClient) {}

	getOverride(selectedDate: string): Observable<ProductionDayHourOverride[]> {
		let params = new HttpParams();
		params = params.append('selectedDate', selectedDate);
		return this.http.get<ProductionDayHourOverride[]>(`${this.baseUrl}/overrides`, {params: params});
	}

	updateOverride(selectedDate: string, overrides: ProductionDayHourOverride[]): Observable<ProductionHours> {
		let params = new HttpParams();
		params = params.append('selectedDate', selectedDate);
		return this.http.post<ProductionHours>(`${this.baseUrl}/overrides`, overrides, {params: params});
	}
}
