<div class="m-4">
	<div class="flex flex-row">
		<mat-accordion multi *ngIf="freightForm" [formGroup]="freightForm" class="w-3/5 mr-4">
			<mat-expansion-panel [expanded]="expandValid.shipmentInfo" formGroupName="shipmentInfoForm">
				<mat-expansion-panel-header>
					<mat-panel-title> Shipment Info</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex flex-col">
					<div class="flex">
						<mat-form-field appearance="outline" class="w-1/2 mr-2">
							<mat-label>Description</mat-label>
							<input matInput type="text" formControlName="description" [readonly]="!!freightId" />
						</mat-form-field>

						<mat-form-field appearance="outline" class="w-1/2">
							<mat-label>Order Number</mat-label>
							<input matInput type="text" formControlName="shipmentOrderNo" [readonly]="!!freightId" [matAutocomplete]="auto" />
						</mat-form-field>

						<mat-autocomplete #auto>
							<mat-option *ngFor="let data of filteredSalesOrderList | async" [value]="data.id" (click)="populateDestinationForm(data)">
								<div *ngIf="salesOrderList.length">
									<span class="font-bold">{{ data.id }}</span>
								</div>
							</mat-option>
							<mat-option *ngIf="!salesOrderList.length" id="spinner-option"><mat-spinner class="scale-50"></mat-spinner></mat-option>
						</mat-autocomplete>
					</div>
					<div class="flex justify-end" *ngIf="global && projectId">
						<button mat-raised-button (click)="global = false; cancel()" class="mr-2">View Project</button>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="expandValid.equipmentType" formGroupName="equipmentTypeForm">
				<mat-expansion-panel-header>
					<mat-panel-title> Equipment Type</mat-panel-title>
				</mat-expansion-panel-header>
				<div>
					<mat-button-toggle-group formControlName="equipmentType">
						<mat-button-toggle [value]="equipmentType.value" *ngFor="let equipmentType of equipmentTypeList">
							<mat-icon class="mr-2">{{ equipmentType.data }}</mat-icon>
							{{ equipmentType.description }}
						</mat-button-toggle>
					</mat-button-toggle-group>
					<mat-error *ngIf="!freightForm.controls.equipmentTypeForm.controls.equipmentType.valid && !freightId"
						>Equipment Type <strong>required</strong>.
					</mat-error>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="expandValid.origin">
				<mat-expansion-panel-header>
					<mat-panel-title> Origin</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex flex-col">
					<app-client-site
						[context]="ClientSiteComponentContext.INTERNAL"
						componentFormGroupName="originForm"
						siteCode="originSiteCode"
						name="originName"
						address1="originAddress1"
						address2="originAddress2"
						city="originCity"
						state="originState"
						postal="originPostal"
						country="originCountry"
						[readOnly]="!!freightId"
						[locationId]="locationId"
					>
					</app-client-site>

					<div class="m-2">
						<mat-label class="font-medium">Contact</mat-label>
					</div>

					<app-contact
						[context]="ContactComponentContext.SITE"
						contextIdField="originSiteCode"
						componentFormGroupName="originForm"
						contactName="originContactName"
						contactEmail="originContactEmail"
						contactPhone="originContactPhone"
						contactPhoneExt="originContactPhoneExt"
						[readOnly]="!!freightId"
					></app-contact>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="expandValid.destination">
				<mat-expansion-panel-header>
					<mat-panel-title> Destination</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex flex-col">
					<app-client-site
						[context]="ClientSiteComponentContext.CLIENT"
						[contextId]="clientId"
						componentFormGroupName="destinationForm"
						siteCode="destinationSiteCode"
						name="destinationName"
						address1="destinationAddress1"
						address2="destinationAddress2"
						city="destinationCity"
						state="destinationState"
						postal="destinationPostal"
						country="destinationCountry"
						[readOnly]="!!freightId"
						[disableSelection]="true"
						[projectId]="projectId"
					>
					</app-client-site>

					<div class="m-2">
						<mat-label class="font-medium">Contact</mat-label>
					</div>

					<app-contact
						[context]="ContactComponentContext.SITE"
						contextIdField="destinationSiteCode"
						componentFormGroupName="destinationForm"
						contactName="destinationContactName"
						contactEmail="destinationContactEmail"
						contactPhone="destinationContactPhone"
						contactPhoneExt="destinationContactPhoneExt"
						[readOnly]="!!freightId"
					></app-contact>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="expandValid.scheduling" formGroupName="schedulingForm">
				<mat-expansion-panel-header>
					<mat-panel-title> Scheduling</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex flex-col">
					<div class="flex flex-row">
						<mat-form-field appearance="outline" class="w-1/2">
							<mat-label class="font-medium">Delivery Date</mat-label>
							<input matInput [min]="minDate" [matDatepicker]="datepicker" formControlName="deliveryDate" />
							<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
							<mat-datepicker #datepicker> </mat-datepicker>
							<mat-error>Date <strong>required</strong>.</mat-error>
						</mat-form-field>
						<button
							class="mr-2"
							mat-icon-button
							(click)="deleteDate()"
							*ngIf="freightForm.controls.schedulingForm.controls.deliveryDate.value && !freightId"
						>
							<mat-icon>cancel</mat-icon>
						</button>
						<mat-form-field appearance="outline" class="w-1/4 mr-2">
							<mat-label>Delivery Time</mat-label>
							<mat-select formControlName="deliveryTime">
								<mat-option [value]="time.value" *ngFor="let time of timeList">{{ time.description }}</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field appearance="outline" class="w-1/4">
							<mat-label>Time Zone</mat-label>
							<mat-select formControlName="deliveryTimeZone" (selectionChange)="calculateFreightDetails()">
								<mat-option [value]="timeZone.value" *ngFor="let timeZone of timeZoneList">{{ timeZone.description }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="expandValid.specialServices">
				<mat-expansion-panel-header>
					<mat-panel-title> Special Services</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex flex-col">
					<div class="flex flex-row flex-1" formGroupName="accessorialForm">
						<app-accessorials
							*ngIf="!freightId"
							class="flex flex-col flex-1"
							[selectedAccessorialArray]="selectedAccessorials"
							[isNestedForm]="true"
							[displayMode]="AccessorialsComponentDisplayModes.EMIT"
							componentFormGroupName="accessorialForm"
							componentFormArrayName="accessorials"
							(addAccessorialEvent)="addAccessorial($event)"
						></app-accessorials>
					</div>
					<div class="flex flex-col flex-1" formGroupName="specialServicesForm">
						<ng-container formArrayName="shipmentAccessorials">
							<div
								class="flex items-center justify-between border-solid border-2 mb-2 p-4 rounded"
								*ngFor="let shipmentAccessorial of shipmentAccessorials.controls; let i = index"
								[formGroupName]="i"
							>
								<span>{{ shipmentAccessorial.value.name }}</span>
								<button mat-button (click)="deleteShipmentAccessorial(i)" *ngIf="!freightId">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</ng-container>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="expandValid.freightDetails">
				<mat-expansion-panel-header>
					<mat-panel-title> Freight Details</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex flex-col" formGroupName="freightDetailsForm">
					<ng-container formArrayName="shipmentHandlingUnits">
						<ng-container *ngFor="let shipmentHandlingUnit of shipmentHandlingUnits.controls; let i = index" [formGroupName]="i">
							<app-handling-unit
								[index]="i"
								componentFormGroupName="freightDetailsForm"
								componentFormArrayName="shipmentHandlingUnits"
								(deleteHandlingUnitEvent)="deleteShipmentHandlingUnit($event)"
								(recalculateFreightDetails)="calculateFreightDetails($event)"
								[readOnly]="!!freightId"
							></app-handling-unit>
						</ng-container>
					</ng-container>
					<button mat-button color="primary" (click)="addShippingHandlingUnit()" class="flex-none self-start mb-4" *ngIf="!freightId">
						<mat-icon class="text-xs">add_circle</mat-icon>
						Add Handling Unit
					</button>
					<div class="flex flex-row">
						<mat-form-field appearance="outline" class="w-1/3 mr-2">
							<mat-label>Total Linear Dimensions</mat-label>
							<input
								matInput
								type="number"
								formControlName="totalLinearDimension"
								(change)="setFreightDetailsDirty()"
								[readonly]="!!freightId"
							/>
							<mat-error>Linear Dimensions <strong>required</strong>.</mat-error>
						</mat-form-field>
						<mat-form-field appearance="outline" class="w-1/6 mr-2">
							<mat-label>Unit</mat-label>
							<mat-select formControlName="dUnit" (selectionChange)="calculateFreightDetails()">
								<mat-option [value]="dUnit" *ngFor="let dUnit of dUnitList">{{ dUnit }}</mat-option>
							</mat-select>
							<mat-error>Unit <strong>required</strong>.</mat-error>
						</mat-form-field>
						<mat-form-field appearance="outline" class="w-1/3 mr-2">
							<mat-label>Total Weight</mat-label>
							<input matInput type="number" formControlName="totalWeight" (change)="setFreightDetailsDirty()" [readonly]="!!freightId" />
							<mat-error>Weight <strong>required</strong>.</mat-error>
						</mat-form-field>
						<mat-form-field appearance="outline" class="w-1/6 mr-2">
							<mat-label>Unit</mat-label>
							<mat-select formControlName="wUnit" (selectionChange)="calculateFreightDetails()">
								<mat-option [value]="wUnit" *ngFor="let wUnit of wUnitList">{{ wUnit }}</mat-option>
							</mat-select>
							<mat-error>Unit <strong>required</strong>.</mat-error>
						</mat-form-field>
						<button mat-icon-button (click)="calculateFreightDetails()" [disabled]="false && !recalculateFreightDetails" *ngIf="!freightId">
							<mat-icon>calculate</mat-icon>
						</button>
					</div>
					<div class="flex">
						<mat-form-field appearance="outline" class="w-1/3 mr-2">
							<mat-label>Skid Spots Needed</mat-label>
							<input matInput type="number" formControlName="totalSkids" (change)="setFreightDetailsDirty()" [readonly]="!!freightId" />
							<mat-error>Skid Spots <strong>required</strong>.</mat-error>
						</mat-form-field>
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel [expanded]="expandValid.reference" formGroupName="referenceForm">
				<mat-expansion-panel-header>
					<mat-panel-title> Reference</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex flex-col">
					<ng-container formArrayName="shipmentReferences">
						<div class="flex flex-row" *ngFor="let shipmentReference of shipmentReferences.controls; let i = index" formGroupName="{{ i }}">
							<mat-form-field appearance="outline" class="w-1/2 mr-2">
								<mat-label>Source</mat-label>
								<input matInput type="text" formControlName="source" [readonly]="!!freightId" />
								<mat-error>Source <strong>required</strong>.</mat-error>
							</mat-form-field>
							<mat-form-field appearance="outline" class="w-1/2 mr-2">
								<mat-label>Reference #</mat-label>
								<input matInput type="text" formControlName="referenceNo" [readonly]="!!freightId" />
								<mat-error>Reference <strong>required</strong>.</mat-error>
							</mat-form-field>
							<button mat-button (click)="deleteShipmentReference(i)" class="mt-2 ml-2" *ngIf="!freightId">
								<mat-icon class="my-2">delete</mat-icon>
							</button>
						</div>
					</ng-container>
					<button mat-button color="primary" (click)="addShippingReference()" class="flex-none self-start mb-4" *ngIf="!freightId">
						<mat-icon class="text-xs">add_circle</mat-icon>
						Add Reference #
					</button>
					<mat-form-field appearance="outline">
						<mat-label>Instructions</mat-label>
						<textarea matInput type="text" formControlName="instructions" [readonly]="!!freightId"></textarea>
					</mat-form-field>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
		<div class="flex flex-col w-2/5 self-start sticky top-0 mat-elevation-z8 rounded-lg">
			<div class="flex flex-row justify-between items-center p-4">
				<mat-label class="text-xl font-medium">Summary</mat-label>
				<div>
					<button mat-raised-button (click)="cancel()" class="mr-2">Cancel</button>
					<button mat-raised-button color="primary" (click)="saveShipment()" [disabled]="!freightForm.dirty" *ngIf="!freightId">
						Save Shipment
					</button>
				</div>
			</div>
			<mat-divider></mat-divider>
			<div>
				<div class="flex flex-row justify-between">
					<div class="flex flex-1 justify-center items-center font-bold p-4">
						<mat-icon class="mr-2">calendar_today</mat-icon>
						{{ deliveryDate }}
					</div>
					<mat-divider vertical></mat-divider>
					<div class="flex flex-1 justify-center items-center font-bold p-4">
						<mat-icon class="mr-2">schedule</mat-icon>
						{{ deliveryTime }}
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="flex justify-center items-center font-bold p-4">
					<ng-container *ngIf="selectedEquipmentType">
						<mat-icon class="mr-2">{{ selectedEquipmentType.data }}</mat-icon>
						{{ selectedEquipmentType.description }}
					</ng-container>
				</div>
				<mat-divider></mat-divider>
				<div class="flex flex-row justify-between">
					<div class="flex flex-1 flex-col p-4">
						<mat-icon>place</mat-icon>
						<span>{{ freightForm.controls.originForm.controls.originName.value }}</span>
						<span>{{ freightForm.controls.originForm.controls.originAddress1.value }}</span>
						<span>{{ freightForm.controls.originForm.controls.originAddress2.value }}</span>
						<div class="flex flex-row">
							{{ formatCity(freightForm.controls.originForm.controls.originCity.value) }}
							{{ freightForm.controls.originForm.controls.originState.value }}
							{{ freightForm.controls.originForm.controls.originPostal.value }}
							{{ freightForm.controls.originForm.controls.originCountry.value }}
						</div>
					</div>
					<mat-divider vertical></mat-divider>
					<div class="flex flex-1 flex-col p-4">
						<mat-icon>tour</mat-icon>
						<span>{{ freightForm.controls.destinationForm.controls.destinationName.value }}</span>
						<span>{{ freightForm.controls.destinationForm.controls.destinationAddress1.value }}</span>
						<span>{{ freightForm.controls.destinationForm.controls.destinationAddress2.value }}</span>
						<div class="flex flex-row">
							{{ formatCity(freightForm.controls.destinationForm.controls.destinationCity.value) }}
							{{ freightForm.controls.destinationForm.controls.destinationState.value }}
							{{ freightForm.controls.destinationForm.controls.destinationPostal.value }}
							{{ freightForm.controls.destinationForm.controls.destinationCountry.value }}
						</div>
					</div>
				</div>
			</div>
			<mat-divider></mat-divider>
			<div class="flex flex-col pt-4 pl-4">
				<span *ngFor="let shipmentAccessorial of shipmentAccessorials.controls">{{ shipmentAccessorial.value.name }}</span>
			</div>
			<div class="flex flex-1 justify-between font-medium p-4">
				<div class="flex flex-row">{{ (freightForm.controls.freightDetailsForm.controls.totalSkids.value | number) || 0 }} Skid Slots</div>
				<div class="flex flex-col">
					<span *ngFor="let handlingUnit of handlingUnitSummary | keyvalue"
						>{{ handlingUnit.value }} {{ handlingUnit.key }}{{ handlingUnit.value > 1 ? 'S' : '' }}</span
					>
				</div>
				<div class="flex flex-row">
					{{ (freightForm.controls.freightDetailsForm.controls.totalLinearDimension.value | number) || 0 }}
					{{ freightForm.controls.freightDetailsForm.controls.dUnit.value }}
				</div>
				<div class="flex flex-row">
					{{ (freightForm.controls.freightDetailsForm.controls.totalWeight.value | number) || 0 }}
					{{ freightForm.controls.freightDetailsForm.controls.wUnit.value }}
				</div>
			</div>
			<mat-divider></mat-divider>
			<app-validation-errors *ngIf="freightForm" [formGroup]="freightForm"></app-validation-errors>
		</div>
	</div>
</div>
