<mat-expansion-panel [formGroup]="salesOrderForm" expanded>
	<mat-expansion-panel-header>
		<mat-panel-title> Approval Info </mat-panel-title>
	</mat-expansion-panel-header>
	<div *ngIf="loading">
		<mat-spinner class="scale-50"></mat-spinner>
	</div>
	<div *ngIf="!loading">
		<div class="flex flex-col">
			<div class="flex flex-row space-x-2" *ngIf="approval">
				<span class="font-bold"> Approved By: </span>
				<span> {{ approval.approverName }}</span>
			</div>
			<div class="flex flex-row space-x-2" *ngIf="approval">
				<span class="font-bold"> Approval Date: </span>
				<span> {{ approval.createdTs | date }} </span>
			</div>
			<div class="flex flex-row space-x-2" *ngIf="approval">
				<span class="font-bold"> Requested Install: </span>
				<span *ngIf="approval.installDateType === 'install'"> {{ approval.installDate | date }} </span>
				<span *ngIf="approval.installDateType !== 'install'"> {{ getEstimateText(approval.installDate) }} </span>
			</div>
			<mat-form-field class="w-3/4 mt-4">
				<mat-label>PO Number</mat-label>
				<input matInput formControlName="po" />
				<mat-error *ngIf="salesOrderForm.get('po')?.touched && salesOrderForm.get('po')?.hasError('required')">This is a required field</mat-error>
        <mat-error *ngIf="salesOrderForm.get('po')?.touched && salesOrderForm.get('po')?.hasError('maxlength')">{{salesOrderForm.get('po')?.value.length}} / 35 max characters allowed</mat-error>
			</mat-form-field>
		</div>

		<div class="flex flex-row space-x-2 w-full mb-4" *ngIf="poUrl">
			<button mat-button color="primary" class="align-self-end" type="button" (click)="downloadPoUpload()">VIEW PO UPLOAD</button>
		</div>

		<div *ngIf="!this.existingPo" class="mb-2 font-bold">
			<span>This quote has not been externally approved.</span>
		</div>

		<div class="w-full my-2">
			<mat-divider></mat-divider>
		</div>

		<div class="flex flex-col w-3/4 mt-6">
			<mat-form-field>
				<mat-label> Sales Order Number </mat-label>
				<input type="string" formControlName="salesOrderNo" matInput />
			</mat-form-field>

			<app-install-ship-date [formGroup]="salesOrderForm" [column]="true"></app-install-ship-date>
		</div>
	</div>
</mat-expansion-panel>
