import {ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ShipmentService} from '../../core/services/shipment.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Shipment} from '../../core/models/shipment.model';
import {startOfDay} from 'date-fns';
import {ShipmentHandlingUnit} from '../../core/models/shipment-handling-unit.model';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Freight} from '../../core/models/freight.model';
import {FreightBroker} from '../../core/models/freight-broker.interface';
import {FreightDetailCalculationService} from '../../core/services/freight-detail-calculation.service';
import {FreightBid} from '../../core/models/freight-bid.interface';
import {MatExpansionPanel} from '@angular/material/expansion';
import {FreightService} from '../../core/services/freight.service';
import {ProjectFreight} from '../../core/models/project-freight.interface';
import {Code} from '../../core/models/shipping-code.model';
import {ShippingCodesService} from '../../core/services/shipping-codes.service';
import {Broker} from '../../core/models/broker.interface';
import {CreateShippingLogDialogComponent} from '../../shipping-log/create-shipping-log-dialog/create-shipping-log-dialog.component';
import {PhonePipe} from '../../shared/pipes/phone.pipe';
import {Project} from '../../core/models/project.interface';

export interface FreightListEntry {
	freightId?: number;
	deliveryDate?: string;
	deliveryTime?: string;
	deliveryTimeZone?: string;
	deliveryName?: string;
	deliveryAddress1?: string;
	deliveryAddress2?: string;
	deliveryCity?: string;
	deliveryState?: string;
	deliveryPostal?: string;
	deliveryCountry?: string;
	booked?: boolean;
	bookAccepted?: boolean;
	ready: boolean;
	shipments: Shipment[];
	freightBrokers?: FreightBroker[];
	bidCount?: number;
	rateRequestCount?: number;
	bids?: FreightBid[];
	expanded?: boolean;
	bookings?: FreightBid[];
	logged?: boolean;
	expired?: boolean;
	editDate?: boolean;
	newDate?: Date;
	orderInfo?: string;
	locationName?: string;
}
@Component({
	selector: 'app-project-freight',
	templateUrl: './project-freight.component.html',
	styleUrls: ['./project-freight.component.scss']
})
export class ProjectFreightComponent implements OnInit {
	@ViewChildren('freightPanel') freightPanels: QueryList<MatExpansionPanel>;
	projectId: number | undefined;

	freightList: FreightListEntry[] = [];
	selectedFreight: FreightListEntry | null = null;
	selectedHandlingUnits: {[key: string]: number} = {};
	selectedTotalLinearDimensions: number = 0;
	selectedDUnit: string = '';
	selectedTotalWeight: number = 0;
	selectedWUnit: string = '';
	selectedTotalSkids: number = 0;
	selectedShipmentAccessorials: string[] = [];
	validShipmentIds: number[] = [];

	filter = '';
	filterType = '';
	locationFilter = '';

	lastFilter = '';
	lastFilterType = '';

	timeList: Code[] = [];
	timeZoneList: Code[] = [];

	brokers: Broker[] = [];

	locations: string[] = [];

	busy = false;

	constructor(
		private route: ActivatedRoute,
		private shipmentService: ShipmentService,
		private _snackBar: MatSnackBar,
		private dialog: MatDialog,
		private changeDetector: ChangeDetectorRef,
		private freightDetailCalculationService: FreightDetailCalculationService,
		private freightService: FreightService,
		private router: Router,
		private codeService: ShippingCodesService
	) {}

	ngOnInit() {
		this.timeList = this.codeService.getCodes('TIME');
		this.timeZoneList = this.codeService.getCodes('TIMEZONE');

		let currentRoute = this.route;
		while (currentRoute.children[0]) {
			currentRoute = currentRoute.children[0];
		}
		currentRoute.params.subscribe({
			next: (params) => {
				this.projectId = params['projectId'];
				this.getFreight();
			}
		});
	}

	getFreight() {
		this.busy = true;
		this.freightList = [];
		this.selectedFreight = null;
		if (this.projectId) {
			this.shipmentService.getProjectShipments(this.projectId).subscribe({
				next: (projectFreight) => {
					this.processFreight(projectFreight);
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Get Project Shipments Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		} else {
			this.shipmentService.getGlobalShipments().subscribe({
				next: (projectFreight) => {
					this.processFreight(projectFreight);
					this.locations = projectFreight.locations;
					this.busy = false;
				},
				error: (err) => {
					this._snackBar.open('Get Global Shipments Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	processFreight(projectFreight: ProjectFreight) {
		this.brokers = projectFreight.brokers;
		this.addFreight(projectFreight.freights, projectFreight.projects);
		this.buildFreightList(projectFreight.shipments, projectFreight.projects);
		this.freightList.sort((a, b) => {
			let aDate = new Date(0);
			let bDate = new Date(0);
			if (a.deliveryDate) {
				aDate = new Date(a.deliveryDate);
			}
			if (b.deliveryDate) {
				bDate = new Date(b.deliveryDate);
			}
			if (!a.freightId && !b.freightId) {
				return aDate > bDate ? 1 : -1;
			}
			if (a.freightId && !b.freightId) {
				return -1;
			}
			if (!a.freightId && b.freightId) {
				return 1;
			}
			return aDate > bDate ? 1 : -1;
		});
	}

	addFreight(freights: Freight[], projects: Project[]) {
		freights.forEach((freight) => {
			if (freight.shipments && freight.shipments.length) {
				let deliveryDate: string = 'Not Scheduled';
				let deliveryTime: string = '';
				let deliveryTimeZone: string = '';
				let logged = false;
				let expired = false;
				let orderInfo: string = '';
				const shipments: Shipment[] = [];
				freight.shipments.forEach((shipment) => {
					if (shipment.shippingLogId) {
						logged = true;
					}
					if (orderInfo === '' && shipment.projectId) {
						const projectInfo = projects.find((project) => project.id === shipment.projectId);
						if (projectInfo) {
							if (projectInfo.orderNo > '') {
								orderInfo += projectInfo.orderNo + ' - ';
							}
							orderInfo += projectInfo.name;
						}
					}
					shipment.valid = true;
					shipments.push(shipment);
				});
				if (freight.shipments[0].deliveryDate) {
					deliveryDate = startOfDay(new Date(freight.shipments[0].deliveryDate)).toLocaleDateString();
					if (freight.shipments[0].deliveryTime) {
						deliveryTime = freight.shipments[0].deliveryTime;
					}
					if (freight.shipments[0].deliveryTimeZone) {
						deliveryTimeZone = freight.shipments[0].deliveryTimeZone;
					}
				}
				let requestCount = 0;
				let bidCount = 0;
				let bids: FreightBid[] = [];
				let bookings: FreightBid[] = [];
				let booked = false;
				let bookAccepted = false;
				if (freight.freightBrokers) {
					requestCount = freight.freightBrokers.length;
					freight.freightBrokers.forEach((broker) => {
						if (broker.freightBids && broker.freightBids.length) {
							bidCount += 1;
							broker.freightBids.forEach((bid) => (bid.brokerId = broker.brokerId));
							bids.push(
								...broker.freightBids.filter(
									(bid) =>
										bid.bidStatus === 'submitted' ||
										bid.bidStatus === 'booking-requested' ||
										bid.bidStatus === 'booked' ||
										bid.freightBooking
								)
							);
						}
					});
					booked = bids.filter((bid) => bid.bidStatus === 'booking-requested' || bid.bidStatus === 'booked').length > 0;
					bookAccepted = bids.filter((bid) => bid.freightBooking).length > 0;
					bookings = bids.filter((bid) => bid.freightBooking || bid.bidStatus === 'booking-requested');
				}
				if (!bookAccepted && new Date() > new Date(deliveryDate)) {
					expired = true;
				}
				this.freightList.push({
					freightId: freight.id,
					deliveryDate: deliveryDate,
					deliveryTime: deliveryTime,
					deliveryTimeZone: deliveryTimeZone,
					deliveryName: freight.shipments[0].destinationName,
					deliveryAddress1: freight.shipments[0].destinationAddress1,
					deliveryAddress2: freight.shipments[0].destinationAddress2,
					deliveryCity: freight.shipments[0].destinationCity,
					deliveryState: freight.shipments[0].destinationState,
					deliveryPostal: freight.shipments[0].destinationPostal,
					deliveryCountry: freight.shipments[0].destinationCountry,
					ready: true,
					shipments: shipments,
					freightBrokers: freight.freightBrokers,
					rateRequestCount: requestCount,
					bidCount: bidCount,
					bids: bids,
					booked: booked,
					bookAccepted: bookAccepted,
					bookings: bookings,
					logged: logged,
					expired: expired,
					newDate: new Date(deliveryDate),
					orderInfo: orderInfo
				});
			}
		});
	}

	buildFreightList(shipments: Shipment[], projects: Project[]) {
		shipments.forEach((shipment) => {
			let deliveryDate: string = 'Not Scheduled';
			let deliveryTime: string = '';
			let deliveryTimeZone: string = '';
			let freight: FreightListEntry | undefined;
			if (shipment.deliveryDate) {
				deliveryDate = startOfDay(new Date(shipment.deliveryDate)).toLocaleDateString();
				if (shipment.deliveryTime) {
					deliveryTime = shipment.deliveryTime;
				}
				if (shipment.deliveryTimeZone) {
					deliveryTimeZone = shipment.deliveryTimeZone;
				}
			}
			freight = this.freightList.find(
				(freight) =>
					freight.freightId === 0 &&
					freight.deliveryDate === deliveryDate &&
					freight.deliveryName === shipment.destinationName &&
					freight.deliveryAddress1 === shipment.destinationAddress1 &&
					freight.deliveryAddress2 === shipment.destinationAddress2 &&
					freight.deliveryCity === shipment.destinationCity &&
					freight.deliveryState === shipment.destinationState &&
					freight.deliveryPostal === shipment.destinationPostal &&
					freight.deliveryCountry === shipment.destinationCountry
			);
			shipment.valid = this.shipmentService.validateShipment(shipment);
			if (freight) {
				freight.shipments?.push(shipment);
				freight.ready = this.freightReady(freight);
			} else {
				let orderInfo = '';
				let locationName = '';
				if (orderInfo === '' && shipment.projectId) {
					const projectInfo = projects.find((project) => project.id === shipment.projectId);
					if (projectInfo) {
						if (projectInfo.orderNo > '') {
							orderInfo += projectInfo.orderNo + ' - ';
						}
						orderInfo += projectInfo.name;
						if (projectInfo.location) {
							locationName = projectInfo.location;
						}
					}
				}
				const ready =
					deliveryDate !== 'Not Scheduled' &&
					shipment.destinationName &&
					shipment.destinationAddress1 &&
					shipment.destinationCity &&
					shipment.destinationState &&
					shipment.destinationPostal &&
					shipment.destinationCountry &&
					shipment.valid;
				this.freightList.push({
					freightId: 0,
					deliveryDate: deliveryDate,
					deliveryTime: deliveryTime,
					deliveryTimeZone: deliveryTimeZone,
					deliveryName: shipment.destinationName,
					deliveryAddress1: shipment.destinationAddress1,
					deliveryAddress2: shipment.destinationAddress2,
					deliveryCity: shipment.destinationCity,
					deliveryState: shipment.destinationState,
					deliveryPostal: shipment.destinationPostal,
					deliveryCountry: shipment.destinationCountry,
					ready: !!ready,
					shipments: [shipment],
					newDate: shipment.deliveryDate,
					orderInfo: orderInfo,
					locationName: locationName
				});
			}
		});
	}

	selectFreight(index: number) {
		this.selectedFreight = this.filteredFreightList[index];
		if (this.selectedFreight) {
			this.selectedHandlingUnits = {};
			this.selectedTotalLinearDimensions = 0;
			this.selectedDUnit = '';
			this.selectedTotalWeight = 0;
			this.selectedWUnit = '';
			this.selectedTotalSkids = 0;
			this.selectedShipmentAccessorials = [];
			this.selectedFreight.shipments.forEach((shipment) => {
				if (shipment.totalSkids) {
					this.selectedTotalSkids += shipment.totalSkids;
				}
				if (shipment.shipmentHandlingUnits) {
					shipment.shipmentHandlingUnits.forEach((handlingUnit) => {
						if (handlingUnit.huType && handlingUnit.qty) {
							if (!this.selectedHandlingUnits[handlingUnit.huType]) {
								this.selectedHandlingUnits[handlingUnit.huType] = handlingUnit.qty;
							} else {
								this.selectedHandlingUnits[handlingUnit.huType] += handlingUnit.qty;
							}
						}
					});
				}
				if (this.selectedDUnit === '' && shipment.dUnit) {
					this.selectedDUnit = shipment.dUnit;
				}
				if (this.selectedWUnit === '' && shipment.wUnit) {
					this.selectedWUnit = shipment.wUnit;
				}
				if (shipment.totalLinearDimension && shipment.dUnit) {
					this.selectedTotalLinearDimensions += this.freightDetailCalculationService.convertDimension(
						shipment.totalLinearDimension,
						shipment.dUnit,
						this.selectedDUnit
					);
				}
				if (shipment.totalWeight && shipment.wUnit) {
					this.selectedTotalWeight += this.freightDetailCalculationService.convertWeight(
						shipment.totalWeight,
						shipment.wUnit,
						this.selectedWUnit
					);
				}
				shipment.shipmentAccessorials?.forEach((accessorial) => {
					if (accessorial.name && !this.selectedShipmentAccessorials.find((name) => name === accessorial.name)) {
						this.selectedShipmentAccessorials.push(accessorial.name);
					}
				});
			});
			const shipmentIds = this.getValidShipmentIds();
			this.validShipmentIds = shipmentIds;
		}
	}

	getValidShipmentIds() {
		const validShipments = this.selectedFreight?.shipments.filter((shipment) => shipment.valid).map((shipment) => shipment.id);
		const shipmentIds: number[] = [];
		validShipments?.forEach((id) => {
			if (id) {
				shipmentIds.push(id);
			}
		});
		return shipmentIds;
	}

	get deliveryTime(): string {
		let deliveryTime = '';
		const time = this.timeList.find((time) => time.value === this.selectedFreight?.deliveryTime);
		if (time && time.description) {
			deliveryTime = time.description;
		}
		const timeZone = this.timeZoneList.find((timeZone) => timeZone.value === this.selectedFreight?.deliveryTimeZone);
		if (timeZone && timeZone.description) {
			deliveryTime += ' ' + timeZone.description;
		}
		return deliveryTime;
	}

	freightReady(freight: FreightListEntry) {
		const validShipmentCount = this.validShipments(freight);
		return !!(
			freight.deliveryDate !== 'Not Scheduled' &&
			freight.deliveryName &&
			freight.deliveryAddress1 &&
			freight.deliveryCity &&
			freight.deliveryState &&
			freight.deliveryPostal &&
			freight.deliveryCountry &&
			validShipmentCount > 0
		);
	}

	validShipments(freight: FreightListEntry): number {
		const validShipments = freight.shipments?.filter((shipment) => shipment.valid);
		let validShipmentCount = 0;
		if (validShipments) {
			validShipmentCount = validShipments.length;
		}
		return validShipmentCount;
	}

	handlingUnitSummary(handlingUnits: ShipmentHandlingUnit[] | undefined): {[key: string]: number} {
		const handlingUnitSummary: {[key: string]: number} = {};
		if (handlingUnits) {
			handlingUnits.forEach((handlingUnit) => {
				if (handlingUnit.huType && handlingUnit.qty) {
					if (!handlingUnitSummary[handlingUnit.huType]) {
						handlingUnitSummary[handlingUnit.huType] = handlingUnit.qty;
					} else {
						handlingUnitSummary[handlingUnit.huType] += handlingUnit.qty;
					}
				}
			});
		}
		return handlingUnitSummary;
	}

	deleteShipment(freightIndex: number, shipmentIndex: number) {
		const confirm = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				title: 'Confirm Delete Shipment',
				message: 'Shipment will be permanently deleted. Are you sure?'
			}
		});
		confirm.afterClosed().subscribe((result) => {
			if (result) {
				const id = this.filteredFreightList[freightIndex].shipments[shipmentIndex].id;
				if (id) {
					this.busy = true;
					this.shipmentService.remove(id).subscribe({
						next: () => {
							this._snackBar.open('Shipment Deleted.');
							this.filteredFreightList[freightIndex].shipments.splice(shipmentIndex, 1);
							if (!this.filteredFreightList[freightIndex].shipments.length) {
								this.filteredFreightList.splice(freightIndex, 1);
							}
							this.busy = false;
						},
						error: (err) => {
							this._snackBar.open('Delete Shipment Failed: ' + err.error.message);
							this.busy = false;
						}
					});
				}
			}
		});
	}

	bookFreight(freightIndex: number, bidIndex: number) {
		let bid: FreightBid = {};
		const freight = this.filteredFreightList[freightIndex];
		if (freight.bids && freight.bids[bidIndex].id) {
			bid = freight.bids[bidIndex];
		}
		if (bid.id) {
			this.busy = true;
			this.freightService.bookFreight(bid, this.projectId).subscribe({
				next: () => {
					this._snackBar.open('Booking Sent');
					this.busy = false;
					this.getFreight();
				},
				error: (err) => {
					this._snackBar.open('Booking Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}

	expandChange(expanded: boolean, freightIndex: number) {
		const expandedPanelCount = this.freightPanels.filter((panel) => panel.expanded).length;
		if (expanded || !expandedPanelCount) {
			this.selectedFreight = null;
			this.changeDetector.detectChanges();
			if (expanded && this.filteredFreightList[freightIndex].ready) {
				this.selectFreight(freightIndex);
			}
		}
	}

	get filteredFreightList(): FreightListEntry[] {
		const filter = this.filter.toLowerCase();
		if (filter !== this.lastFilter || this.filterType !== this.lastFilterType) {
			this.lastFilter = filter;
			this.lastFilterType = this.filterType;
			this.selectedFreight = null;
			this.changeDetector.detectChanges();
		}
		return this.freightList.filter(
			(freight) =>
				(this.filterType === '' ||
					(this.filterType === 'BOOKED' && freight.booked && freight.bookAccepted) ||
					(this.filterType === 'AWAIT_BOOK' && freight.booked && !freight.bookAccepted) ||
					(this.filterType === 'RATES_REQUESTED' && freight.freightId && !freight.booked) ||
					(this.filterType === 'READY_TO_RATE' && freight.freightId === 0 && freight.ready) ||
					(this.filterType === 'INVALID' && !freight.ready)) &&
				(this.filter === '' ||
					freight.deliveryDate?.toLowerCase().includes(filter) ||
					freight.deliveryName?.toLowerCase().includes(filter) ||
					freight.deliveryAddress1?.toLowerCase().includes(filter) ||
					freight.deliveryAddress2?.toLowerCase().includes(filter) ||
					freight.deliveryCity?.toLowerCase().includes(filter) ||
					freight.deliveryState?.toLowerCase().includes(filter) ||
					freight.deliveryPostal?.toLowerCase().includes(filter) ||
					freight.deliveryCountry?.toLowerCase().includes(filter) ||
					freight.orderInfo?.toLowerCase().includes(filter) ||
					freight.shipments.filter(
						(shipment) =>
							shipment.description?.toLowerCase().includes(filter) ||
							shipment.shipmentOrderNo?.toLowerCase().includes(filter) ||
							shipment.originName?.toLowerCase().includes(filter) ||
							shipment.originAddress1?.toLowerCase().includes(filter) ||
							shipment.originAddress2?.toLowerCase().includes(filter) ||
							shipment.originCity?.toLowerCase().includes(filter) ||
							shipment.originState?.toLowerCase().includes(filter) ||
							shipment.originPostal?.toLowerCase().includes(filter) ||
							shipment.originCountry?.toLowerCase().includes(filter)
					).length > 0 ||
					(freight.bids &&
						freight.bids?.filter(
							(bid) =>
								bid.freightBidNo?.toLowerCase().includes(filter) ||
								bid.brokerName?.toLowerCase().includes(filter) ||
								bid.brokerScac?.toLowerCase().includes(filter) ||
								bid.carrierName?.toLowerCase().includes(filter) ||
								bid.carrierScac?.toLowerCase().includes(filter)
						).length > 0)) &&
				(this.locationFilter === '' || freight.locationName === this.locationFilter)
		);
	}

	navigateToNewShipment() {
		if (this.projectId) {
			this.router.navigate(['/project', this.projectId, 'freight', 'shipment']).then();
		} else {
			this.router.navigate(['/freight/shipment']).then();
		}
	}

	navigateToShipment(shipmentId: number | undefined) {
		if (shipmentId) {
			if (this.projectId) {
				this.router.navigate(['/project', this.projectId, 'freight', 'shipment', shipmentId]).then();
			} else {
				this.router.navigate(['/freight/shipment', shipmentId]).then();
			}
		}
	}

	downloadFile(event: Event, fileName: string | undefined, bidId: number | undefined) {
		event.stopPropagation();
		if (bidId && fileName) {
			this.freightService.getBol(bidId).subscribe((response) => {
				//window.open() was opening then closing the tab, this way just simply downloads the file.
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = response;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(a.href);
				document.body.removeChild(a);
			});
		}
	}

	brokerInfo(brokerId: number | undefined) {
		const broker = this.brokers.find((broker) => broker.id === brokerId);
		let brokerInfo = '';
		if (broker) {
			brokerInfo = broker.name;
			if (broker.phone) {
				brokerInfo += ' - ' + new PhonePipe().transform(broker.phone);
			}
			if (broker.phoneExt) {
				brokerInfo += ' x' + broker.phoneExt;
			}
		}
		return brokerInfo;
	}

	logFreight() {
		const confirm = this.dialog.open(CreateShippingLogDialogComponent, {
			width: '800px',
			maxWidth: '90vw',
			data: {
				freight: this.selectedFreight
			}
		});
		confirm.afterClosed().subscribe((result) => {
			if (result) {
				this.getFreight();
			}
		});
	}

	removeFreight(freightIndex: number) {
		const confirm = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				title: 'Confirm Remove Shipments',
				message: 'Shipments will be permanently removed. Are you sure?'
			}
		});
		confirm.afterClosed().subscribe((result) => {
			if (result) {
				const ids = this.filteredFreightList[freightIndex].shipments.map((shipment) => shipment.id) as number[];
				if (ids.length) {
					this.busy = true;
					this.shipmentService.deactivate(ids).subscribe({
						next: () => {
							this._snackBar.open('Shipments Removed.');
							this.getFreight();
						},
						error: (err) => {
							this._snackBar.open('Remove Shipments Failed: ' + err.error.message);
							this.busy = false;
						}
					});
				}
			}
		});
	}

	updateDate(freight: FreightListEntry) {
		const shipmentIds = freight.shipments.map((shipment) => shipment.id);
		if (freight.newDate && shipmentIds.length) {
			this.busy = true;
			this.shipmentService.updateDate(freight.newDate?.toISOString().substring(0, 10), shipmentIds).subscribe({
				next: () => {
					this._snackBar.open('Shipment Date Updated.');
					this.getFreight();
				},
				error: (err) => {
					this._snackBar.open('Shipment Date Update Failed: ' + err.error.message);
					this.busy = false;
				}
			});
		}
	}
}
