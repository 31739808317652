import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {InstallCalendar} from '../models/install-calendar.interface';
import {addDays, isWeekend} from 'date-fns';

@Injectable({
	providedIn: 'root'
})
export class InstallCalendarService {
	baseUrl: string = `${environment.url}/install-calendar`;
	constructor(private http: HttpClient) {}

	getInstallCalendar(selectedDate: Date | null = null): Observable<InstallCalendar> {
		let params = new HttpParams();
		if (selectedDate) {
			params = params.append('selectedDate', selectedDate.toISOString().substring(0, 10));
		}
		return this.http.get<InstallCalendar>(`${this.baseUrl}`, {params: params});
	}

	calcEndDate(startDate: Date | null, duration: number | null, weekends: boolean | null): Date | null {
		let endDate: Date;

		if (!weekends) {
			weekends = false;
		}
		if (!duration) {
			duration = 0;
		}
		duration = Math.floor(duration);
		if (startDate) {
			endDate = new Date(startDate);
			if (!weekends) {
				while (isWeekend(endDate)) {
					endDate = addDays(endDate, 1);
				}
			}
			while (duration > 1) {
				endDate = addDays(endDate, 1);
				if (!weekends) {
					while (isWeekend(endDate)) {
						endDate = addDays(endDate, 1);
					}
				}
				duration--;
			}
			return endDate;
		}
		return null;
	}
}
