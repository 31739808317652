<div class="p-2 flex justify-center">
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="priceLevel-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name or level number."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openCreatePriceLevelModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 h-[80vh] border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingPriceLevels">
		<!--Name Column-->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
			<td mat-cell *matCellDef="let priceLevel">
				{{ priceLevel.name }}
			</td>
		</ng-container>
		<!--Email Column-->
		<ng-container matColumnDef="levelNumber">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by level number">Level Number</th>
			<td mat-cell *matCellDef="let priceLevel">
				{{ priceLevel.levelNumber }}
			</td>
		</ng-container>
		<!--Discount Column-->
		<!--    <ng-container matColumnDef="discount">-->
		<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by discount">Discount</th>-->
		<!--      <td mat-cell *matCellDef="let priceLevel">-->
		<!--        {{ getCurrentDiscount(priceLevel) }}-->
		<!--      </td>-->
		<!--    </ng-container>-->
		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let priceLevel" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="priceLevel.expanded ? 'expanded' : 'collapsed'">
					<app-price-level-list-detail [priceLevel]="priceLevel" class="w-full"></app-price-level-list-detail>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let priceLevel; let i = dataIndex; columns: columnsToDisplay"
			class="priceLevel-row cursor-pointer"
			(click)="priceLevel.expanded = !priceLevel.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingPriceLevels">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingPriceLevels">
		<mat-spinner></mat-spinner>
	</div>
</div>
