<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl">{{ code.glNumber }}&nbsp;-&nbsp;{{ code.description | titlecase }} Details</span>
		<button mat-icon-button (click)="form.enable()" [disabled]="form.enabled">
			<mat-icon>edit</mat-icon>
		</button>
		<button mat-icon-button (click)="openConfirmDeleteModal(code)" [disabled]="form.enabled">
			<mat-icon>delete</mat-icon>
		</button>
	</div>
	<div class="pt-4">
		<div *ngIf="form.enabled" class="w-full flex justify-end">
			<mat-checkbox [checked]="multiLine" (change)="updateMultiLine($event)" color="primary">Multi Line </mat-checkbox>
		</div>
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>GL #</mat-label>
				<input matInput formControlName="glNumber" />
			</mat-form-field>
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Description</mat-label>
				<input *ngIf="!multiLine" matInput formControlName="description" />
				<textarea *ngIf="multiLine" matInput formControlName="description"></textarea>
			</mat-form-field>
		</div>
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Type</mat-label>
				<mat-select formControlName="type">
					<mat-option *ngFor="let code of codeTypes" [value]="code.code">
						{{ code.code }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Category</mat-label>
				<mat-select formControlName="category">
					<mat-option *ngFor="let category of categories" [value]="category">
						{{ category }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
