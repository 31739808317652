import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Client} from '../models/client.interface';
import {ClientDefaults} from '../models/client-defaults.interface';
import {Template} from '../models/template.interface';
import {ClientPreview} from '../models/client-preview.interface';

@Injectable({
	providedIn: 'root'
})
export class ClientService {
	baseURL: string = `${environment.url}/clients`;
	constructor(private http: HttpClient) {}

	findAll(): Observable<Client[]> {
		return this.http.get<Client[]>(this.baseURL);
	}

	findOneFromErp(id: string): Observable<ClientPreview> {
		return this.http.get<Client>(`${this.baseURL}/erp/${id}`);
	}

	findAllFromErp(): Observable<ClientPreview[]> {
		return this.http.get<Client[]>(`${this.baseURL}/erp`);
	}

	getDefaults(id: number): Observable<ClientDefaults> {
		return this.http.get<ClientDefaults>(`${this.baseURL}/${id}/defaults`);
	}

	findAllTemplatesForClient(clientId: string, type?: string) {
		let params;
		if (type) {
			params = new HttpParams().set('type', type);
		}
		return this.http.get<Template[]>(`${environment.url}/clients/${clientId}/templates`, {params: params});
	}
}
