<div class="flex flex-col flex-auto overflow-hidden max-h-full">
	<div class="p-2 flex justify-center">
		<mat-form-field class="search-field" subscriptSizing="dynamic">
			<mat-label>Search</mat-label>
			<input matInput [(ngModel)]="filterValue" (keyup)="applyFilter()" placeholder="Broker/Carrier, Email, Status" #input />
		</mat-form-field>
		<button mat-mini-fab color="primary" [disabled]="isLoading" (click)="openBrokerDialog(true)" class="absolute left-0 top-0 mt-2 ml-2">
			<mat-icon>add</mat-icon>
		</button>
	</div>
	<form [formGroup]="brokerForm" (ngSubmit)="requestRates()" autocomplete="off" class="flex-shrink overflow-x-auto">
		<ng-container formArrayName="formRows">
			<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 broker-table">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox
							(change)="$event ? toggleAllRows() : null"
							[checked]="!isNoneSelected() && isAllSelected()"
							[indeterminate]="!isNoneSelected() && !isAllSelected()"
						>
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let element" [formGroup]="element">
						<mat-checkbox [formControlName]="'selected'" (click)="$event.stopPropagation()" [checked]="element.value.selected">
						</mat-checkbox>
					</td>
				</ng-container>

				<!-- Name Column -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>Broker/Carrier</th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						{{ element.controls['name'].controls['value'].value }}
					</td></ng-container
				>

				<!-- Email Column -->
				<ng-container matColumnDef="info">
					<th mat-header-cell *matHeaderCellDef>Info</th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						<div class="flex flex-col my-2" *ngIf="element.controls['email'].controls['value'].value || element.get('phone').value">
							<span>{{ element.controls['email'].controls['value'].value }}</span>
							<div class="flex">
								<span *ngIf="element.get('phone')?.value.slice(0, 3)">{{ element.get('phone')?.value | phone }}</span>
								<span *ngIf="element.get('phoneExt')?.value" class="ml-2">x{{ element.get('phoneExt')?.value }}</span>
							</div>
						</div>
					</td>
				</ng-container>

				<!-- Status Column -->
				<ng-container matColumnDef="status">
					<th mat-header-cell *matHeaderCellDef>Status</th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						<div [ngClass]="getStatusClass(element)">{{ element.controls.brokerStatus.value }}</div>
					</td>
				</ng-container>

				<!-- Edit Column -->
				<ng-container matColumnDef="edit">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
						<button (click)="openBrokerDialog(true, element.value)" type="button" class="ml-2">
							<mat-icon>edit</mat-icon>
						</button>
					</td>
				</ng-container>

				<!--Expanded View-->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
						<div [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
							<app-associated-contacts [expanded]="element.expanded" [broker]="element.value"></app-associated-contacts>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr
					mat-row
					*matRowDef="let element; columns: displayedColumns"
					class="broker-row hover:bg-gray-100 cursor-pointer"
					(click)="element.expanded = !element.expanded"
				></tr>
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row" *matNoDataRow>
					<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</ng-container>
	</form>
	<div class="flex flex-col flex-fit flex-shrink-0 flex-grow justify-end">
		<button
			class="p-4 mat-elevation-z8 get-rates"
			[disabled]="isLoading || shippingDetailsInvalid || selectedBrokers.length < 1"
			mat-raised-button
			color="primary"
			(click)="requestRates()"
		>
			GET RATES
		</button>
	</div>
	<mat-card *ngIf="isLoading" class="flex justify-center items-center">
		<mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
	</mat-card>
</div>
