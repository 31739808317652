import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
@Component({
	selector: 'app-project-drafting-delete',
	templateUrl: './project-drafting-delete.component.html',
	styleUrls: ['./project-drafting-delete.component.scss']
})
export class ProjectDraftingDeleteComponent {
	constructor(private dialog: MatDialogRef<ProjectDraftingDeleteComponent>) {}
	closeModal() {
		this.dialog.close('cancel');
	}
	deleteFile() {
		this.dialog.close('delete');
	}
}
