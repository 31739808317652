<div *ngIf="isLoading" class="w-full h-full flex justify-center items-center">
	<mat-spinner></mat-spinner>
</div>
<div *ngIf="!isLoading" class="pb-4">
	<section class="w-full flex justify-center mb-2 h-6">
		<button (click)="clearActiveFilter()" mat-fab class="text-xl absolute left-2 font-bold z-10 cursor-default" id="bulb">
			{{ projectPreviews.length }}
		</button>
		<mat-button-toggle-group aria-label="Statuses" class="right-2 w-11/12 mt-4 h-full z-0">
			<mat-button-toggle
				*ngFor="let status of nonZeroStatuses | sort : 'asc' : 'status'; index as i"
				[className]="' bg-' + colors[i] + ' h-full'"
				[ngClass]="activeFilter && activeFilter.description !== status.description ? 'grayed-out' : 'bg-' + colors[i]"
				[ngStyle]="{width: getWidth(status, i), minWidth: getWidth(status, i) + 1}"
				(click)="setActiveFilter(status); filterTable()"
			>
			</mat-button-toggle>
		</mat-button-toggle-group>
	</section>

	<section class="w-full h-10 flex items-center justify-center">
		<div class="w-5/6 mt-6 flex justify-around">
			<div
				*ngFor="let status of nonZeroStatuses | sort : 'asc' : 'status'; index as i"
				class="flex justify-center cursor-pointer min-w-fit"
				[ngClass]="activeFilter && activeFilter.description !== status.description ? 'gray-text' : ''"
				(click)="setActiveFilter(status); filterTable()"
			>
				<span
					[id]="status.description.toLowerCase() + '-number-indicator'"
					[className]="'h-full text-lg md:text-4xl font-semibold md:font-bold mr-1 text-' + colors[i]"
					[ngClass]="activeFilter && activeFilter.description !== status.description ? 'gray-text' : ''"
					*ngIf="status.projectCount !== 0"
				>
					{{ status.projectCount }}
				</span>
				<div *ngIf="status.projectCount !== 0" class="grid mt-1">
					<span
						[className]="'text-sm md:text-md font-semibold md:font-bold text-' + colors[i]"
						[ngClass]="activeFilter && activeFilter.description !== status.description ? 'gray-text' : ''"
						>{{ status.description | uppercase }}</span
					>
					<span
						[className]="'text-xs relative bottom-1 text-' + colors[i]"
						[ngClass]="activeFilter && activeFilter.description !== status.description ? 'gray-text' : ''"
						>project(s)</span
					>
				</div>
			</div>
		</div>
	</section>

	<section class="flex flex-col justify-center items-center mt-4 h-[75vh] overflow-auto">
		<div class="flex w-full justify-between">
			<div class="flex justify-center flex-1">
				<mat-form-field class="mt-1 mr-2" appearance="outline">
					<mat-label>Warehouse</mat-label>
					<mat-select multiple [(ngModel)]="locationFilter" class="mr-2" (selectionChange)="selectLocation()">
						<mat-option [value]="location" *ngFor="let location of locations">
							{{ location }}
						</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-slide-toggle
					class="mt-5 mr-2"
					labelPosition="after"
					[checked]="activeInactiveBoolean"
					color="primary"
					#activeToggle
					(change)="activeInactiveToggle()"
				>
					<b>Active</b>
				</mat-slide-toggle>
				<mat-form-field class="w-1/2 mt-1 mb-4" color="primary" subscriptSizing="dynamic">
					<mat-label>Search</mat-label>
					<input
						id="client-searchbar"
						type="text"
						(keyup)="applyFilter($event)"
						placeholder="Search by client, status, location, project name, etc."
						matInput
						[value]="filterValue"
						#input
					/>
				</mat-form-field>
				<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-3 ml-2" routerLink="/create-project">
					<mat-icon>add</mat-icon>
				</button>
			</div>
			<div class="flex flex-col mr-16">
				<div class="flex">
					<mat-spinner *ngIf="busy" class="spinner m-2" diameter="20"></mat-spinner>
					<button mat-stroked-button class="mr-2" [disabled]="busy" (click)="getProjects()">
						<mat-icon>refresh</mat-icon>
						Refresh
					</button>
				</div>
				<div class="m-2 font-medium text-right">
					{{ dataSource.filteredData.length }} result{{ dataSource.filteredData.length === 1 ? '' : 's' }}
				</div>
			</div>
		</div>
		<div class="w-[94vw] border-gray-700 border-2 rounded h-full overflow-auto">
			<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matMultiSort>
				<!--Name Column-->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-multi-sort-header="name" sortActionDescription="Sort by job">Job</th>
					<td mat-cell *matCellDef="let project">
						{{ project.name }}
					</td>
				</ng-container>

				<!--Order Number-->
				<ng-container matColumnDef="orderNo">
					<th mat-header-cell *matHeaderCellDef mat-multi-sort-header="orderNo" sortActionDescription="Sort by order number">Order</th>
					<td mat-cell *matCellDef="let project">
						{{ project.orderNo }}
					</td>
				</ng-container>

				<!--Client Column-->
				<ng-container matColumnDef="clientId">
					<th mat-header-cell *matHeaderCellDef mat-multi-sort-header="clientId" sortActionDescription="Sort by client">Client</th>
					<td mat-cell *matCellDef="let project">
						{{ project.clientId | titlecase }}
					</td>
				</ng-container>

				<!--Stage Column-->
				<ng-container matColumnDef="stage">
					<th mat-header-cell *matHeaderCellDef mat-multi-sort-header="stage">Stage</th>
					<td mat-cell *matCellDef="let project">
						{{ getStage(project.workflowStatus.subParentId) | uppercase }}
					</td>
				</ng-container>

				<!--Status Column-->
				<ng-container matColumnDef="project.workflowStatus.description">
					<th
						mat-header-cell
						*matHeaderCellDef
						mat-multi-sort-header="project.workflowStatus.description"
						sortActionDescription="Sort by status"
					>
						Status
					</th>
					<td mat-cell *matCellDef="let project">
						{{ project.workflowStatus.description }}
					</td>
				</ng-container>

				<!--Date Column-->
				<ng-container matColumnDef="installDate">
					<th mat-header-cell *matHeaderCellDef mat-multi-sort-header="installDate" sortActionDescription="Sort by date">Install Date</th>
					<td mat-cell *matCellDef="let project">
						{{ project.installDate ? (project.installDate | date : 'MM/dd/y') : getEstimateText(project.estimateDate) }}
					</td>
				</ng-container>

				<!--Location Column-->
				<ng-container matColumnDef="location">
					<th mat-header-cell *matHeaderCellDef mat-multi-sort-header="location" sortActionDescription="Sort by location">Location</th>
					<td mat-cell *matCellDef="let project">
						{{ project.city ? (project.city | titlecase) + ',' : null }}&nbsp;{{
							project.state ? (project.state | uppercase) : null
						}}&nbsp;{{ project.postal ? project.postal : null }}
					</td>
				</ng-container>

				<!--LCD Column-->
				<ng-container matColumnDef="lastDraftingContactDate">
					<th
						[hidden]="activeFilter?.description !== 'Drafting'"
						mat-header-cell
						*matHeaderCellDef
						mat-multi-sort-header="lastDraftingContactDate"
						sortActionDescription="Sort by LCD Date"
					>
						Last Contact
					</th>
					<td [hidden]="activeFilter?.description !== 'Drafting'" mat-cell *matCellDef="let project">
						{{ project.lastDraftingContactDate | date : 'MM/dd/y' }}
					</td>
				</ng-container>

				<!--RFQ Column-->
				<ng-container matColumnDef="lastRfqDate">
					<th
						[hidden]="activeFilter?.description !== 'Quoting'"
						mat-header-cell
						*matHeaderCellDef
						mat-multi-sort-header="lastRfqDate"
						sortActionDescription="Sort by RFQ Date"
					>
						RFQ
					</th>
					<td [hidden]="activeFilter?.description !== 'Quoting'" mat-cell *matCellDef="let project">
						{{ project.lastRfqDate | date : 'MM/dd/y' }}
					</td>
				</ng-container>

				<!--Warehouse Column-->
				<ng-container matColumnDef="warehouse">
					<th mat-header-cell *matHeaderCellDef mat-multi-sort-header="warehouse" sortActionDescription="Sort by warehouse">Warehouse</th>
					<td mat-cell *matCellDef="let project">
						{{ project.location | titlecase }}
					</td>
				</ng-container>

				<!--Team Members Column-->
				<ng-container matColumnDef="members">
					<th mat-header-cell *matHeaderCellDef>Members</th>
					<td mat-cell *matCellDef="let project" class="flex flex-wrap items-center">
						<div
							*ngFor="let member of project.members"
							class="h-8 w-8 text-white text-md items-center justify-center flex rounded-[50%] mt-1 mx-1"
							[style.background-color]="getColor(member.memberName)"
						>
							{{ getInitials(member.memberName) }}
						</div>
					</td>
				</ng-container>

				<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
				<ng-container matColumnDef="expandedDetail">
					<td mat-cell *matCellDef="let project" [attr.colspan]="columnsToDisplay.length">
						<div class="overflow-hidden flex" [@detailExpand]="project.expanded ? 'expanded' : 'collapsed'">
							<app-project-detail class="w-full" [projectId]="project.id" *ngIf="project.expanded" [codes]="codes"></app-project-detail>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
				<tr
					mat-row
					*matRowDef="let project; let i = dataIndex; columns: columnsToDisplay"
					class="project-row"
					(click)="project.expanded = !project.expanded"
				></tr>
				<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
				<!-- Row shown when there is no matching data. -->
				<tr class="mat-row flex" *matNoDataRow>
					<td class="mat-cell p-4" colspan="4">No data matching the filter "{{ input.value }}"</td>
				</tr>
			</table>
		</div>
	</section>
</div>
