<div class="flex w-full" [formGroup]="quoteForm">
	<div class="flex flex-1 mt-4 ml-4">
		<mat-accordion class="top w-full" multi>
			<ng-container formArrayName="rooms">
				<mat-expansion-panel expanded="true" *ngFor="let room of rooms.controls; let i = index" formGroupName="{{ i }}">
					<mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
						<mat-panel-title class="font-medium text-lg">
							<div class="flex flex-1 items-start justify-between">
								<div class="p-4">{{ roomName(i) }} ({{ roomCount(i) }})</div>
								<div class="p-4">$ {{ roomTotal(i) | number : '1.2-2' }}</div>
								<div class="flex flex-end">
									<mat-icon *ngIf="roomError(i)" class="mt-4 text-red">warning</mat-icon>
									<button mat-button (click)="deleteRoom(i); $event.stopPropagation()" class="mt-2 ml-2">
										<mat-icon class="my-2">delete</mat-icon>
									</button>
								</div>
							</div>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<app-quick-quote-config [index]="i" (updateEstimate)="generateSummary()" componentFormArrayName="rooms"> </app-quick-quote-config>
				</mat-expansion-panel>
			</ng-container>
			<div class="w-full flex justify-center my-4">
				<button color="primary" (click)="addRoom()" mat-mini-fab>+</button>
			</div>
		</mat-accordion>
	</div>
	<div class="flex flex-col w-72 m-4 p-4 mat-elevation-z8 rounded-lg z-10 self-start sticky top-0">
		<div class="flex justify-between font-medium">
			<span>Total Rooms:</span>
			<span>{{ totalRooms | number : '1.0-0' }}</span>
		</div>
		<div *ngFor="let room of roomList" class="flex justify-between" [ngClass]="{'text-red': room.error}">
			<span class="ml-2">{{ room.desc }}</span>
			<span>{{ room.count | number : '1.0-0' }}</span>
		</div>
		<div class="flex justify-between font-medium mt-2">
			<span>Parts:</span>
			<span></span>
		</div>
		<div *ngFor="let part of carriagePartList" class="flex justify-between" [ngClass]="{'text-red': part.error}">
			<span class="ml-2">{{ part.desc }}</span>
			<span>{{ part.count | number : '1.0-0' }}</span>
		</div>
		<div *ngFor="let part of trackPartList" class="flex justify-between" [ngClass]="{'text-red': part.error}">
			<span class="ml-2">{{ part.desc }}</span>
			<span>{{ part.count | number : '1.0-0' }}</span>
		</div>
		<div *ngFor="let part of trackHwPartList" class="flex justify-between" [ngClass]="{'text-red': part.error}">
			<span class="ml-2">{{ part.desc }}</span>
			<span>{{ part.count | number : '1.0-0' }}</span>
		</div>
		<div class="mb-2"></div>
		<div *ngFor="let part of tierList" class="flex justify-between" [ngClass]="{'text-red': part.error}">
			<span class="ml-2">{{ part.desc }}</span>
			<span>{{ part.count | number : '1.0-0' }}</span>
		</div>
		<div class="mb-2"></div>
		<div *ngFor="let part of shelfList" class="flex justify-between" [ngClass]="{'text-red': part.error}">
			<span class="ml-2">{{ part.desc }}</span>
			<span>{{ part.count | number : '1.0-0' }}</span>
		</div>
		<div class="flex justify-between font-medium mb-2">
			<span>Shelf Count:</span>
			<span>{{ shelfCount | number : '1.0-0' }}</span>
		</div>
		<div class="mb-2">
			<mat-checkbox (change)="generateSummary()" formControlName="install">Install</mat-checkbox>
		</div>
		<div class="flex justify-between" [ngClass]="{'text-red': carriageErrors}">
			<span>Mobile Carriage:</span>
			<span>${{ mobileTotal | number : '1.2-2' }}</span>
		</div>
		<div class="flex justify-between" [ngClass]="{'text-red': shelfErrors}">
			<span>Bulk Rack Shelving:</span>
			<span>${{ shelvingTotal | number : '1.2-2' }}</span>
		</div>
		<div *ngIf="airFlowTotal" class="flex justify-between">
			<span>Air Flow:</span>
			<span>${{ airFlowTotal | number : '1.2-2' }}</span>
		</div>
		<div *ngIf="trayTotal" class="flex justify-between">
			<span>Trays:</span>
			<span>${{ trayTotal | number : '1.2-2' }}</span>
		</div>
		<div *ngIf="lightingTotal" class="flex justify-between">
			<span>Lighting:</span>
			<span>${{ lightingTotal | number : '1.2-2' }}</span>
		</div>
		<div *ngIf="mezzanineTotal" class="flex justify-between">
			<span>Mezzanine:</span>
			<span>${{ mezzanineTotal | number : '1.2-2' }}</span>
		</div>
		<div *ngIf="installTotal" class="flex justify-between">
			<span>Install:</span>
			<span>${{ installTotal | number : '1.2-2' }}</span>
		</div>
		<div class="flex justify-between font-medium" [ngClass]="{'text-red': hasErrors}">
			<span>Total Estimate:</span>
			<span>${{ totalEstimate | number : '1.2-2' }}</span>
		</div>
		<div *ngIf="hasErrors" class="text-red">* Estimate is INVALID - missing parts/pricing required for the configured dimensions.</div>
	</div>
</div>
