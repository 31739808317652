export enum GlCodeTypeEnum {
	FREIGHT = 'FREIGHT',
	INSTALL = 'INSTALL',
	FIXTURE = 'FIXTURE',
	UPCHARGE = 'UPCHARGE',
	WIRE_GRID = 'WIREGRID',
	RACK_SOLID = 'RACKSOLID',
	PARTICLE_BOARD = 'PARTICLEBOARD',
	MISC_MAT = 'MISCELLANEOUS MATERIALS',
	INSPECTION = 'INSPECTION',
	SURVEY = 'SURVEY',
	RACK_TAB = 'RACKTAB',
	STEEL_PERF = 'STEELPERF',
	TAX = 'TAX',
	MATERIAL_COST_SURCHARGE = 'MATERIALCOSTSURCHARGE',
	DISCOUNT = 'DISCOUNT'
}
