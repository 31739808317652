import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Status} from '../models/status.interface';

@Injectable({
	providedIn: 'root'
})
export class StatusService {
	statusDataSource: BehaviorSubject<Status[]> = new BehaviorSubject<Status[]>([]);
	readonly statuses: Observable<Status[]> = this.statusDataSource.asObservable();
	baseUrl: string = `${environment.url}/core/statuses`;

	constructor(private http: HttpClient) {
		this.findAll();
	}

	create(status: Status): Observable<Status> {
		return this.http.post<Status>(this.baseUrl, status);
	}

	findAll(): void {
		this.http.get<Status[]>(this.baseUrl).subscribe((statuses) => {
			this.statusDataSource.next(statuses);
		});
	}

	findOne(id: number): Observable<Status> {
		return this.http.get<Status>(`${this.baseUrl}/${id}`);
	}

	update(status: Status): Observable<Status> {
		return this.http.put<Status>(`${this.baseUrl}/${status.id}`, status);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}
}
