<h2 mat-dialog-title>Log Shipment</h2>
<mat-dialog-content class="mat-typography">
	<div class="flex flex-col" [formGroup]="shippingLogForm" *ngIf="!loading">
		<div class="flex">
			<div class="flex flex-col font-medium w-3/5 mr-8">
				<div class="m-2">{{ this.shippingLogForm.controls.orderNo.value }} - {{ this.shippingLogForm.controls.jobName.value }}</div>
				<div class="m-2">
					{{ this.shippingLogForm.controls.city.value }} , {{ this.shippingLogForm.controls.state.value }}
					{{ this.shippingLogForm.controls.postal.value }}
				</div>
				<div class="m-2">
					<span class="font-medium">Ship Date:</span> {{ this.shippingLogForm.controls.shipDate.value | date : 'fullDate' }}
				</div>
				<div class="m-2">
					<span class="font-medium">Scheduled Delivery Date:</span>
					{{ this.shippingLogForm.controls.originalDeliveryDate.value | date : 'fullDate' }}
				</div>
				<div class="m-2">
					<span class="font-medium">Scheduled Delivery Time:</span> {{ this.shippingLogForm.controls.originalDeliveryTime.value }}
				</div>
			</div>
			<div class="flex flex-col w-2/5">
				<div class="m-2" *ngIf="this.shippingLogForm.controls.brokerName.value">
					<span class="font-medium">Broker:</span> {{ this.shippingLogForm.controls.brokerName.value }}
				</div>
				<div class="m-2" *ngIf="this.shippingLogForm.controls.carrierName.value">
					<span class="font-medium">Carrier:</span> {{ this.shippingLogForm.controls.carrierName.value }}
				</div>
				<div class="m-2" *ngIf="this.shippingLogForm.controls.proNo.value">
					<span class="font-medium">Pro No:</span> {{ this.shippingLogForm.controls.proNo.value }}
				</div>
			</div>
		</div>
		<div class="flex">
			<div class="flex flex-col font-medium w-3/5 mr-8">
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label>Account Manager</mat-label>
						<input matInput formControlName="accountManager" />
						<mat-error>Account Manager <strong>required</strong>.</mat-error>
					</mat-form-field>
				</div>
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label class="font-medium">Actual Delivery Date</mat-label>
						<input matInput [matDatepicker]="datepicker" formControlName="deliveryDate" />
						<mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
						<mat-datepicker #datepicker></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label>Actual Delivery Time</mat-label>
						<mat-select formControlName="deliveryTime">
							<mat-option [value]="time.value" *ngFor="let time of timeList">{{ time.description }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label class="font-medium">Invoice Received Date</mat-label>
						<input matInput [matDatepicker]="datepickerInvoice" formControlName="invoiceDate" />
						<mat-datepicker-toggle matIconSuffix [for]="datepickerInvoice"></mat-datepicker-toggle>
						<mat-datepicker #datepickerInvoice></mat-datepicker>
					</mat-form-field>
					<button
						class="mr-2"
						mat-icon-button
						(click)="shippingLogForm.controls.invoiceDate.setValue(null)"
						*ngIf="shippingLogForm.controls.invoiceDate.value"
					>
						<mat-icon>cancel</mat-icon>
					</button>
				</div>
			</div>
			<div class="flex flex-col w-2/5">
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label>Budget</mat-label>
						<input matInput formControlName="budget" />
						<mat-error>Budget <strong>required</strong>.</mat-error>
					</mat-form-field>
				</div>
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label>Cost</mat-label>
						<input matInput formControlName="cost" />
						<mat-error>Cost <strong>required</strong>.</mat-error>
					</mat-form-field>
				</div>
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label>Additional Cost</mat-label>
						<input matInput formControlName="additionalCost" />
					</mat-form-field>
				</div>
				<div class="m-2">
					<mat-form-field appearance="outline">
						<mat-label>Additional Cost Reason</mat-label>
						<mat-select formControlName="additionalCostReason">
							<mat-option [value]="code.code" *ngFor="let code of codes">{{ code.codeDescription }}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div class="m-8" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!loading">
	<button mat-raised-button mat-dialog-close *ngIf="!busy">Cancel</button>
	<button mat-raised-button color="primary" (click)="saveShippingLog()" *ngIf="!busy" [disabled]="shippingLogForm.invalid">Save</button>
	<div class="m-8" *ngIf="busy">
		<mat-spinner diameter="20"></mat-spinner>
	</div>
</mat-dialog-actions>
