import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ProjectDocument} from '../models/project-document.interface';
import {Observable, Subject} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ProjectDocumentService {
	baseUrl: string = `${environment.url}/project`;
	surveyFileSubject = new Subject<File>();
	surveyDtoSubject = new Subject<ProjectDocument>();
	surveyFileRemoveSubject = new Subject<File>();
	surveyDtoRemoveSubject = new Subject<ProjectDocument>();
	surveyFileUploadedSubject = new Subject<boolean>();
	constructor(private http: HttpClient) {}

	create(formData: FormData, id: number): Observable<any> {
		return this.http.post<any>(`${this.baseUrl}/${id}/documents`, formData);
	}

	findOne(id: number, documentName: string): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/${id}/documents/${documentName}`);
	}

	delete(id: number): Promise<void> {
		return new Promise((resolve) => {
			this.http.delete<any>(`${this.baseUrl}/documents/${id}`).subscribe(() => {
				resolve();
			});
		});
	}

	archive(id: number, ids: (number | undefined)[]): Observable<ProjectDocument[]> {
		return this.http.post<ProjectDocument[]>(`${this.baseUrl}/${id}/documents/archive`, ids);
	}
}
