import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UrlService} from '../../core/services/url.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {QuoteService} from '../../core/services/quote.service';
import {MagicLinkLog} from '../../core/models/magic-link-log.interface';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {format} from 'date-fns';

@Component({
	selector: 'app-quote-email-list',
	templateUrl: './quote-email-list.component.html',
	styleUrls: ['./quote-email-list.component.scss']
})
export class QuoteEmailListComponent implements OnInit, OnDestroy {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<MagicLinkLog> = new MatTableDataSource<MagicLinkLog>();
	columnsToDisplay: string[] = [
		'issuedName',
		'emailTo',
		'formattedInitialViewedDateTime',
		'formattedLastViewedDateTime',
		'lastAction',
		'tokenStatus'
	];

	previousUrlSubscription: Subscription;
	projectId: number;
	quoteId: number;
	params: Subscription;
	emailLogs: MagicLinkLog[];
	isLoadingEmails: boolean = true;

	constructor(
		private urlService: UrlService,
		private router: Router,
		private route: ActivatedRoute,
		private snackbar: MatSnackBar,
		private quoteService: QuoteService
	) {}

	ngOnInit(): void {
		this.params = this.route.params.subscribe({
			next: (params: Params): void => {
				this.quoteId = params['quoteId'];
				this.projectId = params['projectId'];
				this.getEmails();
			},
			error: (err: Error): void => {
				console.error(err);
				this.snackbar.open('Please reload page', SnackbarActionEnum.ERROR);
			}
		});
	}

  ngOnDestroy(): void {
    this.previousUrlSubscription?.unsubscribe();
  }

	backToQuoteList(): void {
		this.previousUrlSubscription = this.urlService.previousUrl$.subscribe((previousUrl: string): void => {
			if (previousUrl === '/quote') {
				this.router.navigate(['/quote']).then();
			} else {
				this.router.navigate([`/project/${this.projectId}/quote`]).then();
			}
		});
	}

	getEmails(): void {
		this.quoteService.getEmailHistory(this.quoteId).subscribe({
			next: (emails: MagicLinkLog[]): void => {
				this.emailLogs = emails;
				this.formatEmailLogDates();
				this.dataSource = new MatTableDataSource<MagicLinkLog>(this.emailLogs);
				this.isLoadingEmails = false;
			},
			error: (err: Error): void => {
				console.error(err);
				this.snackbar.open('Failed to retrieve emails associated with this quote', SnackbarActionEnum.ERROR);
				this.isLoadingEmails = false;
			}
		});
	}

	formatEmailLogDates(): void {
		this.emailLogs.forEach((log: MagicLinkLog): void => {
			if (log.initialViewedDatetime) {
				log.formattedInitialViewedDateTime = this.formatDateTime(log.initialViewedDatetime);
			}
			if (log.lastViewedDatetime) {
				log.formattedLastViewedDateTime = this.formatDateTime(log.lastViewedDatetime);
			}
		});
	}

	formatDateTime(value: Date): string {
		const date: Date = new Date(value);
		const utcDate: Date = new Date(
			Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
		);
		return `${format(utcDate, 'M/dd/yyyy')} at ${format(utcDate, 'h:mm:ss a')}`;
	}
}
