import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {TransitTime} from '../models/transit-time.interface';

@Injectable({
	providedIn: 'root'
})
export class TransitTimeService {
	baseUrl: string = `${environment.url}/transit-time`;
	transitTimeSubject: Subject<TransitTime[]> = new Subject<TransitTime[]>();
	constructor(private http: HttpClient) {}

	create(transitTime: TransitTime): Observable<TransitTime> {
		return this.http.post<TransitTime>(this.baseUrl, transitTime);
	}

	findAll(): void {
		this.http.get<TransitTime[]>(this.baseUrl).subscribe((response: TransitTime[]) => {
			this.transitTimeSubject.next(response);
		});
	}

	findOne(id: number): Observable<TransitTime> {
		return this.http.get<TransitTime>(`${this.baseUrl}/${id}`);
	}

	update(transitTime: TransitTime): Observable<TransitTime> {
		return this.http.put<TransitTime>(`${this.baseUrl}/${transitTime.id}`, transitTime);
	}

	remove(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`);
	}
}
