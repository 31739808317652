import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BrokersService} from '../../../core/services/brokers.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {FreightBroker} from '../../../core/models/freight-broker.interface';
import {ValidatorRegexEnum} from '../../../core/enums/validator-regex.enum';

@Component({
	selector: 'app-broker-info',
	templateUrl: './broker-info.component.html',
	styleUrls: ['./broker-info.component.scss']
})
export class BrokerInfoComponent implements OnInit {
	editMode: boolean;
	form: FormGroup;
	broker: FreightBroker;

	constructor(
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialogRef: MatDialogRef<BrokerInfoComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private brokersService: BrokersService
	) {
		this.form = this.formBuilder.group({
			email: ['', [Validators.pattern(ValidatorRegexEnum.EMAIL), Validators.maxLength(254)]],
			scac: ['', Validators.maxLength(10)],
			name: ['', [Validators.required, Validators.maxLength(100)]],
			phone: ['', Validators.maxLength(40)],
			phoneExt: ['', Validators.maxLength(10)]
		});

		if (this.editMode) {
			this.broker = this.data.broker;
			this.form.patchValue(this.broker);
			this.form.get('firstName')?.setValue(this.broker.name?.split(' ')[0]);
			this.form.get('lastName')?.setValue(this.broker.name?.split(' ')[1]);
		}
	}

	ngOnInit() {}

	createBroker(): void {
		this.brokersService.create(this.form.getRawValue()).subscribe({
			next: (response) => {
				this.dialogRef.close(response);
				this.snackbar.open('User successfully created', SnackbarActionEnum.SUCCESS);
				this.brokersService.findAll();
			},
			error: (err) => {
				console.error(err);
				this.snackbar.open('Failed to create user', SnackbarActionEnum.ERROR);
			}
		});
	}

	updateBroker(): void {
		this.brokersService.update(this.broker.id!, this.form.getRawValue()).subscribe({
			next: (response) => {
				this.dialogRef.close(response);
				this.snackbar.open('User successfully updated', SnackbarActionEnum.SUCCESS);
			},
			error: (err) => {
				console.error(err);
				this.snackbar.open('Failed to update user', SnackbarActionEnum.ERROR);
			}
		});
	}
}
