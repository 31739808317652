import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Status} from '../../../core/models/status.interface';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StatusService} from '../../../core/services/status.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {ConfirmationDialogComponent} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {ProjectPreview} from '../../../core/models/project-preview.interface';

@Component({
	selector: 'app-status-list-detail',
	templateUrl: './status-list-detail.component.html',
	styleUrls: ['./status-list-detail.component.scss']
})
export class StatusListDetailComponent implements OnInit {
	@Input() status: Status;
	@Input() projects: ProjectPreview[];
	form: FormGroup;
	statuses: FormArray;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private statusService: StatusService,
		private snackbar: MatSnackBar
	) {
		this.statuses = this.formBuilder.array([]);
		this.form = this.formBuilder.group({
			id: [''],
			status: ['', Validators.required],
			description: ['', Validators.required],
			predecessor: [''],
			subParentId: [''],
			statuses: this.statuses
		});
	}
	ngOnInit() {
		this.status.statuses?.forEach((substatus: Status) => {
			this.statuses.push(
				this.formBuilder.group({
					id: [substatus.id],
					status: [substatus.status],
					description: [substatus.description],
					predecessor: [substatus.predecessor],
					subParentId: [substatus.subParentId]
				})
			);
		});
		this.form.patchValue(this.status);
		this.form.disable();
	}

	onSubmit() {
		const formValue = this.form.getRawValue();

		this.statusService.update(formValue).subscribe(
			(response) => {
				this.snackbar.open('Status Updated!', SnackbarActionEnum.SUCCESS);
				this.statusService.findAll();
				this.form.disable();
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}

	addNewStatus() {
		this.statuses.push(
			this.formBuilder.group({
				status: ['', Validators.required],
				description: ['', Validators.required],
				predecessor: [''],
				subParentId: [this.status.id]
			})
		);
	}

	openConfirmDeleteModal(substatus: AbstractControl | Status) {
		//if there is a project at the status to be deleted, then alert the user they must change those projects' status before deleting.
		if (this.projects.find((p) => p.statusId === (substatus instanceof AbstractControl ? substatus.get('id')?.value : substatus.id))) {
			let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
				width: '300px',
				disableClose: true,
				data: {
					title: 'Attention!',
					message: 'There are projects currently at this status. This status cannot be deleted until there are no projects at this status.'
				}
			});
		} else {
			let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
				width: '300px',
				disableClose: true,
				data: {
					title: 'Confirm Delete',
					message:
						'Are you sure you want to delete the ' +
						(substatus instanceof AbstractControl ? substatus.get('description')?.value : substatus.description) +
						' status?'
				}
			});
			dialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.deleteStatus(substatus instanceof AbstractControl ? substatus.get('id')?.value : substatus.id);
				}
			});
		}
	}

	deleteStatus(id: number) {
		this.statusService.remove(id).subscribe(
			(response) => {
				this.statusService.findAll();
				this.snackbar.open('Status Deleted!', SnackbarActionEnum.SUCCESS);
			},
			(error) => {
				this.snackbar.open(error.error?.message, SnackbarActionEnum.ERROR);
			}
		);
	}
}
