import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {ProjectsService} from './core/services/projects.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ProjectsModule} from './projects/projects.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ClientSiteService} from './core/services/client-site.service';
import {ContactService} from './core/services/contact.service';
import {LoginComponent} from './core/components/login/login.component';
import {AuthInterceptorProviders} from './core/interceptors/auth.interceptor';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {AuthService} from './core/services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CookieService} from 'ngx-cookie-service';
import {AppInitProvider, AppInitService} from './core/services/app-init.service';
import {QuoteService} from './core/services/quote.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SharedModule} from './shared/shared.module';
import {ShippingCodesService} from './core/services/shipping-codes.service';
import {ProjectModule} from './project/project.module';
import {MatRadioModule} from '@angular/material/radio';
import {ProjectCreateModule} from './project-create/project-create.module';
import {MainToolbarComponent} from './core/components/main-toolbar/main-toolbar.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {SideNavComponent} from './core/components/side-nav/side-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {FooterComponent} from './core/components/footer/footer.component';
import {CalendarModule} from './calendar/calendar.module';
import {QuoteModule} from './quote/quote.module';
import {ChangePasswordRequestComponent} from './core/components/change-password-request/change-password-request.component';
import {FirstLoginResetPasswordComponent} from './core/components/first-login-reset-password/first-login-reset-password.component';
import {MaintenanceModule} from './maintenance/maintenance.module';
import {ShippingLogComponent} from './shipping-log/shipping-log.component';
import {CreateShippingLogDialogComponent} from './shipping-log/create-shipping-log-dialog/create-shipping-log-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {ShippingLogListDetailComponent} from './shipping-log/shipping-log-list-detail/shipping-log-list-detail.component';
import {InstallCalendarModule} from './install-calendar/install-calendar.module';
import {NotificationsComponent} from './notifications/notifications.component';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AuditLogComponent} from './core/components/audit-log/audit-log.component';
import {AuditLogDisplayComponent} from './core/components/audit-log/audit-log-display/audit-log-display.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {QuickQuoteComponent} from './quick-quote/quick-quote.component';
import {MatSliderModule} from '@angular/material/slider';
import {QuickQuoteConfigComponent} from './quick-quote/quick-quote-config/quick-quote-config.component';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		MainToolbarComponent,
		SideNavComponent,
		FooterComponent,
		FirstLoginResetPasswordComponent,
		ChangePasswordRequestComponent,
		ShippingLogComponent,
		CreateShippingLogDialogComponent,
		ShippingLogListDetailComponent,
		NotificationsComponent,
		AuditLogComponent,
		AuditLogDisplayComponent,
		QuickQuoteComponent,
		QuickQuoteConfigComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatCardModule,
		MatTableModule,
		MatFormFieldModule,
		MatInputModule,
		MatSortModule,
		MatIconModule,
		MatTableModule,
		ProjectCreateModule,
		MatPaginatorModule,
		ProjectModule,
		ProjectsModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
		MatAutocompleteModule,
		SharedModule,
		MatRadioModule,
		MatMenuModule,
		MatBadgeModule,
		CalendarModule,
		MatSidenavModule,
		MaintenanceModule,
		QuoteModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatSelectModule,
		InstallCalendarModule,
		MatListModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatSliderModule,
		MatExpansionModule
	],
	providers: [
		AppComponent,
		ProjectsService,
		QuoteService,
		AuthService,
		AuthInterceptorProviders,
		MatSnackBar,
		CookieService,
		AppInitProvider,
		AppInitService,
		ClientSiteService,
		ContactService,
		ShippingCodesService,

		{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
