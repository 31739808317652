import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import {PriceLevel} from '../../../core/models/price-level.interface';
import {MatDialog} from '@angular/material/dialog';
import {PriceLevelService} from '../../../core/services/price-level.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {PriceLevelDiscount} from '../../../core/models/price-level-discount';
import {isBefore, startOfDay} from 'date-fns';
import {HttpStatusCode} from '@angular/common/http';

@Component({
	selector: 'app-price-level-list-detail',
	templateUrl: './price-level-list-detail.component.html',
	styleUrls: ['./price-level-list-detail.component.scss']
})
export class PriceLevelListDetailComponent implements OnInit {
	@Input() priceLevel: PriceLevel;
	form: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private priceLevelService: PriceLevelService,
		private snackbar: MatSnackBar
	) {
		this.form = this.formBuilder.group({
			id: [''],
			name: ['', Validators.required],
			levelNumber: [''],
			active: [''],
			priceLevelDiscounts: this.formBuilder.array([])
		});
	}

	ngOnInit() {
		this.form.patchValue(this.priceLevel);
		this.form.get('id')?.setValue(this.priceLevel.id);
		this.priceLevel.priceLevelDiscounts
			?.sort((a, b) => {
				return new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime();
			})
			?.forEach((discount: PriceLevelDiscount) => {
				const discountForm: FormGroup = this.formBuilder.group({
					id: [discount.id],
					name: [discount.name],
					effectiveDate: [discount.effectiveDate],
					levelNumber: [discount.levelNumber],
					priceLevelId: [discount.priceLevelId],
					levelDiscount: [(discount.levelDiscount * 100).toFixed(2)]
				});
				(this.form.controls['priceLevelDiscounts'] as FormArray).push(discountForm);
			});
		this.form.disable();
	}

	onSubmit() {
		const formValue: PriceLevel = this.form.getRawValue();
		formValue.priceLevelDiscounts = formValue.priceLevelDiscounts?.map((discount: PriceLevelDiscount) => ({
			...discount,
			effectiveDate: new Date(discount.effectiveDate).toISOString().split('T')[0],
			levelDiscount: discount.levelDiscount / 100
		}));
		this.priceLevelService.update(this.priceLevel.id, formValue).subscribe(
			() => {
				this.snackbar.open('PriceLevel Info Updated!', SnackbarActionEnum.SUCCESS);
				this.priceLevelService.findAll();
				this.form.disable();
			},
			(error) => {
				if (error.error.status === HttpStatusCode.Conflict) {
					this.snackbar.open(error.error.error, SnackbarActionEnum.ERROR);
				} else {
					this.snackbar.open('Error Updating PriceLevel Info', SnackbarActionEnum.ERROR);
				}
			}
		);
	}

	get discounts(): FormArray {
		return this.form.controls['priceLevelDiscounts'] as FormArray;
	}

	addDiscount(): void {
		const discountForm: FormGroup = this.formBuilder.group({
			name: [this.priceLevel.name],
			effectiveDate: [new Date().toISOString(), [Validators.required, this.dateValidator]],
			levelNumber: [this.priceLevel.levelNumber],
			priceLevelId: [this.priceLevel.id],
			levelDiscount: [0, [Validators.required]]
		});
		this.discounts.insert(0, discountForm);
	}

	deleteDiscount(configIndex: number): void {
		this.discounts.removeAt(configIndex);
	}

	dateValidator(control: FormControl): {[s: string]: boolean} | null {
		if (control.value) {
			const date = startOfDay(new Date(control.value));
			const today = startOfDay(new Date());
			if (isBefore(date, today)) {
				return {invalidDate: true};
			}
		}
		return null;
	}
}
