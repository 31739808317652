import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {GlCodeService} from '../../../core/services/gl-code.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {Code} from '../../../core/models/code.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
	selector: 'app-create-gl-code',
	templateUrl: './create-gl-code.component.html',
	styleUrls: ['./create-gl-code.component.scss']
})
export class CreateGlCodeComponent {
	form: FormGroup;
	codeTypes: Code[];
	multiLine = false;
	constructor(
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialogRef: MatDialogRef<CreateGlCodeComponent>,
		private glCodeService: GlCodeService
	) {
		this.form = this.formBuilder.group({
			glNumber: ['', Validators.required],
			description: ['', [Validators.required, Validators.maxLength(256)]],
			type: ['', Validators.required],
			category: ['', [Validators.required, Validators.maxLength(100)]]
		});
	}

	createGlCode(): void {
		const formValue = this.form.getRawValue();
		if (!this.multiLine) {
			formValue.description = formValue.description.replace(/\r?\n|\r/g, ' ');
		}
		this.glCodeService.create(formValue).subscribe(
			(response) => {
				this.dialogRef.close();
				this.snackbar.open('GL Code Successfully Created!', SnackbarActionEnum.SUCCESS);
				this.glCodeService.subjectFindAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}

	updateMultiLine(change: MatCheckboxChange) {
		this.multiLine = change.checked;
	}
}
