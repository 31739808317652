import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {StatusService} from '../../../core/services/status.service';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {Status} from '../../../core/models/status.interface';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-create-status',
	templateUrl: './create-status.component.html',
	styleUrls: ['./create-status.component.scss']
})
export class CreateStatusComponent implements OnInit, OnDestroy {
	form: FormGroup;
	statuses: Status[];
	statusSubscription: Subscription;

	constructor(
		private formBuilder: FormBuilder,
		private snackbar: MatSnackBar,
		public dialogRef: MatDialogRef<CreateStatusComponent>,
		private statusService: StatusService
	) {
		this.form = this.formBuilder.group({
			status: ['', [Validators.required, Validators.maxLength(100)]],
			description: ['', [Validators.required, Validators.maxLength(256)]],
			predecessor: [''],
			subParentId: [null]
		});
	}

	ngOnInit() {
		this.statusService.findAll();
		this.statusSubscription = this.statusService.statusDataSource.subscribe((response) => {
			this.statuses = response;
			this.form.get('status')?.setValue(this.statuses[this.statuses.length - 1].status + 100);
			let substatuses = this.statuses[this.statuses.length - 1].statuses;
			if (substatuses) {
				this.form.get('predecessor')?.setValue(substatuses[substatuses?.length - 1]?.id);
			} else {
				this.form.get('predecessor')?.setValue(this.statuses[this.statuses.length - 1].id);
			}
		});
	}

	createStatus(): void {
		this.statusService.create(this.form.getRawValue()).subscribe(
			(response) => {
				this.dialogRef.close();
				this.snackbar.open('Status Created!', SnackbarActionEnum.SUCCESS);
				this.statusService.findAll();
			},
			(error) => {
				this.snackbar.open('Something went wrong! Please try again.', SnackbarActionEnum.ERROR);
			}
		);
	}
	ngOnDestroy() {
		this.statusSubscription.unsubscribe();
	}
}
