import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ProjectInstall} from '../../core/models/project-install.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../core/services/projects.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InstallCalendarService} from '../../core/services/install-calendar.service';
import {ValidatorRegexEnum} from '../../core/enums/validator-regex.enum';
import {ContactComponentContextEnum} from '../../core/enums/contact-component.enum';
import {ContactComponent} from '../../shared/components/contact/contact.component';

export interface UpdateProjectInstallDialogData {
	project: ProjectInstall;
}
export interface UpdateProjectInstallDialogResponse {
	success: boolean;
	project: ProjectInstall;
}

@Component({
	selector: 'app-update-project-install-dialog',
	templateUrl: './update-project-install-dialog.component.html',
	styleUrls: ['./update-project-install-dialog.component.scss']
})
export class UpdateProjectInstallDialogComponent implements OnInit {
	@ViewChild('contact') contact: ContactComponent;

	projectInstallForm = new FormGroup({
		id: new FormControl(0),
		name: new FormControl(''),
		orderNo: new FormControl(''),
		projectNumber: new FormControl(''),
		description: new FormControl(''),
		union: new FormControl(false),
		stockPermitReq: new FormControl(false),
		nightWork: new FormControl(false),
		specialInspection: new FormControl(false),
		city: new FormControl(''),
		state: new FormControl(''),
		postal: new FormControl(''),
		country: new FormControl(''),
		installDate: new FormControl(new Date()),
		clientId: new FormControl(''),
		installDuration: new FormControl(0, [Validators.required, Validators.min(1)]),
		installEndDate: new FormControl(new Date()),
		installColor: new FormControl('', [Validators.required]),
		installWeekend: new FormControl(false),
		installPermitPickup: new FormControl(false),
		installInspection: new FormControl(false),
		installContactId: new FormControl(),
		contactName: new FormControl(''),
		contactEmail: new FormControl('', [Validators.pattern(ValidatorRegexEnum.EMAIL)]),
		contactPhone: new FormControl('', [Validators.pattern(ValidatorRegexEnum.PHONE)]),
		contactPhoneExt: new FormControl('')
	});

	colors: string[] = [
		'#fca5a5',
		'#fdba74',
		'#fcd34d',
		'#fde047',
		'#bef264',
		'#86efac',
		'#6ee7b7',
		'#5eead4',
		'#67e8f9',
		'#7dd3fc',
		'#93c5fd',
		'#a5b4fc',
		'#c4b5fd',
		'#d8b4fe',
		'#f0abfc',
		'#f9a8d4',
		'#fda4af'
	];

	ContactComponentContext = ContactComponentContextEnum;

	loading = false;
	busy = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: UpdateProjectInstallDialogData,
		private dialogRef: MatDialogRef<UpdateProjectInstallDialogComponent>,
		private projectsService: ProjectsService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar,
		private installCalendarService: InstallCalendarService
	) {
		dialogRef.disableClose = true;
	}

	ngOnInit(): void {
		this.projectInstallForm.patchValue(this.data.project);
		this.projectInstallForm.controls.installDuration.markAsTouched();
		this.projectInstallForm.controls.installColor.markAsTouched();
	}

	saveProjectInstall() {
		if (!this.contact.modifyDisabled) {
			this.contact.modifyContact();
		}
		let projectInstall: ProjectInstall = <ProjectInstall>{};
		Object.assign(projectInstall, this.projectInstallForm.value);
		// @ts-ignore
		projectInstall.installDate = new Date(projectInstall.installDate).toISOString().substring(0, 10);
		// @ts-ignore
		projectInstall.installEndDate = new Date(projectInstall.installEndDate).toISOString().substring(0, 10);
		this.projectsService.updateInstall(projectInstall).subscribe({
			next: () => {
				const response: UpdateProjectInstallDialogResponse = {
					success: true,
					project: projectInstall
				};
				this.dialogRef.close(response);
			},
			error: (err) => {
				this._snackBar.open('Save Project Install failed: ' + err.error.message);
				this.busy = false;
			}
		});
	}

	calcEndDate() {
		let weekends = this.projectInstallForm.controls.installWeekend.value;
		const startDate = this.projectInstallForm.controls.installDate.value;
		let duration = this.projectInstallForm.controls.installDuration.value;

		if (startDate) {
			let endDate = this.installCalendarService.calcEndDate(startDate, duration, weekends);
			this.projectInstallForm.controls.installEndDate.setValue(endDate);
		}
	}
}
