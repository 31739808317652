<div class="p-2 flex justify-center">
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="user-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, email or phone number."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openCreateTransitTimeModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 h-[80vh] border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoadingTimes">
		<!--State Column-->
		<ng-container matColumnDef="state">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by state" class="transit-column">State</th>
			<td mat-cell *matCellDef="let time">
				{{ time.state }}
			</td>
		</ng-container>

		<!--Days Column-->
		<ng-container matColumnDef="days">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number of days" class="transit-column">
				# of Days
			</th>
			<td mat-cell *matCellDef="let time">
				{{ time.days }}
			</td>
		</ng-container>

		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns. -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let time" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="time.expanded ? 'expanded' : 'collapsed'">
					<app-transit-time-list-detail [transitTime]="time" class="w-full"></app-transit-time-list-detail>
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let time; let i = dataIndex; columns: columnsToDisplay"
			class="transit-time-row cursor-pointer"
			(click)="time.expanded = !time.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!isLoadingTimes">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoadingTimes">
		<mat-spinner></mat-spinner>
	</div>
</div>
