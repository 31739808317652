import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
	selector: 'app-lcd-change-modal',
	templateUrl: './lcd-change-modal.component.html',
	styleUrls: ['./lcd-change-modal.component.scss']
})
export class LcdChangeModalComponent implements OnInit {
	@Input('lastDraftingContactDate') lastDraftingContactDate: Date;
	@Input('projectId') projectId: number;
	form: FormGroup;
	constructor(private fb: FormBuilder) {
		this.form = this.fb.group({
			id: [''],
			lastDraftingContactDate: ['', Validators.required]
		});
	}

	ngOnInit() {
		this.form.get('lastDraftingContactDate')?.setValue(this.lastDraftingContactDate);
		this.form.get('id')?.setValue(this.projectId);
	}
}
