import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ContentChild,
	EventEmitter,
	SimpleChanges,
	OnChanges,
	AfterViewInit,
	OnDestroy
} from '@angular/core';
import {Client} from '../../core/models/client.interface';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InstallLocation} from '../../core/models/install-location.interface';
import {InstallLocationService} from '../../core/services/install-location.service';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {ProjectsService} from '../../core/services/projects.service';
import {EstimateDateOption} from '../../core/models/estimate-date-option.interface';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {Project} from '../../core/models/project.interface';
import {Template} from '../../core/models/template.interface';
import {TemplatesService} from '../../core/services/templates.service';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ProjectFileUploadComponent} from '../project-file-upload/project-file-upload.component';
import {CreateInstallLocationComponent} from '../../maintenance/install-location/create-install-location/create-install-location.component';
import {map, Observable, startWith, Subscription} from 'rxjs';
import {LocationService} from '../../core/services/location.service';
import {Location} from '../../core/models/location.interface';

@Component({
	selector: 'app-project-details',
	templateUrl: './project-details.component.html',
	styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@ContentChild(ProjectDetailsComponent) projectDetails: ProjectDetailsComponent;
	@ViewChild('estimatedInstallCheckbox') estimatedInstallCheckbox: MatCheckbox;
	@ViewChild('dayCheckbox') dayCheckbox: MatCheckbox;
	@ViewChild('nonSeismicCheckbox') nonSeismicCheckbox: MatCheckbox;
	@ViewChild(ProjectFileUploadComponent) surveyFileComponent: ProjectFileUploadComponent;
	@ViewChild('unknownInstallCheckbox') unknownInstallCheckbox: MatCheckbox;
	@Input() client: Partial<Client> | undefined;
	@Input() existingEstimate: boolean = false;
	@Input() form: FormGroup;
	@Input() project: Project | null;
	@Input() projectDocumentDtos: ProjectDocument[] = [];
	@Input() templateOptions: Template[] = [];
	@Input() selectedTemplate: Template;
	updateProject: EventEmitter<boolean> = new EventEmitter();
	installLocations: InstallLocation[] = [];
	warehouseLocations: Location[] = [];
	filteredLocations: Observable<InstallLocation[]>[] = [];
	dateOptions: EstimateDateOption[] = [];
	prevSelectedTemplate: Template;
	formSubscription: Subscription;
	nonSeismicDefault: boolean = true;

	constructor(
		private snackbar: MatSnackBar,
		private fb: FormBuilder,
		private projectsService: ProjectsService,
		private installLocationService: InstallLocationService,
		private templateService: TemplatesService,
		private dialog: MatDialog,
		private locationService: LocationService
	) {}

	ngOnInit() {
		this.dateOptions = this.projectsService.getDateRangeOptions();
		this.installLocationService.installLocationSubject.subscribe((response) => {
			this.installLocations = response;
			this.locations.controls.forEach((location: AbstractControl, index: number) => {
				this.manageNameControl(index);
			});
		});
		this.locationService.locationSubject.subscribe((response) => {
			this.warehouseLocations = response;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['form'] && changes['form'].firstChange) {
			this.installLocationService.findAll();
			//set timout to allow changes to percolate through to next change detection cycle.
			setTimeout(() => {
				this.locations.controls.forEach((location: AbstractControl, index: number) => {
					this.manageNameControl(index);
				});
			});
			this.locationService.findAll();
		}
	}

	ngAfterViewInit() {
		this.formSubscription = this.form.valueChanges.subscribe((value) => {
			if (this.nonSeismicCheckbox && value.seismic) {
				this.nonSeismicCheckbox.checked = false;
			}
			if (this.dayCheckbox && !value.nightWork) this.dayCheckbox.checked = true;
		});
	}

	manageNameControl(index: number) {
		let locationControls = this.form.get('locations') as FormArray;
		this.filteredLocations[index] = locationControls.at(index).valueChanges.pipe(
			startWith<string | InstallLocation>(''),
			map((value) => (typeof value === 'string' ? value : value?.name)),
			map((name) => (name ? this._filter(name) : this.installLocations.slice()))
		);
	}

	_filter(value: string): InstallLocation[] {
		const filterValue = value.toLowerCase();
		return this.installLocations.filter((option: InstallLocation) => option.name.toLowerCase().includes(filterValue));
	}

	displayFn(location: InstallLocation): string {
		return location?.name ?? '';
	}

	openCreateInstallModal(text: string) {
		const dialogRef = this.dialog.open(CreateInstallLocationComponent, {
			disableClose: true
		});
		dialogRef.componentInstance.selectorInputValue = text;
	}

	applyTemplate(template: Template) {
		//Make call to the template service to get the project template.
		this.templateService.findTemplateEntity<Project>(template.id).subscribe(
			(project: Project) => {
				const surveyValue = this.form.controls['survey'].value;
				const projectWithoutLocations: Project = {...project};
				projectWithoutLocations.locations = [];
				projectWithoutLocations.survey = surveyValue;
				this.form.patchValue(projectWithoutLocations);
				if (project.estimateDate) {
					this.existingEstimate = true;
				}
			},
			(error): void => {
				console.error(error);
				this.snackbar.open('Failed to Load Template', SnackbarActionEnum.ERROR);
			}
		);
		this.prevSelectedTemplate = template;
	}
	templateSelection(template: Template) {
		if (this.form.dirty) {
			const confirm = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Apply Template',
					message: 'Are you sure you would like apply this template? Any changes already made will be overwritten.'
				}
			});
			confirm.afterClosed().subscribe((result) => {
				if (result) {
					this.applyTemplate(template);
				} else {
					this.selectedTemplate = this.prevSelectedTemplate;
				}
			});
		} else {
			this.applyTemplate(template);
		}
	}
	unionOrWage(fieldName: string) {
		if (fieldName === 'union') {
			this.form.controls['union'].setValue(true);
			this.form.controls['prevailingWage'].setValue(false);
			this.form.controls['nonUnion'].setValue(false);
		} else if (fieldName === 'wage') {
			this.form.controls['union'].setValue(false);
			this.form.controls['prevailingWage'].setValue(true);
			this.form.controls['nonUnion'].setValue(false);
		} else {
			this.form.controls['union'].setValue(false);
			this.form.controls['prevailingWage'].setValue(false);
		}
	}

	dayOrNight(type: string) {
		if (type === 'night') {
			this.form.controls['nightWork'].setValue(true);
			this.dayCheckbox.checked = false;
		} else {
			this.form.controls['nightWork'].setValue(false);
			this.dayCheckbox.checked = true;
		}
	}
	seismicOrNonSeismic(type: string) {
		if (type === 'seismic') {
			this.form.controls['seismic'].setValue(true);
			this.form.controls['structuralCalcs'].setValue(false);
			this.nonSeismicCheckbox.checked = false;
		} else {
			this.form.controls['seismic'].setValue(false);
			this.form.controls['seismicCalcs'].setValue(false);
			this.form.controls['specialInspection'].setValue(false);
			this.nonSeismicCheckbox.checked = true;
		}
	}
	removeIncludeInstallOptions() {
		if (!this.form.controls['includeInstall'].value) {
			this.form.controls['union'].setValue(false);
			this.form.controls['prevailingWage'].setValue(false);
			this.form.controls['nonUnion'].setValue(false);
			this.form.controls['nightWork'].setValue(false);
		}
	}
	addNewLocationInput() {
		const locations = this.form.get('locations') as FormArray;
		locations.push(this.fb.control(null, Validators.required));
		this.manageNameControl(locations.controls.length - 1);
	}

	get locations() {
		return this.form.get('locations') as FormArray;
	}

	stockroomSeismicCheck() {
		if (this.form.get('stockPermitReq')?.value) {
			this.form.controls['seismic'].setValue(true);
			this.nonSeismicCheckbox.checked = false;
			this.form.controls['structuralCalcs'].setValue(false);
		}
	}

	compareDates(date1: Date, date2: string): boolean {
		if (date2 === '') {
			return !date1.getTime;
		}
		if (!date1.getTime) {
			return null === date2;
		}
		const parsedDate = new Date(date2);
		return new Date(date1).toISOString().substring(0, 10) === parsedDate.toISOString().substring(0, 10);
	}

	updateEstimateCheck(change: MatCheckboxChange) {
		this.form.controls['installDate'].setValue('');
		this.form.controls['estimateDate'].setValue('');
		this.existingEstimate = change.checked;
	}

	removeLocation(indexToRemove: number) {
		const locations = this.form.get('locations') as FormArray;
		const currentLocation = locations.controls[indexToRemove].value;
		if (this.projectDocumentDtos.filter((each) => each.installLocationId === currentLocation.id).length) {
			this.dialog.open(ConfirmationDialogComponent, {
				width: '400px',
				data: {
					title: 'Location Cannot Be Removed!',
					message:
						'This location cannot be removed because there are files for this location in the dropzone! Please remove these files before deleting this location.'
				}
			});
		} else if (this.project?.documents.filter((each) => each.installLocationId === currentLocation.id).length) {
			this.dialog.open(ConfirmationDialogComponent, {
				width: '400px',
				data: {
					title: 'Location Cannot Be Removed!',
					message:
						'This location cannot be removed because there are files for this location in the dropzone! Please remove these files on the "Drafting Documents" tab before deleting this location.'
				}
			});
		} else {
			locations.removeAt(indexToRemove);
		}
	}

	surveyRemove() {
		const surveyValue: boolean = this.form.controls['survey'].value;
		const surveyFileExists: ProjectDocument | undefined = this.project?.documents.find((doc) => doc.subType == 'Survey');
		if (!surveyValue && surveyFileExists) {
			const confirm = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Delete Survey File?',
					message: 'Unchecking this box will permanently delete the survey file from this project. Do you wish to continue?'
				}
			});
			this.form.controls['survey'].setValue(true);
			confirm.afterClosed().subscribe(async (result) => {
				if (result) {
					try {
						const surveyFile = this.project?.documents.find((doc) => doc.subType == 'Survey');
						await this.surveyFileComponent.deleteFile(surveyFile!);
						this.form.controls['survey'].setValue(false);
					} catch {
						throw new Error('Error deleting survey file');
					} finally {
						this.updateProject.emit(true);
					}
				} else {
					this.form.controls['survey'].setValue(!surveyValue);
				}
			});
		}
		if (!surveyValue && !surveyFileExists) {
			const surveyFileDto = this.projectDocumentDtos.find((doc) => doc.subType == 'Survey');
			const surveyFileToDelete = this.surveyFileComponent.files.find((file) => file.name === surveyFileDto?.documentName);
			if (surveyFileToDelete) {
				this.surveyFileComponent.onRemove(surveyFileToDelete);
			}
		}
	}

	ngOnDestroy() {
		this.formSubscription.unsubscribe();
	}
}
