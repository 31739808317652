<h2 mat-dialog-title>Is This Project RFQ?</h2>
<mat-dialog-content class="mat-typography">
	<p>
		This project has an RFQ date. <br />
		Should this project be moved to the RFQ Status?
	</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close>Keep in Drafting</button>
	<button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Move to RFQ</button>
</mat-dialog-actions>
