<div class="flex justify-center mt-2">
	<div *ngIf="priceConfigType.priceConfigs?.length; else elseBlock" class="p-2 mb-2 border border-gray-500 rounded w-[750px]">
		<div class="flex justify-between font-semibold mb-2">
			<span class="w-1/4 ">Percentage?</span>
			<span class="w-1/4 ">Charge</span>
			<span class="w-1/4">Effective Date</span>
      <span class="w-1/4"></span>
		</div>
		<mat-divider></mat-divider>
		<div *ngFor="let priceConfig of priceConfigType.priceConfigs" class="flex justify-between">
			<span class="w-1/4 translate-y-2.5 translate-x-6">{{ priceConfig.isPercent ? 'Yes' : 'No' }}</span>
			<span class="w-1/4 translate-y-2.5">{{
				priceConfig.isPercent ? priceConfig.charge! * 100 + '%' : (priceConfig.charge | currency)
			}}</span>
			<span class="w-1/4 translate-y-2.5">{{ priceConfig.effectiveDate | date }}</span>
      <span class="w-1/4 flex -translate-y-1">
        <button mat-icon-button (click)="openPriceConfigDialog(true, priceConfig)"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button (click)="deletePriceConfig(priceConfig.id!)"><mat-icon>delete</mat-icon></button>
      </span>
		</div>
	</div>
</div>

<ng-template #elseBlock>
	<div class="m-2">
		<span>No price configurations for this type</span>
	</div>
</ng-template>
