import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {Project} from '../../core/models/project.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {ProjectsService} from '../../core/services/projects.service';
import {StatusService} from '../../core/services/status.service';
import {Status} from '../../core/models/status.interface';
import {ProjectDocument} from '../../core/models/project-document.interface';
import {ProjectDocumentService} from '../../core/services/project-document.service';
import {Router, RouterStateSnapshot} from '@angular/router';
import {Subscription} from 'rxjs';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Quote} from '../../core/models/quote.interface';
import {QuoteService} from '../../core/services/quote.service';
import {QuoteDocument} from "../../core/models/quote-document.interface";

@Component({
	selector: 'app-project-drafting',
	templateUrl: './project-drafting.component.html',
	styleUrls: ['./project-drafting.component.scss']
})
export class ProjectDraftingComponent implements OnInit, OnDestroy {
	@Input() files: File[] = [];
	project: Project;
	statuses: Status[];
	uploadedFiles: ProjectDocument[] = [];
	projectDocumentDtos: ProjectDocument[] = [];
	isLoading: boolean = true;
	navigationSubscription: Subscription;
  projectSubscription: Subscription;
	dialogConfirmed: boolean = false;

	quotes: Quote[] = [];
	quoteSubscription: Subscription;

	constructor(
		private projectService: ProjectsService,
		private statusesService: StatusService,
		private projectDocumentService: ProjectDocumentService,
		private snackbar: MatSnackBar,
		private router: Router,
		public dialog: MatDialog,
		private quoteService: QuoteService
	) {}

	ngOnInit() {
		this.statusesService.statuses.subscribe((statuses: Status[]): void => {
			this.statuses = statuses;
		});

		this.projectSubscription = this.projectService.project.subscribe({
			next: (project: Project | null): void => {
				if (project) {
					this.project = project;
					this.uploadedFiles = project.documents;
					this.getChangeRequestDocuments(project.id);
					this.isLoading = false;
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.navigationSubscription?.unsubscribe();
		this.quoteSubscription?.unsubscribe();
    this.projectSubscription?.unsubscribe();
	}

	getChangeRequestDocuments(projectId: number): void {
		this.quoteSubscription = this.projectService.findAllChangeRequestDocumentsByQuote(projectId).subscribe({
			next: (quotes: Quote[]): void => {
				this.quotes = quotes;
			},
			error: (err): void => {
				console.error(err);
				this.snackbar.open('Failed to check if change request documents have been uploaded.');
			}
		});
	}

	confirmNavigationAway(nextState: RouterStateSnapshot | undefined): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Unsaved Files!',
					message: 'You have unsaved files waiting to be uploaded that will be lost. Would you like to leave anyway?'
				}
			});
			this.navigationSubscription = dialogRef.afterClosed().subscribe((response): void => {
				if (response) {
					this.dialogConfirmed = true;
					this.router.navigate([nextState?.url]).then((response: boolean): void => {
						resolve();
						this.navigationSubscription.unsubscribe();
					});
				} else {
					reject();
				}
			});
		});
	}

	hasUnsavedFiles(): boolean {
		return Boolean(this.files.length) && !this.dialogConfirmed;
	}

	updateProjectDocs(): void {
		this.isLoading = true;
		const newDocs: FormData = new FormData();
		for (let i: number = 0; i < this.files.length; i++) {
			newDocs.append('files', this.files[i], this.files[i].name);
		}
		let newDtos: ProjectDocument[] = this.projectDocumentDtos.filter(
			(dto) => this.files.filter((file) => file.name === dto.documentName).length
		);
		newDocs.append('body', JSON.stringify(newDtos));
		this.projectDocumentService.create(newDocs, this.project.id).subscribe(
			(response): void => {
				//I know this looks stupid, but the arrays' reference needs to change to trigger change detection in the file upload component
				let newUploadedFiles = this.uploadedFiles.filter((each: ProjectDocument) => true);
				response.forEach((document: ProjectDocument, i: number) => {
					let spliceIndex = this.files.findIndex((doc, i) => {
						return doc.name === response[i].documentName;
					});
					newUploadedFiles.push(response[i]);
					this.files.splice(spliceIndex, 1);
				});
				this.uploadedFiles = newUploadedFiles;
				this.isLoading = false;
				//update project-service project subject
				this.projectService.setProject(this.project?.id);
				this.snackbar.open('Document Uploaded', SnackbarActionEnum.SUCCESS);
				// this.projectDocumentDtos = []
			},
			(error): void => {
				console.error(error);
				this.isLoading = false;
				this.snackbar.open('Failed to Upload Document', SnackbarActionEnum.ERROR);
				throw new Error('Something went wrong with your request. Please try again soon.');
			}
		);
	}

	handleFileAddEmit(files: File[]) {
		files.forEach((file: File) => {
			this.files.push(file);
		});
	}

	handleFileRemoveEmit(file: File) {
		this.files.splice(this.files.indexOf(file), 1);
		let documentDto = this.projectDocumentDtos.find((dto: ProjectDocument) => dto.documentName === file.name);
		this.projectDocumentDtos.splice(this.projectDocumentDtos.indexOf(documentDto!), 1);
	}

	handleDocumentDtoEmit(documents: ProjectDocument[]) {
		documents.forEach((document: ProjectDocument) => {
			if (
				!this.projectDocumentDtos.find(
					(dto: ProjectDocument) => dto.documentName === document.documentName && dto.installLocationId === document.installLocationId
				)
			) {
				this.projectDocumentDtos.push(document);
			}
		});
	}

	downloadFile(event: Event, quoteDocument: QuoteDocument) {
		event.stopPropagation();
    const fileName = quoteDocument.documentName;
		this.quoteService.findOneQuoteDocument(this.project.id, quoteDocument.id!).subscribe((response): void => {
			//window.open() was opening then closing the tab, this way just simply downloads the file.
			const a = document.createElement('a');
			a.style.display = 'none';
			a.href = response;
			a.download = fileName!;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(a.href);
			document.body.removeChild(a);
		});
	}
}
