<h1 mat-dialog-title>{{ isSuccessful ? 'Navision Sales Order Successfully Created!' : 'Fatal Error!' }}</h1>
<mat-dialog-content class="">
	<div *ngIf="!isSuccessful" class="text-base text-center">
		<p class="text-red">There was a problem creating the Sales Order in Navision:</p>
		<p>{{ error.message }}</p>
	</div>
	<h2 id="details-title" *ngIf="isSuccessful">{{ '&nbsp;Details&nbsp;' }}</h2>
	<div class="flex flex-row justify-center" *ngIf="isSuccessful">
		<div class="flex flex-col w-1/3">
			<div class="text-center">
				<b>Sales Order #</b>
				<p>{{ salesOrder.id ? salesOrder.id : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Account Manager</b>
				<p>{{ salesOrder.accountManager ? (salesOrder.accountManager | titlecase) : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Additional Charge</b>
				<p>{{ salesOrder.additionalChargeAmount ? salesOrder.additionalChargeAmount : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Payment Terms Code</b>
				<p>{{ salesOrder.paymentTermsCode ? salesOrder.paymentTermsCode : 'N/A' }}</p>
			</div>
		</div>
		<div class="flex flex-col w-1/3">
			<div class="text-center">
				<b>Total Gross Weight</b>
				<p>{{ salesOrder.totalGrossWeight ? (salesOrder.totalGrossWeight | number) : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Total Great Weight</b>
				<p>{{ salesOrder.totalNetWeight ? (salesOrder.totalNetWeight | number) : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Ship To Name</b>
				<p>{{ salesOrder.shipToName ? (salesOrder.shipToName | titlecase) : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Ship To Contact</b>
				<p>{{ salesOrder.shipToContact ? salesOrder.shipToContact : 'N/A' }}</p>
			</div>
		</div>
		<div class="flex flex-col w-1/3">
			<div class="text-center">
				<b>Bill To Name</b>
				<p>{{ salesOrder.billToName ? (salesOrder.billToName | titlecase) : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Bill To Contact</b>
				<p>{{ salesOrder.billToContact ? salesOrder.billToContact : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Sell To Name</b>
				<p>{{ salesOrder.sellToName ? (salesOrder.sellToName | titlecase) : 'N/A' }}</p>
			</div>
			<div class="text-center">
				<b>Sell To Contact</b>
				<p>{{ salesOrder.sellToContact ? salesOrder.sellToContact : 'N/A' }}</p>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
