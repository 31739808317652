<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">{{ task.name | titlecase }} Details</span>
		<button mat-icon-button (click)="form.enable()" [disabled]="form.enabled">
			<mat-icon>edit</mat-icon>
		</button>
		<button mat-icon-button (click)="openConfirmDeleteModal(task)" [disabled]="form.enabled">
			<mat-icon>delete</mat-icon>
		</button>
	</div>
	<div class="pt-4">
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">
				<mat-label>Name</mat-label>
				<input matInput formControlName="name" />
			</mat-form-field>
			<mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">
				<mat-label>Multiplier</mat-label>
				<input matInput formControlName="multiplier" />
			</mat-form-field>
			<mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">
				<mat-label>List Type</mat-label>
				<input matInput formControlName="listType" />
			</mat-form-field>
		</div>
		<!--    <div class="w-full flex gap-4 justify-center">-->
		<!--      <mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">-->
		<!--        <mat-label>GL List</mat-label>-->
		<!--        <input matInput formControlName="glList" />-->
		<!--      </mat-form-field>-->
		<!--      <mat-form-field class="w-1/3" (click)="form.enable()" tabindex="0">-->
		<!--        <mat-label>Cat List</mat-label>-->
		<!--        <input matInput formControlName="catList" />-->
		<!--      </mat-form-field>-->
		<!--    </div>-->
	</div>
	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
