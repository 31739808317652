import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Contact} from '../../../../core/models/contact.model';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
	selector: 'app-request-approval-dialog',
	templateUrl: './request-approval-dialog.component.html',
	styleUrls: ['./request-approval-dialog.component.scss']
})
export class RequestApprovalDialogComponent {
	contactForm: FormGroup;
	erpClientId: '';
	clientName: '';
	contacts: Contact[] = [];
  resend: boolean;
  quoteId: number;
	hideMessageInput: boolean = false;
	DEFAULT_MESSAGE: string = 'MMI Storage Solutions is requesting you review a quote for a project.';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<RequestApprovalDialogComponent>,
		private fb: FormBuilder,
    private snackbar: MatSnackBar
	) {
		this.erpClientId = data.erpClientId;
		this.contactForm = this.fb.group({
			subject: [data.defaultSubject],
			message: ['']
		});
    this.resend = data.resend;
    this.quoteId = data.quoteId;
	}

	onSubmit(): void {
		if (this.contactForm.valid && this.contacts.length) {
			const approvalInfo = {
				contacts: this.contacts,
				message: this.contactForm.get('message')?.value,
				subject: this.contactForm.get('subject')?.value,
        resend: this.resend
			};
			this.dialogRef.close(approvalInfo);
		} else if (!this.contacts.length) {
      this.snackbar.open('Please select contacts in order to request approval!')
    }
	}

	close(): void {
		this.dialogRef.close();
	}

	selectionChanged(event: Contact[]): void {
		this.contacts = event;
	}
}
