<h2 mat-dialog-title>Create GL Code</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
	<div class="pt-4">
		<div class="w-full flex justify-end">
			<mat-checkbox [checked]="multiLine" (change)="updateMultiLine($event)" color="primary">Multi Line </mat-checkbox>
		</div>
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>GL #</mat-label>
				<input matInput formControlName="glNumber" />
				<mat-error *ngIf="form.get('glNumber')?.hasError('required')"> GL Number is required </mat-error>
			</mat-form-field>
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Description</mat-label>
				<input *ngIf="!multiLine" matInput formControlName="description" />
				<textarea *ngIf="multiLine" matInput formControlName="description"></textarea>
				<mat-error *ngIf="form.get('description')?.hasError('required')"> Description is required </mat-error>
				<mat-error *ngIf="form.get('description')?.hasError('maxlength')">
					{{ this.form.get('description')?.value.length }} / 256 Maximum Characters Allowed</mat-error
				>
			</mat-form-field>
		</div>
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Type</mat-label>
				<mat-select formControlName="type">
					<mat-option *ngFor="let code of codeTypes" [value]="code.code">
						{{ code.code }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="form.get('type')?.hasError('required')"> Type is required </mat-error>
			</mat-form-field>
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Category</mat-label>
				<input matInput formControlName="category" />
				<mat-error *ngIf="form.get('category')?.hasError('maxlength')">
					{{ this.form.get('category')?.value.length }} / 100 Maximum Characters Allowed</mat-error
				>
				<mat-error *ngIf="form.get('category')?.hasError('required')"> Category is required </mat-error>
			</mat-form-field>
		</div>
	</div>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close="true" class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createGlCode()">Create</button>
</div>
