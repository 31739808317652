import {Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {QuoteService} from '../../core/services/quote.service';
import {Subscription} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {QuoteHeader} from '../../core/models/quote-header.interface';
import {DatePipe} from '@angular/common';
import {ProjectsService} from '../../core/services/projects.service';
import {MatDialog} from '@angular/material/dialog';
import {ProjectSelectDialogComponent} from '../project-select/project-select-dialog.component';
import {SnackbarActionEnum} from '../../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmationDialogComponent} from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {QuoteStatusEnum} from '../../core/enums/quote-status.enum';
import {UrlService} from '../../core/services/url.service';
import {Project} from '../../core/models/project.interface';

@Component({
	selector: 'app-quote-header-list',
	templateUrl: './quote-header-list.component.html',
	styleUrls: ['./quote-header-list.component.scss']
})
export class QuoteHeaderListComponent implements OnInit, OnDestroy {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}

	sort: MatSort;
	projectId: number;
	routeSubscription: Subscription;
	quotesSubscription: Subscription;
	projectSubscription: Subscription;
	dataSource: MatTableDataSource<QuoteHeader> = new MatTableDataSource<QuoteHeader>();
	columnsToDisplay: string[] = [];
	columnsToDisplayForAllQuotes: string[] = [
		'quoteNumber',
		'quoteDate',
		'status',
		'email',
		'project.name',
		'project.clientId',
		'location',
		'salesOrder',
		'poNumber',
		'grandTotal',
		'view-quote',
		'quote-actions'
	];
	columnsToDisplayForProjectLevelQuotes: string[] = [
		'quoteNumber',
		'quoteDate',
		'status',
		'email',
		'location',
		'salesOrder',
		'poNumber',
		'grandTotal',
		'view-quote',
		'quote-actions'
	];
	isLoadingQuotes: boolean = true;
	setHeight: boolean = false;
	busy: boolean = false;
	createNewQuoteDisabled: boolean = false;
	viewQuoteButtonDisabled: boolean = false;

	constructor(
		private quotesService: QuoteService,
		private projectService: ProjectsService,
		private datePipe: DatePipe,
		private dialog: MatDialog,
		private router: Router,
		private snackBar: MatSnackBar,
		private route: ActivatedRoute,
		private ref: ChangeDetectorRef,
		private urlService: UrlService
	) {}

	ngOnInit() {
		this.routeSubscription = this.route.params.subscribe((params) => {
			if (params['projectId']) {
				this.projectId = +params['projectId'];
				this.setHeight = false;
			}
		});

		if (this.projectId) {
			this.projectSubscription = this.projectService.project.subscribe({
				next: (project: Project | null): void => {
					this.createNewQuoteDisabled = project?.clientId === 'PROSPECT';
				},
				error: (error: Error): void => console.error(error)
			});
			this.quotesSubscription = this.projectService.findQuotesAssociatedWithProject(this.projectId).subscribe({
				next: (response: QuoteHeader[]) => {
					this.columnsToDisplay = this.columnsToDisplayForProjectLevelQuotes;
					this.buildDataSource(response);
				},
				error: (err: any) => {
					console.error(err);
					this.snackBar.open('Error loading quote headers', SnackbarActionEnum.ERROR);
				}
			});
		} else {
			this.quotesSubscription = this.quotesService.findAllQuotes().subscribe({
				next: (response: QuoteHeader[]) => {
					this.columnsToDisplay = this.columnsToDisplayForAllQuotes;
					this.buildDataSource(response);
				},
				error: (err: any): void => {
					console.error(err);
					this.snackBar.open('Error loading quote headers', SnackbarActionEnum.ERROR);
				}
			});
		}
		this.urlService.setPreviousUrl(this.router.url);
	}

	ngOnDestroy(): void {
		this.quotesSubscription?.unsubscribe();
		this.projectSubscription?.unsubscribe();
	}

	buildDataSource(response: QuoteHeader[]): void {
		this.dataSource = new MatTableDataSource<QuoteHeader>(response);
		this.dataSource.sortingDataAccessor = (item: QuoteHeader, property: string) => {
			switch (property) {
				case 'project.clientId':
					return item.project.clientId;
				case 'quoteDate':
					return new Date(item.date);
				default:
					return item[property];
			}
		};
		this.isLoadingQuotes = false;
	}

	applyFilter(event: Event): void {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		this.dataSource.filterPredicate = (data: QuoteHeader, filter: string) => {
			const clientName = data.project.clientId!.toLowerCase();
			//string and numeric so users can type out number date or month name in search bar
			const quoteDateString = this.datePipe.transform(data.date, 'longDate')?.toLowerCase();
			const quoteDateNumeric = this.datePipe.transform(data.date, 'MM/dd/y')?.toString();
			return (
				clientName.includes(filter) ||
				(quoteDateString && quoteDateString.includes(filter)) ||
				(quoteDateNumeric && quoteDateNumeric.includes(filter)) ||
				data.quoteNumber.toString().includes(filter) ||
				data.project.city?.toLowerCase().includes(filter) ||
				data.project.state?.toLowerCase().includes(filter) ||
				data.project.postal?.includes(filter) ||
				data.poNumber?.toString().includes(filter) ||
				data.project.name?.toLowerCase().includes(filter) ||
				data.salesOrder?.toString().includes(filter) ||
				false
			);
		};
		this.ref.detectChanges();
	}

	openProjectSelectDialog(): void {
		if (!this.projectId) {
			this.dialog.open(ProjectSelectDialogComponent, {minWidth: '30%'});
			return;
		}
		this.router.navigate(['project', this.projectId, 'quote', 'new']);
	}

	navigateToQuote(quote: QuoteHeader) {
		this.viewQuoteButtonDisabled = true;
		this.router.navigate([`/project/${quote['projectId']}/quote/${quote.id}`]).then((response: boolean) => {
			this.viewQuoteButtonDisabled = response;
		});
	}

	hasChangeRequest(quoteHeader: QuoteHeader): boolean {
		return quoteHeader.status === QuoteStatusEnum.CHANGE_REQUESTED;
	}

	getQuoteNumber(quoteHeader: QuoteHeader): string | undefined {
		if (!quoteHeader?.quoteNumber) {
			return;
		}
		return `${quoteHeader.quoteNumber}${(quoteHeader.versionSeq ?? 0) > 0 ? '-' + quoteHeader.versionSeq : ''}`;
	}

	goToOrder(quote: QuoteHeader): void {
		this.router.navigate(['project', this.projectId ?? quote['projectId'], 'quote', quote.id, 'create-order']);
	}

	goToEmailList(quote: QuoteHeader): void {
		this.router.navigate(['project', this.projectId ?? quote['projectId'], 'quote', quote.id, 'email']);
	}

	deleteQuote(quote: QuoteHeader): void {
		this.dialog
			.open(ConfirmationDialogComponent, {data: {message: `Are you sure you want to delete Quote ${quote.quoteNumber}?`}})
			.afterClosed()
			.subscribe((result: boolean) => {
				if (result) {
					this.busy = true;
					this.quotesService.remove(quote.id).subscribe({
						next: () => {
							const index: number = this.dataSource.data.findIndex((_quote: QuoteHeader) => _quote.id === quote.id);
							this.dataSource.data.splice(index, 1);
							this.dataSource._updateChangeSubscription();
							this.snackBar.open(`Quote ${quote.quoteNumber} Deleted.`);
							this.busy = false;
						},
						error: (err) => {
							this.snackBar.open(`Failed to delete Quote ${quote.quoteNumber}. ${err.error.message}`);
							this.busy = false;
						}
					});
				}
			});
	}

	protected readonly QuoteStatusEnum = QuoteStatusEnum;
}
