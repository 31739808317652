import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
	selector: 'app-drafters-notes',
	templateUrl: './drafters-notes.component.html',
	styleUrls: ['./drafters-notes.component.scss']
})
export class DraftersNotesComponent {
	@Input() form: FormGroup;
	constructor() {}
}
