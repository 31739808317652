<mat-card class="p-4">
	<mat-card-title>{{ editMode ? 'Edit Get Back' : 'Create New Get Back' }}</mat-card-title>

	<form #ngForm="ngForm" [formGroup]="form">
		<mat-form-field class="w-full mt-2" *ngIf="!editMode">
			<mat-label>Line Item</mat-label>
			<mat-select [(value)]="selected" (selectionChange)="onSelection($event.value)">
				<mat-option
					*ngFor="let option of getBackOptions"
					[value]="option">
					{{ option.glNumber + ' - ' + option.description }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="w-full {{editMode ? 'mt-2' : '' }}">
			<span matPrefix class="ml-3 text-gray-500">$</span>
			<mat-label>Get Back Amount</mat-label>
			<input matInput placeholder="Get Back Amount" formControlName="price" IsDecimal (blur)="checkForTrailingZero()" />
			<mat-error *ngIf="ngForm.submitted && form.get('price')?.invalid">Amount is required</mat-error>
			<mat-error *ngIf="form.get('price')?.touched && form.get('price')?.invalid">Amount is required</mat-error>
		</mat-form-field>

		<mat-form-field class="w-full">
			<mat-label>Comment</mat-label>
			<textarea type="text" matInput formControlName="comment"></textarea>
		</mat-form-field>

		<div class="flex justify-end space-x-2">
			<button mat-flat-button (click)="closeDialog()" type="button">CANCEL</button>
			<button mat-flat-button color="primary" type="submit" (click)="onSubmit()">
				{{ editMode ? 'UPDATE GET BACK' : 'ADD GET BACK' }}
			</button>
		</div>
	</form>
</mat-card>
