import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormGroupDirective} from '@angular/forms';
import {isWeekend, subDays} from 'date-fns';
import {distinctUntilChanged, Subject, takeUntil, throttleTime} from 'rxjs';

@Component({
	selector: 'app-install-ship-date',
	templateUrl: './install-ship-date.component.html',
	styleUrls: ['./install-ship-date.component.scss']
})
export class InstallShipDateComponent implements OnInit {
	@Input() componentFormGroupName: string = '';
	@Input() installDate: string = 'installDate';
	@Input() transitDays: string = 'transitDays';
	@Input() estimatedTransit: string = 'estimatedTransit';
	@Input() shipDate: string = 'shipDate';
	@Input() location: string = '';
	@Input() navShipDate: Date;
	@Input() column: boolean = false;

	installShipDateForm: FormGroup;

	transitWeekends: number = 0;

	private initDate = new Subject();
	private initDays = new Subject();

	constructor(private rootFormGroup: FormGroupDirective) {}

	ngOnInit(): void {
		this.installShipDateForm = this.rootFormGroup.form as FormGroup;
		if (this.componentFormGroupName > '') {
			this.installShipDateForm = this.installShipDateForm.get(this.componentFormGroupName) as FormGroup;
		}
		if (this.installShipDateForm && this.installDate && this.installDate > '') {
			this.installShipDateForm
				.get(this.installDate)
				?.valueChanges.pipe(distinctUntilChanged(), throttleTime(500), takeUntil(this.initDate))
				.subscribe((val) => {
					this.adjustShippingTime();
					this.initDate.next(true);
					this.initDate.complete();
				});
			this.installShipDateForm
				.get(this.transitDays)
				?.valueChanges.pipe(distinctUntilChanged(), throttleTime(500), takeUntil(this.initDays))
				.subscribe((val) => {
					this.adjustShippingTime();
					this.initDays.next(true);
					this.initDays.complete();
				});
		}
	}

	decreaseTransit() {
		this.installShipDateForm?.get(this.transitDays)?.markAsDirty();
		const transitDays = this.installShipDateForm?.get(this.transitDays);
		if (transitDays) {
			transitDays.setValue(transitDays.value - 1);
		}
		this.adjustShippingTime();
	}

	increaseTransit() {
		this.installShipDateForm?.get(this.transitDays)?.markAsDirty();
		const transitDays = this.installShipDateForm?.get(this.transitDays);
		if (transitDays) {
			transitDays.setValue(transitDays.value + 1);
		}
		this.adjustShippingTime();
	}

	calcWeekends(startDate: Date, days: number) {
		let weekendCount = 0;
		if (isWeekend(startDate)) {
			weekendCount++;
			days++;
		}
		while (days > 0) {
			startDate = subDays(startDate, 1);
			if (isWeekend(startDate)) {
				weekendCount++;
			} else {
				days--;
			}
		}
		return weekendCount;
	}

	adjustShippingTime() {
		const transitDays = this.installShipDateForm.get(this.transitDays)?.value;
		let installDate = this.installShipDateForm.get(this.installDate)?.value;
		if (installDate) {
			installDate = new Date(installDate);
			this.transitWeekends = this.calcWeekends(installDate, transitDays);
			const transitTotal = transitDays + this.transitWeekends;
			this.installShipDateForm?.get(this.shipDate)?.setValue(subDays(installDate, transitTotal));
		}
	}

	navShipWarn() {
		if (this.installShipDateForm.controls['shipDate'].value && this.navShipDate) {
			return (
				new Date(this.installShipDateForm.controls['shipDate'].value).toISOString().substring(0, 10) !==
				new Date(this.navShipDate).toISOString().substring(0, 10)
			);
		}
		return true;
	}

	disableDecrease() {
		const transitDays = this.installShipDateForm?.get(this.transitDays);
		if (transitDays && transitDays.value > 1) {
			return false;
		}
		return true;
	}
}
