import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {Subject, take} from 'rxjs';
import {CommaRemovalPipe} from '../../../shared/pipes/comma-removal.pipe';
import {GlCodeService} from 'src/app/core/services/gl-code.service';
import {GlCode} from '../../../core/models/gl-code.interface';
import {GlCodeTypeEnum} from '../../../core/enums/gl-code-type.enum';
import {MatSelect} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-freight-install',
	templateUrl: './freight-install.component.html',
	styleUrls: ['./freight-install.component.scss']
})
export class FreightInstallComponent implements OnInit {
	@ViewChild('commentSelect') commentSelect: MatSelect;
	@Input() itemTotal: number = 0;
	@Input() itemTotalSubject: Subject<number> = new Subject<number>();
	@Input() submitted: boolean = false;
	@Input() quoteForm: FormGroup;
	@Input() installCheckedOnProject: boolean;

	percentageGroup: FormGroup = this.fb.group({
		percentageControl: new FormControl<string>('27.5')
	});
	noItemTotalProvided: boolean = false;
	installCommentOptions: GlCode[] = [];

	constructor(
		private controlContainer: ControlContainer,
		private decimalPipe: DecimalPipe,
		private fb: FormBuilder,
		private currencyPipe: CurrencyPipe,
		private format: CommaRemovalPipe,
		private glCodeService: GlCodeService,
		private snackbar: MatSnackBar
	) {}

	ngOnInit(): void {
		this.glCodeService
			.findAll()
			.pipe(take(1))
			.subscribe(
				(res) => {
					this.installCommentOptions = res.filter((code) => code.type === GlCodeTypeEnum.INSTALL);
				},
				(err) => {
					this.snackbar.open('There was a problem loading Install Comment options. Please try again later.', SnackbarActionEnum.ERROR);
				}
			);

		if (!this.installCheckedOnProject) {
			this.setInstallCostValue(0);
			this.setPercentageValue(0);
			this.quoteForm.get('installLineItem')?.get('price')?.setValue(0);
			this.quoteForm.get('installLineItem')?.get('comment')?.setValue(0);
		} else {
			if (this.quoteForm.get('installLineItem')?.get('price')?.value) {
				this.setInstallCostValue(this.quoteForm.get('installLineItem')?.get('price')?.value);
			}
			if (this.quoteForm.get('installLineItem')?.get('comment')?.value) {
				this.setPercentageValue(parseFloat(this.quoteForm.get('installLineItem')?.get('comment')?.value));
			}
		}

		if (this.submitted) {
			this.percentageControl.disable();
		} else {
			this.zeroValueCheck();
		}

		this.itemTotalSubject.subscribe((itemTotal: number): void => {
			this.itemTotal = itemTotal;
			this.zeroValueCheck();
		});
	}

	zeroValueCheck(): void {
		if (this.itemTotal === 0) {
			this.noItemTotalProvided = true;
			this.percentageControl.disable();
		} else {
			this.percentageControl.enable();
			if (
				this.quoteForm.get('installLineItem')?.get('comment')?.value &&
				this.quoteForm.get('installLineItem')?.get('comment')?.value !== ''
			) {
				this.setPercentageValue(parseFloat(this.quoteForm.get('installLineItem')?.get('comment')?.value));
			} else {
				this.setInstallCostValue(this.itemTotal * this.percentageValue);
			}
		}
	}

	installOptionSelect(option: any) {
		this.quoteForm.controls['installComment'].setValue(option.value);
		this.quoteForm.markAsDirty();
	}

	get percentageControl() {
		return this.percentageGroup.controls['percentageControl'];
	}

	get percentageValue(): number {
		return parseFloat(this.percentageControl.value) / 100.0;
	}

	setInstallCostValue(value: number): void {
		value = parseFloat(value.toFixed(2));
		this.installLineItem.get('price')?.setValue(parseFloat(value.toFixed(2)));
	}

	setPercentageValue(value: number): void {
		this.percentageGroup.get('percentageControl')?.setValue(parseFloat(value.toFixed(2)));
	}

	percentageChanged(): void {
		if (this.percentageValue) {
			this.setInstallCostValue(this.itemTotal * this.percentageValue);
		}
	}

	estimatedCostChanged(): void {
		if (this.itemTotal) {
			this.setPercentageValue((parseFloat(this.installLineItem.get('price')?.value.toFixed(2)) / this.itemTotal) * 100.0);
		}
	}

	trailingZeroCheck(): void {
		this.setInstallCostValue(parseFloat(this.installLineItem.get('price')?.value.toFixed(2)));
		this.quoteForm
			.get('installLineItem')
			?.get('comment')
			?.setValue(this.percentageValue * 100);
	}

	get installCost(): string | null {
		const value: string = this.format.transform(this.installLineItem.get('price')?.value.toString(), true);
		return this.currencyPipe.transform(parseFloat(value));
	}

	get installLineItem(): FormGroup {
		return this.quoteForm.controls['installLineItem'] as FormGroup;
	}
}
