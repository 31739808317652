<section class="p-2 editable" [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="mb-4 flex h-8 justify-start items-center">
		<span class="font-medium text-xl mr-2">{{ transitTime.state | uppercase }} Details</span>
		<button mat-icon-button (click)="form.enable()" [disabled]="form.enabled">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
	<div class="pt-4">
		<div class="w-full flex gap-4">
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>State</mat-label>
				<input matInput formControlName="state" />
			</mat-form-field>
			<mat-form-field class="w-1/2" (click)="form.enable()" tabindex="0">
				<mat-label>Days</mat-label>
				<input matInput formControlName="days" />
			</mat-form-field>
		</div>
	</div>
	<div class="flex justify-end">
		<button
			*ngIf="form.enabled"
			[disabled]="form.invalid"
			mat-flat-button
			color="primary"
			type="button"
			(click)="form.dirty ? onSubmit() : form.disable()"
			class="mt-2"
		>
			Save
		</button>
	</div>
</section>
