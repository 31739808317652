<div class="h-full flex tab-group">
	<div class="flex flex-col w-44 h-auto bg-gray-100 tab-header text-center pt-2 min-w-[11rem]">
		<div class="text-xl font-medium flex items-center justify-center flex-wrap">
			{{ project?.clientId | titlecase }}
			<mat-icon class="cursor-pointer ml-1 mb-1" (click)="changeClient()" [ngClass]="{invisible: hasQuotesOrPastDraftingOrInRFQ}"
				>edit</mat-icon
			>
		</div>
		<div>{{ project?.name }}</div>
		<!--div class="mb-4 halt-group">
			<button mat-button [ngClass]="project?.halted ? 'halted' : ''" class="w-4/5 !h-6" (click)="haltProject()">
				<mat-icon [ngClass]="project?.halted ? 'text-white' : 'text-gray-400'">warning</mat-icon>
				<span class="text-gray-400" *ngIf="!project?.halted">HALT</span>
				<span class="text-white" *ngIf="project?.halted">HALTED</span>
			</button>
			<div *ngIf="project?.halted">
				<label class="font-medium">Reason:</label>
				<span class="block">{{ project?.haltComment }}</span>
			</div>
		</div-->
		<div
			(click)="openStatusModal()"
			class="cursor-pointer flex flex-col status-indicator rounded-md self-center p-3 mb-4 text-gray-700 w-4/5 justify-center items-center bg-primary/10 px-2"
		>
			<span class="text-lg font-medium">{{ stage?.description }}</span>
			<span class="text-xs">{{ project?.workflowStatus?.description }}</span>
		</div>

		<div class="flex flex-col h-3/4">
			<button class="menu-item" [routerLink]="'overview'" [ngClass]="{active: activeTab === 'overview'}">Overview</button>
			<button class="menu-item" [routerLink]="'drafting'" [ngClass]="{active: activeTab === 'drafting'}">Drafting Documents</button>
			<button class="menu-item" [routerLink]="'quote'" [ngClass]="{active: activeTab === 'quote'}">Quotes</button>
			<button class="menu-item" [routerLink]="'estimate'" [ngClass]="{active: activeTab === 'estimate'}" *ngIf="showLink()">Hours</button>
			<button class="menu-item" [routerLink]="'scheduling'" [ngClass]="{active: activeTab === 'scheduling'}" *ngIf="showLink()">
				Scheduling
			</button>
			<button class="menu-item" [routerLink]="'picklist'" [ngClass]="{active: activeTab === 'picklist'}" *ngIf="showLink()">
				Picklist
			</button>
			<button class="menu-item" [routerLink]="'freight'" [ngClass]="{active: activeTab === 'freight'}" *ngIf="showLink()">Freight</button>
			<button class="menu-item" [routerLink]="'activity'" [ngClass]="{active: activeTab === 'activity'}">Activity</button>
		</div>
	</div>
	<div>
		<router-outlet></router-outlet>
	</div>
</div>
