<mat-expansion-panel [formGroup]="form">
	<mat-expansion-panel-header> Drafters Notes </mat-expansion-panel-header>
	<mat-form-field class="w-full" appearance="outline">
		<mat-label>Notes</mat-label>
		<textarea matInput formControlName="note"></textarea>
		<mat-error *ngIf="form.get('note')?.hasError('maxlength')">
			{{ this.form.get('note')?.value.length }} / 500 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>
</mat-expansion-panel>
