import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {FreightBroker} from '../../../../core/models/freight-broker.interface';
import {Contact} from '../../../../core/models/contact.interface';
import {ContactService} from '../../../../core/services/contact.service';
import {SnackbarActionEnum} from '../../../../core/enums/snackbar-action.enum';
import {ContactInfoComponent} from '../contact-info/contact-info.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ContactTypeEnum} from '../../../../core/enums/contact-type.enum';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-associated-contacts',
	templateUrl: './associated-contacts.component.html',
	styleUrls: ['./associated-contacts.component.scss']
})
export class AssociatedContactsComponent implements OnChanges, OnDestroy {
	@Input() broker: FreightBroker;
	@Input() expanded: boolean;

	relatedContacts: Contact[] = [];
	contactSubscription: Subscription;
	loading: boolean = true;

	constructor(private contactService: ContactService, private dialog: MatDialog, private snackbar: MatSnackBar) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.expanded && !this.contactSubscription) {
			this.contactSubscription = this.contactService
				.findContactsByType(this.broker.brokerId!.toString(), ContactTypeEnum.BROKER)
				.subscribe({
					next: (contacts: Contact[]) => {
						this.relatedContacts = contacts;
						this.loading = false;
					},
					error: (error) => console.error(error)
				});
		}
	}

	removeContactAssociation(contact: Contact, index: number): void {
		this.contactService.deleteContactAssociation(contact.id!, this.broker.id!.toString(), ContactTypeEnum.BROKER).subscribe({
			next: () => {
				this.relatedContacts.splice(index);
			},
			error: (err: any) => {
				this.snackbar.open('Failed to delete contact', SnackbarActionEnum.ERROR);
				console.error(err);
			}
		});
	}

	openContactModal(editMode: boolean, contact?: Contact): void {
		const contactInfoDialog = this.dialog.open(ContactInfoComponent, {
			data: {
				contact: contact,
				editMode: editMode,
				refId: this.broker.brokerId,
				refType: ContactTypeEnum.BROKER
			}
		});

		contactInfoDialog.afterClosed().subscribe((result: Contact) => {
			if (!result) {
				return;
			}

			const contactIndex: number = this.relatedContacts.findIndex((contact: Contact) => contact.id === result.id);

			if (contactIndex !== -1) {
				this.relatedContacts[contactIndex] = result;
			} else {
				this.relatedContacts.push(result);
			}
		});
	}

	ngOnDestroy(): void {
		this.contactSubscription?.unsubscribe();
	}
}
