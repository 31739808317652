<div class="w-[75vw]">
	<div class="mt-2 ml-4">
		<mat-expansion-panel *ngIf="quotes.length && !isLoading">
			<mat-expansion-panel-header>
				<mat-panel-title></mat-panel-title>
				<mat-panel-description
					><strong>Change Request File Uploads</strong>
					<mat-icon class="ml-1 -translate-y-0.5 text-yellow">warning</mat-icon></mat-panel-description
				>
			</mat-expansion-panel-header>
			<div class="flex flex-wrap">
				<mat-card *ngFor="let quote of quotes" class="p-4 mt-2 ml-2">
					<mat-card-title-group>
						<mat-card-title><strong>Uploaded By:</strong> {{ quote.changeReqEmail }}</mat-card-title>
					</mat-card-title-group>

					<div class="mt-2 flex flex-col">
						<span
							><strong>In Response To Quote No:&nbsp;</strong>{{ quote.quoteNumber
							}}<span *ngIf="quote.version">{{ '-' + quote.version.split(' ')[1] }}</span></span
						>
						<span class="mt-2"><strong>Uploaded On:</strong> {{ quote.changeReqDate | date }}</span>

						<span class="mt-2"></span><strong>Comments:</strong>
						<span>{{ quote.changeReqDescription }}</span>
						<div class="flex space-x-2 mt-2">
							<span><strong>Files:</strong></span>
							<div class="flex flex-col">
								<a
									*ngFor="let document of quote.documents"
									class="cursor-pointer underline text-blue-500"
									(click)="downloadFile($event, document)"
								>
									{{ document.documentName }}
								</a>
							</div>
						</div>
					</div>
				</mat-card>
			</div>
		</mat-expansion-panel>
	</div>

	<div class="w-[75vw] mx-4 pr-4">
		<div *ngIf="project?.locations?.length">
			<app-project-file-upload
				*ngFor="let location of project.locations"
				[type]="location.type"
				[projectId]="project.id"
				[uploadedFiles]="project.documents"
				[fileChanges]="uploadedFiles"
				(fileAddEmitter)="handleFileAddEmit($event)"
				(fileRemoveEmitter)="handleFileRemoveEmit($event)"
				(documentDtoEmitter)="handleDocumentDtoEmit($event)"
			></app-project-file-upload>
		</div>

		<div *ngIf="!isLoading && !project?.locations?.length" class="text-center">
			<h2>No Install Locations Selected!</h2>
			<p>To upload drafting files, first select an install location on the 'Overview' tab.</p>
		</div>
		<div class="w-full flex justify-end items-end">
			<button (click)="updateProjectDocs()" class="mt-2" mat-flat-button color="primary" [disabled]="!files.length">SAVE</button>
		</div>
	</div>
	<div id="spinner-container" *ngIf="isLoading">
		<mat-spinner></mat-spinner>
	</div>
</div>
