import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Project, ProjectUpdate} from '../core/models/project.interface';
import {ProjectsService} from '../core/services/projects.service';
import {MatTabGroup} from '@angular/material/tabs';
import {ProjectDraftingComponent} from './project-drafting/project-drafting.component';
import {ProjectStopModalComponent} from './project-stop-modal/project-stop-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import {SnackbarActionEnum} from '../core/enums/snackbar-action.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {QuickStatusEditComponent} from '../projects/project-quick-status/project-quick-status-edit/quick-status-edit.component';
import {combineLatestWith, Subscription, take} from 'rxjs';
import {Status} from '../core/models/status.interface';
import {StatusService} from '../core/services/status.service';
import {ClientSearchComponent} from '../project-create/client-search/client-search.component';
import {Client} from '../core/models/client.interface';
import {Quote} from '../core/models/quote.interface';

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {
	@ViewChild(ProjectDraftingComponent) draftingComponent: ProjectDraftingComponent;
	@ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
	project: Project | undefined;
	projectId: number;
	projectSubscription: Subscription;
	form: FormGroup;
	existingEstimate: boolean = false;
	freightLink: string;
	currentTabIndex = 0;
	activeTab = 'overview';
	statuses: Status[] = [];
	stage: Status | undefined;
	hasQuotesOrPastDraftingOrInRFQ: boolean = false;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private projectsService: ProjectsService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private router: Router,
		private statusService: StatusService
	) {}

	ngOnInit() {
		this.getCurrentActiveTab();

		this.route.params.subscribe((params) => {
			this.projectId = params['projectId'];
			this.projectsService.setProject(this.projectId);
		});

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.getCurrentActiveTab();
			}
		});

		this.projectSubscription = this.projectsService.project
			.pipe(combineLatestWith(this.statusService.statuses))
			.subscribe(([project, statuses]) => {
				if (project) {
					this.project = project;
					this.setHasQuotesOrPastDraftingOrInRFQ();
				}
				if (project && statuses) {
					this.stage = this.statuses.find((status) => status.id === this.project?.workflowStatus.subParentId)!;
				}
				this.statuses = statuses;
			});
	}

	ngOnDestroy(): void {
		this.projectSubscription?.unsubscribe();
	}

	getCurrentActiveTab() {
		const route = this.router.url;

		switch (true) {
			case route.includes('freight'):
				this.activeTab = 'freight';
				break;
			case route.includes('overview'):
				this.activeTab = 'overview';
				break;
			case route.includes('quote'):
				this.activeTab = 'quote';
				break;
			case route.includes('activity'):
				this.activeTab = 'activity';
				break;
			case route.includes('drafting'):
				this.activeTab = 'drafting';
				break;
			case route.includes('create-order'):
				this.activeTab = 'create-order';
				break;
			case route.includes('estimate'):
				this.activeTab = 'estimate';
				break;
			case route.includes('scheduling'):
				this.activeTab = 'scheduling';
				break;
			case route.includes('picklist'):
				this.activeTab = 'picklist';
				break;
			default:
				this.activeTab = 'overview';
		}
	}

	getPillBackgroundColor(createdTs: Date): string {
		return 'bg-lightGreen';
	}

	mergeAndUpdateProject() {
		const mergedProject = {...this.project, ...this.form.getRawValue()};
		this.updateProject(mergedProject);
	}

	updateProject(project: ProjectUpdate): void {
		this.projectsService.updateOnly(project).subscribe(
			(response: Project): void => {
				this.project = response;
			},
			(error): void => {
				this.displayErrorSnackBar();
				console.error(error);
			}
		);
	}

	haltProject(): void {
		//If project is already halted, show confirmation modal to un-halt
		if (this.project?.halted) {
			const confirm = this.dialog.open(ConfirmationDialogComponent, {
				data: {
					title: 'Resume Project',
					message: 'Are you sure you would like to resume this project?'
				}
			});
			confirm.afterClosed().subscribe((result) => {
				if (result) {
					this.updateProject({id: this.project?.id, haltComment: '', halted: false});
				}
			});
		} else {
			//If we're HALTING then display modal to enter in comment
			//Open Stop Project Modal
			const haltDialogRef = this.dialog.open(ProjectStopModalComponent, {
				width: '500px'
			});

			haltDialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.updateProject({id: this.project?.id, haltComment: result.reason, halted: true});
				}
			});
		}
	}

	displayErrorSnackBar() {
		this.snackBar.open('Error Updating Project', SnackbarActionEnum.ERROR);
	}

	openStatusModal() {
		const dialogRef = this.dialog.open(QuickStatusEditComponent, {
			width: '300px',
			disableClose: true
		});
		dialogRef.componentInstance.currentStatusId = this.project?.workflowStatus.id ?? 0;
		dialogRef.componentInstance.currentDepartmentId = this.stage?.id ?? 0;
		dialogRef.componentInstance.allStatuses = this.statuses;

		//Listen for the update event which will
		dialogRef.componentInstance.updatesStatusId.subscribe((result) => {
			const project = <Project>this.project;
			project.workflowStatus.id = result;
			//Send only the new status ID.
			this.projectsService.update({id: project.id, workflowStatus: project.workflowStatus}, false, false);
			dialogRef.componentInstance.closeModal();
		});
	}

	setHasQuotesOrPastDraftingOrInRFQ(): void {
		let existingQuotes: Quote[] = [];
		this.projectsService
			.findQuotesAssociatedWithProject(this.projectId)
			.pipe(take(1))
			.subscribe((response) => {
				existingQuotes = response;
				const statuses = this.statusService.statusDataSource.value;
				const draftingStage = statuses.find((status) => status.description === 'Drafting');
				const inDrafting = draftingStage!.statuses.filter((status) => status.id == this.project?.workflowStatus.id).length > 0;
				const inRFQ = this.project?.workflowStatus.description === 'RFQ';
				this.hasQuotesOrPastDraftingOrInRFQ = !!existingQuotes.length || (!inDrafting && !inRFQ);
			});
	}

	changeClient() {
		const dialogRef = this.dialog.open(ClientSearchComponent, {
			width: '800px'
		});
		dialogRef.componentInstance.modalMode = true;
		dialogRef.componentInstance.modalClientEmitter.subscribe((result: Client) => {
			if (this.project) {
				this.project.clientId = result.id;
				this.project.locations = this.project.locations.map((location: any) => location.type);
				this.projectsService.update(this.project, false, false);
			}
		});
	}

	showLink(): boolean {
		return !!(this.stage?.status && this.stage.status >= 300) || !!this.project?.orderNo;
	}
}
