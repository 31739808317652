import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {TransitTimeService} from '../../../core/services/transit-time.service';
import {CreateTransitTimeComponent} from '../create-transit-time/create-transit-time.component';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
	selector: 'app-transit-time-list',
	templateUrl: './transit-time-list.component.html',
	styleUrls: ['./transit-time-list.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed, void', style({height: '0px', minHeight: '0'})),
			state('expanded', style({height: '*'})),
			transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
			transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
		])
	]
})
export class TransitTimeListComponent implements OnInit {
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.dataSource.sort = this.sort;
	}
	sort: MatSort;
	dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
	columnsToDisplay: string[] = ['state', 'days'];
	isLoadingTimes: boolean = true;

	constructor(private transitTimeService: TransitTimeService, private dialog: MatDialog) {}
	ngOnInit() {
		this.dataSource = new MatTableDataSource();
		this.transitTimeService.findAll();
		this.transitTimeService.transitTimeSubject.subscribe((response) => {
			// Store the expanded state of each row before updating the data source
			const expandedStates: boolean[] = [];
			for (let i = 0; i < this.dataSource.data.length; i++) {
				expandedStates[i] = this.dataSource.data[i].expanded;
			}
			// Update the data source with new data
			for (const updatedRow of response) {
				const index = this.dataSource.data.findIndex((row) => row.id === updatedRow.id);
				if (index !== -1) {
					// Preserve the expanded state of the row before updating it
					updatedRow.expanded = this.dataSource.data[index].expanded;
					this.dataSource.data[index] = updatedRow;
				} else {
					// Add the new row to the data source
					this.dataSource.data.push(updatedRow);
				}
			}
			// Restore the expanded state of each row after updating the data source
			for (let i = 0; i < this.dataSource.data.length; i++) {
				this.dataSource.data[i].expanded = expandedStates[i];
			}
			// Refresh the data source to update the table
			this.dataSource.data = [...this.dataSource.data];
			this.isLoadingTimes = false;
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreateTransitTimeModal() {
		const dialogRef = this.dialog.open(CreateTransitTimeComponent, {
			width: '70vw',
			maxWidth: '800px',
			disableClose: true
		});
	}
}
