<div class="h-12 w-full flex mt-2 toolbar relative pb-2">
	<img src="/assets/mmi-logo.jpg" alt="MMI Logo" class="h-12" />
	<div
		(click)="navigate(data)"
		*ngFor="let data of navOptions"
		class="ml-4 flex items-center hover:bg-gray-100 hover:rounded-lg ml-4 p-2 cursor-pointer"
	>
		<div class="flex items-center">
			<span class="w-max text-md md:text-lg">{{ data.text }}</span>
		</div>
	</div>
	<div class="w-full"></div>
	<app-notifications></app-notifications>

	<button mat-icon-button [matMenuTriggerFor]="adminOptions">
		<mat-icon> settings</mat-icon>
	</button>
	<mat-menu #adminOptions="matMenu">
		<button *ngIf="isAdmin()" routerLink="/users" mat-menu-item>Users</button>
		<button *ngIf="isAdmin()" routerLink="/codes" mat-menu-item>Codes</button>
		<button *ngIf="isAdmin()" routerLink="/roles" mat-menu-item>Roles</button>
		<button *ngIf="isAdmin()" routerLink="/statuses" mat-menu-item>Statuses</button>
		<button *ngIf="isAdmin()" routerLink="/gl-codes" mat-menu-item>GL Codes</button>
		<button *ngIf="isAdmin()" disabled routerLink="/transit-times" mat-menu-item>Transit Times</button>
		<button *ngIf="isAdmin()" routerLink="/price-level" mat-menu-item>Price Level</button>
		<button *ngIf="isAdmin()" routerLink="/price-configuration" mat-menu-item>Price Configuration</button>
		<button *ngIf="isAdmin()" routerLink="/task-types" mat-menu-item>Task Types</button>
		<button *ngIf="isAdmin()" disabled mat-menu-item>Clients</button>
		<button *ngIf="isAdmin()" disabled mat-menu-item>Contact</button>
		<button *ngIf="isAdmin()" disabled mat-menu-item>Parts</button>
		<button *ngIf="isAdmin()" disabled mat-menu-item>Install Locations</button>
		<button *ngIf="isAdmin()" disabled mat-menu-item>Carriers</button>
		<button *ngIf="isAdmin()" disabled mat-menu-item>Accessorials</button>
		<button *ngIf="isAdmin()" disabled mat-menu-item>Handling Units</button>
		<button *ngIf="!isAdmin()" disabled mat-menu-item>No options available...</button>
	</mat-menu>

	<button mat-icon-button [matMenuTriggerFor]="userOptions" class="mr-4">
		<div
			class="h-8 w-8 relative -top-1 -left-1 text-white text-sm items-center justify-center flex rounded-[50%]"
			[style.background-color]="getColor()"
		>
			{{ getInitials() }}
		</div>
	</button>
	<mat-menu #userOptions="matMenu">
		<button (click)="logout()" mat-menu-item>Logout</button>
	</mat-menu>
</div>
