<div class="p-2 flex justify-center">
	<mat-form-field class="w-1/2" color="primary" subscriptSizing="dynamic">
		<mat-label>Search</mat-label>
		<input
			id="user-searchbar"
			type="text"
			(keyup)="applyFilter($event)"
			placeholder="Search by name, email or phone number."
			matInput
			#searchInput
		/>
	</mat-form-field>
	<button mat-mini-fab color="primary" class="absolute left-0 top-0 mt-2 ml-2" (click)="openCreateTaskTypeModal()">
		<mat-icon>add</mat-icon>
	</button>
</div>

<div class="w-3/4 h-[80vh] border border-gray-700 border-2 rounded h-full overflow-auto m-auto">
	<table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-full" matSort *ngIf="!isLoading">
		<!--Name Column-->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
			<td mat-cell *matCellDef="let task">
				{{ task.name }}
			</td>
		</ng-container>

		<!--GL List Column-->
		<!--    <ng-container matColumnDef="glList">-->
		<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by GL Number">-->
		<!--        GL List-->
		<!--      </th>-->
		<!--      <td mat-cell *matCellDef="let task">-->
		<!--        {{ task.glList ? task.glList : 'N/A/' }}-->
		<!--      </td>-->
		<!--    </ng-container>-->

		<!--Cat List Column-->
		<!--    <ng-container matColumnDef="catList">-->
		<!--      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Category Type">-->
		<!--        Cat List-->
		<!--      </th>-->
		<!--      <td mat-cell *matCellDef="let task">-->
		<!--        {{ task.catList ? (task.catList | uppercase) : 'N/A'}}-->
		<!--      </td>-->
		<!--    </ng-container>-->

		<!--Multiplier Column-->
		<ng-container matColumnDef="multiplier">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Multiplier">Multiplier</th>
			<td mat-cell *matCellDef="let task">
				{{ task.multiplier }}
			</td>
		</ng-container>

		<!--List Type Column-->
		<ng-container matColumnDef="listType">
			<th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by List Type">List Type</th>
			<td mat-cell *matCellDef="let task">
				{{ task.listType }}
			</td>
		</ng-container>

		<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns. -->
		<ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let task" [attr.colspan]="columnsToDisplay.length">
				<div class="overflow-hidden flex" [@detailExpand]="task.expanded ? 'expanded' : 'collapsed'">
					<app-task-type-list-detail [task]="task" class="w-full"></app-task-type-list-detail>
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
		<tr
			mat-row
			*matRowDef="let task; let i = dataIndex; columns: columnsToDisplay"
			class="task-row cursor-pointer"
			(click)="task.expanded = !task.expanded"
		></tr>
		<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row flex" *matNoDataRow>
			<td class="mat-cell p-4" colspan="4" *ngIf="!isLoading">No data matching the filter "{{ searchInput.value }}"</td>
		</tr>
	</table>
	<div class="h-full w-full flex justify-center align-middle items-center" *ngIf="isLoading">
		<mat-spinner></mat-spinner>
	</div>
</div>
