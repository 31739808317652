import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {GlCodeService} from '../../../core/services/gl-code.service';
import {GlCode} from '../../../core/models/gl-code.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../core/enums/snackbar-action.enum';
import {CodeService} from '../../../core/services/code.service';
import {CodeTypeEnum} from '../../../core/enums/code-type.enum';
import {Code} from '../../../core/models/code.interface';
import {combineLatestWith} from 'rxjs';
import {QuoteLine} from '../../../core/models/quote-line.interface';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CostAdjustmentDialogComponent} from './cost-adjustment-dialog/cost-adjustment-dialog.component';
import {CommaRemovalPipe} from '../../../shared/pipes/comma-removal.pipe';
import {QuoteLineCategoryEnum} from '../../../core/enums/quote-line-category.enum';

@Component({
	selector: 'app-cost-adjustments',
	templateUrl: './cost-adjustments.component.html',
	styleUrls: ['./cost-adjustments.component.scss']
})
export class CostAdjustmentsComponent implements OnInit {
	@Input() quoteForm: FormGroup;
	@Input() costAdjustments: QuoteLine[] = [];
	@Input() ngForm: FormGroupDirective;

	@Input() costAdjustmentTotal: number;
	@Output() costAdjustmentTotalChange: EventEmitter<number> = new EventEmitter<number>();

	glCodes: GlCode[] = [];
	getBackCategories: Code[] = [];
	loading: boolean = true;

	constructor(
		private fb: FormBuilder,
		private glCodeService: GlCodeService,
		private codeService: CodeService,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private format: CommaRemovalPipe
	) {}

	ngOnInit(): void {
		this.glCodeService
			.findAll()
			.pipe(combineLatestWith(this.codeService.findByType(CodeTypeEnum.GET_BACK_TYPE)))
			.subscribe({
				next: ([glCodes, getBackCategories]): void => {
					this.glCodes = glCodes;
					this.getBackCategories = getBackCategories;
					this.loading = false;
				},
				error: (err: any): void => {
					console.error(err);
					this.snackBar.open(
						this.glCodes.length === 0 ? 'Error loading Gl Codes' : 'Error loading Get Back Categories',
						SnackbarActionEnum.ERROR
					);
				}
			});
		this.buildCostAdjustmentFormArray();
	}

	calculateTotal(): void {
		const values: QuoteLine[] = this.quoteForm.get('getBacks')?.value;
		let total: number = 0;
		values.forEach((value: QuoteLine): void => {
			if (value.price) {
				const valueWithCommaRemoved: string = this.format.transform(value.price.toString(), true);
				total += parseFloat(valueWithCommaRemoved);
			}
		});
		this.costAdjustmentTotal = total;
		this.costAdjustmentTotalChange.emit(total);
	}

	buildCostAdjustmentFormArray(): void {
		if (this.costAdjustments.length) {
			this.costAdjustments.forEach((cost: QuoteLine): void => {
				const group: FormGroup = this.buildFormGroup(cost);
				this.getBacks.push(group);
			});
		}
		this.subscribeToChanges();
	}

	subscribeToChanges(): void {
		this.quoteForm.get('getBacks')?.valueChanges.subscribe({
			next: (): void => {
				this.calculateTotal();
			},
			error: (error): void => {
				console.error(error);
			}
		});
	}

	buildFormGroup(getBack: QuoteLine): FormGroup {
		return this.fb.group({
			glNumber: [getBack.glNumber, Validators.required],
			description: [getBack.description],
			price: [getBack.price?.toFixed(2) ?? '0.00', Validators.required],
			category: [QuoteLineCategoryEnum.GET_BACK],
			comment: [getBack.comment],
			type: [getBack.type]
		});
	}

	openCostAdjustmentModal(editMode: boolean, getBack: QuoteLine, index?: number, formGroup?: AbstractControl<QuoteLine>): void {
		const options: QuoteLine[] = this.assembleGetBackOptions();
		const costAdjustmentDialog: MatDialogRef<CostAdjustmentDialogComponent> = this.dialog.open(CostAdjustmentDialogComponent, {
			data: {
				getBackOptions: options,
				getBackCategories: this.getBackCategories,
				formGroup: formGroup ?? this.buildFormGroup(getBack),
				editMode: editMode
			},
			width: '35vw'
		});

		costAdjustmentDialog.afterClosed().subscribe({
			next: (costAdjustmentFormGroup: FormGroup): void => {
				if (costAdjustmentFormGroup) {
					if (!editMode) {
						this.getBacks.push(costAdjustmentFormGroup);
					} else {
						this.getBacks.at(index!).setValue(costAdjustmentFormGroup.value);
					}
				}
				this.quoteForm.markAsDirty();
			}
		});
	}

	assembleGetBackOptions(): QuoteLine[] {
		const getBackOptions: QuoteLine[] = [];
		const getBackArray = this.quoteForm.get('getBacks')?.value;
		this.addGetBackOption(getBackOptions, getBackArray, 'freightOption');
		this.addGetBackOption(getBackOptions, getBackArray, 'wireGridLineItem');
		this.addGetBackOption(getBackOptions, getBackArray, 'materialCost');
		this.addGetBackOption(getBackOptions, getBackArray, 'particleBoardLineItem');
		this.addGetBackOption(getBackOptions, getBackArray, 'steelPerfLineItem');
		this.addGetBackOption(getBackOptions, getBackArray, 'rackSolidLineItem');
		this.addGetBackOption(getBackOptions, getBackArray, 'installLineItem');
		const array: QuoteLine[] = this.quoteForm.get('associatedCosts')?.value;
		array.forEach((cost: QuoteLine): void => {
			if (cost.price !== 0 && !cost.description?.startsWith('Third')) {
				const found = getBackArray.find((line: QuoteLine) => line.description === cost.description);
				if (!found) {
					getBackOptions.push(cost);
				}
			}
		});

		return getBackOptions;
	}

	addGetBackOption(getBackOptions: QuoteLine[], getBackArray: QuoteLine[], formControlName: string): void {
		if (
			this.quoteForm.get(formControlName)?.get('price')?.value &&
			this.quoteForm.get(formControlName)?.get('price')?.value !== '0.00' &&
			this.quoteForm.get(formControlName)?.get('price')?.value !== 0
		) {
			const found: QuoteLine | undefined = getBackArray.find(
				(line: QuoteLine): boolean => line.description === this.quoteForm.get(formControlName)?.get('description')?.value
			);
			if (!found) {
				getBackOptions.push(this.quoteForm.get(formControlName)?.value);
			}
		}
	}

	removeCostAdjustment(index: number): void {
		this.getBacks.removeAt(index);
	}

	get getBacks(): FormArray {
		return this.quoteForm.controls['getBacks'] as FormArray;
	}
}
