import {Component, OnInit} from '@angular/core';
import {ProjectsService} from '../../core/services/projects.service';
import {ProjectActivity} from '../../core/models/project-activity.interface';
import {ActivatedRoute, Params} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../core/services/user.service';
import {User} from '../../core/models/user.interface';
import {format} from 'date-fns';

@Component({
	selector: 'app-project-activity',
	templateUrl: './project-activity.component.html',
	styleUrls: ['./project-activity.component.scss']
})
export class ProjectActivityComponent implements OnInit {
	projectActivities: ProjectActivity[] = [];
	projectId: number;

	constructor(
		private projectService: ProjectsService,
		private route: ActivatedRoute,
		private snackbar: MatSnackBar,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.route.params.subscribe((params: Params) => {
			if (params['projectId']) {
				this.projectId = params['projectId'];
				this.projectService.getProjectActivities(this.projectId).subscribe({
					next: (activities) => {
						this.projectActivities = activities.sort(
							(a: ProjectActivity, b: ProjectActivity) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
						);
						this.projectActivities.forEach((activity) => {
							activity.userInitial = activity.user
								? activity.user.firstName.charAt(0).toUpperCase() + activity.user.lastName.charAt(0).toUpperCase()
								: '?';
							const date = new Date(activity.dateTime);
							const utcDate: Date = new Date(
								Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
							);
							activity.dateTimeFormatted = `on ${format(utcDate, 'M/dd/yyyy')} at ${format(utcDate, 'h:mm:ss a')}`;
						});
					},
					error: (error) => {
						this.snackbar.open('Failed to load Project Activities: ' + error.error.message);
					}
				});
			}
		});
	}

	getColor(user: User): string {
		const userFullName = user?.firstName + ' ' + user?.lastName;
		return this.userService.getUserColor(userFullName);
	}
}
