import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Accessorial} from '../../../core/models/accessorial.interface';
import {ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {AccessorialService} from '../../../core/services/accessorials.service';
import {map, Observable, startWith} from 'rxjs';
import {AccessorialsComponentDisplayModesEnum} from '../../../core/enums/accessorials-component.enum';

@Component({
	selector: 'app-accessorials',
	templateUrl: './accessorials.component.html',
	styleUrls: ['./accessorials.component.scss'],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: FormGroupDirective
		}
	]
})
export class AccessorialsComponent implements OnInit, OnChanges {
	@Input() selectedAccessorialArray: Accessorial[] = [];
	@Input() submitted: boolean = false;

	@Input() quoteId: number;
	@Input() isNestedForm: boolean = false;
	@Input() componentFormArrayName: string = '';
	@Input() componentFormGroupName: string = '';
	@Input() displayMode: AccessorialsComponentDisplayModesEnum = AccessorialsComponentDisplayModesEnum.CHIP;
	@Output() addAccessorialEvent = new EventEmitter<Accessorial>();

	AccessorialsComponentDisplayModes = AccessorialsComponentDisplayModesEnum;

	parentForm: FormGroup = new FormGroup({});
	optionSelected: boolean = false;

	accessorialControl = new FormControl<string | Accessorial>('');
	accessorialOptions: Accessorial[] = [];
	filteredAccessorials: Observable<Accessorial[]> = new Observable<Accessorial[]>();

	constructor(
		public controlContainer: ControlContainer,
		private formBuilder: FormBuilder,
		private accessorialService: AccessorialService
	) {}

	ngOnInit(): void {
		this.parentForm = this.controlContainer.control as FormGroup;

		if (this.isNestedForm) {
			this.selectedAccessorialArray.forEach((accessorial) => {
				this.accessorials.push(new FormControl(accessorial));
			});
		}

		if (this.submitted) {
			this.accessorialControl.disable();
		}

		this.accessorialService.accessorials.subscribe({
			next: (accessorials) => {
				this.accessorialOptions = accessorials;
				this.autoCompleteFilter();
			},
			error: (err) => {
				console.error(err);
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['selectedAccessorialArray'] && !changes['selectedAccessorialArray'].firstChange) {
			this.accessorials.clear();
			changes['selectedAccessorialArray'].currentValue.forEach((accessorial: Accessorial) => {
				this.accessorials.push(new FormControl(accessorial));
			});
			this.accessorialControl.setValue('');
		}
	}

	autoCompleteFilter(): void {
		this.filteredAccessorials = this.accessorialControl.valueChanges.pipe(
			startWith(''),
			map((value) => this.accessorialFilter(value))
		);
	}

	displayFn(accessorial: Accessorial): string {
		return accessorial && accessorial.name ? accessorial.name : '';
	}

	get accessorials() {
		return this.isNestedForm
			? ((this.parentForm.controls[this.componentFormGroupName] as FormGroup).controls[this.componentFormArrayName] as FormArray)
			: (this.parentForm.controls[this.componentFormArrayName] as FormArray);
	}

	accessorialFilter(value: any): Accessorial[] {
		const filterValue = value === null || value instanceof Object ? '' : value.toLowerCase();

		const matches = this.accessorialOptions.filter(
			(accessorial: Accessorial) => filterValue === '' || accessorial.name?.toLowerCase().includes(filterValue)
		);

		const formValue = this.accessorials.value;

		return formValue === null
			? matches
			: matches.filter(
					(accessorialOption) =>
						!formValue.find((selectedAccessorial: Accessorial) => selectedAccessorial.accessorialId === accessorialOption.id)
			  );
	}

	onSelection(accessorial: Accessorial): void {
		if (accessorial && !this.selectedAccessorialArray.includes(accessorial)) {
			this.selectedAccessorialArray.push(accessorial);
			if (this.isNestedForm) {
				this.accessorials.push(new FormControl(accessorial));
			} else {
				this.accessorials.push(
					new FormControl({
						name: accessorial.name,
						accessorialId: accessorial.id,
						quoteId: this.quoteId
					})
				);
			}

			this.accessorialControl.setValue('');
		}
		this.autoCompleteFilter();
		this.addAccessorialEvent.emit(accessorial);

		this.parentForm.markAsDirty();
	}

	remove(selected: Accessorial): void {
		const index = this.selectedAccessorialArray.indexOf(selected);

		if (index >= 0) {
			this.selectedAccessorialArray.splice(index, 1);
			this.accessorials.removeAt(index);
			this.accessorialControl.setValue('');
		}

		this.parentForm.markAsDirty();
	}
}
