export enum ActivityTypeEnum {
	STATUS_CHANGE = 'STATUSCHANGE',
	QUOTE = 'QUOTE',
	SALES_ORDER = 'SALES_ORDER',
	PROJECT = 'PROJECT',
	FREIGHT_RATE = 'FREIGHT_RATE',
	FREIGHT_BID = 'FREIGHT_BID',
	SHIPMENT = 'SHIPMENT',
	DRAFTING = 'DRAFTING'
}
