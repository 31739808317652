export enum CodeTypeEnum {
	GET_BACK_TYPE = 'GETBACKTYPE',
	EVENT_TYPE = 'EVENTTYPE',
	NOTIFY_TYPE = 'NOTIFYTYPE',
	PRICE_FACTOR = 'PRICEFACTR',
	GL_CODE_TYPE = 'GLCODETYPE',
	PROJECT_INACTIVE_REASON = 'PRJINACTREAS',
	SHIPPING_ADDITIONAL_COST_REASON = 'SHPADTLCOSTREAS',
	CHANGE_REQUEST_REASONS = 'CHANGEREQUESTREASONS',
	TAX_JURISDICTION = 'TAXJURISDICTION'
}
