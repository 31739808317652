<div class="mt-2 mb-2 no-break">
	<mat-accordion>
		<mat-expansion-panel expanded="true">
			<mat-expansion-panel-header>
				<mat-panel-title class="font-bold" *ngIf="configForm.get('name')?.valid">
					Shelf Configuration{{ ' - ' + configForm.get('name')?.value }}</mat-panel-title
				>
				<mat-panel-title class="font-bold" *ngIf="!configForm.get('name')?.touched && !configForm.get('name')?.value">
					Shelf Configuration
				</mat-panel-title>
				<mat-panel-title class="font-bold text-red" *ngIf="configForm.get('name')?.touched && !configForm.get('name')?.value">
					Shelf Configuration*
				</mat-panel-title>
				<mat-label class="mr-4">Units: {{ totalUnits }}</mat-label>
				<div class="flex flex-row print:hidden">
					<mat-icon class="mr-4" (click)="$event.stopPropagation(); copyConfig()">file_copy</mat-icon>
					<mat-icon class="mr-4" (click)="deleteConfig()">delete</mat-icon>
				</div>
			</mat-expansion-panel-header>

			<form [formGroup]="configForm">
				<div class="flex flex-col w-full">
					<mat-form-field appearance="outline" class="w-1/5">
						<mat-label>Name</mat-label>
						<input type="text" matInput class="text-xs form-control" formControlName="name" />
						<mat-error *ngIf="configFormControl['name'].touched && configFormControl['name'].invalid">
							<span class="text-danger" *ngIf="configFormControl['name'].errors?.['required']"> Name is <strong>required</strong> </span>
						</mat-error>
					</mat-form-field>

					<div class="flex flex-row space-x-3 w-full">
						<mat-form-field appearance="outline" class="w-36">
							<mat-label>Height</mat-label>
							<input type="number" min="0" matInput formControlName="height" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Shelves</mat-label>
							<input type="number" min="0" matInput formControlName="shelves" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Type</mat-label>
							<mat-select placeholder="Type" formControlName="type">
								<mat-option *ngFor="let option of typeOptions" [value]="option.type">{{ option.name }}</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field appearance="outline" class="w-36">
							<mat-label>Levels to Split</mat-label>
							<input matInput type="number" min="0" formControlName="levelsToSplit" />
						</mat-form-field>

						<mat-form-field appearance="outline" class="w-36">
							<mat-label>Thick Levels</mat-label>
							<input matInput min="0" type="number" formControlName="thickLevels" />
						</mat-form-field>
					</div>

					<div class="flex flex-row space-x-3 w-full">
						<mat-form-field appearance="outline">
							<mat-label>Hang Rows - No Front</mat-label>
							<input type="number" min="0" matInput formControlName="hangNoFront" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Hang Rows - w/ Front</mat-label>
							<input type="number" min="0" matInput formControlName="hangFront" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>Hang Rows - w/ Shelf</mat-label>
							<input type="number" min="0" matInput formControlName="hangShelf" />
						</mat-form-field>

						<mat-form-field appearance="outline">
							<mat-label>12"-36" FTB DRH</mat-label>
							<input type="number" min="0" matInput formControlName="addFtbDrh" />
						</mat-form-field>
					</div>

					<mat-divider></mat-divider>

					<div class="mt-4" formArrayName="quoteConfigShelves">
						<div *ngFor="let shelfConfig of quoteConfigShelves.controls; let i = index">
							<div [formGroupName]="i" class="flex flex-row space-x-2 w-1/2">
								<mat-form-field appearance="outline" class="w-1/2">
									<mat-label>Dimensions</mat-label>
									<mat-select formControlName="partId">
										<mat-option *ngFor="let dimension of dimensions" [value]="dimension.id">{{ dimension.description }}</mat-option>
									</mat-select>
								</mat-form-field>

								<mat-form-field appearance="outline" class="w-1/4">
									<mat-label>Starters</mat-label>
									<input type="number" min="0" matInput formControlName="starters" />
								</mat-form-field>

								<mat-form-field appearance="outline" class="w-1/4">
									<mat-label>Adders</mat-label>
									<input type="number" min="0" matInput formControlName="adders" />
								</mat-form-field>
								<button class="mb-4 print:hidden">
									<mat-icon (click)="deleteShelfConfig(i)">delete</mat-icon>
								</button>
							</div>
						</div>
					</div>
					<div class="flex flex-row w-1/2 justify-end print:hidden">
						<button mat-mini-fab color="primary" class="mr-12" style="font-size: 24px" (click)="addShelfConfig()">+</button>
					</div>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>
</div>
