<h2 mat-dialog-title>Create Contact</h2>
<mat-dialog-content class="m-2" [formGroup]="form">
	<mat-form-field class="w-1/4 pt-2 pr-2">
		<mat-label>Name</mat-label>
		<input matInput formControlName="name" />
		<mat-error *ngIf="form.get('name')?.hasError('required')"> First name is required </mat-error>
		<mat-error *ngIf="form.get('name')?.hasError('maxlength')">
			{{ this.form.get('name')?.value.length }} / 100 Maximum Characters Allowed</mat-error
		>
	</mat-form-field>
	<mat-form-field class="w-1/2 pt-2">
		<mat-label>Email</mat-label>
		<input matInput formControlName="email" />
		<mat-error *ngIf="form.get('email')?.hasError('required')"> First name is required </mat-error>
		<mat-error *ngIf="form.get('email')?.hasError('maxlength')">
			{{ this.form.get('email')?.value.length }} / 254 Maximum Characters Allowed</mat-error
		>
		<mat-error *ngIf="form.get('email')?.hasError('pattern')"> Please enter a valid email address </mat-error>
	</mat-form-field>
	<div class="h-20">
		<mat-form-field class="pr-2">
			<mat-label>Phone No.</mat-label>
			<input matInput formControlName="phone" />
			<mat-error *ngIf="form.get('phone')?.hasError('maxlength')">
				{{ this.form.get('phone')?.value.length }} / 40 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
		<mat-form-field class="w-3/12">
			<mat-label>Ext</mat-label>
			<input matInput formControlName="phoneExt" />
			<mat-error *ngIf="form.get('phoneExt')?.hasError('maxlength')">
				{{ this.form.get('phoneExt')?.value.length }} / 10 Maximum Characters Allowed</mat-error
			>
		</mat-form-field>
	</div>
</mat-dialog-content>
<div class="flex justify-end p-4">
	<button mat-button mat-dialog-close="true" class="mr-2">Cancel</button>
	<button [disabled]="form.invalid" mat-raised-button color="primary" (click)="createContact()">Create</button>
</div>
