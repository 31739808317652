import {Component, EventEmitter, Input, Output} from '@angular/core';
import {map, Observable, startWith, Subject, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {PartData} from '../temporary-part-data/manualPartsData.interface';
import {QuoteLine} from '../../../../core/models/quote-line.interface';
import {PartService} from '../../../../core/services/part.service';
import {QuoteLineCategoryEnum} from '../../../../core/enums/quote-line-category.enum';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarActionEnum} from '../../../../core/enums/snackbar-action.enum';

@Component({
	selector: 'app-manual-parts-input',
	templateUrl: './manual-parts-input.component.html',
	styleUrls: ['./manual-parts-input.component.scss']
})
export class ManualPartsInputComponent {
	@Input() previouslySelectedParts: QuoteLine[] = [];
	@Input() filterMobilePartsSubject: Subject<boolean>;
	@Input() partDeletedSubject: Subject<string>;
	@Input() submitted: boolean;
	@Input() partsLoading: boolean;
	@Output() result: EventEmitter<PartData> = new EventEmitter<PartData>();

	partsSubscription: Subscription;
	rawPartsData: PartData[] = [];
	filteredData: Observable<PartData[]>;

	selectControl: FormControl<any> = new FormControl();
	filterString: string = '';

	constructor(private partService: PartService, private snackBar: MatSnackBar) {}

	async ngOnInit(): Promise<void> {
		if (this.submitted) {
			this.selectControl.disable();
		} else {
			this.partsSubscription = this.partService.parts.subscribe((parts: PartData[]): void => {
				this.rawPartsData = parts;
				this.buildOptions();
				this.startFilter(false);
			});

			this.partDeletedSubject.subscribe({
				next: (event: string): void => {
					let index: number = this.rawPartsData.findIndex((data) => data.part.item === event);
					this.rawPartsData[index].selected = false;
				},
				error: (err): void => {
					console.error(err);
					this.snackBar.open('Error deleting part', SnackbarActionEnum.ERROR);
				}
			});

			this.filterMobilePartsSubject.subscribe({
				next: (filterMobileParts: boolean): void => {
					this.startFilter(filterMobileParts, this.selectControl.value);
				},
				error: (err): void => {
					console.error(err);
					this.snackBar.open('Error filtering parts', SnackbarActionEnum.ERROR);
				}
			});
		}
	}

	startFilter(filterMobileParts: boolean, filterString?: string): void {
		this.filteredData = this.selectControl.valueChanges.pipe(
			startWith(filterString ? filterString : ''),
			map((value) => (typeof value === 'string' ? value : this.filterString)),
			map((filter) => this.filter(filter, filterMobileParts))
		);
	}

	buildOptions(): void {
		this.previouslySelectedParts.forEach((part: QuoteLine) => {
			const index: number = this.rawPartsData.findIndex((data: PartData) => part.erpItemRef === data.part.erpItemRef);

			if (index !== -1) {
				this.rawPartsData[index].selected = true;
			}
		});
	}

	ngOnDestroy(): void {
		this.partsSubscription?.unsubscribe();
		this.partDeletedSubject?.unsubscribe();
		this.filterMobilePartsSubject?.unsubscribe();
		this.result?.unsubscribe();
	}

	filter(filter: string, filterMobileParts: boolean): PartData[] {
		this.filterString = filter;
		if (filter.length > 0) {
			return this.rawPartsData.filter((option: PartData): boolean | undefined => {
				if (filterMobileParts) {
					if (
						option.part.item &&
						option.part.description &&
						(option.part.category === QuoteLineCategoryEnum.MOBILE_PART || option.selected)
					) {
						return (
							option.part.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
							option.part.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0
						);
					} else {
						return;
					}
				} else {
					if (option.part.item && option.part.description) {
						return (
							option.part.item.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
							option.part.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0
						);
					} else {
						return;
					}
				}
			});
		} else {
			return filterMobileParts
				? this.rawPartsData
						.filter((data: PartData): boolean => {
							return data.part.category === QuoteLineCategoryEnum.MOBILE_PART;
						})
						.slice()
				: this.rawPartsData.slice();
		}
	}

	displayFn(): string {
		return '';
	}

	optionClicked(event: Event, mobilePart: PartData): void {
		event.stopPropagation();
		this.toggleSelection(mobilePart);
	}

	toggleSelection(mobilePart: PartData): void {
		mobilePart.selected = !mobilePart.selected;
		this.result.emit(mobilePart);
	}

	onClicked(): void {
		if (!this.selectControl.value) {
			this.selectControl.setValue('');
		}
	}
}
