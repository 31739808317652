import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {Quote} from '../../../core/models/quote.interface';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {GlCode} from '../../../core/models/gl-code.interface';
import {GlCodeService} from '../../../core/services/gl-code.service';
import {GlCodeTypeEnum} from '../../../core/enums/gl-code-type.enum';

@Component({
	selector: 'app-freight-estimator',
	templateUrl: './freight-estimator.component.html',
	styleUrls: ['./freight-estimator.component.scss']
})
export class FreightEstimatorComponent implements OnInit {
	@Input() quote: Quote = {};
	@Input() submitted: boolean;
	@Input() ngForm: FormGroupDirective;
	@Input() thirdPartyFreight: boolean;
  @Input() quoteForm: FormGroup;
  @Output() thirdPartyFreightSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  glCodes: GlCode[];
	noFreightCostForm: FormGroup;

	constructor(
		private fb: FormBuilder,
		private decimalPipe: DecimalPipe,
		private currencyPipe: CurrencyPipe,
		private glCodeService: GlCodeService
	) {}

	ngOnInit(): void {
		this.glCodeService.findAll().subscribe((response: GlCode[]) => {
			this.glCodes = response.filter((code: GlCode) => code.type === GlCodeTypeEnum.FREIGHT);
		});

		this.noFreightCostForm = this.fb.group({
			noFreightCost: new FormControl(false)
		});

		if (this.submitted) {
			this.noFreightCostForm.get('noFreightCost')?.disable();
		}

		if (!this.quote.accessorials) {
			this.quote.accessorials = [];
		}

		this.noFreightCostForm.valueChanges.subscribe((value): void => {
			if (value.noFreightCost) {
				this.quoteForm.get('freightOption')?.get('price')?.setValue('0.00');
				this.quoteForm.get('freightOption')?.get('price')?.disable();
			}

			if (!value.noFreightCost) {
				this.quoteForm.get('freightOption')?.get('price')?.enable();
			}
		});
		if (this.thirdPartyFreight) {
			this.noFreightCostForm?.controls['noFreightCost'].setValue(this.thirdPartyFreight);
		}
	}

	setFreightCostValue(value: number) {
		this.quoteForm.get('freightOption')?.get('price')?.setValue(this.decimalPipe.transform(value, '.2'));
	}

	get freightCost(): string | null {
		const value: string = this.format(this.quoteForm.get('freightOption')?.get('price')?.value.toString());
		return this.currencyPipe.transform(parseFloat(value));
	}

	checkForTrailingZero(): void {
		const value: string = this.format(this.quoteForm.get('freightOption')?.get('price')?.value);
		this.setFreightCostValue(parseFloat(value));
	}

	format(input: string): string {
		return input !== null && input.includes(',') ? input.replaceAll(',', '') : input;
	}

  get freightOption(): FormGroup {
    return this.quoteForm.get('freightOption') as FormGroup;
  }

}
