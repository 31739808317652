import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectCreateComponent} from './project-create.component';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {ClientSearchComponent} from './client-search/client-search.component';
import {ProjectDetailsComponent} from './project-details/project-details.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {MatInputModule} from '@angular/material/input';
import {TeamMembersComponent} from './team-members/team-members.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {ProjectFileUploadComponent} from './project-file-upload/project-file-upload.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ProjectFileArchiveDialogComponent} from './project-file-upload/project-file-archive-dialog/project-file-archive-dialog.component';
import {DraftersNotesComponent} from './drafters-notes/drafters-notes.component';

@NgModule({
	declarations: [
		ProjectCreateComponent,
		TeamMembersComponent,
		ClientSearchComponent,
		ProjectDetailsComponent,
		ProjectFileUploadComponent,
		ProjectFileArchiveDialogComponent,
		DraftersNotesComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		FormsModule,
		MatCardModule,
		MatRadioModule,
		MatExpansionModule,
		MatIconModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatInputModule,
		MatDividerModule,
		MatProgressSpinnerModule,
		MatButtonModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatFormFieldModule,
		MatCheckboxModule,
		NgxDropzoneModule,
		MatTooltipModule,
		MatDialogModule,
		MatAutocompleteModule
	],
	exports: [ProjectDetailsComponent, TeamMembersComponent, ProjectFileUploadComponent, DraftersNotesComponent]
})
export class ProjectCreateModule {}
