import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductionDayHourService} from '../../../core/services/production-day-hour.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProductionDayHourOverride} from '../../../core/models/production-day-hour-override.model';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProductionHours} from '../../../core/models/production-hours.interface';
import {AppComponent} from '../../../app.component';
import {UserTypeEnum} from '../../../core/enums/user-type.enum';

export interface ProductionHourOverrideDialogData {
	date: string;
}
export interface ProductionHourOverrideDialogResponse {
	success: boolean;
	overrides: ProductionHours;
}

@Component({
	selector: 'app-production-hour-override-dialog',
	templateUrl: './production-hour-override-dialog.component.html',
	styleUrls: ['./production-hour-override-dialog.component.scss']
})
export class ProductionHourOverrideDialogComponent implements OnInit {
	overrideForm = new FormGroup({
		taskOverrides: this.fb.array([])
	});

	productionHoursOverrides: ProductionDayHourOverride[] = [];

	admin: boolean = false;

	loading = false;
	busy = false;

	constructor(
		@Inject(AppComponent) private parent: AppComponent,
		@Inject(MAT_DIALOG_DATA) public data: ProductionHourOverrideDialogData,
		private dialogRef: MatDialogRef<ProductionHourOverrideDialogComponent>,
		private productionDayHourService: ProductionDayHourService,
		private fb: FormBuilder,
		private _snackBar: MatSnackBar
	) {
		this.admin = !!(this.parent.user?.type && this.parent.user.type === UserTypeEnum.ADMIN);
		dialogRef.disableClose = true;
	}

	ngOnInit(): void {
		this.busy = true;
		this.loading = true;
		this.productionDayHourService.getOverride(this.data.date).subscribe({
			next: (overrides) => {
				this.productionHoursOverrides = overrides;
				this.taskOverrides.clear();
				overrides.forEach((override) => this.addOverride(override));
				this.busy = false;
				this.loading = false;
			},
			error: (err) => {
				this._snackBar.open('Get Production Hours Override failed: ' + err.error.message);
				this.busy = false;
				this.loading = false;
			}
		});
	}

	addOverride(override: ProductionDayHourOverride) {
		const newOverride = this.fb.group({
			id: [override.id],
			taskTypeId: [override.taskTypeId],
			date: [this.data.date],
			hours: [override.hours, [Validators.min(0)]],
			comment: [override.comment],
			name: [override.taskTypeDescription]
		});
		this.taskOverrides.push(newOverride);
	}

	saveOverrides() {
		let taskOverrides: ProductionDayHourOverride[] = [];
		Object.assign(taskOverrides, ...[this.taskOverrides.value]);
		this.busy = true;
		this.productionDayHourService.updateOverride(this.data.date, taskOverrides).subscribe({
			next: (overrides) => {
				const response: ProductionHourOverrideDialogResponse = {
					success: true,
					overrides: overrides
				};
				this.dialogRef.close(response);
			},
			error: (err) => {
				this._snackBar.open('Save Production Hours Override failed: ' + err.error.message);
				this.busy = false;
			}
		});
	}

	get taskOverrides(): FormArray {
		return this.overrideForm.controls.taskOverrides;
	}

	showMessage(override: ProductionDayHourOverride) {
		return override.id && override.hours === null;
	}
}
