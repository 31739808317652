<div class="mt-2" [hidden]="(maxFileLength == 1 && (uploadedFiles | archive : false)?.length) || (maxFileLength == 1 && files.length)">
	<mat-expansion-panel expanded>
		<mat-expansion-panel-header>
			<mat-panel-title> {{ type ? type.name : 'Choose a Location...' }} </mat-panel-title>
		</mat-expansion-panel-header>
		<div class="flex">
			<div class="flex flex-col">
				<div class="text-right" *ngIf="(uploadedFiles | archive : false).length">
					<button mat-icon-button (click)="openArchiveModal()" [matTooltip]="'Archive'"><mat-icon>archive</mat-icon></button>
				</div>
				<ngx-dropzone
					(change)="onFilesAdded($event)"
					[expandable]="true"
					[ngStyle]="{
						minHeight: '200px',
						height: 'fit-content',
						overflow: 'auto',
						alignItems: this.files.length || (this.uploadedFiles | archive : false)?.length ? 'start' : 'center'
					}"
				>
					<ngx-dropzone-label>
						<div class="bg-violet-300 p-2 circle">
							<mat-icon>upload_file</mat-icon>
						</div>
						<span class="text-violet-600 underline">Click to upload&nbsp;</span><span>or drag and drop.</span>
					</ngx-dropzone-label>
					<!--      File Previews-->
					<ngx-dropzone-preview
						*ngFor="let f of files"
						[file]="f"
						[removable]="true"
						(removed)="onRemove(f)"
						(click)="stopPropagation($event)"
						class="cursor-default border border-black relative"
					>
						<ngx-dropzone-label class="text-black"
							>{{ f.name }} ({{ f.type }})
							<span class="absolute bottom-0 right-1 italic text-red">New</span>
						</ngx-dropzone-label>
					</ngx-dropzone-preview>
					<!--      Already Uploaded Previews-->
					<ngx-dropzone-preview
						class="border border-black relative"
						*ngFor="let file of uploadedFiles | archive : false"
						[removable]="true"
						(click)="downloadFile($event, file.documentHash)"
						(removed)="openDeleteModal(file)"
					>
						<ngx-dropzone-label class="text-black"
							>{{ file.documentName }} ({{ file.type }})
							<span class="absolute bottom-0 right-1 italic text-green">Uploaded</span>
						</ngx-dropzone-label>
					</ngx-dropzone-preview>
				</ngx-dropzone>
			</div>
			<div class="flex flex-col mx-4" *ngIf="(uploadedFiles | archive : true).length">
				<div class="font-medium">Archived</div>
				<a
					class="cursor-pointer my-2 underline"
					(click)="downloadFile($event, file.documentHash)"
					*ngFor="let file of uploadedFiles | archive : true"
				>
					{{ file.archiveTs | date : 'medium' }} {{ file.documentName }}
				</a>
			</div>
		</div>
	</mat-expansion-panel>
</div>
<!--Survey New File Preview-->
<div class="mt-4" *ngIf="maxFileLength == 1 && files.length">
	<ngx-dropzone-preview
		*ngFor="let f of files"
		[file]="f"
		[removable]="true"
		(removed)="onRemove(f)"
		(click)="stopPropagation($event)"
		class="flex justify-center cursor-default border border-black relative"
	>
		<ngx-dropzone-label class="text-black text-center">
			<span class="absolute top-0 left-1 italic text-gray-500">Survey Doc:</span>
			{{ f.name }} ({{ f.type }})
			<span class="absolute bottom-0 right-1 italic text-red">New</span>
		</ngx-dropzone-label>
	</ngx-dropzone-preview>
</div>
<!--Survey Uploaded File Preview-->
<div class="mt-4 flex" *ngIf="maxFileLength == 1 && (uploadedFiles | archive : false)?.length && this.subType == 'Survey'">
	<div class="flex flex-col">
		<div class="text-right" *ngIf="(uploadedFiles | archive : false).length">
			<button mat-icon-button (click)="openArchiveModal()" [matTooltip]="'Archive'"><mat-icon>archive</mat-icon></button>
		</div>
		<ngx-dropzone-preview
			*ngFor="let file of uploadedFiles | archive : false"
			[removable]="true"
			(click)="downloadFile($event, file.documentHash)"
			(removed)="openDeleteModal(file)"
			class="flex justify-center cursor-pointer border border-black relative"
		>
			<ngx-dropzone-label class="text-black text-center">
				<span class="absolute top-0 left-1 italic text-gray-500">Survey Doc:</span>
				{{ file.documentName }} ({{ file.type }})
				<span class="absolute bottom-0 right-1 italic text-green">Uploaded</span>
			</ngx-dropzone-label>
		</ngx-dropzone-preview>
	</div>
	<div class="flex flex-col mx-4" *ngIf="(uploadedFiles | archive : true).length">
		<div class="font-medium">Archived</div>
		<a
			class="cursor-pointer my-2 underline"
			(click)="downloadFile($event, file.documentHash)"
			*ngFor="let file of uploadedFiles | archive : true"
		>
			{{ file.archiveTs | date : 'medium' }} {{ file.documentName }}
		</a>
	</div>
</div>
